import Box from '@mui/material/Box'
import MiniSlide from '../helpers/MiniSlide'

type SlideListProps = { handleLayoutClick: IdClickFunction, currentMaster: SingleMaster | undefined, currentLayoutId: number }

function Layouts({ handleLayoutClick, currentMaster, currentLayoutId }: SlideListProps) {

  return (
    <Box sx={{ width: 1, display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
      {currentMaster && currentMaster.logicalMaster.layouts.map((layout: Layout, i: number) => (
        <Box sx={{ width: '200px', m: 1 }} key={String(i)}  >
          <MiniSlide
            onClickFunction={() => handleLayoutClick(String(i))}
            title={layout.name}
            thisId={String(i)}
            selectedId={String(currentLayoutId)}
            renderDetail={currentMaster.layoutRenderDetail[i]}
          />
        </Box>
      ))}
    </Box>
  )
}

export default Layouts