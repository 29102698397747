import { AmplifyUser } from '@aws-amplify/ui'
import { useState } from 'react'
import { Box, Paper, Typography, Button, CircularProgress } from "@mui/material"
import { useTheme } from '@mui/material/styles'
import { useAppSelector } from '../../store/hooks'
import { getSubscriptionOptions } from '../../store/subscriptionOptionSlice'
import { getCurrentAuthenticatedUser, getCurrentPlanId, getSubBaseInfo, getSubPaymentInfo } from '../../lib/auth'
import Grid from '@mui/material/Unstable_Grid2'
import { canCancel } from '../../lib/auth'
import { cancelSubscriptionCall } from '../../lib/restCalls'
import { useNavigate } from "react-router-dom"
import { profilePath, securePagesPath } from "../../lib/constants"
import SubCancelDialog from './SubCancelDialog'

type ActiveSubscriptionProps = { currentUser: AmplifyUser | undefined }

function ActiveSubscription({ currentUser }: ActiveSubscriptionProps) {
    const theme = useTheme()
    let navigate = useNavigate()
    const allSubscriptionSpecs = useAppSelector(getSubscriptionOptions)
    const userPlanId = getCurrentPlanId(currentUser)
    const subBaseInfo = getSubBaseInfo(currentUser)
    const subPaymentInfo = getSubPaymentInfo(currentUser)
    var subscriptionOption: SubscriptionOptionSpec | undefined = undefined
    if (userPlanId && userPlanId in allSubscriptionSpecs) subscriptionOption = allSubscriptionSpecs[userPlanId]
    const userCanCanel = canCancel(currentUser)

    const firstRowWidth = 5
    const secondRowWidth = 7

    // local state
    const [waitingForCancel, setWaitingForCancel] = useState(0)
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false)

    const handleCancel = async () => {
        setCancelDialogOpen(false)
        setWaitingForCancel(1)
        let { status } = await cancelSubscriptionCall()
        if (status !== 204) {
            alert('Cancelling subscription failed. Please try again later.')
            setWaitingForCancel(0)
            return
        }
        // Refreshing the user until webhook with cancelled Sub arrived
        const step = 5000
        const max = 60000
        var elapsed = 0
        var newUser: AmplifyUser | undefined = await getCurrentAuthenticatedUser()
        while (elapsed <= max && canCancel(newUser)) {
            newUser = await getCurrentAuthenticatedUser(true)
            if (canCancel(newUser)) {
                await new Promise(resolve => setTimeout(resolve, step))
                elapsed += step
                setWaitingForCancel(elapsed)
            }
        }
        if (canCancel(newUser)) alert('Cancellation takes longer than usual. If your subscription is not cancelled after 10 minutes and a refresh of the profile page, please contact support.')
        setWaitingForCancel(0)
        navigate(`/${securePagesPath}/${profilePath}`)
    }

    return (
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            <Box sx={{ maxWidth: 1000, minWidth: '60vw' }}>
                <Typography variant="h6" gutterBottom color={theme.palette.text.disabled} width={1}>
                    Your current subscription:
                </Typography>
                <Paper elevation={3} sx={{ width: 1, p: 2 }}>
                    {waitingForCancel === 0 ?
                        <Grid container columnSpacing={1} rowSpacing={0}>
                            <Grid container xs={12} sm={8}>
                                <Grid xs={firstRowWidth}>
                                    <Typography variant="h6" gutterBottom color='primary'>
                                        {subscriptionOption ? subscriptionOption.name : '-'}
                                    </Typography>
                                </Grid>
                                <Grid xs={secondRowWidth}>

                                </Grid>
                                <Grid xs={firstRowWidth}>
                                    <Typography variant="subtitle2" color='default' >
                                        Cycle:
                                    </Typography>
                                </Grid>
                                <Grid xs={secondRowWidth}>
                                    {subscriptionOption ?
                                        <Typography variant="body2" color='default' >
                                            {subscriptionOption.paymentPrice} {subscriptionOption.currencySymbol} every {subscriptionOption.intervalCount > 1 && subscriptionOption.intervalCount} {subscriptionOption.intervalUnitDisplayText}{subscriptionOption.intervalCount > 1 && 's'}
                                        </Typography>
                                        :
                                        <Typography variant="body2" color='default' >-</Typography>}
                                </Grid>
                                <Grid xs={firstRowWidth}>
                                    <Typography variant="subtitle2" color='default' >
                                        Status:
                                    </Typography>
                                </Grid>
                                <Grid xs={secondRowWidth}>
                                    <Typography variant="body2" color='default' >
                                        {subBaseInfo?.status === 'SUSPENDED' && 'paused'}{(subBaseInfo?.status !== 'SUSPENDED' && subBaseInfo?.status) && subBaseInfo?.status?.toLocaleLowerCase()}
                                    </Typography>
                                </Grid>
                                <Grid xs={firstRowWidth}>
                                    <Typography variant="subtitle2" color='default' >
                                        Next billing:
                                    </Typography>
                                </Grid>
                                <Grid xs={secondRowWidth}>
                                    <Typography variant="body2" color='default' >
                                        {subBaseInfo?.nextBillingDate ? subBaseInfo?.nextBillingDate.toISOString().split('T')[0] + " (UTC)" : '-'}
                                    </Typography>
                                </Grid>
                                <Grid xs={firstRowWidth}>
                                    {!subBaseInfo?.nextBillingDate &&
                                        <Typography variant="subtitle2" color='default' >
                                            Expires on:
                                        </Typography>}
                                </Grid>
                                <Grid xs={secondRowWidth}>
                                    {!subBaseInfo?.nextBillingDate &&
                                        <Typography variant="body2" color='default' >
                                            {subPaymentInfo?.payedUntilDate ? subPaymentInfo?.payedUntilDate.toISOString().split('T')[0] + " (UTC)" : '-'}
                                        </Typography>}
                                </Grid>
                            </Grid>
                            <Grid xs={12} sm={4} sx={{
                                display: 'flex', justifyContent: { xs: 'flex-start', sm: 'flex-end' },
                                alignItems: { xs: 'flex-start', sm: 'flex-end' }, mt: 4
                            }}>
                                <Button variant="outlined" color="error" disabled={!userCanCanel || waitingForCancel > 0}
                                    onClick={() => setCancelDialogOpen(true)}>
                                    {waitingForCancel === 0 ?
                                        'Cancel'
                                        :
                                        <CircularProgress />}
                                </Button>
                            </Grid>
                        </Grid>
                        :
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 1, height: 1, flexWrap: 'wrap' }}>
                            <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', mt: 5, mb: 2 }}>
                                <CircularProgress />
                            </Box>
                            <Box sx={{ width: 1, display: 'flex', justifyContent: 'center' }}>
                                <Typography variant="subtitle2" color={theme.palette.text.disabled} sx={{ textAlign: 'center' }}>
                                    {waitingForCancel < 10000 && 'Cancelling your subscription...'}
                                    {waitingForCancel >= 10000 && waitingForCancel < 20000 && 'Awaiting confirmation...'}
                                    {waitingForCancel >= 20000 && 'This takes a bit longer than usual. Thank you for your patience...'}
                                </Typography>
                            </Box>
                        </Box>
                    }
                </Paper>

            </Box >
            <SubCancelDialog handleCancel={handleCancel} handleClose={() => setCancelDialogOpen(false)} open={cancelDialogOpen} width={"50vw"} ></SubCancelDialog>
        </Box >
    )
}

export default ActiveSubscription