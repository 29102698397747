import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"

type TermsConditionsProps = { termsStatus: boolean, setTermsStatus: (s: boolean) => void }

function TermsConditions({ termsStatus, setTermsStatus }: TermsConditionsProps) {
  return (
    <div>
      <Dialog
        open={termsStatus}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Terms & conditions</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>
              <h3>General Terms and Conditions of Quasarfox OÜ (Limited)</h3>
              <br />
              <p>
                <h4>&sect;1 Applicability to Entrepreneurs and Definitions</h4>
              </p>
              <p>
                (1) The following General Terms and Conditions apply to all services between us and a consumer in their version valid at the time of ordering.
                <br /><br />
                A consumer is any natural person who enters into a legal transaction for purposes that predominantly cannot be attributed to their commercial or self-employed professional activity.
                <br /><br />
              </p>
              <p>
                <h4>&sect;2 Conclusion of Contract, Storage of Contract Text</h4>
              </p>
              <p>
                (1) The following regulations regarding the conclusion of the contract apply to the acquisition of a license for the digital services on www.protoslides.com.
                <br /> <br />
                (2) In the case of the conclusion of a contract, the contract is concluded with
                <br />

              </p>
              <div>
                <br />
                Quasarfox OÜ (Limited) <br />
                Sepapaja 6 <br />
                15551 Tallinn <br />
                Estonia <br />
                e-Business Register: 16257111 <br /><br />
              </div>
              <p>

                <br />
                (3) The consumer submits a binding contract offer by successfully completing the payment process provided on the website.
                <br /><br />
                By accepting the payment (Capture), we accept your offer. You will receive confirmation from our Payment Provider (PayPal).
                <br /><br />
                (4) The terms and conditions can be viewed at any time at https://www.protoslides.com/. For security reasons, your order data is no longer accessible via the internet.
                <br /> <br />
              </p>
              <p>
                <h4>&sect;3 Prices, Shipping Costs, Payment, Due Date</h4>
              </p>
              <p>
                (1) The prices indicated include the statutory value-added tax.
                <br /> <br />
                (2) The consumer has the option of payment via PayPal or credit card (Visa, Mastercard, both via Paypal).
                <br /><br /> <br />
              </p>
              <p>
                <h4>&sect;4 Delivery</h4>
              </p>
              <p>
                (1) Access to the digital services is usually granted within a few minutes.
                <br /> <br />

              </p><hr /><br />
              <p>
                <h4>&sect;5 Right of Withdrawal for the Customer as a Consumer</h4>
              </p>
              <p>
                <strong>
                  Right of Withdrawal for Consumers
                  <br />
                </strong>
                <br />
                Consumers have a right of withdrawal in accordance with the following provisions, whereby consumers are any natural persons who enter into a legal transaction for purposes that cannot be predominantly attributed to their commercial or self-employed professional activity:
                <br />
              </p>
              <p>
                <br />
                <strong>
                  Right of Withdrawal
                </strong>
                <br />     <br />
                You have the right to revoke this contract within fourteen days without giving reasons. This does not apply if you have already used the paid digital service.
                <br />
                The withdrawal period is fourteen days from the day the contract is concluded.
                <br />  <br />
                To exercise your right of withdrawal, you must inform us
              </p>
              <div>
                Quasarfox OÜ (Limited) <br />
                Sepapaja 6   <br />
                15551 Tallinn  <br />
                Estonia  <br />
                e-Business Register: 16257111   <br />
                Email: protoslides@quasarfox.com
              </div>
              <p>
                by means of a clear statement by email about your decision to withdraw from this contract.
                <br />    <br />
                To comply with the withdrawal period, it is sufficient to send the notification of the exercise of the right of withdrawal before the withdrawal period expires.
                <br /> <br />
                <strong>
                  Consequences of Withdrawal
                </strong>
                <br />  <br />
                If you withdraw from this contract, we will reimburse you for all payments that we have received from you without delay and at the latest within fourteen days from the day on which we received notification of your withdrawal from this contract. We will use the same means of payment for this repayment that you used for the original transaction, unless expressly agreed otherwise with you; in no case will you be charged fees for this repayment.
                <br /> <br /> <br /> <br />
                <strong>
                  End of Right of Withdrawal Information
                </strong>
                <br /> <br /> <hr />  <br /> <br />
              </p>
              <p>
                <h4>&sect;6 Warranty</h4>
              </p>
              <p>
                The statutory warranty regulations apply.
                <br /> <br />
              </p>
              <p>
                <h4>&sect;7 Contract Language</h4>
              </p>
              <p>
                The contract language is exclusively English.
                <br />  <br /> <br />
              </p>
              <p>
                Terms and Conditions as of Dec.2023
              </p>
              <p>
                <em>
                  <a href="http://www.agb.de">
                    Free Terms and Conditions
                  </a>
                  created by agb.de
                </em>
              </p>

            </div>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setTermsStatus(false) }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  )
}

export default TermsConditions
