import { framePaddingNarrow } from "../../../lib/constants"

export const textbox1Presets: Record<string, ElementPreset> = {
    DEATH_STAR:
    {
        displayName: 'Textbox with bottom icon',
        type: 'TEXTBOX1',
        framePadding: framePaddingNarrow,
        "box": {
            "height": 0.50,
            "left": 0.080,
            "top": 0.28,
            "width": 0.34
        },
        "content": {
            "body": {
                "richText": [
                    {
                        "children": [
                            {
                                "bold": false,
                                "italic": false,
                                "smallCaps": false,
                                "text": "The ",
                                "underline": false
                            },
                            {
                                "bold": true,
                                "italic": false,
                                "smallCaps": false,
                                "text": "Death Star II",
                                "underline": false
                            },
                            {
                                "bold": false,
                                "italic": false,
                                "smallCaps": false,
                                "text": " was the second Death Star battlestation to be constructed by the Galactic Empire. ",
                                "underline": false
                            }
                        ],
                        "type": "paragraph"
                    },
                    {
                        "children": [
                            {
                                "bold": false,
                                "italic": false,
                                "smallCaps": false,
                                "text": "",
                                "underline": false
                            }
                        ],
                        "type": "paragraph"
                    },
                    {
                        "children": [
                            {
                                "bold": false,
                                "italic": false,
                                "smallCaps": false,
                                "text": "It was the same size as its predecessor, measuring 160 kilometers in diameter, and was ",
                                "underline": false
                            },
                            {
                                "bold": true,
                                "italic": false,
                                "smallCaps": false,
                                "text": "built after the destruction of the first Death Star ",
                                "underline": false
                            },
                            {
                                "bold": false,
                                "italic": false,
                                "smallCaps": false,
                                "text": "at the Battle of Yavin",
                                "underline": false
                            },
                            {
                                "bold": true,
                                "italic": false,
                                "smallCaps": false,
                                "text": ".",
                                "underline": false
                            },
                            {
                                "bold": false,
                                "italic": false,
                                "smallCaps": false,
                                "text": " ",
                                "underline": false
                            }
                        ],
                        "type": "paragraph"
                    },
                    {
                        "children": [
                            {
                                "bold": false,
                                "italic": false,
                                "smallCaps": false,
                                "text": "",
                                "underline": false
                            }
                        ],
                        "type": "paragraph"
                    },
                    {
                        "children": [
                            {
                                "bold": false,
                                "italic": false,
                                "smallCaps": false,
                                "text": "Darth Sidious insisted on the reconstruction of the battlestation as it was an ",
                                "underline": false
                            },
                            {
                                "bold": true,
                                "italic": false,
                                "smallCaps": false,
                                "text": "integral part in his plan to destroy the Rebel Alliance",
                                "underline": false
                            },
                            {
                                "bold": false,
                                "italic": false,
                                "smallCaps": false,
                                "text": ", leaving the galaxy in the hands of the Empire.",
                                "underline": false
                            }
                        ],
                        "type": "paragraph"
                    }
                ]
            },
            "header": {
                "richText": [
                    {
                        "children": [
                            {
                                "bold": true,
                                "italic": false,
                                "smallCaps": false,
                                "text": "The second Death Star",
                                "underline": false
                            }
                        ],
                        "type": "paragraph"
                    }
                ]
            },
            "iconSettings": {
                "iconBox": {
                    "iconSource": {
                        "id": "NP_5289018",
                        "provider": "NP",
                        "providerId": "5289018",
                        "thumbnailUrl": "https://static.thenounproject.com/png/5289018-200.png"
                    }
                },
                "iconPosition": "CENTERCENTER",
                "iconSize": 4,
                "iconVisible": true
            }
        }

    },
    EMPTY:
    {
        displayName: 'Empty',
        type: 'TEXTBOX1',
        framePadding: framePaddingNarrow,
        "box": {
            "height": 0.3397018172350193,
            "left": 0.08000508750357278,
            "top": 0.27999459600735643,
            "width": 0.2660060975609756
        },
        "content": {
            "body": {
                "richText": [
                    {
                        "children": [
                            {
                                "bold": false,
                                "italic": false,
                                "smallCaps": false,
                                "text": "Text...",
                                "underline": false
                            }
                        ],
                        "type": "paragraph"
                    }
                ]
            },
            "header": {
                "richText": [
                    {
                        "children": [
                            {
                                "bold": true,
                                "italic": false,
                                "smallCaps": false,
                                "text": "Header",
                                "underline": false
                            }
                        ],
                        "type": "paragraph"
                    }
                ]
            },
            "iconSettings": {
                "iconBox": {
                    "iconSource": {
                        "id": "NP_698181",
                        "provider": "NP",
                        "providerId": "698181",
                        "thumbnailUrl": "https://static.thenounproject.com/png/698181-200.png"
                    }
                },
                "iconPosition": "TOPRIGHT",
                "iconSize": 4,
                "iconVisible": true
            }
        }

    }
}