import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box } from '@mui/material'
import DataStatementContent from './DataStatementContent'

type DataStatementProps = { dataStatementStatus: boolean, setDataStatementStatus: (s: boolean) => void }

function DataStatement({ dataStatementStatus, setDataStatementStatus }: DataStatementProps) {
  return (
    <Box>
      <Dialog open={dataStatementStatus} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <DataStatementContent />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setDataStatementStatus(false) }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default DataStatement