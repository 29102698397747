import { postSessionCall } from "./restCalls"

const sessionStorageName = 'protoslides_session'

export function getValidStorageSessionId() {
  // Check if 'session' object exists in localStorage
  const sessionData = localStorage.getItem(sessionStorageName)

  if (sessionData) {
    try {
      // Parse 'session' object from localStorage
      const session = JSON.parse(sessionData) as SessionDataType

      // Get current time and calculate difference in milliseconds
      const currentTime = new Date().getTime()
      const sessionCreationTime = new Date(session.creationTimestamp).getTime()
      const days = 3
      const limitDaysInMillis = days * 24 * 60 * 60 * 1000 // days in milliseconds

      // Check if session is not more than x days old
      if (currentTime - sessionCreationTime <= limitDaysInMillis) {
        return session.sessionId
      } else {
        return undefined
      }
    } catch (error) {
      return undefined
    }
  } else return undefined
}

export async function checkSession() {
  // check for valid session
  let existingSessionId = getValidStorageSessionId()
  if (existingSessionId) return existingSessionId

  // If session doesn't exist or is older than 3 days, create a new session in BE
  const response = await postSessionCall()

  // after the rest call check for valid session in storage again that might have been created in parallel
  existingSessionId = getValidStorageSessionId()
  if (existingSessionId) { return existingSessionId }

  // if not we work with the response from the restcall
  if (response.status === 200 && response.sessionData) {
    // Store new session in localStorage
    localStorage.setItem(sessionStorageName, JSON.stringify(response.sessionData))
    return response.sessionData.sessionId
  }
  return undefined
}
