import { useState } from 'react'
import Box from '@mui/material/Box'
import MiniSlide from '../helpers/MiniSlide'
import MasterDetail from './MasterDetail'
import FileDropzone from '../helpers/FileDropzone'
import Fade from '@mui/material/Fade'


type MastersProps = {
  handleMasterClick?: IdClickFunction,
  handleNewMasterFile?: (file: File) => void,
  handleMasterDelete?: IdClickFunction,
  masters: Masters, currentMasterId?: string,
  mastersInAnalysis: MasterAnalysisLoadingStateType[],
  handleDefaultLayoutChange?: (masterId: string, defaultLayout: number) => void,
  handleMasterMainColChange?: (masterId: string, primXmlKey: string | null, neuXmlKey: string | null) => void,
}

function Masters({ handleMasterClick, handleNewMasterFile, handleMasterDelete, masters, currentMasterId, mastersInAnalysis, handleDefaultLayoutChange, handleMasterMainColChange }: MastersProps) {
  // local state
  const [detailsVisible, setDetailsVisible] = useState(false)
  const [clickedMasterId, setClickedMasterId] = useState<undefined | string>(undefined)
  const tileWidth = '200px'

  // Activating the detail view for a master
  const handleMasterDetailActivation = (masterId: string) => {
    setDetailsVisible(true)
    setClickedMasterId(masterId)
  }

  // Activating the detail view for a master
  const handleMasterDeleteClick = (masterId: string | undefined) => {
    setDetailsVisible(false)
    if (handleMasterDelete) handleMasterDelete(masterId)
  }

  const masterArray = (Object.values(masters) as Array<SingleMaster>)

  return (
    <Box sx={{ width: 1, display: 'grid', gridTemplateColumns: '1fr' }}>

      {/* masters */}
      <Box sx={{ width: 1, gridColumnStart: 1, gridRowStart: 1 }}>
        <Fade in={!detailsVisible}>
          <Box sx={{ width: 1, display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
            {handleNewMasterFile && handleMasterDelete &&
              <Box sx={{ width: tileWidth, m: 1 }} key={String(-1)} >
                <FileDropzone handleFile={handleNewMasterFile} accept={{ 'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'] }} />
              </Box>
            }

            {masters && masterArray.map((master: SingleMaster, i: number) => (
              <Box sx={{ width: tileWidth, m: 1 }} key={master.logicalMaster.masterId}>
                <MiniSlide
                  onClickFunction={handleMasterClick ? () => handleMasterClick(master.logicalMaster.masterId) : () => handleMasterDetailActivation(master.logicalMaster.masterId)}
                  title={master.logicalMaster.name}
                  thisId={master.logicalMaster.masterId}
                  selectedId={currentMasterId}
                  renderDetail={master.layoutRenderDetail[0]}
                />
              </Box>
            ))}

            {mastersInAnalysis.map((masterInAnalysis: MasterAnalysisLoadingStateType, i: number) => (
              <Box sx={{ width: tileWidth, m: 1 }} key={masterInAnalysis.masterId}>
                <MiniSlide
                  title={masterInAnalysis.filename}
                />
              </Box>
            ))}
          </Box>
        </Fade>
      </Box>

      {/* details with layouts & delete */}
      <Box sx={{ width: 1, gridColumnStart: 1, gridRowStart: 1 }}>
        <Fade in={detailsVisible} >
          <div>
            <MasterDetail masters={masters} handleMasterDetailClose={() => setDetailsVisible(false)} currentMasterId={clickedMasterId}
              handleMasterDelete={handleMasterDeleteClick} handleDefaultLayoutChange={handleDefaultLayoutChange} handleMasterMainColChange={handleMasterMainColChange} />
          </div>
        </Fade>
      </Box>
    </Box>
  )
}

export default Masters