import { createSlice, createSelector } from '@reduxjs/toolkit'
import { RootState } from './store'

export interface DisplayControlsState {
  selectedElements: string[],
  activeElements: string[]
}

const initialState: DisplayControlsState = { selectedElements: [], activeElements: [] }
type DisplayControlsStateActionSelection = { payload: { elementId: string } }
type DisplayControlsStateActionActivation = DisplayControlsStateActionSelection

// slice definition
export const displayControlsSlice = createSlice({
  name: 'displayControls',
  initialState,
  reducers: {
    setSelectedElements: (state, action: { payload: { selectedElements: string[] } }) => {
      state.selectedElements = action.payload.selectedElements
    },
    addSelectedElement: (state, action: DisplayControlsStateActionSelection) => {
      if (!(state.selectedElements.includes(action.payload.elementId))) {
        state.selectedElements.unshift(action.payload.elementId)
      }
    },
    removeSelectedElement: (state, action: DisplayControlsStateActionSelection) => {
      if (state.selectedElements.includes(action.payload.elementId)) {
        state.selectedElements = state.selectedElements.filter(i => i !== action.payload.elementId)
      }
    },
    setActiveElements: (state, action: { payload: { activeElements: string[] } }) => {
      state.activeElements = action.payload.activeElements
    },
    addActiveElement: (state, action: DisplayControlsStateActionActivation) => {
      if (!(state.activeElements.includes(action.payload.elementId))) {
        state.activeElements.unshift(action.payload.elementId)
      }
    },
    removeActiveElement: (state, action: DisplayControlsStateActionActivation) => {
      if (state.activeElements.includes(action.payload.elementId)) {
        state.activeElements = state.activeElements.filter(i => i !== action.payload.elementId)
      }
    },
  },
})

//actions
export const { setSelectedElements, addSelectedElement, removeSelectedElement, setActiveElements, addActiveElement, removeActiveElement } = displayControlsSlice.actions
//selectors TODO: Use imple selectors directly
export const getDisplayControls = (state: RootState) => state.displayControls
export const getSelectedElements = (state: RootState) => state.displayControls.selectedElements
export const getActiveElements = (state: RootState) => state.displayControls.activeElements
export const getElementSelectionState = createSelector(
  [(state: RootState, props: ElementIndex) => state.displayControls,
  (state: RootState, props: ElementIndex) => props.elementId],
  (displayControls, elementId) => displayControls.selectedElements.includes(elementId)
)
export const getElementActivationState = createSelector(
  [(state: RootState, props: ElementIndex) => state.displayControls,
  (state: RootState, props: ElementIndex) => props.elementId],
  (displayControls, elementId) => displayControls.activeElements.includes(elementId)
)

export default displayControlsSlice.reducer
