import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './store'

// Type definitions
const initialState: LoadingState = { MASTERANALYSIS: [] }
type LoadingStatePayload = { payload: LoadingState }
type LoadingStateMasterAnalysisPayload = { payload: MasterAnalysisLoadingStateType }
type LoadingStateMasterAnalysisCleanPayload = { payload: { masterIdsToRemove: string[] } }

// slice definition
export const loadingStateSlice = createSlice({
  name: 'loadingState',
  initialState,
  reducers: {
    setLoadingState: (state, action: LoadingStatePayload) => {
      return action.payload
    },
    addMasterAnalysisLoadingState: (state, action: LoadingStateMasterAnalysisPayload) => {
      state.MASTERANALYSIS.push(action.payload)
    },
    cleanMasterAnalysisLoadingStates: (state, action: LoadingStateMasterAnalysisCleanPayload) => {
      // remove old entries & entries appearing in provided masterId list
      const currentTime = Date.now()
      state.MASTERANALYSIS = state.MASTERANALYSIS.filter(obj => {
        const diffInSeconds = (currentTime - obj.starttime) / 1000 // Calculate difference in seconds
        // only keep entries if younger than 180 seconds and not in the masterIdsToRemove list 
        return diffInSeconds <= 180 && !action.payload.masterIdsToRemove.includes(obj.masterId)
      })
    },
  },
})

//actions
export const { setLoadingState, addMasterAnalysisLoadingState, cleanMasterAnalysisLoadingStates } = loadingStateSlice.actions

// selectors
export const getLoadingState = (state: RootState) => state.loadingState

export default loadingStateSlice.reducer
