import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './store'
import { initPresentationSlideSelection } from '../lib/init'

// Type definitions
const initialState: PresentationSelection = initPresentationSlideSelection
type PresentationSelectionPayload = { payload: PresentationSelection }

// slice definition
export const presentationSelectionSlice = createSlice({
  name: 'presentationSelection',
  initialState,
  reducers: {
    setPresentationSelection: (state, action: PresentationSelectionPayload) => {
      return action.payload
    },
    setCurrentPresentation: (state, action: { payload: string | undefined }) => {
      state.currentPresentationId = action.payload
    },
    setCurrentSlide: (state, action: { payload: string | undefined }) => {
      state.currentSlideId = action.payload
    },
    setPresentationPanelVisible: (state) => {
      state.presentationPanelVisible = true
      state.slidePanelVisible = false
    },
    setSlidePanelVisible: (state) => {
      state.presentationPanelVisible = false
      state.slidePanelVisible = true
    },
  },
})

//actions
export const { setPresentationSelection, setCurrentPresentation, setCurrentSlide, setPresentationPanelVisible, setSlidePanelVisible } = presentationSelectionSlice.actions

// selectors
// TODO: Look into correct selector structure for multiple component usage of same selector (https://react-redux.js.org/api/hooks)
export const getPresentationSelection = (state: RootState) => state.presentationSelection
export const getPresentationPanelVisible = (state: RootState) => state.presentationSelection.presentationPanelVisible
export const getSlidePanelVisible = (state: RootState) => state.presentationSelection.slidePanelVisible
export const getCurrentPresentationId = (state: RootState) => state.presentationSelection.currentPresentationId
export const getCurrentSlideId = (state: RootState) => state.presentationSelection.currentSlideId

export default presentationSelectionSlice.reducer
