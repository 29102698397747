import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { hexToRgba } from '../../lib/colors'
import Tooltip from '@mui/material/Tooltip'
import { CircularProgress, Skeleton } from '@mui/material'

type MiniSlideProps = {
  onClickFunction?: IdClickFunction, title?: string, thisId?: string | undefined,
  selectedId?: string | undefined, renderDetail?: SlideRenderDetail | undefined
}

function MiniSlide({ onClickFunction, title, thisId, selectedId, renderDetail }: MiniSlideProps) {
  const theme = useTheme()
  const titleHeight = '1.3em'

  return (
    <Paper elevation={1} sx={{
      display: 'grid', gridTemplateColumns: '1fr', boxSizing: 'border-box', alignContent: 'space-between',
      width: 1, cursor: "pointer", position: "relative", overflow: "hidden", minWidth: '5px', minHeight: '5px'
    }}
      onClick={onClickFunction ? (event) => onClickFunction(thisId) : undefined}>

      {/* First grid row */}

      {/* Image Box and prevent the image from being clickable */}
      <Box sx={{
        gridColumnStart: 1, gridRowStart: 1,
        width: 1, height: 1, zIndex: 1,
        paddingBottom: '56.25%' //make total height at least 16/9 
      }}></Box>

      {renderDetail ? // the image
        (renderDetail.slideImgB64 ?
          <Box sx={{ gridColumnStart: 1, gridRowStart: 1, position: "relative", width: 1, zIndex: 0 }}>
            <img src={`data:image/${renderDetail.renderFormat};base64,${renderDetail.slideImgB64}`} alt="Slide"
              style={{ display: "block", width: "100%" }} />
          </Box> :
          <Skeleton variant="rectangular" sx={{ gridColumnStart: 1, gridRowStart: 1, position: "relative", width: 1, zIndex: 0, paddingBottom: '56.25%' }}>

          </Skeleton>
        ) : // or a loading spinner
        <Box sx={{
          gridColumnStart: 1, gridRowStart: 1, position: "relative", width: 1,
          zIndex: 0, p: 1, pt: '', display: 'flex', justifyContent: 'center', alignItems: 'center'
        }}>
          <CircularProgress sx={{ height: 1 }} />
        </Box>
      }

      {/* Box with the selection frame */}
      <Box sx={{
        gridColumnStart: 1, gridRowStart: 1, gridRowEnd: 'span 2',
        width: 1, height: 1, top: 0, left: 0, zIndex: 2,
        ...((selectedId === thisId && thisId !== undefined) && { border: "solid 3px", borderColor: theme.palette.primary.main })
      }}></Box>

      {/* Second grid row */}

      {/* Title */}
      {title &&
        <Tooltip title={title}>
          <Box sx={{ gridColumnStart: 1, gridRowStart: 2, width: 1, pl: 1, pr: 1, height: titleHeight, zIndex: 3, display: "flex", justifyContent: "center", overflow: 'hidden' }}>
            <Typography variant="caption" sx={{ width: 1, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', textAlign: 'center' }}>
              {title}
            </Typography>
          </Box>
        </Tooltip>}

      {/* Title duplicated bg image */}
      {title && renderDetail &&
        <Box sx={{
          gridColumnStart: 1, gridRowStart: 2,
          width: 1, height: titleHeight, zIndex: 1, overflow: 'hidden',
        }}>
          <img src={`data:image/${renderDetail.renderFormat};base64,${renderDetail.slideImgB64}`} alt="Slide"
            style={{ zIndex: 1, display: "block", transform: 'scaleY(-1)', width: "100%" }} />
        </Box>}

      {/* Title separation line */}
      {/* {title &&
        <Box sx={{
          gridColumnStart: 1, gridRowStart: 2,
          width: 1, height: '1px', zIndex: 1,
          backgroundColor: hexToRgba(theme.palette.background.default, 0.),
          WebkitBackdropFilter: 'invert(10%) ' + theme.customStyling.blur,
          backdropFilter: 'invert(10%) ' + theme.customStyling.blur,
        }}></Box>} */}


      {/* Transparent, blurry background */}
      {title &&
        <Box sx={{
          gridColumnStart: 1, gridRowStart: 2,
          width: 1, height: titleHeight, zIndex: 1,
          backgroundColor: hexToRgba(theme.palette.background.default, 0.5),
          WebkitBackdropFilter: 'invert(15%) ' + theme.customStyling.blur,
          backdropFilter: 'invert(15%) ' + theme.customStyling.blur,
        }}></Box>}


    </Paper >
  )
}

export default MiniSlide