import { Auth } from '@aws-amplify/auth'
import { Theme as AmplifyTheme, defaultTheme } from '@aws-amplify/ui-react'
import { Theme as MuiThemeType } from '@mui/material/styles'
import { emptyUserStore } from '../lib/slideHandling'
import { AmplifyUser } from '@aws-amplify/ui'
import { Actions } from '../components/helpers/UseHistory'

export const canSubscribe = (currentUser: AmplifyUser | undefined) => {
  const subBaseInfo = getSubBaseInfo(currentUser)
  const subPaymentInfo = getSubPaymentInfo(currentUser)
  const tokenIatDate = getTokenIatDate(currentUser)
  // user should have iat date if he's logged in
  if (!tokenIatDate) return false

  // if user is in a payed period he cannot subscribe
  if (subPaymentInfo && subPaymentInfo.payedUntilDate && subPaymentInfo.payedUntilDate.getTime() >= tokenIatDate.getTime()) {
    return false
  }

  // if user has an active or suspended subscription he cannot subscribe
  if (subBaseInfo && subBaseInfo.status && ['ACTIVE', 'SUSPENDED'].includes(subBaseInfo.status)) {
    return false
  }

  // in all other cases he can subscribe
  return true
}

export const hasValidSubscription = (currentUser: AmplifyUser | undefined) => {
  const subBaseInfo = getSubBaseInfo(currentUser)
  const subPaymentInfo = getSubPaymentInfo(currentUser)
  const tokenIatDate = getTokenIatDate(currentUser)

  // user should have iat date if he's logged in
  if (!tokenIatDate) return false

  // if user is in a payed period he has a valid subscription
  if (subPaymentInfo && subPaymentInfo.payedUntilDate && subPaymentInfo.payedUntilDate.getTime() >= tokenIatDate.getTime()) {
    return true
  }

  // Special handling for faster Sub start: On subscription start day, an active subscription is sufficient without payment
  if (subBaseInfo && subBaseInfo.status && subBaseInfo.startDate &&
    subBaseInfo.status === 'ACTIVE' && subBaseInfo.startDate.getTime() === tokenIatDate.getTime()) {
    return true
  }

  // In all other cases user has no valid sub
  return false
}

export const getSubPaymentInfo = (currentUser: AmplifyUser | undefined) => {
  // user should have atttributes object and iat if he's logged in
  if (!currentUser?.attributes) return undefined
  // we need the name of the subscription attributes
  if (!process.env.REACT_APP_SUB_PAYED_UNTIL_ATTR_NAME) return undefined

  const payed_until_string = currentUser?.attributes['custom:' + process.env.REACT_APP_SUB_PAYED_UNTIL_ATTR_NAME]
  // Without payment string there's no payment info
  if (!payed_until_string) return undefined

  try {
    const payed_until_obj = JSON.parse(payed_until_string) as SubPaymentInfoBe
    var subPaymentInfo: SubPaymentInfo = {
      payedUntilDate: payed_until_obj.payed_until ? new Date(payed_until_obj.payed_until.split('T')[0]) : undefined,
      payedOnDate: payed_until_obj.payed_at ? new Date(payed_until_obj.payed_at.split('T')[0]) : undefined,
      subscriptionId: payed_until_obj.subscription_id ? payed_until_obj.subscription_id : undefined
    }
    return subPaymentInfo
  } catch (err) {
    return undefined
  }
}

export const getSubBaseInfo = (currentUser: AmplifyUser | undefined) => {
  // user should have atttributes object and iat if he's logged in
  if (!currentUser?.attributes) return undefined
  // we need the name of the subscription attributes
  if (!process.env.REACT_APP_SUB_BASE_INFO_ATTR_NAME) return undefined

  const base_info_string = currentUser?.attributes['custom:' + process.env.REACT_APP_SUB_BASE_INFO_ATTR_NAME]
  // Without base_info string there's no sub base info info
  if (!base_info_string) return undefined

  try {
    const base_info_obj = JSON.parse(base_info_string) as SubBaseInfoBe
    var subBaseInfo: SubBaseInfo = {
      status: base_info_obj.status ? base_info_obj.status : undefined,
      subscriptionId: base_info_obj.subscription_id ? base_info_obj.subscription_id : undefined,
      planId: base_info_obj.plan_id ? base_info_obj.plan_id : undefined,
      startDate: base_info_obj.start_time ? new Date(base_info_obj.start_time.split('T')[0]) : undefined,
      nextBillingDate: base_info_obj.next_billing_time ? new Date(base_info_obj.next_billing_time.split('T')[0]) : undefined,
    }
    return subBaseInfo
  } catch (err) {
    return undefined
  }
}

export const getCurrentPlanId = (currentUser: AmplifyUser | undefined) => {
  const subBaseInfo = getSubBaseInfo(currentUser)
  return subBaseInfo?.planId
}

export const getCurrentUserEmail = (currentUser: AmplifyUser | undefined) => {
  return currentUser?.attributes?.email
}

export const getTokenIatDate = (currentUser: AmplifyUser | undefined) => {
  // user should have atttributes object and iat if he's logged in
  if (!currentUser?.attributes) return undefined
  const iat = currentUser.getSignInUserSession()?.getIdToken().payload.iat
  if (!iat) return undefined

  try {
    //rounding away the daily seconds and multiply with 1000 to have ms for Date creation
    var iatDate = new Date(Math.floor(iat / (24 * 3600)) * (24 * 3600) * 1000)
    return iatDate
  } catch (err) {
    return undefined
  }
}

export const canCancel = (currentUser: AmplifyUser | undefined) => {
  const subBaseInfo = getSubBaseInfo(currentUser)

  // if user has an active or suspended subscription he can cancel
  if (subBaseInfo && subBaseInfo.status && ['ACTIVE', 'SUSPENDED'].includes(subBaseInfo.status)) {
    return true
  }

  // in all other cases he cannot cancel
  return false
}


export const performLogout = async (dispatch: any, histActions: Actions) => {
  emptyUserStore(dispatch, histActions)
  await Auth.signOut()
}

export const getJwtTokens = async () => {
  // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically
  try {
    const session = await Auth.currentSession()
    return { accessToken: session.getAccessToken().getJwtToken(), idToken: session.getIdToken().getJwtToken() }
  } catch (error: any) {
    return { accessToken: undefined, idToken: undefined }
  }
}

export async function getCurrentAuthenticatedUser(bypassCache = false) {
  try {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: bypassCache // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
    return user
  } catch (err) {
    return undefined
  }
};

export function parseJwt(token: string) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}


export function getAmplifyTheme(theme: MuiThemeType) {
  const amplifyTheme: AmplifyTheme = {
    name: 'amplifyTheme',
    tokens: {
      colors: {
        brand: {
          primary: {
            10: { value: theme.palette.primary.dark },
            20: { value: theme.palette.primary.dark },
            40: { value: theme.palette.primary.main },
            80: { value: theme.palette.primary.main },
            90: { value: theme.palette.primary.light },
            100: { value: theme.palette.primary.light },
          },
          secondary: {
            10: { value: theme.palette.secondary.dark },
            20: { value: theme.palette.secondary.dark },
            40: { value: theme.palette.secondary.main },
            80: { value: theme.palette.secondary.main },
            90: { value: theme.palette.secondary.light },
            100: { value: theme.palette.secondary.light },
          }
        },
        font: {
          primary: { value: theme.palette.text.primary },
        },
        neutral: {
          10: defaultTheme.tokens.colors.neutral[100],
          20: defaultTheme.tokens.colors.neutral[90],
          40: defaultTheme.tokens.colors.neutral[80],
          80: defaultTheme.tokens.colors.neutral[40],
          90: defaultTheme.tokens.colors.neutral[20],
          100: defaultTheme.tokens.colors.neutral[10],
        },
        black: { value: theme.palette.text.primary },
        white: { value: theme.palette.background.default },
      },
    },
  }
  return amplifyTheme
}