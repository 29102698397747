import Menu from '@mui/material/Menu'
import { PopoverOrigin } from '@mui/material'

interface ContextMenuProps {
    children: React.ReactNode, menuOpen: boolean, menuAnchorEl: null | HTMLElement, handleClose: () => void,
    transformOrigin: PopoverOrigin | undefined, anchorOrigin: PopoverOrigin | undefined, pointerPosition: 'left' | 'right' | 'center',
    bottomPointer?: undefined | boolean
}

function ContextMenu({ children, menuOpen, menuAnchorEl, handleClose, transformOrigin, anchorOrigin, pointerPosition, bottomPointer }: ContextMenuProps) {
    const pointerSize = 10
    const pointerRight = (pointerPosition === 'right') ? '14px' : undefined
    let pointerLeft = (pointerPosition === 'left') ? '14px' : undefined
    if (pointerPosition === 'center') { pointerLeft = `calc(50% - ${pointerSize / 2}px)` }

    return (
        <Menu
            anchorEl={menuAnchorEl}
            open={menuOpen}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: bottomPointer ? undefined : 1.5,
                    mb: bottomPointer ? 1.5 : undefined,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: bottomPointer ? undefined : 0,
                        bottom: bottomPointer ? 0 : undefined,
                        right: pointerRight,
                        left: pointerLeft,
                        width: pointerSize,
                        height: pointerSize,
                        bgcolor: 'background.paper',
                        transform: bottomPointer ? 'translateY(50%) rotate(45deg)' : 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={transformOrigin}
            anchorOrigin={anchorOrigin}
        >
            {children}
        </Menu>
    )
}

export default ContextMenu