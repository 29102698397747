import { framePaddingNarrow } from "../../../lib/constants"

export const numeration1Presets: Record<string, ElementPreset> = {
    FRENCH_PRESS_5:
    {
        displayName: 'French Press instructions (5)',
        titleText: 'How to brew coffee in a french press',
        type: 'NUMERATION1',
        framePadding: framePaddingNarrow,
        box: {
            height: 0.60,
            left: 0.29,
            top: 0.28,
            width: 0.41
        },
        "content": {
            "items": [
                {
                    "richText": [
                        {
                            "children": [
                                {
                                    "bold": true,
                                    "italic": false,
                                    "smallCaps": false,
                                    "text": "Measure and grind",
                                    "underline": false
                                }
                            ],
                            "type": "paragraph"
                        },
                        {
                            "children": [
                                {
                                    "bold": false,
                                    "italic": false,
                                    "smallCaps": false,
                                    "text": "Measure out 56g (about 8 Tablespoons) of coffee and grind it as coarse as breadcrumbs. Add the coffee to your warmed up French Press.",
                                    "underline": false
                                }
                            ],
                            "type": "paragraph"
                        }
                    ],
                },
                {
                    "richText": [
                        {
                            "children": [
                                {
                                    "bold": true,
                                    "italic": false,
                                    "smallCaps": false,
                                    "text": "Add water",
                                    "underline": false
                                }
                            ],
                            "type": "paragraph"
                        },
                        {
                            "children": [
                                {
                                    "bold": false,
                                    "italic": false,
                                    "smallCaps": false,
                                    "text": "Start your count-up timer as soon as you add hot water. Fill it up halfway to the top saturating all the grounds, making sure that there are no dry spots.",
                                    "underline": false
                                }
                            ],
                            "type": "paragraph"
                        }
                    ],
                },
                {
                    "richText": [
                        {
                            "children": [
                                {
                                    "bold": true,
                                    "italic": false,
                                    "smallCaps": false,
                                    "text": "Stir",
                                    "underline": false
                                }
                            ],
                            "type": "paragraph"
                        },
                        {
                            "children": [
                                {
                                    "bold": false,
                                    "italic": false,
                                    "smallCaps": false,
                                    "text": "After 1 minute, use a wooden spoon or spatula to break the top layer. Give it a good stir.",
                                    "underline": false
                                }
                            ],
                            "type": "paragraph"
                        }
                    ],
                },
                {
                    "richText": [
                        {
                            "children": [
                                {
                                    "bold": true,
                                    "italic": false,
                                    "smallCaps": false,
                                    "text": "Add more water",
                                    "underline": false
                                }
                            ],
                            "type": "paragraph"
                        },
                        {
                            "children": [
                                {
                                    "bold": false,
                                    "italic": false,
                                    "smallCaps": false,
                                    "text": "Now, fill it all the way to the top with water. Put the top on and allow the coffee to brew without pressing it down.",
                                    "underline": false
                                }
                            ],
                            "type": "paragraph"
                        }
                    ],
                },
                {
                    "richText": [
                        {
                            "children": [
                                {
                                    "bold": true,
                                    "italic": false,
                                    "smallCaps": false,
                                    "text": "Press & enjoy",
                                    "underline": false
                                }
                            ],
                            "type": "paragraph"
                        },
                        {
                            "children": [
                                {
                                    "bold": false,
                                    "italic": false,
                                    "smallCaps": false,
                                    "text": "After 4 minutes, you are ready to press. Firmly push the press all the way down and enjoy your coffee.",
                                    "underline": false
                                }
                            ],
                            "type": "paragraph"
                        }
                    ],
                }
            ]
        }
    },
    EMPTY_3:
    {
        displayName: 'Empty (3)',
        titleText: 'Title...',
        type: 'NUMERATION1',
        framePadding: framePaddingNarrow,
        "box": {
            "height": 0.3261678802933851,
            "left": 0.29684959969869473,
            "top": 0.2935285329489907,
            "width": 0.3399390243902439
        },
        "content": {
            "items": [
                {
                    "richText": [
                        {
                            "children": [
                                {
                                    "bold": false,
                                    "italic": false,
                                    "smallCaps": false,
                                    "text": "Item 1",
                                    "underline": false
                                }
                            ],
                            "type": "paragraph"
                        }
                    ]
                },
                {
                    "richText": [
                        {
                            "children": [
                                {
                                    "bold": false,
                                    "italic": false,
                                    "smallCaps": false,
                                    "text": "Item 2",
                                    "underline": false
                                }
                            ],
                            "type": "paragraph"
                        }
                    ]
                },
                {
                    "richText": [
                        {
                            "children": [
                                {
                                    "bold": false,
                                    "italic": false,
                                    "smallCaps": false,
                                    "text": "Item 3",
                                    "underline": false
                                }
                            ],
                            "type": "paragraph"
                        }
                    ]
                }
            ]
        }
    }
}