import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import SlideExample1 from '../../img/slide_example1.jpg'
import SlideExample2 from '../../img/slide_example2.jpg'
import SlideExample3 from '../../img/slide_example3.jpg'
import SlideExample4 from '../../img/slide_example4.jpg'
import SlideExample5 from '../../img/slide_example5.jpg'
import SlideExample6 from '../../img/slide_example6.jpg'
import SlideExample7 from '../../img/slide_example7.jpg'
import { Box, useTheme } from '@mui/material'

export default function QuiltedImageListBackground() {
    const theme = useTheme()

    const itemData = theme.palette.mode === 'light' ? itemDataLight : itemDataDark

    return (
        <Box sx={{ width: 1 }}>
            <ImageList
                sx={{ width: 1, transform: 'rotate(-20deg)', mt: 0, overflow: 'visible', opacity: 1 }}
                variant="quilted"
                cols={4}
                gap={16}
            >
                {itemData.map((item, count) => (
                    <ImageListItem
                        key={count}
                        cols={item.cols || 1}
                        rows={item.rows || 1}
                    >
                        <img
                            src={item.img}
                            alt={item.title}
                            style={{ boxShadow: '5px 5px 30px 5px rgba(0,0,0,0.1)' }}
                            loading="lazy"
                        />

                    </ImageListItem>
                ))}
            </ImageList>
        </Box>
    )
}

const itemDataDark = [
    {
        img: SlideExample6, // Local package image
        title: 'Slide Example 6',
        rows: 2,
        cols: 2,
    },
    {
        img: SlideExample6, // Local package image
        title: 'Slide Example 6',
    },
    {
        img: SlideExample6, // Local package image
        title: 'Slide Example 6',
    },
    {
        img: SlideExample6, // Local package image
        title: 'Slide Example 6',
    },
    {
        img: SlideExample6, // Local package image
        title: 'Slide Example 6',
    },
    {
        img: SlideExample6, // Local package image
        title: 'Slide Example 6',
    },
    {
        img: SlideExample6, // Local package image
        title: 'Slide Example 6',
    },
    {
        img: SlideExample6, // Local package image
        title: 'Slide Example 6',
        rows: 2,
        cols: 2,
    },
    {
        img: SlideExample6, // Local package image
        title: 'Slide Example 6',
        rows: 2,
        cols: 2,
    },
    {
        img: SlideExample6, // Local package image
        title: 'Slide Example 6',
    },
    {
        img: SlideExample6, // Local package image
        title: 'Slide Example 6',
    },
    {
        img: SlideExample6, // Local package image
        title: 'Slide Example 6',
    },
    {
        img: SlideExample6, // Local package image
        title: 'Slide Example 6',
        rows: 2,
        cols: 2,
    },
]

const itemDataLight = [
    {
        img: SlideExample4, // Local package image
        title: 'Slide Example 4',
        rows: 2,
        cols: 2,
    },
    {
        img: SlideExample5, // Local package image
        title: 'Slide Example 5',
    },
    {
        img: SlideExample1, // Local package image
        title: 'Slide Example 1',
    },
    {
        img: SlideExample7, // Local package image
        title: 'Slide Example 7',
    },
    {
        img: SlideExample2, // Local package image
        title: 'Slide Example 2',
    },
    {
        img: SlideExample4, // Local package image
        title: 'Slide Example 4',
    },
    {
        img: SlideExample3, // Local package image
        title: 'Slide Example 3',
    },
    {
        img: SlideExample2, // Local package image
        title: 'Slide Example 2',
        rows: 2,
        cols: 2,
    },
    {
        img: SlideExample1, // Local package image
        title: 'Slide Example 1',
        rows: 2,
        cols: 2,
    },
    {
        img: SlideExample5, // Local package image
        title: 'Slide Example 5',
    },
    {
        img: SlideExample2, // Local package image
        title: 'Slide Example 2',
    },
    {
        img: SlideExample4, // Local package image
        title: 'Slide Example 4',
    },
    {
        img: SlideExample4, // Local package image
        title: 'Slide Example 4',
        rows: 2,
        cols: 2,
    },
]