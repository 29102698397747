import { useEffect, useState, useRef } from 'react'
import Editor from '../containers/Editor'
import SecuredPages from '../containers/SecuredPages'
import LandingTables from '../landing/tables/LandingTables'
import Box from '@mui/material/Box'
import { Route, Routes } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { Auth } from '@aws-amplify/auth'
import { AmplifyUser } from '@aws-amplify/ui'
import { getCurrentAuthenticatedUser } from '../../lib/auth'
import _ from 'lodash'
import { securePagesPath, editorPath, profilePath } from "../../lib/constants"
import { initUserStore } from '../../lib/slideHandling'
import { useAppSelector, useAppDispatch } from '../../store/hooks'
import { performLogout } from '../../lib/auth'
import { getRenderingOptions } from '../../store/renderingOptionsSlice'
import Footer from '../containers/Footer'
import DataStatementContent from '../legal/DataStatementContent'
import { devlog, removeTrailingSlash, strBeforeFirstSlash } from '../../lib/misc'
import { getValidStorageSessionId } from '../../lib/noLoginSessions'
import { useOnlineStatus } from '../helpers/UseOnlineStatus'
import Landing from '../landing/general/Landing'
import { useHistContext } from '../helpers/UseHistory'

// Responsible for the highest level routing,
// observing the login state and performing the user data loads & store cleanups on login/logout

function TopLevelRouter() {
  const dispatch = useAppDispatch()
  let location = useLocation()
  const { histActions } = useHistContext()

  // state
  const renderingOptions = useAppSelector(getRenderingOptions)
  const [currentUser, setCurrentUser] = useState<AmplifyUser | undefined>(undefined)
  const currentUserRef = useRef<AmplifyUser | undefined>()
  currentUserRef.current = currentUser
  const [lastSessionId, setLastSessionId] = useState<string | undefined>(undefined)
  const lastSessionIdRef = useRef<string | undefined>()
  lastSessionIdRef.current = lastSessionId
  const [sessionStoreInitialized, setSessionStoreInitialized] = useState<boolean>(false)
  const sessionStoreInitializedRef = useRef<boolean>()
  sessionStoreInitializedRef.current = sessionStoreInitialized
  const isOnlineRef = useRef<boolean>()
  isOnlineRef.current = useOnlineStatus()


  // functions
  // check current user login state and changes and perform loading & required store actions
  async function updateOwner() {
    let logOut = false

    const newUser = await getCurrentAuthenticatedUser()
    // Did the user change?
    if (!_.isEqual(newUser, currentUserRef.current)) {
      // is the new user undefined --> there was a logout
      if (newUser === undefined) {
        await performLogout(dispatch, histActions)
        // init session store
        initUserStore(dispatch, renderingOptions, histActions)
        logOut = true
      } else
        // was the old user undefined --> there was a login
        if (currentUserRef.current === undefined) {
          devlog(newUser)
          initUserStore(dispatch, renderingOptions, histActions, true)
          window.gtag('event', 'login')
        }
      // update the use state
      setCurrentUser(newUser)
    }

    const newSessionId = getValidStorageSessionId()
    //If we have no logged in user
    if (newUser === undefined && !logOut) {
      // if there is no valid sessionId in browser storage we init the store
      if (newSessionId === undefined) {
        initUserStore(dispatch, renderingOptions, histActions)
        setSessionStoreInitialized(true)
        setLastSessionId(undefined)
      }
      // if there is a sessionId it might be from a browser reload (-> initUserStore) 
      // or just from continous usage (-> initUserStore if sessionId changed)
      else {
        if (lastSessionIdRef.current === undefined) { //browser reload
          if (!sessionStoreInitializedRef.current) { initUserStore(dispatch, renderingOptions, histActions) }
          setLastSessionId(newSessionId)
        } else {
          if (lastSessionIdRef.current !== newSessionId) { //changed by rest call
            initUserStore(dispatch, renderingOptions, histActions)
            setLastSessionId(newSessionId)
          }
        }
      }
    }
  }

  // hook to set auth config if location changes
  useEffect(() => {
    // configure the authenticator
    const cleanedCurrentHref = removeTrailingSlash(document.location.href.split("?")[0])
    const domain = strBeforeFirstSlash(cleanedCurrentHref)
    var redirect = cleanedCurrentHref
    if (!(redirect.endsWith(securePagesPath + '/' + editorPath) || redirect.endsWith(securePagesPath + '/' + profilePath))) redirect = domain + '/' + securePagesPath + '/' + profilePath
    Auth.configure({
      Auth: {
        region: process.env.REACT_APP_REGION,
        userPoolId: process.env.REACT_APP_USERPOOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USERPOOLCLIENT_ID,
        authenticationFlowType: 'USER_SRP_AUTH',
        oauth: {
          redirectSignIn: redirect,
          redirectSignOut: redirect,
          scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          responseType: 'code',
          domain: process.env.REACT_APP_USERPOOLDOMAIN + ".auth." + process.env.REACT_APP_REGION + ".amazoncognito.com"
        }
      }
    })
  }, [location])

  // onMount hook
  useEffect(() => {
    // getUser on first mount
    updateOwner()

    // defining the timer to check the user state
    const timerId = setInterval(() => {
      if (isOnlineRef.current) updateOwner()
    }, 500)

    // cleanup event listener and timer
    return () => {
      clearTimeout(timerId)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignContent: 'space-between', minHeight: '100vh' }}>
      <Box sx={{ width: 1 }}>
        <Routes>
          <Route path='/' element={<Landing currentUser={currentUser} />} />
          <Route path='/tables' element={<LandingTables currentUser={currentUser} />} />
          <Route path={editorPath} element={<Editor currentUser={currentUser} />} />
          <Route path={securePagesPath + '/*'} element={<SecuredPages currentUser={currentUser} />} />
          <Route path='/dataprivacy' element={<DataStatementContent />} />
        </Routes>
      </Box>
      <Box sx={{ width: 1, height: 1, mt: 8 }}>
        <Footer />
      </Box>
    </Box>
  )
}

export default TopLevelRouter
