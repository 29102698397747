import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import HeaderToolbar from '../../containers/HeaderToolbar'
import { AmplifyUser } from '@aws-amplify/ui'
import QuiltedImageListTables from './QuiltedImageListTables'
import ProductFeatures from '../ProductFeatures'
import { Palette, BlurOn, AutoAwesome, Apps, Lock } from '@mui/icons-material'
import { RiFileExcel2Line } from 'react-icons/ri'
import HeroTextTables from './HeroTextTables'
import { useEffect, useState } from 'react'
import SectionBackground from '../SectionBackground'
import { centeredFullWidthWrappingFlex } from '../../../lib/style'
import { hexToRgba } from '../../../lib/colors'

type LandingTablesProps = { currentUser: AmplifyUser | undefined }

//https://thefront.maccarianagency.com/
function LandingTables({ currentUser }: LandingTablesProps) {
  const theme = useTheme()
  const [heroHeight, setHeroHeight] = useState(window.innerHeight)

  useEffect(() => {
    const handleResize = () => {
      setHeroHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const sectionHeaderFontSize = 1.5
  const sectionSubtitleFontSize = 1.2
  const maxWidth = 1500
  const maxWidthHeader = 2000

  return (
    <Box sx={{ ...centeredFullWidthWrappingFlex, alignItems: 'flex-start', overflow: 'hidden' }}>

      {/* Header */}
      <HeaderToolbar currentUser={currentUser} maxWidth={maxWidthHeader} extraHoriPadding={true}>{ }</HeaderToolbar>

      {/* Hero section */}
      <Box sx={{ ...centeredFullWidthWrappingFlex, display: 'grid', gridTemplateColumns: '1fr' }}>

        {/* Bg gradient */}
        <Box sx={{ ...centeredFullWidthWrappingFlex, height: 1, gridColumnStart: 1, gridRowStart: 1 }}>
          <SectionBackground />
        </Box>

        {/* Herocontent */}
        <Box sx={{ ...centeredFullWidthWrappingFlex, gridColumnStart: 1, gridRowStart: 1 }}>
          <Box sx={{ ...centeredFullWidthWrappingFlex, maxWidth: maxWidth, ml: 3, mr: 3 }}>
            {/* Hero area shifting the lower countent out of the screen if screen height not too high but keeps a minimum height for the image*/}
            <Box sx={{
              justifyContent: 'center',
              width: 1, display: 'flex',
              minHeight: { xs: undefined, sm: heroHeight < 1000 ? `max(calc(${heroHeight}px - ${theme.customStyling.headerHeight}px), 800px)` : 800 },
              mb: heroHeight < 1000 ? undefined : 16
            }}>
              <Box sx={{
                ...centeredFullWidthWrappingFlex, mt: -12, width: { xs: '100%', sm: '55%' }, minHeight: 600, pr: { xs: 0, sm: 8 }
              }}>
                <HeroTextTables currentUser={currentUser} />
              </Box>
              <Box sx={{ position: 'relative', ml: 4, mr: -8, mt: -8, zIndex: -1, width: '45%', overflow: 'visible', display: { 'xs': 'none', 'sm': 'grid' }, gridTemplateColumns: '1fr' }}>
                <Box sx={{ position: 'absolute', width: 740, gridColumnStart: 1, gridRowStart: 1 }}>
                  <Box
                    sx={{
                      position: 'absolute', width: 1840, height: 940, borderRadius: '50%', left: -400, top: -100,
                      backgroundImage: `radial-gradient(${hexToRgba(theme.palette.primary.main, 0.5)}, transparent 70%)`,
                    }}
                  />
                </Box>
                <Box sx={{ position: 'absolute', width: 740, gridColumnStart: 1, gridRowStart: 1 }}>
                  <QuiltedImageListTables />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Features section */}
      <Box sx={{ ...centeredFullWidthWrappingFlex, mt: 8, display: 'grid', gridTemplateColumns: '1fr' }}>

        {/* Features content */}
        <Box sx={{ ...centeredFullWidthWrappingFlex, gridColumnStart: 1, gridRowStart: 1 }}>
          <Box sx={{ ...centeredFullWidthWrappingFlex, maxWidth: maxWidth, ml: 3, mr: 3 }}>
            {/* Title */}
            <Box sx={{ ...centeredFullWidthWrappingFlex }}>
              <Box sx={{ ...centeredFullWidthWrappingFlex, maxWidth: 1000 }}>
                <Typography variant="h6" sx={{ width: 1, textAlign: 'center', fontSize: { xs: `${sectionHeaderFontSize * 0.8}em`, sm: `${sectionHeaderFontSize}em` } }}>
                  Beatiful Powerpoint slides made easy
                </Typography>
                <Typography variant="body1" color={theme.palette.text.secondary} sx={{ width: 1, textAlign: 'center', fontSize: { xs: `${sectionSubtitleFontSize * 0.8}em`, sm: `${sectionSubtitleFontSize}em` } }}>
                  Project overviews, key fact sheets, hierarchies, icon grids, enumerations & more with icons and infographics at your fingertips.
                </Typography>
              </Box>
            </Box>

            <Box sx={{ width: 1, display: 'flex' }}>
              <ProductFeatures features={featuresList} />
            </Box>
          </Box>
        </Box>
      </Box>

    </Box >
  )
}

const iconSx = { width: '100%', height: '100% ' }

const featuresList = [
  {
    icon: <Palette sx={iconSx} />,
    subtitle: 'Your master',
    text: "Stick to your company's or client's corporate design by uploading and working in your Powerpoint master.",
  },
  {
    icon: <AutoAwesome sx={iconSx} />,
    subtitle: 'AI-Designer',
    text: 'Let Protoslides analyze your master palette & layouts to handle the color assignment for you.',
  },
  {
    icon: <Apps sx={iconSx} />,
    subtitle: 'Powerful & flexible slide components',
    text: "Focus on your content, not on shifting small shapes. Protoslide's flexible components will do the heavy lifting for you.",
  },
  {
    icon: <RiFileExcel2Line style={iconSx} />,
    subtitle: 'Paste from Excel',
    text: "Just copy & paste your Excel tables into your Protoslides table for a key-note ready presentation.",
  },
  {
    icon: <BlurOn sx={iconSx} />,
    subtitle: 'Blurred background style',
    text: "Component frames with iOS style background blur for layouts with background image.",
  },
  {
    icon: <Lock sx={iconSx} />,
    subtitle: 'Secure',
    text: "Data storage on servers in the European Union under one of the strictest data protection regulations in the world (GDPR).",
  },
]

export default LandingTables
