import { Placement } from "react-joyride"
import { useState, useEffect } from 'react'
import Joyride, { ACTIONS, EVENTS, STATUS, Step, CallBackProps } from 'react-joyride'
import { getUserGuideStyleOptions } from "./style"
import { Typography, useTheme } from "@mui/material"
import { contentStyle, contentVariant, slidePanelTutorialCompletedStorageKey } from "./misc"
import { AddCircle, ArrowUpward, MoreVert } from "@mui/icons-material"

export const steps: Step[] = [
    {
        title: 'Slide overview',
        target: '.left_drawer_handle',
        placement: 'right' as Placement,
        content: <Typography variant={contentVariant} style={contentStyle}>
            After selecting a presentation, you'll find <b>all slides within that presentation</b> in the slide panel on the left.
            You can always go back to the presentation overview, by clicking the <ArrowUpward sx={{ mb: -0.5, pt: 0.5 }} />- icon.
        </Typography>,
        disableBeacon: true
    },
    {
        title: 'Slide overview',
        target: '.presentation_actions_button',
        placement: 'right' as Placement,
        content: <Typography variant={contentVariant} style={contentStyle}>
            The <MoreVert sx={{ mb: -0.5, pt: 0.5, mr: -0.5 }} />- icon at the top gives you access to presentation settings:
            <ul>
                <li><b>Master:</b> Change the master of the current presentation.</li>
                <li><b>Style:</b> Change the style of the presentation. (fill style, rounded edges, ...)</li>
                <li><b>Typography:</b> Set the desired font size of normal text for the whole presentation.</li>
                <li><b>Download:</b> Download the presentation.</li>
                <li><b>Duplicate, delete</b></li>
            </ul>
        </Typography>,
        disableBeacon: true
    },
    {
        title: 'Select slide',
        target: '.slide_image',
        placement: 'right-start' as Placement,
        content: <Typography variant={contentVariant} style={contentStyle}>
            To select a slide and show it in the central main editor window, just click the desired thumbnail image.
        </Typography>,
        disableBeacon: true
    },
    {
        title: 'Slide settings',
        target: '.slide_actions_button',
        placement: 'right' as Placement,
        content: <Typography variant={contentVariant} style={contentStyle}>
            The <MoreVert sx={{ mb: -0.5, pt: 0.5, mr: -0.5 }} />- icon on the thumbnail image gives you access to slide settings:
            <ul>
                <li><b>Layout:</b> Select the slide layout to use from the current master of the presentation.</li>
                <li><b>Download:</b> Download the slide.</li>
                <li><b>Copy, paste, duplicate, delete</b></li>
            </ul>
        </Typography>,
        disableBeacon: true
    },
    {
        title: 'Create slides',
        content: <Typography variant={contentVariant} style={contentStyle}>
            You can add a slide with the <AddCircle sx={{ mb: -0.5, pt: 0.5 }} />- icon.
        </Typography>,
        target: '.create_slide_button',
        placement: 'right' as Placement,
        disableBeacon: true
    },
]


function SlidePanelTutorial() {
    const theme = useTheme()
    const [showJoyride, setShowJoyride] = useState<boolean>(false)
    const [stepIndex, setStepIndex] = useState<number>(0)

    const handleJoyrideCallback = async (data: CallBackProps) => {
        const { status, type, action, index } = data

        if (action === ACTIONS.NEXT && type === EVENTS.STEP_AFTER) {
            if (index === 0) gtag("event", "tutorial_begin")
            setStepIndex(stepIndex + 1)
        }

        if (action === ACTIONS.PREV && type === EVENTS.STEP_AFTER) {
            setStepIndex(stepIndex - 1)
        }

        if (action === ACTIONS.SKIP) {
            //Set a flag in local storage to indicate that the tutorial has been completed
            localStorage.setItem(slidePanelTutorialCompletedStorageKey, 'true')
            //Hide Joyride
            setShowJoyride(false)
        }
        if (status === STATUS.FINISHED) {
            gtag("event", "tutorial_complete")
            //Set a flag in local storage to indicate that the tutorial has been completed
            localStorage.setItem(slidePanelTutorialCompletedStorageKey, 'true')
            //Hide Joyride
            setShowJoyride(false)
        }
    }

    useEffect(() => {
        // Check local storage for the flag
        const joyrideCompleted = localStorage.getItem(slidePanelTutorialCompletedStorageKey)

        // Show Joyride only if the tutorial has not been completed
        setShowJoyride(!joyrideCompleted)
    }, [])

    return (
        <Typography variant={contentVariant} style={contentStyle}>
            {showJoyride && (
                <Joyride
                    steps={steps}
                    styles={{ options: getUserGuideStyleOptions(theme) }}
                    continuous
                    showProgress
                    showSkipButton
                    scrollToFirstStep
                    disableOverlay
                    hideCloseButton
                    disableCloseOnEsc
                    run={true}
                    callback={handleJoyrideCallback}
                    stepIndex={stepIndex}
                    disableScrolling
                />
            )}
            {/* Your main component content here */}
        </Typography>
    )
}

export default SlidePanelTutorial
