import RelRnd from '../RelRnd'
import { useState } from "react"
import Box from '@mui/material/Box'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import IconButton from '@mui/material/IconButton'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Grow from '@mui/material/Grow'
import { Descendant } from 'slate'
import { numeration1InitItemWordCount } from '../ElementDefinitions'
import { richLoremParagraph } from '../../../lib/text'
import _ from 'lodash'
import NeutralCircleButton from '../../helpers/NeutralCircleButton'
import Tooltip from '@mui/material/Tooltip'
import { ClickAwayListener, useTheme } from '@mui/material'
import RichTextPopover from '../../text/RichTextPopover'
import InElementBox from '../../helpers/InElementBox'

type NumerationProps = {
  el: LogicalElement, relRndProps: BasicRelRndProps, elementActive: boolean, elementSelected: boolean,
  updateElementContent: ElementContentChangeFunction
}

function Numeration1({ el, relRndProps, elementActive, elementSelected, updateElementContent }: Readonly<NumerationProps>) {
  const theme = useTheme()

  // localState
  const elementContent = el.content! as Numeration1Content
  const [activeItem, setActiveItem] = useState<number | undefined>(undefined)

  const deleteItem = (i: number) => {
    if (elementContent && relRndProps.currentSlideId && elementContent.items.length > 1) {
      setActiveItem(undefined)
      let newItems = [...elementContent.items]
      let newContent = { ...elementContent }
      newContent.items = newItems
      newContent.items.splice(i, 1)
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "APPEARANCE_CHANGE")
    }
  }

  const addItem = () => {
    if (elementContent && relRndProps.currentSlideId) {
      let newItems = [...elementContent.items]
      let newContent = { ...elementContent }
      newContent.items = newItems
      newContent.items.push({ richText: richLoremParagraph(numeration1InitItemWordCount) })
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "APPEARANCE_CHANGE")
    }
  }

  const handleItemChange = (newText: Descendant[], i: number) => {
    if (elementContent && relRndProps.currentSlideId && !_.isEqual(elementContent.items[i].richText, newText)) {
      let newItems = [...elementContent.items]
      let newContent = { ...elementContent }
      newContent.items = newItems
      newContent.items[i] = { ...newContent.items[i], richText: newText }
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "IMMEDIATE_ELEMENT_CONTENT_TYPING")
    }
  }

  const handleElementTitleTextChange = (newText: Descendant[]) => {
    if (relRndProps.handleElementTitleTextChange) relRndProps.handleElementTitleTextChange(el, newText)
  }

  const handleItemActivation = (myIndex: number | undefined, newIndex: number | undefined) => {
    // click away is only allowed if the current active index is the index of the ClickAwayActor/Listener
    if (myIndex === activeItem && newIndex === undefined) setActiveItem(undefined)
    if (newIndex !== undefined) setActiveItem(newIndex)
  }

  return (
    <RelRnd {...relRndProps} el={el} elementSelected={elementSelected} elementActive={elementActive} >
      <Box sx={{
        width: 1, height: 1, display: "flex", justifyContent: "center", flexWrap: "wrap", alignContent: "flex-start",
        alignItems: "flex-start", position: "relative",

      }}>
        {/* the element title */}
        {el.title?.showTitle && el.title?.richTextBox &&
          <RichTextPopover el={el} relRndProps={relRndProps} richTextBox={el.title?.richTextBox}
            handleBlur={handleElementTitleTextChange} textRole='TITLE' />}

        {elementContent.items.map((item, i: number) => (
          item.textBox && (
            <ClickAwayListener onClickAway={() => { handleItemActivation(i, undefined) }} key={String(i) + JSON.stringify(item.richText)}>
              <Box onClick={() => handleItemActivation(i, i)}>

                {/* the item text box */}
                <RichTextPopover el={el} relRndProps={relRndProps} richTextBox={item} textRole='BODY1'
                  handleBlur={(newText: Descendant[]) => { handleItemChange(newText, i) }}
                />

                {/* the item remove icons */}
                <Grow in={elementActive && activeItem === i} unmountOnExit>
                  <div>
                    <InElementBox el={el} relRndProps={relRndProps} box={{ ...item.textBox, left: item.textBox.left + item.textBox.width, width: 0.0 }} >
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pl: '30px', height: 1 }}>
                        <NeutralCircleButton size={30}>
                          <Tooltip title="Remove numeration element">
                            <IconButton onClick={(event) => { event.stopPropagation(); deleteItem(i) }} size="small" >
                              <DeleteForeverIcon fontSize="small" color="error" />
                            </IconButton>
                          </Tooltip>
                        </NeutralCircleButton>
                      </Box>
                    </InElementBox>
                  </div>
                </Grow>
              </Box>
            </ClickAwayListener>
          )
        ))}

        {/* the item add icon */}
        <Grow in={elementActive} unmountOnExit>
          <div>
            <InElementBox el={el} relRndProps={relRndProps} box={{ ...el.box, top: el.box.top + el.box.height, height: 0.0 }} >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pt: '5px', width: 1 }}>
                <NeutralCircleButton size={45}>
                  <Tooltip title="Add numeration element">
                    <IconButton onClick={(event) => addItem()} size="small" >
                      <AddCircleIcon sx={{ color: theme.palette.primary.main }} fontSize="large" />
                    </IconButton>
                  </Tooltip>
                </NeutralCircleButton>
              </Box>
            </InElementBox>
          </div>
        </Grow>

      </Box>
    </RelRnd>
  )
}

export default Numeration1
