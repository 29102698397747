import { createSlice, createSelector } from '@reduxjs/toolkit'
import { RootState } from './store'

// Type definitions
const initialState: Presentations = {}
type PresentationsPayload = { payload: Presentations }
type PresentationActionPresentationIndex = { payload: PresentationIndex }
type PresentationActionSinglePresentation = { payload: PresentationIndex & { singlePresentation: SinglePresentation } }
type PresentationActionRemoveSlide = { payload: PresentationIndex & { slideId: string | undefined } }
type PresentationActionSetMaster = { payload: PresentationIndex & { masterId: string | undefined } }

// slice definition
export const presentationsSlice = createSlice({
  name: 'presentations',
  initialState,
  reducers: {
    setPresentations: (state, action: PresentationsPayload) => {
      return action.payload
    },
    setSinglePresentation: (state, action: PresentationActionSinglePresentation) => {
      if (action.payload.presentationId) {
        state[action.payload.presentationId] = action.payload.singlePresentation
      }
    },
    removeSlideId: (state, action: PresentationActionRemoveSlide) => {
      if (action.payload.presentationId && action.payload.slideId) {
        state[action.payload.presentationId].slideIds = state[action.payload.presentationId].slideIds.filter(item => item !== action.payload.slideId)
      }
    },
    setMasterId: (state, action: PresentationActionSetMaster) => {
      if (action.payload.presentationId && action.payload.masterId) {
        state[action.payload.presentationId].masterId = action.payload.masterId
      }
    },
    removeSinglePresentation: (state, action: PresentationActionPresentationIndex) => {
      if (action.payload.presentationId) delete state[action.payload.presentationId]
    }
  },
})

//actions
export const { setPresentations, setSinglePresentation, removeSlideId, removeSinglePresentation } = presentationsSlice.actions

// selectors
// TODO: Look into correct selector structure for multiple component usage of same selector (https://react-redux.js.org/api/hooks)
export const getPresentations = (state: RootState) => state.presentations
export const getSinglePresentation = createSelector(
  [(state: RootState, props: PresentationIndex) => state.presentations,
  (state: RootState, props: PresentationIndex) => props.presentationId],
  (presentations, presentationId) => ((presentationId && presentationId in presentations) ? presentations[presentationId] : undefined)
)

export default presentationsSlice.reducer
