import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'

type PositionSelectorProps = {
    allowedPositions: PositionKey[],
    currentPosition: PositionKey | undefined,
    visible?: boolean,
    handleNewPosition: (newPositionKey: PositionKey) => void
}

function PositionSelector({ allowedPositions, currentPosition, handleNewPosition, visible }: PositionSelectorProps) {
    const theme = useTheme()
    if (visible === undefined) visible = true

    const innerBoxStyle = {
        position: 'relative',
        width: 'calc(100% - 100%/ 4)',
        height: 'calc(100% - 100%/ 4)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '8px',
        left: 'calc(100% / 8)',
        top: 'calc(100% / 8)',
        bgcolor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    }

    const deselectedCircleBgColor = theme.palette.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[600]

    const circleStyle = {
        width: 'calc(100% / 4)',
        height: 'calc(100% / 4)',
        borderRadius: '50%',
        position: 'absolute',
        cursor: 'pointer'
    }


    const isCircleVisible = (position: PositionKey) => allowedPositions.includes(position)

    return (
        <Box sx={{ position: 'relative', width: 1, height: 1 }}>
            <Box sx={innerBoxStyle}></Box>
            {isCircleVisible('TOPLEFT') &&
                <Box onClick={() => handleNewPosition('TOPLEFT')} sx={{ ...circleStyle, top: 0, left: 0, backgroundColor: visible && currentPosition === 'TOPLEFT' ? theme.palette.primary.main : deselectedCircleBgColor }} />}

            {isCircleVisible('TOPCENTER') &&
                <Box onClick={() => handleNewPosition('TOPCENTER')} sx={{ ...circleStyle, top: 0, left: '50%', transform: 'translateX(-50%)', backgroundColor: visible && currentPosition === 'TOPCENTER' ? theme.palette.primary.main : deselectedCircleBgColor }} />}

            {isCircleVisible('TOPRIGHT') &&
                <Box onClick={() => handleNewPosition('TOPRIGHT')} sx={{ ...circleStyle, top: 0, right: 0, backgroundColor: visible && currentPosition === 'TOPRIGHT' ? theme.palette.primary.main : deselectedCircleBgColor }} />}

            {isCircleVisible('CENTERLEFT') &&
                <Box onClick={() => handleNewPosition('CENTERLEFT')} sx={{ ...circleStyle, top: '50%', left: 0, transform: 'translateY(-50%)', backgroundColor: visible && currentPosition === 'CENTERLEFT' ? theme.palette.primary.main : deselectedCircleBgColor }} />}

            {isCircleVisible('CENTERCENTER') &&
                <Box onClick={() => handleNewPosition('CENTERCENTER')} sx={{ ...circleStyle, top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: visible && currentPosition === 'CENTERCENTER' ? theme.palette.primary.main : deselectedCircleBgColor }} />}

            {isCircleVisible('CENTERRIGHT') &&
                <Box onClick={() => handleNewPosition('CENTERRIGHT')} sx={{ ...circleStyle, top: '50%', right: 0, transform: 'translateY(-50%)', backgroundColor: visible && currentPosition === 'CENTERRIGHT' ? theme.palette.primary.main : deselectedCircleBgColor }} />}

            {isCircleVisible('BOTTOMLEFT') &&
                <Box onClick={() => handleNewPosition('BOTTOMLEFT')} sx={{ ...circleStyle, bottom: 0, left: 0, backgroundColor: visible && currentPosition === 'BOTTOMLEFT' ? theme.palette.primary.main : deselectedCircleBgColor }} />}

            {isCircleVisible('BOTTOMCENTER') &&
                <Box onClick={() => handleNewPosition('BOTTOMCENTER')} sx={{ ...circleStyle, bottom: 0, left: '50%', transform: 'translateX(-50%)', backgroundColor: visible && currentPosition === 'BOTTOMCENTER' ? theme.palette.primary.main : deselectedCircleBgColor }} />}

            {isCircleVisible('BOTTOMRIGHT') &&
                <Box onClick={() => handleNewPosition('BOTTOMRIGHT')} sx={{ ...circleStyle, bottom: 0, right: 0, backgroundColor: visible && currentPosition === 'BOTTOMRIGHT' ? theme.palette.primary.main : deselectedCircleBgColor }} />}
        </Box>
    )
}

export default PositionSelector