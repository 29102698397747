import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import Typography from '@mui/material/Typography'
import Layouts from '../slides/Layouts'
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import Palette from './Palette'
import { LoadingButton } from '@mui/lab'

type MasterDetailProps = {
  handleDefaultLayoutChange?: (masterId: string, defaultLayout: number) => void,
  handleMasterDetailClose: () => void,
  handleMasterDelete: IdClickFunction,
  handleMasterMainColChange?: (masterId: string, primXmlKey: string | null, neuXmlKey: string | null) => void,
  currentMasterId?: string | undefined,
  masters: Masters
}

function MasterDetail({ handleDefaultLayoutChange, handleMasterDelete, handleMasterDetailClose, currentMasterId, masters, handleMasterMainColChange }: MasterDetailProps) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const currentMaster = currentMasterId ? masters[currentMasterId] : undefined
  const currentLogiMaster = currentMaster?.logicalMaster

  // state
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [requestedPrimXml, setRequestedPrimXml] = useState<string | undefined | null>(undefined)
  const [requestedNeuXml, setRequestedNeuXml] = useState<string | undefined | null>(undefined)
  const [selectedPrimXml, setSelectedPrimXml] = useState<string | undefined | null>(currentLogiMaster?.primXmlKey)
  const [selectedNeuXml, setSelectedNeuXml] = useState<string | undefined | null>(currentLogiMaster?.neuXmlKey)

  const analyzing = ((requestedPrimXml !== undefined && requestedNeuXml !== undefined) && (requestedPrimXml !== currentLogiMaster?.primXmlKey || requestedNeuXml !== currentLogiMaster?.neuXmlKey))
  const buttonDisabled = ((selectedPrimXml === currentLogiMaster?.primXmlKey && selectedNeuXml === currentLogiMaster?.neuXmlKey) || analyzing)

  //when the master changes we reassign the selected states
  useEffect(() => {
    setSelectedNeuXml(currentLogiMaster?.neuXmlKey)
    setSelectedPrimXml(currentLogiMaster?.primXmlKey)
  }, [currentLogiMaster])

  const handleLayoutClick: IdClickFunction = (layoutId) => {
    if (layoutId !== undefined) {
      const layoutIdNumber = Number(layoutId)
      if (layoutIdNumber !== currentMaster?.logicalMaster.defaultLayout && handleDefaultLayoutChange && currentMaster)
        handleDefaultLayoutChange(currentMaster.logicalMaster.masterId, layoutIdNumber)
    }
  }


  const handleApplyClick = () => {
    if (currentMasterId && selectedPrimXml !== undefined && selectedNeuXml !== undefined && handleMasterMainColChange) {
      setRequestedPrimXml(selectedPrimXml)
      setRequestedNeuXml(selectedNeuXml)
      handleMasterMainColChange(currentMasterId, selectedPrimXml, selectedNeuXml)
    }
  }

  return (
    <Box sx={{ width: 1, height: 1 }} >

      {/* Top controls */}
      <Box sx={{ width: 1, display: "flex", justifyContent: "space-between", alignItems: "center" }} >
        <Tooltip title="Back to masters overview">
          <IconButton onClick={(event) => { handleMasterDetailClose() }} sx={{ flexGrow: 0 }} size="medium" >
            <ArrowBackIcon fontSize="medium" sx={{ color: theme.palette.primary.main }} />
          </IconButton>
        </Tooltip>
        <Tooltip title={currentMaster?.logicalMaster.name}>
          <Typography variant="subtitle1" sx={{ flexGrow: 1, textAlign: 'center', display: 'block', width: 1, maxWidth: '55vw' }} noWrap={true} color='text.secondary'>
            <b>Master Name: </b>{currentMaster?.logicalMaster.name}
          </Typography>
        </Tooltip>
        <Tooltip title="Delete master">
          <IconButton onClick={() => setDeleteDialogOpen(true)} sx={{ flexGrow: 0 }} size="medium" >
            <DeleteForeverIcon fontSize="medium" color="error" />
          </IconButton>
        </Tooltip>
      </Box>

      <Box sx={{ ml: 1, mt: 2, mb: 1 }} >
        <Typography variant="button" color='text.disabled' >Select main colors</Typography>
      </Box >

      <Box sx={{ width: 1, display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexWrap: 'wrap' }} >
        <Box sx={{ display: "flex", width: '300px', justifyContent: "flex-start", alignItems: "flex-start", ml: 1, flexWrap: 'wrap' }}>
          <Typography variant="subtitle2" sx={{ width: 1, mb: 1 }}>Primary: </Typography>
          <Palette masters={masters} currentMasterId={currentMasterId} selectedXMLKey={selectedPrimXml}
            handlePaletteClick={(s: string) => setSelectedPrimXml(s)} />
          <FormControlLabel control={
            <Checkbox size='medium' checked={selectedPrimXml === null} onChange={() => setSelectedPrimXml(null)} />}
            label="Automatic" />
        </Box>
        <Box sx={{ display: "flex", width: '300px', justifyContent: "flex-start", alignItems: "flex-start", ml: 1, flexWrap: 'wrap' }}>
          <Typography variant="subtitle2" sx={{ width: 1, mb: 1 }}>Neutral: </Typography>
          <Palette masters={masters} currentMasterId={currentMasterId} selectedXMLKey={selectedNeuXml}
            handlePaletteClick={(s: string) => setSelectedNeuXml(s)} />
          <FormControlLabel control={
            <Checkbox size='medium' checked={selectedNeuXml === null} onChange={() => setSelectedNeuXml(null)} />}
            label="Automatic" />
        </Box>
        <LoadingButton sx={{ mt: 2, ml: 1, width: 160 }} variant='contained' disabled={buttonDisabled}
          loading={analyzing} onClick={handleApplyClick} loadingPosition="start">
          {analyzing ? <span>Analyzing</span> : <span>Apply</span>}
        </LoadingButton>
      </Box>

      {/* selectedNeuXml: {String(selectedNeuXml)} <br />
      selectedPrimXml: {String(selectedPrimXml)}<br />
      requestedNeuXml: {String(requestedNeuXml)}<br />
      requestedPrimXml: {String(requestedPrimXml)}<br />
      master neuXmlKey: {String(currentLogiMaster?.neuXmlKey)}<br />
      master primXmlKey: {String(currentLogiMaster?.primXmlKey)}<br /> */}

      <Box sx={{ ml: 1, mt: 4, mb: 1 }} >
        <Typography variant="button" color='text.disabled' >Select default layout</Typography>
      </Box >

      {/* Layouts */}
      <Layouts handleLayoutClick={handleLayoutClick} currentMaster={currentMaster}
        currentLayoutId={currentMaster?.logicalMaster.defaultLayout !== undefined ? currentMaster?.logicalMaster.defaultLayout : 0} />

      {/* Deletion confirmation */}
      <Dialog
        fullScreen={fullScreen}
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xl"
      >
        <DialogTitle id="responsive-dialog-title">
          Confirm deletion
        </DialogTitle>
        <DialogContent>
          Do you really want to delete the master: <b>{currentMaster?.logicalMaster.name}</b>?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} autoFocus>
            Keep
          </Button>
          <Button color="error" onClick={() => { handleMasterDelete(currentMaster?.logicalMaster.masterId); setDeleteDialogOpen(false) }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </Box >
  )
}

export default MasterDetail