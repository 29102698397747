import RelRnd from '../RelRnd'
import Box from '@mui/material/Box'
import { Descendant } from 'slate'
import _ from 'lodash'
import ToggleButton from '@mui/material/ToggleButton'
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon'
import { StyledToggleButtonGroup } from '../../../lib/style'
import { defaultIcon, textBoxDefaultIconSettings } from '../ElementDefinitions'
import RichTextPopover from '../../text/RichTextPopover'
import IconSettings from '../../elements/IconSettings'
import { useState } from 'react'
import { Tooltip } from '@mui/material'
import InElementBox from '../../helpers/InElementBox'
import NoDragPopover from '../../helpers/NoDragPopover'


type TextBox1Props = {
  el: LogicalElement, relRndProps: BasicRelRndProps, elementActive: boolean, elementSelected: boolean,
  updateElementContent: ElementContentChangeFunction
}

function TextBox1({ el, relRndProps, elementActive, elementSelected, updateElementContent }: Readonly<TextBox1Props>) {

  // local state
  const [iconSettingsPopoverAnchorEl, setIconSettingsPopoverAnchorEl] = useState<EventTarget & HTMLElement | null>(null)

  // shortcuts
  const elementContent = el.content! as TextBox1Content
  const header = elementContent.header
  const body = elementContent.body
  const orgIcon: TextBoxIconSettings = elementContent.iconSettings || textBoxDefaultIconSettings
  const box = orgIcon.iconBox?.box
  const iconVisible = orgIcon.iconVisible
  const iconPosition = orgIcon.iconPosition || textBoxDefaultIconSettings.iconPosition
  const iconSize = orgIcon.iconSize || textBoxDefaultIconSettings.iconSize
  const currentIconSettings: TableCellIconSettings = {
    box: box, visible: iconVisible, position: iconPosition, size: iconSize,
    iconSource: orgIcon.iconBox?.iconSource || defaultIcon
  }

  const handleIconStateChange = (newIconSettings: TableCellIconSettings) => {
    if (elementContent && relRndProps.currentSlideId && !_.isEqual(currentIconSettings, newIconSettings)) {
      let newContent = { ...elementContent }
      newContent.iconSettings = {
        iconVisible: newIconSettings.visible, iconPosition: newIconSettings.position,
        iconSize: newIconSettings.size, iconBox: { box: box, iconSource: newIconSettings.iconSource }
      }
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "APPEARANCE_CHANGE")
    }
  }

  const handleHeaderChange = (newText: Descendant[]) => {
    if (elementContent && relRndProps.currentSlideId && !_.isEqual(elementContent.header.richText, newText)) {
      let newHeader = { ...elementContent.header, 'richText': newText }
      let newContent = { ...elementContent }
      newContent.header = newHeader
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "IMMEDIATE_ELEMENT_CONTENT_TYPING")
    }
  }

  const handleBodyChange = (newText: Descendant[]) => {
    if (elementContent && relRndProps.currentSlideId && !_.isEqual(elementContent.body.richText, newText)) {
      let newBody = { ...elementContent.body, 'richText': newText }
      let newContent = { ...elementContent }
      newContent.body = newBody
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "IMMEDIATE_ELEMENT_CONTENT_TYPING")
    }
  }

  const handleElementTitleTextChange = (newText: Descendant[]) => {
    if (relRndProps.handleElementTitleTextChange) relRndProps.handleElementTitleTextChange(el, newText)
  }

  const elementSpecificPanel = (
    <StyledToggleButtonGroup size="small" arial-label="element specific options">
      <Tooltip title="Icon settings">
        <ToggleButton selected={iconVisible} color="primary" onClick={(event) => setIconSettingsPopoverAnchorEl(event.currentTarget)} value="" id='text_box1_icon_button'>
          <InsertEmoticonIcon />
        </ToggleButton>
      </Tooltip>
    </StyledToggleButtonGroup>
  )

  return (
    <RelRnd {...relRndProps} el={el} elementSelected={elementSelected} elementActive={elementActive} elementSpecificPanel={elementSpecificPanel}>
      <Box sx={{
        width: 1, height: 1, display: "flex", justifyContent: "center", flexWrap: "wrap", alignContent: "flex-start",
        alignItems: "flex-start", position: "relative",

      }}>

        {/* the element title */}
        {el.title?.showTitle && el.title?.richTextBox &&
          <RichTextPopover el={el} relRndProps={relRndProps} richTextBox={el.title?.richTextBox}
            handleBlur={handleElementTitleTextChange} textRole='TITLE' />}

        {/* the element Header */}
        <RichTextPopover el={el} relRndProps={relRndProps} richTextBox={header} handleBlur={handleHeaderChange} textRole='HEADER1' />

        {/* the element Body */}
        <RichTextPopover el={el} relRndProps={relRndProps} richTextBox={body} handleBlur={handleBodyChange} textRole='BODY1' />

        {/* the clickable icon box to open the settings */}
        {iconVisible &&
          <InElementBox el={el} relRndProps={relRndProps} box={box} >
            <Box sx={{ width: 1, height: 1, cursor: 'pointer' }} onClick={() => { setIconSettingsPopoverAnchorEl(document.getElementById('text_box1_icon_button')) }}></Box>
          </InElementBox>}
      </Box>

      <NoDragPopover open={!!iconSettingsPopoverAnchorEl} anchorEl={iconSettingsPopoverAnchorEl} onClose={() => setIconSettingsPopoverAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Box sx={{ overflow: 'hidden' }}>
          <IconSettings
            allowedPositions={['TOPLEFT', 'TOPRIGHT', 'BOTTOMCENTER', 'CENTERCENTER']}
            allowedSizes={[{ displayName: 'small', size: 3 }, { displayName: 'medium', size: 4 }, { displayName: 'large', size: 5 }]} currentIconState={currentIconSettings}
            handleIconStateChange={handleIconStateChange} />
        </Box>
      </NoDragPopover>
    </RelRnd>
  )
}

export default TextBox1
