import { Link } from '@mui/material'
import React from 'react'


function DataStatementContent() {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <h2 id="m716">Preamble</h2>
      <p>
        With the following privacy policy we would like to inform you which types of
        your personal data (hereinafter also abbreviated as "data") we process for
        which purposes and in which scope. The privacy statement applies to all
        processing of personal data carried out by us, both in the context of
        providing our services and in particular on our websites, in mobile
        applications and within external online presences, such as our social media
        profiles (hereinafter collectively referred to as "online services").
      </p>
      <p>The terms used are not gender-specific.</p>
      <p>Last Update: 26. December 2023</p>
      <h2>Table of contents</h2>{" "}
      <ul className="index">
        <li>

          Preamble

        </li>
        <li>

          Controller

        </li>
        <li>

          Overview of processing operations

        </li>
        <li>

          Relevant legal bases

        </li>
        <li>

          Security Precautions

        </li>
        <li>

          Transmission of Personal Data

        </li>
        <li>

          International data transfers

        </li>
        <li>

          Erasure of data

        </li>
        <li>

          Rights of Data Subjects

        </li>
        <li>

          Use of Cookies

        </li>
        <li>

          Business services

        </li>
        <li>

          Providers and services used in the course of business

        </li>
        <li>

          Payment Procedure

        </li>
        <li>

          Provision of online services and web hosting

        </li>
        <li>

          Special Notes on Applications (Apps)

        </li>
        <li>

          Registration, Login and User Account

        </li>
        <li>

          Single Sign-on Authentication

        </li>
        <li>

          Cloud Services

        </li>
        <li>

          Commercial communication by E-Mail, Postal Mail, Fax or Telephone

        </li>
        <li>

          Web Analysis, Monitoring and Optimization

        </li>
        <li>

          Online Marketing

        </li>
        <li>

          Profiles in Social Networks (Social Media)

        </li>
        <li>

          Plugins and embedded functions and content

        </li>
        <li>

          Management, Organization and Utilities

        </li>
        <li>

          Terminology and Definitions

        </li>
      </ul>
      <h2 id="m3">Controller</h2>
      <p>
        Quasarfox OÜ
        <br />
        Sepapaja tn 6<br />
        15551 Tallinn
        <br />
        Harju Maakond, Estonia
      </p>
      E-mail address:{" "}
      <Link href="mailto:protoslides@quasarfox.com">protoslides@quasarfox.com</Link><br />
      Legal Notice: www.protoslides.com/dataprivacy
      <h2 id="mOverview">Overview of processing operations</h2>
      <p>
        The following table summarises the types of data processed, the purposes for
        which they are processed and the concerned data subjects.
      </p>
      <h3>Categories of Processed Data</h3>
      <ul>
        <li>Inventory data.</li>
        <li>Payment Data.</li>
        <li>Contact data.</li>
        <li>Content data.</li>
        <li>Contract data.</li>
        <li>Usage data.</li>
        <li>Meta, communication and process data.</li>
        <li>Event Data (Facebook).</li>
      </ul>
      <h3>Categories of Data Subjects</h3>
      <ul>
        <li>Customers.</li>
        <li>Employees.</li>
        <li>Prospective customers.</li>
        <li>Communication partner.</li>
        <li>Users.</li>
        <li>Business and contractual partners.</li>
      </ul>
      <h3>Purposes of Processing</h3>
      <ul>
        <li>
          Provision of contractual services and fulfillment of contractual
          obligations.
        </li>
        <li>Contact requests and communication.</li>
        <li>Security measures.</li>
        <li>Direct marketing.</li>
        <li>Web Analytics.</li>
        <li>Targeting.</li>
        <li>Office and organisational procedures.</li>
        <li>Remarketing.</li>
        <li>Conversion tracking.</li>
        <li>Clicktracking.</li>
        <li>Affiliate Tracking.</li>
        <li>Managing and responding to inquiries.</li>
        <li>Feedback.</li>
        <li>Marketing.</li>
        <li>Profiles with user-related information.</li>
        <li>Authentication processes.</li>
        <li>Provision of our online services and usability.</li>
        <li>Information technology infrastructure.</li>
      </ul>
      <h2 id="m2427">Relevant legal bases</h2>
      <p>
        <strong>Relevant legal bases according to the GDPR: </strong>In the
        following, you will find an overview of the legal basis of the GDPR on which
        we base the processing of personal data. Please note that in addition to the
        provisions of the GDPR, national data protection provisions of your or our
        country of residence or domicile may apply. If, in addition, more specific
        legal bases are applicable in individual cases, we will inform you of these
        in the data protection declaration.
      </p>
      <ul>
        <li>
          <strong>Consent (Article 6 (1) (a) GDPR)</strong> - The data subject has
          given consent to the processing of his or her personal data for one or
          more specific purposes.
        </li>
        <li>
          <strong>
            Performance of a contract and prior requests (Article 6 (1) (b) GDPR)
          </strong>{" "}
          - Performance of a contract to which the data subject is party or in order
          to take steps at the request of the data subject prior to entering into a
          contract.
        </li>
        <li>
          <strong>
            Compliance with a legal obligation (Article 6 (1) (c) GDPR)
          </strong>{" "}
          - Processing is necessary for compliance with a legal obligation to which
          the controller is subject.
        </li>
        <li>
          <strong>Legitimate Interests (Article 6 (1) (f) GDPR)</strong> -
          Processing is necessary for the purposes of the legitimate interests
          pursued by the controller or by a third party, except where such interests
          are overridden by the interests or fundamental rights and freedoms of the
          data subject which require protection of personal data.
        </li>
      </ul>
      <p>
        <strong>
          Relevant legal basis according to the Swiss Data Protection Act:{" "}
        </strong>
        If you are located in Switzerland, we process your data based on the Federal
        Data Protection Act (abbreviated as "Swiss DPA"). This also applies if our
        processing of your data otherwise affects you in Switzerland and you are
        affected by the processing. The Swiss DPA does not generally provide that a
        legal basis for the processing of personal data must be stated (unlike, for
        example, the GDPR). We process personal data only when the processing is
        lawful, is conducted in good faith, and is proportionate (Article 6 (1) and
        (2) of the Swiss DPA). Furthermore, we only collect personal data for a
        specific purpose that is recognisable to the person concerned and process it
        only in a manner that is compatible with these purposes (Article 6 (3) of
        the Swiss DPA).
      </p>
      <p>
        <strong>
          Reference to the applicability of the GDPR and the Swiss DPA:{" "}
        </strong>
        These privacy notices serve both to provide information in accordance with
        the Swiss Federal Act on Data Protection (Swiss DPA) and the General Data
        Protection Regulation (GDPR).
      </p>
      <h2 id="m27">Security Precautions</h2>
      <p>
        We take appropriate technical and organisational measures in accordance with
        the legal requirements, taking into account the state of the art, the costs
        of implementation and the nature, scope, context and purposes of processing
        as well as the risk of varying likelihood and severity for the rights and
        freedoms of natural persons, in order to ensure a level of security
        appropriate to the risk.
      </p>
      <p>
        The measures include, in particular, safeguarding the confidentiality,
        integrity and availability of data by controlling physical and electronic
        access to the data as well as access to, input, transmission, securing and
        separation of the data. In addition, we have established procedures to
        ensure that data subjects' rights are respected, that data is erased, and
        that we are prepared to respond to data threats rapidly. Furthermore, we
        take the protection of personal data into account as early as the
        development or selection of hardware, software and service providers, in
        accordance with the principle of privacy by design and privacy by default.
      </p>
      <p>
        TLS/SSL encryption (https): To protect the data of users transmitted via our
        online services, we use TLS/SSL encryption. Secure Sockets Layer (SSL) is
        the standard technology for securing internet connections by encrypting the
        data transmitted between a website or app and a browser (or between two
        servers). Transport Layer Security (TLS) is an updated and more secure
        version of SSL. Hyper Text Transfer Protocol Secure (HTTPS) is displayed in
        the URL when a website is secured by an SSL/TLS certificate.
      </p>
      <h2 id="m25">Transmission of Personal Data</h2>
      <p>
        In the context of our processing of personal data, it may happen that the
        data is transferred to other places, companies or persons or that it is
        disclosed to them. Recipients of this data may include, for example, service
        providers commissioned with IT tasks or providers of services and content
        that are embedded in a website. In such cases, the legal requirements will
        be respected and in particular corresponding contracts or agreements, which
        serve the protection of your data, will be concluded with the recipients of
        your data.
      </p>
      <h2 id="m24">International data transfers</h2>
      <p>
        Data Processing in Third Countries: If we process data in a third country
        (i.e., outside the European Union (EU) or the European Economic Area (EEA)),
        or if the processing is done within the context of using third-party
        services or the disclosure or transfer of data to other individuals,
        entities, or companies, this is only done in accordance with legal
        requirements. If the data protection level in the third country has been
        recognized by an adequacy decision (Article 45 GDPR), this serves as the
        basis for data transfer. Otherwise, data transfers only occur if the data
        protection level is otherwise ensured, especially through standard
        contractual clauses (Article 46 (2)(c) GDPR), explicit consent, or in cases
        of contractual or legally required transfers (Article 49 (1) GDPR).
        Furthermore, we provide you with the basis of third-country transfers from
        individual third-country providers, with adequacy decisions primarily
        serving as the foundation. "Information regarding third-country transfers
        and existing adequacy decisions can be obtained from the information
        provided by the EU Commission:{" "}
        <Link
          href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_en"
          target="_blank" rel="noreferrer"
        >
          https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_en.
        </Link>
      </p>
      <p>
        EU-US Trans-Atlantic Data Privacy Framework: Within the context of the
        so-called "Data Privacy Framework" (DPF), the EU Commission has also
        recognized the data protection level for certain companies from the USA as
        secure within the adequacy decision of 10th July 2023. The list of certified
        companies as well as additional information about the DPF can be found on
        the website of the US Department of Commerce at{" "}
        <Link href="https://www.dataprivacyframework.gov/" target="_blank" rel="noreferrer">
          https://www.dataprivacyframework.gov/.
        </Link>{" "}
        We will inform you which of our service providers are certified under the
        Data Privacy Framework as part of our data protection notices.
      </p>
      <p>
        Disclosure of Personal Data Abroad: In accordance with the Swiss Data
        Protection Act (DSG), we only disclose personal data abroad when an
        appropriate level of protection for the affected persons is ensured (Art. 16
        Swiss DSG). If the Federal Council does not determine that there is an
        adequate level of protection (list of states:{" "}
        <Link
          href="https://www.bj.admin.ch/bj/de/home/staat/datenschutz/internationales/anerkennung-staaten.html"
          target="_blank" rel="noreferrer"
        >
          https://www.bj.admin.ch/bj/de/home/staat/datenschutz/internationales/anerkennung-staaten.html
        </Link>
        ), we implement alternative security measures. These measures may include
        international agreements, specific guarantees, data protection clauses in
        contracts, standard data protection clauses approved by the Federal Data
        Protection and Information Commissioner (FDPIC), or internal company data
        protection regulations previously recognised by the FDPIC or a competent
        data protection authority of another country.
      </p>
      <p>
        Under Art. 16 of the Swiss DSG, exceptions can be made for the disclosure of
        data abroad if certain conditions are met, including the consent of the
        affected person, contract execution, public interest, protection of life or
        physical integrity, publicly made data or data from a legally provided
        register. Such disclosures always comply with the legal requirements.
      </p>
      <h2 id="m12">Erasure of data</h2>
      <p>
        The data processed by us will be erased in accordance with the statutory
        provisions as soon as their processing is revoked or other permissions no
        longer apply (e.g. if the purpose of processing this data no longer applies
        or they are not required for the purpose). If the data is not deleted
        because they are required for other and legally permissible purposes, their
        processing is limited to these purposes. This means that the data will be
        restricted and not processed for other purposes. This applies, for example,
        to data that must be stored for commercial or tax reasons or for which
        storage is necessary to assert, exercise or defend legal claims or to
        protect the rights of another natural or legal person. In the context of our
        information on data processing, we may provide users with further
        information on the deletion and retention of data that is specific to the
        respective processing operation.
      </p>
      <h2 id="m10">Rights of Data Subjects</h2>
      <p>
        Rights of the Data Subjects under the GDPR: As data subject, you are
        entitled to various rights under the GDPR, which arise in particular from
        Articles 15 to 21 of the GDPR:
      </p>
      <ul>
        <li>
          <strong>
            Right to Object: You have the right, on grounds arising from your
            particular situation, to object at any time to the processing of your
            personal data which is based on letter (e) or (f) of Article 6(1) GDPR,
            including profiling based on those provisions. Where personal data are
            processed for direct marketing purposes, you have the right to object at
            any time to the processing of the personal data concerning you for the
            purpose of such marketing, which includes profiling to the extent that
            it is related to such direct marketing.
          </strong>
        </li>
        <li>
          <strong>Right of withdrawal for consents:</strong> You have the right to
          revoke consents at any time.
        </li>
        <li>
          <strong>Right of access:</strong> You have the right to request
          confirmation as to whether the data in question will be processed and to
          be informed of this data and to receive further information and a copy of
          the data in accordance with the provisions of the law.
        </li>
        <li>
          <strong>Right to rectification:</strong> You have the right, in accordance
          with the law, to request the completion of the data concerning you or the
          rectification of the incorrect data concerning you.
        </li>
        <li>
          <strong>Right to Erasure and Right to Restriction of Processing:</strong>{" "}
          In accordance with the statutory provisions, you have the right to demand
          that the relevant data be erased immediately or, alternatively, to demand
          that the processing of the data be restricted in accordance with the
          statutory provisions.
        </li>
        <li>
          <strong>Right to data portability:</strong> You have the right to receive
          data concerning you which you have provided to us in a structured, common
          and machine-readable format in accordance with the legal requirements, or
          to request its transmission to another controller.
        </li>
        <li>
          <strong>Complaint to the supervisory authority:</strong> In accordance
          with the law and without prejudice to any other administrative or judicial
          remedy, you also have the right to lodge a complaint with a data
          protection supervisory authority, in particular a supervisory authority in
          the Member State where you habitually reside, the supervisory authority of
          your place of work or the place of the alleged infringement, if you
          consider that the processing of personal data concerning you infringes the
          GDPR.
        </li>
      </ul>
      <p>Rights of the data subjects under the Swiss DPA: </p>
      <p>
        As the data subject, you have the following rights in accordance with the
        provisions of the Swiss DPA:
      </p>
      <ul>
        <li>
          <strong>Right to information: </strong>You have the right to request
          confirmation as to whether personal data concerning you are being
          processed, and to receive the information necessary for you to assert your
          rights under the Swiss DPA&nbsp;and to ensure transparent data processing.
        </li>
        <li>
          <strong>Right to data release or transfer: </strong>You have the right to
          request the release of your personal data, which you have provided to us,
          in a common electronic format, as well as its transfer to another data
          controller, provided this does not require disproportionate effort.
        </li>
        <li>
          <strong>Right to rectification: </strong>You have the right to request the
          rectification of inaccurate personal data concerning you.
        </li>
        <li>
          <strong>Right to object, deletion, and destruction: </strong>You have the
          right to object to the processing of your data, as well as to request that
          personal data concerning you be deleted or destroyed.
        </li>
      </ul>
      <h2 id="m134">Use of Cookies</h2>
      <p>
        Cookies are small text files or other data records that store information on
        end devices and read information from the end devices. For example, to store
        the login status in a user account, the contents of a shopping cart in an
        e-shop, the contents accessed or the functions used. Cookies can also be
        used for various purposes, e.g. for purposes of functionality, security and
        convenience of online offers as well as the creation of analyses of visitor
        flows.
      </p>
      <p>
        <strong>Information on consent: </strong>We use cookies in accordance with
        the statutory provisions. Therefore, we obtain prior consent from users,
        except when it is not required by law. In particular, consent is not
        required if the storage and reading of information, including cookies, is
        strictly necessary in order to provide an information society service
        explicitly requested by the subscriber or user. Essential cookies usually
        include cookies with functions related to the display and operability of the
        onlineservice, load balancing, security, storage of users' preferences and
        choices or similar purposes related to the provision of the main and
        secondary functions of the onlineservice requested by users. The revocable
        consent will be clearly communicated to the user and will contain the
        information on the respective cookie use.
      </p>
      <p>
        <strong>Information on legal bases under data protection law: </strong>The
        legal basis under data protection law on which we process users' personal
        data with the use of cookies depends on whether we ask users for consent. If
        users consent, the legal basis for processing their data is their declared
        consent. Otherwise, the data processed with the help of cookies is processed
        on the basis of our legitimate interests (e.g. in a business operation of
        our online services and improvement of its usability) or, if this is done in
        the context of the fulfillment of our contractual obligations, if the use of
        cookies is necessary to fulfill our contractual obligations. For which
        purposes the cookies are processed by us, we do clarify in the course of
        this privacy policy or in the context of our consent and processing
        procedures.
      </p>
      <p>
        <strong>Retention period:</strong> With regard to the retention period, a
        distinction is drawn between the following types of cookies:
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>Temporary cookies (also known as "session cookies"):</strong>{" "}
          Temporary cookies are deleted at the latest after a user has left an
          online service and closed his or her end device (i.e. browser or mobile
          application).&nbsp;
        </li>
        <li>
          <strong>Permanent cookies</strong>: Permanent cookies remain stored even
          after the terminal device is closed. For example, the login status can be
          saved, or preferred content can be displayed directly when the user visits
          a website again. Likewise, user data collected with the help of cookies
          can be used for reach measurement. Unless we provide users with explicit
          information about the type and storage duration of cookies (e.g., as part
          of obtaining consent), users should assume that cookies are permanent and
          that the storage period can be up to two years.
        </li>
      </ul>
      <p>
        <strong>
          General notes on revocation and objection (so-called "Opt-Out"):{" "}
        </strong>
        Users can revoke the consents they have given at any time and object to the
        processing in accordance with legal requirements. Users can restrict the use
        of cookies in their browser settings, among other options (although this may
        also limit the functionality of our online offering). A objection to the use
        of cookies for online marketing purposes can also be made through the
        websites{" "}
        <Link href="https://optout.aboutads.info/" target="_new">
          https://optout.aboutads.info
        </Link>{" "}
        and{" "}
        <Link href="https://www.youronlinechoices.com/" target="_new">
          https://www.youronlinechoices.com/
        </Link>
        .
      </p>
      <ul className="m-elements">
        <li>
          <strong>Processed data types:</strong> Usage data (e.g. websites visited,
          interest in content, access times).
        </li>
        <li>
          <strong>Data subjects:</strong> Users (e.g. website visitors, users of
          online services).
        </li>
        <li>
          <strong>Purposes of Processing:</strong> Provision of our online services
          and usability.
        </li>
        <li className="">
          <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
          GDPR). Consent (Article 6 (1) (a) GDPR).
        </li>
      </ul>
      <p>
        <strong>
          Further information on processing methods, procedures and services used:
        </strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>Processing Cookie Data on the Basis of Consent: </strong>We use a
          cookie management solution in which users' consent to the use of cookies,
          or the procedures and providers mentioned in the cookie management
          solution, can be obtained, managed and revoked by the users. The
          declaration of consent is stored so that it does not have to be retrieved
          again and the consent can be proven in accordance with the legal
          obligation. Storage can take place server-sided and/or in a cookie
          (so-called opt-out cookie or with the aid of comparable technologies) in
          order to be able to assign the consent to a user or and/or his/her device.
          Subject to individual details of the providers of cookie management
          services, the following information applies: The duration of the storage
          of the consent can be up to two years. In this case, a pseudonymous user
          identifier is formed and stored with the date/time of consent, information
          on the scope of the consent (e.g. which categories of cookies and/or
          service providers) as well as the browser, system and used end device;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Consent (Article 6 (1) (a) GDPR).
          </span>
        </li>
        <li>
          <strong>Cookie-Opt-Out: </strong>In the footer of our website you will
          find a link that allows you to change your cookie settings as well as
          revoke corresponding consents;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
            GDPR).
          </span>
        </li>
        <li>
          <strong>Cookiebot: </strong>Cookie-Consent Manager;{" "}
          <strong>Service provider</strong>: Cybot A/S, Havnegade 39, 1058
          Copenhagen, Denmark; <strong>Website:</strong>{" "}
          <Link href="https://www.cookiebot.com" target="_blank" rel="noreferrer">
            https://www.cookiebot.com
          </Link>
          ; <strong>Privacy Policy:</strong>{" "}
          <Link href="https://www.cookiebot.com/en/privacy-policy/" target="_blank" rel="noreferrer">
            https://www.cookiebot.com/en/privacy-policy/
          </Link>
          ; <strong>Data Processing Agreement:</strong> Provided by the service
          provider; <strong>Further Information:</strong> Stored data (on the server
          of the service provider): The IP number of the user in anonymous form (the
          last three digits are set to 0), date and time of the consent, user agent
          of the user's browser, the URL from which the consent was sent, An
          anonymous, random and encrypted key value. the consent status of the user.
        </li>
        <li>
          <strong>Usercentrics: </strong>Cookie-Consent Manager;{" "}
          <strong>Service provider</strong>: Usercentrics GmbH, Sendlinger Strasse
          7, 80331 Munich, Germany; <strong>Website:</strong>{" "}
          <Link href="https://usercentrics.com/" target="_blank" rel="noreferrer">
            https://usercentrics.com/
          </Link>
          . <strong>Privacy Policy:</strong>{" "}
          <Link href="https://usercentrics.com/privacy-policy/" target="_blank" rel="noreferrer">
            https://usercentrics.com/privacy-policy/
          </Link>
          .
        </li>
      </ul>
      <h2 id="m317">Business services</h2>
      <p>
        We process data of our contractual and business partners, e.g. customers and
        interested parties (collectively referred to as "contractual partners")
        within the context of contractual and comparable legal relationships as well
        as associated actions and communication with the contractual partners or
        pre-contractually, e.g. to answer inquiries.
      </p>
      <p>
        We process this data in order to fulfill our contractual obligations. These
        include, in particular, the obligations to provide the agreed services, any
        update obligations and remedies in the event of warranty and other service
        disruptions. In addition, we process the data to protect our rights and for
        the purpose of administrative tasks associated with these obligations and
        company organization. Furthermore, we process the data on the basis of our
        legitimate interests in proper and economical business management as well as
        security measures to protect our contractual partners and our business
        operations from misuse, endangerment of their data, secrets, information and
        rights (e.g. for the involvement of telecommunications, transport and other
        auxiliary services as well as subcontractors, banks, tax and legal advisors,
        payment service providers or tax authorities). Within the framework of
        applicable law, we only disclose the data of contractual partners to third
        parties to the extent that this is necessary for the aforementioned purposes
        or to fulfill legal obligations. Contractual partners will be informed about
        further forms of processing, e.g. for marketing purposes, within the scope
        of this privacy policy.
      </p>
      <p>
        Which data are necessary for the aforementioned purposes, we inform the
        contracting partners before or in the context of the data collection, e.g.
        in online forms by special marking (e.g. colors), and/or symbols (e.g.
        asterisks or the like), or personally.
      </p>
      <p>
        We delete the data after expiry of statutory warranty and comparable
        obligations, i.e. in principle after expiry of 4 years, unless the data is
        stored in a customer account or must be kept for legal reasons of archiving.
        The statutory retention period for documents relevant under tax law as well
        as for commercial books, inventories, opening balance sheets, annual
        financial statements, the instructions required to understand these
        documents and other organizational documents and accounting records is ten
        years and for received commercial and business letters and reproductions of
        sent commercial and business letters six years. The period begins at the end
        of the calendar year in which the last entry was made in the book, the
        inventory, the opening balance sheet, the annual financial statements or the
        management report was prepared, the commercial or business letter was
        received or sent, or the accounting document was created, furthermore the
        record was made or the other documents were created.{" "}
      </p>
      <p>
        If we use third-party providers or platforms to provide our services, the
        terms and conditions and privacy policies of the respective third-party
        providers or platforms shall apply in the relationship between the users and
        the providers.
      </p>
      <ul className="m-elements">
        <li>
          <strong>Processed data types:</strong> Inventory data (e.g. names,
          addresses); Payment Data (e.g. bank details, invoices, payment history);
          Contact data (e.g. e-mail, telephone numbers); Contract data (e.g.
          contract object, duration, customer category); Usage data (e.g. websites
          visited, interest in content, access times). Meta, communication and
          process data (e.g. IP addresses, time information, identification numbers,
          consent status).
        </li>
        <li>
          <strong>Data subjects:</strong> Customers; Prospective customers. Business
          and contractual partners.
        </li>
        <li>
          <strong>Purposes of Processing:</strong> Provision of contractual services
          and fulfillment of contractual obligations; Security measures; Contact
          requests and communication; Office and organisational procedures; Managing
          and responding to inquiries; Conversion tracking (Measurement of the
          effectiveness of marketing activities). Profiles with user-related
          information (Creating user profiles).
        </li>
        <li className="">
          <strong>Legal Basis:</strong> Performance of a contract and prior requests
          (Article 6 (1) (b) GDPR); Compliance with a legal obligation (Article 6
          (1) (c) GDPR). Legitimate Interests (Article 6 (1) (f) GDPR).
        </li>
      </ul>
      <p>
        <strong>
          Further information on processing methods, procedures and services used:
        </strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>Customer Account: </strong>Customers can create an account within
          our online offer (e.g. customer or user account, "customer account" for
          short). If the registration of a customer account is required, customers
          will be informed of this as well as of the details required for
          registration. The customer accounts are not public and cannot be indexed
          by search engines. In the course of registration and subsequent
          registration and use of the customer account, we store the IP addresses of
          the contractual partners along with the access times, in order to be able
          to prove the registration and prevent any misuse of the customer account.
          If the customer account has been terminated, the customer account data
          will be deleted after the termination date, unless it is retained for
          purposes other than provision in the customer account or must be retained
          for legal reasons (e.g. internal storage of customer data, order
          transactions or invoices). It is the customers' responsibility to back up
          their data when terminating the customer Account;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Performance of a contract and prior
            requests (Article 6 (1) (b) GDPR).
          </span>
        </li>
        <li>
          <strong>Economic Analyses and Market Research: </strong>For economic
          reasons and in order to be able to recognise market trends, wishes of
          contractual partners and users, we analyse the data available to us on
          business transactions, contracts, enquiries, etc., whereby the persons
          concerned may include contractual partners, interested parties, customers,
          visitors and users of our online service.
          <br />
          <br />
          The analyses are carried out for the purpose of business evaluations,
          marketing and market research (for example, to determine customer groups
          with different characteristics). If available, we can take into account
          the profiles of registered users with information, e.g. on the services
          they have used. The analyses serve us alone and are not disclosed
          externally unless they are anonymous analyses with summarised, i.e.
          anonymised values. Furthermore, we respect the privacy of the users and
          process the data for analysis purposes as pseudonymously as possible and
          if possible anonymously (e.g. as summarized data);{" "}
          <span className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
            GDPR).
          </span>
        </li>
        <li>
          <strong>Online Shop and E-Commerce: </strong>We process the data of our
          customers in order to enable them to select, purchase or order the
          selected products, goods and related services, as well as their payment
          and delivery, or performance of other services. If necessary for the
          execution of an order, we use service providers, in particular postal,
          freight and shipping companies, in order to carry out the delivery or
          execution to our customers. For the processing of payment transactions we
          use the services of banks and payment service providers. The required
          details are identified as such in the course of the ordering or comparable
          purchasing process and include the details required for delivery, or other
          way of making the product available and invoicing as well as contact
          information in order to be able to hold any consultation;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Performance of a contract and prior
            requests (Article 6 (1) (b) GDPR).
          </span>
        </li>
        <li>
          <strong>Software and Platform Services: </strong>We process the data of
          our users, registered and any test users (hereinafter uniformly referred
          to as "users") in order to provide them with our contractual services and
          on the basis of legitimate interests to ensure the security of our offer
          and to develop it further. The required details are identified as such
          within the context of the conclusion of the agreement, order or comparable
          contract and include the details required for the provision of services
          and invoicing as well as contact information in order to be able to hold
          any further consultations;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Performance of a contract and prior
            requests (Article 6 (1) (b) GDPR).
          </span>
        </li>
      </ul>
      <h2 id="m3575">Providers and services used in the course of business</h2>
      <p>
        As part of our business activities, we use additional services, platforms,
        interfaces or plug-ins from third-party providers (in short, "services") in
        compliance with legal requirements. Their use is based on our interests in
        the proper, legal and economic management of our business operations and
        internal organization.
      </p>
      <ul className="m-elements">
        <li>
          <strong>Processed data types:</strong> Inventory data (e.g. names,
          addresses); Payment Data (e.g. bank details, invoices, payment history);
          Contact data (e.g. e-mail, telephone numbers); Content data (e.g. text
          input, photographs, videos); Contract data (e.g. contract object,
          duration, customer category); Usage data (e.g. websites visited, interest
          in content, access times). Meta, communication and process data (e.g. IP
          addresses, time information, identification numbers, consent status).
        </li>
        <li>
          <strong>Data subjects:</strong> Customers; Prospective customers; Users
          (e.g. website visitors, users of online services); Business and
          contractual partners. Communication partner (Recipients of e-mails,
          letters, etc.).
        </li>
        <li>
          <strong>Purposes of Processing:</strong> Provision of contractual services
          and fulfillment of contractual obligations; Office and organisational
          procedures; Direct marketing (e.g. by e-mail or postal). Web Analytics
          (e.g. access statistics, recognition of returning visitors).
        </li>
        <li className="">
          <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
          GDPR).
        </li>
      </ul>
      <p>
        <strong>
          Further information on processing methods, procedures and services used:
        </strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>Brevo: </strong>E-mail dispatch and automation services;{" "}
          <strong>Service provider</strong>: Sendinblue GmbH, Köpenicker Str. 126,
          10179 Berlin, Germany;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
            GDPR);{" "}
          </span>
          <strong>Website:</strong>{" "}
          <Link href="https://www.brevo.com/" target="_blank" rel="noreferrer">
            https://www.brevo.com/
          </Link>
          ; <strong>Privacy Policy:</strong>{" "}
          <Link href="https://www.brevo.com/de/legal/privacypolicy/" target="_blank" rel="noreferrer">
            https://www.brevo.com/de/legal/privacypolicy/
          </Link>
          . <strong>Data Processing Agreement:</strong> Provided by the service
          provider.
        </li>
        <li>
          <strong>Xolo OÜ: </strong>Business Administration;{" "}
          <strong>Service provider</strong>: Xolo OÜ
          <br />
          Reg no: 12844111
          <br />
          KMKR no: EE101793070
          <br />
          Kalasadama 4<br />
          Tallinn, Estonia 10415; <strong>Website:</strong>{" "}
          <Link href="https://www.xolo.io" target="_blank" rel="noreferrer">
            https://www.xolo.io
          </Link>
          . <strong>Privacy Policy:</strong>{" "}
          <Link href="https://www.xolo.io/zz-en/privacy" target="_blank" rel="noreferrer">
            https://www.xolo.io/zz-en/privacy
          </Link>
          .
        </li>
      </ul>
      <h2 id="m326">Payment Procedure</h2>
      <p>
        Within the framework of contractual and other legal relationships, due to
        legal obligations or otherwise on the basis of our legitimate interests, we
        offer data subjects efficient and secure payment options and use other
        service providers for this purpose in addition to banks and credit
        institutions (collectively referred to as "payment service providers").
      </p>
      <p>
        The data processed by the payment service providers includes inventory data,
        such as the name and address, bank data, such as account numbers or credit
        card numbers, passwords, TANs and checksums, as well as the contract, total
        and recipient-related information. The information is required to carry out
        the transactions. However, the data entered is only processed by the payment
        service providers and stored with them. I.e. we do not receive any account
        or credit card related information, but only information with confirmation
        or negative information of the payment. Under certain circumstances, the
        data may be transmitted by the payment service providers to credit agencies.
        The purpose of this transmission is to check identity and creditworthiness.
        Please refer to the terms and conditions and data protection information of
        the payment service providers.
      </p>
      <p>
        The terms and conditions and data protection information of the respective
        payment service providers apply to the payment transactions and can be
        accessed within the respective websites or transaction applications. We also
        refer to these for further information and the assertion of revocation,
        information and other data subject rights.
      </p>
      <ul className="m-elements">
        <li>
          <strong>Processed data types:</strong> Inventory data (e.g. names,
          addresses); Payment Data (e.g. bank details, invoices, payment history);
          Contract data (e.g. contract object, duration, customer category); Usage
          data (e.g. websites visited, interest in content, access times). Meta,
          communication and process data (e.g. IP addresses, time information,
          identification numbers, consent status).
        </li>
        <li>
          <strong>Data subjects:</strong> Customers. Prospective customers.
        </li>
        <li>
          <strong>Purposes of Processing:</strong> Provision of contractual services
          and fulfillment of contractual obligations.
        </li>
        <li className="">
          <strong>Legal Basis:</strong> Performance of a contract and prior requests
          (Article 6 (1) (b) GDPR).
        </li>
      </ul>
      <p>
        <strong>
          Further information on processing methods, procedures and services used:
        </strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>Mastercard: </strong>Payment-Service-Provider (technical
          integration of online-payment-methods); <strong>Service provider</strong>:
          Mastercard Europe SA, Chaussée de Tervuren 198A, B-1410 Waterloo, Belgium;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Performance of a contract and prior
            requests (Article 6 (1) (b) GDPR);{" "}
          </span>
          <strong>Website:</strong>{" "}
          <Link href="https://www.mastercard.co.uk" target="_blank" rel="noreferrer">
            https://www.mastercard.co.uk
          </Link>
          . <strong>Privacy Policy:</strong>{" "}
          <Link
            href="https://www.mastercard.co.uk/en-gb/about-mastercard/what-we-do/privacy.html"
            target="_blank" rel="noreferrer"
          >
            https://www.mastercard.co.uk/en-gb/about-mastercard/what-we-do/privacy.html
          </Link>
          .
        </li>
        <li>
          <strong>PayPal: </strong>Payment-Service-Provider (technical integration
          of online-payment-methods) (e.g. PayPal, PayPal Plus, Braintree,
          Braintree); <strong>Service provider</strong>: PayPal (Europe) S.à r.l. et
          Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Performance of a contract and prior
            requests (Article 6 (1) (b) GDPR);{" "}
          </span>
          <strong>Website:</strong>{" "}
          <Link href="https://www.paypal.com" target="_blank" rel="noreferrer">
            https://www.paypal.com
          </Link>
          . <strong>Privacy Policy:</strong>{" "}
          <Link
            href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full"
            target="_blank" rel="noreferrer"
          >
            https://www.paypal.com/de/webapps/mpp/ua/privacy-full
          </Link>
          .
        </li>
        <li>
          <strong>Visa: </strong>Payment-Service-Provider (technical integration of
          online-payment-methods); <strong>Service provider</strong>: Visa Europe
          Services Inc., Zweigniederlassung London, 1 Sheldon Square, London W2 6TT,
          UK;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Performance of a contract and prior
            requests (Article 6 (1) (b) GDPR);{" "}
          </span>
          <strong>Website:</strong>{" "}
          <Link href="https://www.visa.de" target="_blank" rel="noreferrer">
            https://www.visa.de
          </Link>
          . <strong>Privacy Policy:</strong>{" "}
          <Link href="https://www.visa.de/datenschutz" target="_blank" rel="noreferrer">
            https://www.visa.de/datenschutz
          </Link>
          .
        </li>
      </ul>
      <h2 id="m225">Provision of online services and web hosting</h2>
      <p>
        We process user data in order to be able to provide them with our online
        services. For this purpose, we process the IP address of the user, which is
        necessary to transmit the content and functions of our online services to
        the user's browser or terminal device.
      </p>
      <ul className="m-elements">
        <li>
          <strong>Processed data types:</strong> Usage data (e.g. websites visited,
          interest in content, access times); Meta, communication and process data
          (e.g. IP addresses, time information, identification numbers, consent
          status). Content data (e.g. text input, photographs, videos).
        </li>
        <li>
          <strong>Data subjects:</strong> Users (e.g. website visitors, users of
          online services). Business and contractual partners.
        </li>
        <li>
          <strong>Purposes of Processing:</strong> Provision of our online services
          and usability; Information technology infrastructure (Operation and
          provision of information systems and technical devices, such as computers,
          servers, etc.).); Security measures. Provision of contractual services and
          fulfillment of contractual obligations.
        </li>
        <li className="">
          <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
          GDPR).
        </li>
      </ul>
      <p>
        <strong>
          Further information on processing methods, procedures and services used:
        </strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>Provision of online offer on rented hosting space: </strong>For
          the provision of our online services, we use storage space, computing
          capacity and software that we rent or otherwise obtain from a
          corresponding server provider (also referred to as a "web hoster");{" "}
          <span className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
            GDPR).
          </span>
        </li>
        <li>
          <strong>Collection of Access Data and Log Files: </strong>The access to
          our online services is logged in the form of so-called "server log files".
          Server log files may include the address and name of the web pages and
          files accessed, the date and time of access, data volumes transferred,
          notification of successful access, browser type and version, the user's
          operating system, referrer URL (the previously visited page) and, as a
          general rule, IP addresses and the requesting provider. The server log
          files can be used for security purposes, e.g. to avoid overloading the
          servers (especially in the case of abusive attacks, so-called DDoS
          attacks) and to ensure the stability and optimal load balancing of the
          servers;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
            GDPR).{" "}
          </span>
          <strong>Retention period:</strong> Log file information is stored for a
          maximum period of 30 days and then deleted or anonymized. Data, the
          further storage of which is necessary for evidence purposes, are excluded
          from deletion until the respective incident has been finally clarified.
        </li>
        <li>
          <strong>E-mail Sending and Hosting: </strong>The web hosting services we
          use also include sending, receiving and storing e-mails. For these
          purposes, the addresses of the recipients and senders, as well as other
          information relating to the sending of e-mails (e.g. the providers
          involved) and the contents of the respective e-mails are processed. The
          above data may also be processed for SPAM detection purposes. Please note
          that e-mails on the Internet are generally not sent in encrypted form. As
          a rule, e-mails are encrypted during transport, but not on the servers
          from which they are sent and received (unless a so-called end-to-end
          encryption method is used). We can therefore accept no responsibility for
          the transmission path of e-mails between the sender and reception on our
          server;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
            GDPR).
          </span>
        </li>
        <li>
          <strong>Content-Delivery-Network: </strong>We use a so-called "Content
          Delivery Network" (CDN). A CDN is a service with whose help contents of
          our online services, in particular large media files, such as graphics or
          scripts, can be delivered faster and more securely with the help of
          regionally distributed servers connected via the Internet;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
            GDPR).
          </span>
        </li>
        <li>
          <strong>Amazon Web Services (AWS): </strong>Services in the field of the
          provision of information technology infrastructure and related services
          (e.g. storage space and/or computing capacities);{" "}
          <strong>Service provider</strong>: Amazon Web Services EMEA SARL, 38
          avenue John F. Kennedy, 1855, Luxembourg;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
            GDPR);{" "}
          </span>
          <strong>Website:</strong>{" "}
          <Link href="https://aws.amazon.com/" target="_blank" rel="noreferrer">
            https://aws.amazon.com/
          </Link>
          ; <strong>Privacy Policy:</strong>{" "}
          <Link href="https://aws.amazon.com/privacy/" target="_blank" rel="noreferrer">
            https://aws.amazon.com/privacy/
          </Link>
          ; <strong>Data Processing Agreement:</strong>{" "}
          <Link href="https://aws.amazon.com/compliance/gdpr-center/" target="_blank" rel="noreferrer">
            https://aws.amazon.com/compliance/gdpr-center/
          </Link>
          . <strong>Basis for third country transfer:</strong>{" "}
          <span className=""> EU-US Data Privacy Framework (DPF), </span>Standard
          Contractual Clauses (
          <Link href="https://aws.amazon.com/en/service-terms/" target="_blank" rel="noreferrer">
            https://aws.amazon.com/en/service-terms/
          </Link>
          ).
        </li>
        <li>
          <strong>1&amp;1 IONOS: </strong>Services in the field of the provision of
          information technology infrastructure and related services (e.g. storage
          space and/or computing capacities); <strong>Service provider</strong>:
          1&amp;1 IONOS SE, Elgendorfer Str. 57, 56410 Montabaur, Germany;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
            GDPR);{" "}
          </span>
          <strong>Website:</strong>{" "}
          <Link href="https://www.ionos.com" target="_blank" rel="noreferrer">
            https://www.ionos.com
          </Link>
          ; <strong>Privacy Policy:</strong>{" "}
          <Link href="https://www.ionos.com/terms-gtc/terms-privacy/" target="_blank" rel="noreferrer">
            https://www.ionos.com/terms-gtc/terms-privacy/
          </Link>
          . <strong>Data Processing Agreement:</strong>{" "}
          <Link
            href="https://www.ionos.de/hilfe/datenschutz/allgemeine-informationen-zur-datenschutz-grundverordnung-dsgvo/auftragsverarbeitung/"
            target="_blank" rel="noreferrer"
          >
            https://www.ionos.de/hilfe/datenschutz/allgemeine-informationen-zur-datenschutz-grundverordnung-dsgvo/auftragsverarbeitung/
          </Link>
          .
        </li>
      </ul>
      <h2 id="m469">Special Notes on Applications (Apps)</h2>
      <p>
        We process the data of the users of our application to the extent necessary
        to provide the users with the application and its functionalities, to
        monitor its security and to develop it further. Furthermore, we may contact
        users in compliance with the statutory provisions if communication is
        necessary for the purposes of administration or use of the application. In
        addition, we refer to the data protection information in this privacy policy
        with regard to the processing of user data.
      </p>
      <p>
        <strong>Legal basis:</strong> The processing of data necessary for the
        provision of the functionalities of the application serves to fulfil
        contractual obligations. This also applies if the provision of the functions
        requires user authorisation (e.g. release of device functions). If the
        processing of data is not necessary for the provision of the functionalities
        of the application, but serves the security of the application or our
        business interests (e.g. collection of data for the purpose of optimising
        the application or security purposes), it is carried out on the basis of our
        legitimate interests. If users are expressly requested to give their consent
        to the processing of their data, the data covered by the consent is
        processed on the basis of the consent.
      </p>
      <ul className="m-elements">
        <li>
          <strong>Processed data types:</strong> Inventory data (e.g. names,
          addresses); Meta, communication and process data (e.g. IP addresses, time
          information, identification numbers, consent status); Payment Data (e.g.
          bank details, invoices, payment history). Contract data (e.g. contract
          object, duration, customer category).
        </li>
        <li>
          <strong>Data subjects:</strong> Users (e.g. website visitors, users of
          online services).
        </li>
        <li>
          <strong>Purposes of Processing:</strong> Provision of contractual services
          and fulfillment of contractual obligations.
        </li>
        <li className="">
          <strong>Legal Basis:</strong> Consent (Article 6 (1) (a) GDPR);
          Performance of a contract and prior requests (Article 6 (1) (b) GDPR).
          Legitimate Interests (Article 6 (1) (f) GDPR).
        </li>
      </ul>
      <p>
        <strong>
          Further information on processing methods, procedures and services used:
        </strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>Commercial use: </strong>We process the data of the users of our
          application, registered and any test users (hereinafter uniformly referred
          to as "users") in order to provide them with our contractual services and
          on the basis of legitimate interests to ensure the security of our
          application and to develop it further. The required details are identified
          as such within the scope of the conclusion of a contract for the use of
          the application, the conclusion of an order, an order or a comparable
          contract and may include the details required for the provision of
          services and any invoicing as well as contact information in order to be
          able to hold any consultations;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Performance of a contract and prior
            requests (Article 6 (1) (b) GDPR).
          </span>
        </li>
      </ul>
      <h2 id="m367">Registration, Login and User Account</h2>
      <p>
        Users can create a user account. Within the scope of registration, the
        required mandatory information is communicated to the users and processed
        for the purposes of providing the user account on the basis of contractual
        fulfilment of obligations. The processed data includes in particular the
        login information (name, password and an e-mail address).
      </p>
      <p>
        Within the scope of using our registration and login functions as well as
        the use of the user account, we store the IP address and the time of the
        respective user action. The storage is based on our legitimate interests, as
        well as the user's protection against misuse and other unauthorized use.
        This data will not be passed on to third parties unless it is necessary to
        pursue our claims or there is a legal obligation to do so.
      </p>
      <p>
        Users may be informed by e-mail of information relevant to their user
        account, such as technical changes.
      </p>
      <ul className="m-elements">
        <li>
          <strong>Processed data types:</strong> Inventory data (e.g. names,
          addresses); Contact data (e.g. e-mail, telephone numbers); Content data
          (e.g. text input, photographs, videos). Meta, communication and process
          data (e.g. IP addresses, time information, identification numbers, consent
          status).
        </li>
        <li>
          <strong>Data subjects:</strong> Users (e.g. website visitors, users of
          online services).
        </li>
        <li>
          <strong>Purposes of Processing:</strong> Provision of contractual services
          and fulfillment of contractual obligations; Security measures; Managing
          and responding to inquiries. Provision of our online services and
          usability.
        </li>
        <li className="">
          <strong>Legal Basis:</strong> Performance of a contract and prior requests
          (Article 6 (1) (b) GDPR). Legitimate Interests (Article 6 (1) (f) GDPR).
        </li>
      </ul>
      <p>
        <strong>
          Further information on processing methods, procedures and services used:
        </strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>Registration with pseudonyms: </strong>Users may use pseudonyms as
          user names instead of real names;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Performance of a contract and prior
            requests (Article 6 (1) (b) GDPR).
          </span>
        </li>
        <li>
          <strong>Users' profiles are public: </strong>The users' profiles are not
          publicly visible or accessible.
        </li>
      </ul>
      <h2 id="m451">Single Sign-on Authentication</h2>
      <p>
        Single Sign-On" or "Single Sign-On Authentication or Logon" are procedures
        that allow users to log in to our online services using a user account with
        a provider of Single Sign-On services (e.g. a social network). The
        prerequisite for Single Sign-On Authentication is that users are registered
        with the respective Single Sign-On provider and enter the required access
        data in the online form provided for this purpose, or are already logged in
        with the Single Sign-On provider and confirm the Single Sign-On login via
        the button.
      </p>
      <p>
        Authentication takes place directly with the respective single sign-on
        provider. Within the scope of such authentication, we receive a user ID with
        the information that the user is logged in with the respective single
        sign-on provider under this user ID and an ID that cannot be used for other
        purposes (so-called "user handle"). Whether we receive further data depends
        solely on the single sign-on procedure used, the data releases selected as
        part of authentication and also which data users have released in the
        privacy or other settings of the user account with the single sign-on
        provider. Depending on the single sign-on provider and the user's choice,
        there can be different data, usually the e-mail address and the user name.
        The password entered by the single sign-on provider as part of the single
        sign-on procedure is neither visible to us nor is it stored by us.{" "}
      </p>
      <p>
        Users are requested to note that their data stored with us can be
        automatically compared with their user account with the single sign-on
        provider, but this is not always possible or actual. If, for example, the
        e-mail addresses of users change, users must change these manually in their
        user account with us.
      </p>
      <p>
        We can use single sign-on authentication, provided that it has been agreed
        with users in the context of pre-fulfillment or fulfilment of the contract,
        in the context of consent processing and otherwise use it on the basis of
        our legitimate interests and the interests of users in an effective and
        secure authentication system.{" "}
      </p>
      <p>
        Should users decide to no longer want to use the link of their user account
        with the Single Sign-On provider for the Single Sign-On procedure, they must
        remove this link within their user account with the Single Sign-On provider.
        If users wish to delete their data from us, they must cancel their
        registration with us.
      </p>
      <ul className="m-elements">
        <li>
          <strong>Processed data types:</strong> Inventory data (e.g. names,
          addresses); Contact data (e.g. e-mail, telephone numbers); Usage data
          (e.g. websites visited, interest in content, access times); Meta,
          communication and process data (e.g. IP addresses, time information,
          identification numbers, consent status); Event Data (Facebook) ("Event
          Data" is data that can be transmitted from us to Facebook, e.g. via
          Facebook pixels (via apps or other means) and relates to persons or their
          actions; the data includes, for example, information about visits to
          websites, interactions with content, functions, installations of apps,
          purchases of products, etc.; Event data is processed for the purpose of
          creating target groups for content and advertising information (Custom
          Audiences). Event Data does not include the actual content (such as
          written comments), login information, and Contact Information (such as
          names, email addresses, and phone numbers). Event Data is deleted by
          Facebook after a maximum of two years, the Custom Audiences created from
          them with the deletion of our Facebook account).
        </li>
        <li>
          <strong>Data subjects:</strong> Users (e.g. website visitors, users of
          online services).
        </li>
        <li>
          <strong>Purposes of Processing:</strong> Provision of contractual services
          and fulfillment of contractual obligations; Security measures.
          Authentication processes.
        </li>
        <li className="">
          <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
          GDPR).
        </li>
      </ul>
      <p>
        <strong>
          Further information on processing methods, procedures and services used:
        </strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>Apple Single-Sign-On: </strong>Authentication service;{" "}
          <strong>Service provider</strong>: Apple Inc., Infinite Loop, Cupertino,
          CA 95014, USA;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
            GDPR);{" "}
          </span>
          <strong>Website:</strong>{" "}
          <Link href="https://www.apple.com" target="_blank" rel="noreferrer">
            https://www.apple.com
          </Link>
          . <strong>Privacy Policy:</strong>{" "}
          <Link href="https://www.apple.com/legal/privacy/en-ww/" target="_blank" rel="noreferrer">
            https://www.apple.com/legal/privacy/en-ww/
          </Link>
          .
        </li>
        <li>
          <strong>Facebook Single-Sign-On: </strong>Authentication service of the
          platform Facebook; <strong>Service provider</strong>: Meta Platforms
          Ireland Limited, Merrion Road, Dublin 4, D04 X2K5, Ireland;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
            GDPR);{" "}
          </span>
          <strong>Website:</strong>{" "}
          <Link href="https://www.facebook.com" target="_blank" rel="noreferrer">
            https://www.facebook.com
          </Link>
          ; <strong>Privacy Policy:</strong>{" "}
          <Link href="https://www.facebook.com/about/privacy" target="_blank" rel="noreferrer">
            https://www.facebook.com/about/privacy
          </Link>
          ; <strong>Data Processing Agreement:</strong>{" "}
          <Link
            href="https://www.facebook.com/legal/terms/dataprocessing"
            target="_blank" rel="noreferrer"
          >
            https://www.facebook.com/legal/terms/dataprocessing
          </Link>
          . <strong>Basis for third country transfer:</strong>{" "}
          <span className=""> EU-US Data Privacy Framework (DPF), </span>Standard
          Contractual Clauses (
          <Link
            href="https://www.facebook.com/legal/EU_data_transfer_addendum"
            target="_blank" rel="noreferrer"
          >
            https://www.facebook.com/legal/EU_data_transfer_addendum
          </Link>
          ).
        </li>
        <li>
          <strong>Google Single-Sign-On: </strong>Authentication service;{" "}
          <strong>Service provider</strong>: Google Ireland Limited, Gordon House,
          Barrow Street, Dublin 4, Ireland;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
            GDPR);{" "}
          </span>
          <strong>Website:</strong>{" "}
          <Link href="https://www.google.com" target="_blank" rel="noreferrer">
            https://www.google.com
          </Link>
          ; <strong>Privacy Policy:</strong>{" "}
          <Link href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
            https://policies.google.com/privacy
          </Link>
          ; <strong>Basis for third country transfer:</strong>{" "}
          <span className=""> EU-US Data Privacy Framework (DPF)</span>.{" "}
          <strong>Opt-Out:</strong> Settings for the Display of Advertisements:{" "}
          <Link
            href="https://myadcenter.google.com/personalizationoff"
            target="_blank" rel="noreferrer"
          >
            https://myadcenter.google.com/personalizationoff
          </Link>
          .
        </li>
      </ul>
      <h2 id="m29">Cloud Services</h2>
      <p>
        We use Internet-accessible software services (so-called "cloud services",
        also referred to as "Software as a Service") provided on the servers of its
        providers for the storage and management of content (e.g. document storage
        and management, exchange of documents, content and information with certain
        recipients or publication of content and information).
      </p>
      <p>
        Within this framework, personal data may be processed and stored on the
        provider's servers insofar as this data is part of communication processes
        with us or is otherwise processed by us in accordance with this privacy
        policy. This data may include in particular master data and contact data of
        data subjects, data on processes, contracts, other proceedings and their
        contents. Cloud service providers also process usage data and metadata that
        they use for security and service optimization purposes.
      </p>
      <p>
        If we use cloud services to provide documents and content to other users or
        publicly accessible websites, forms, etc., providers may store cookies on
        users' devices for web analysis or to remember user settings (e.g. in the
        case of media control).
      </p>
      <ul className="m-elements">
        <li>
          <strong>Processed data types:</strong> Inventory data (e.g. names,
          addresses); Contact data (e.g. e-mail, telephone numbers); Content data
          (e.g. text input, photographs, videos); Usage data (e.g. websites visited,
          interest in content, access times). Meta, communication and process data
          (e.g. IP addresses, time information, identification numbers, consent
          status).
        </li>
        <li>
          <strong>Data subjects:</strong> Customers; Employees (e.g. Employees, job
          applicants); Prospective customers; Communication partner (Recipients of
          e-mails, letters, etc.); Users (e.g. website visitors, users of online
          services). Business and contractual partners.
        </li>
        <li>
          <strong>Purposes of Processing:</strong> Office and organisational
          procedures. Information technology infrastructure (Operation and provision
          of information systems and technical devices, such as computers, servers,
          etc.).).
        </li>
        <li className="">
          <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
          GDPR).
        </li>
      </ul>
      <p>
        <strong>
          Further information on processing methods, procedures and services used:
        </strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>Google Cloud Services: </strong>Cloud infrastructure services and
          cloud-based application software; <strong>Service provider</strong>:
          Google Cloud EMEA Limited, 70 Sir John Rogerson’s Quay, Dublin 2, Ireland;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
            GDPR);{" "}
          </span>
          <strong>Website:</strong>{" "}
          <Link href="https://cloud.google.com/" target="_blank" rel="noreferrer">
            https://cloud.google.com/
          </Link>
          ; <strong>Privacy Policy:</strong>{" "}
          <Link href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
            https://policies.google.com/privacy
          </Link>
          ; <strong>Data Processing Agreement:</strong>{" "}
          <Link
            href="https://cloud.google.com/terms/data-processing-addendum"
            target="_blank" rel="noreferrer"
          >
            https://cloud.google.com/terms/data-processing-addendum
          </Link>
          ; <strong>Basis for third country transfer:</strong>{" "}
          <span className=""> EU-US Data Privacy Framework (DPF), </span>Standard
          Contractual Clauses (
          <Link
            href="https://cloud.google.com/terms/eu-model-contract-clause"
            target="_blank" rel="noreferrer"
          >
            https://cloud.google.com/terms/eu-model-contract-clause
          </Link>
          ). <strong>Further Information:</strong>{" "}
          <Link href="https://cloud.google.com/privacy" target="_blank" rel="noreferrer">
            https://cloud.google.com/privacy
          </Link>
          .
        </li>
        <li>
          <strong>Amazon Web Services (AWS): </strong>Services in the field of the
          provision of information technology infrastructure and related services
          (e.g. storage space and/or computing capacities);{" "}
          <strong>Service provider</strong>: Amazon Web Services EMEA SARL, 38
          avenue John F. Kennedy, 1855, Luxembourg;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
            GDPR);{" "}
          </span>
          <strong>Website:</strong>{" "}
          <Link href="https://aws.amazon.com/" target="_blank" rel="noreferrer">
            https://aws.amazon.com/
          </Link>
          ; <strong>Privacy Policy:</strong>{" "}
          <Link href="https://aws.amazon.com/privacy/" target="_blank" rel="noreferrer">
            https://aws.amazon.com/privacy/
          </Link>
          ; <strong>Data Processing Agreement:</strong>{" "}
          <Link href="https://aws.amazon.com/compliance/gdpr-center/" target="_blank" rel="noreferrer">
            https://aws.amazon.com/compliance/gdpr-center/
          </Link>
          . <strong>Basis for third country transfer:</strong>{" "}
          <span className=""> EU-US Data Privacy Framework (DPF), </span>Standard
          Contractual Clauses (
          <Link href="https://aws.amazon.com/en/service-terms/" target="_blank" rel="noreferrer">
            https://aws.amazon.com/en/service-terms/
          </Link>
          ).
        </li>
      </ul>
      <h2 id="m638">
        Commercial communication by E-Mail, Postal Mail, Fax or Telephone
      </h2>
      <p>
        We process personal data for the purposes of promotional communication,
        which may be carried out via various channels, such as e-mail, telephone,
        post or fax, in accordance with the legal requirements.
      </p>
      <p>
        The recipients have the right to withdraw their consent at any time or to
        object to the advertising communication at any time.
      </p>
      <p>
        After revocation or objection, we store the data required to prove the past
        authorization to contact or send up to three years from the end of the year
        of revocation or objection on the basis of our legitimate interests. The
        processing of this data is limited to the purpose of a possible defense
        against claims. Based on the legitimate interest to permanently observe the
        revocation, respectively objection of the users, we further store the data
        necessary to avoid a renewed contact (e.g. depending on the communication
        channel, the e-mail address, telephone number, name).
      </p>
      <ul className="m-elements">
        <li>
          <strong>Processed data types:</strong> Inventory data (e.g. names,
          addresses); Contact data (e.g. e-mail, telephone numbers); Usage data
          (e.g. websites visited, interest in content, access times). Meta,
          communication and process data (e.g. IP addresses, time information,
          identification numbers, consent status).
        </li>
        <li>
          <strong>Data subjects:</strong> Communication partner (Recipients of
          e-mails, letters, etc.).
        </li>
        <li>
          <strong>Purposes of Processing:</strong> Direct marketing (e.g. by e-mail
          or postal). Web Analytics (e.g. access statistics, recognition of
          returning visitors).
        </li>
        <li className="">
          <strong>Legal Basis:</strong> Consent (Article 6 (1) (a) GDPR). Legitimate
          Interests (Article 6 (1) (f) GDPR).
        </li>
      </ul>
      <p>
        <strong>
          Further information on processing methods, procedures and services used:
        </strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>Brevo: </strong>E-mail dispatch and automation services;{" "}
          <strong>Service provider</strong>: Sendinblue GmbH, Köpenicker Str. 126,
          10179 Berlin, Germany;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
            GDPR);{" "}
          </span>
          <strong>Website:</strong>{" "}
          <Link href="https://www.brevo.com/" target="_blank" rel="noreferrer">
            https://www.brevo.com/
          </Link>
          ; <strong>Privacy Policy:</strong>{" "}
          <Link href="https://www.brevo.com/de/legal/privacypolicy/" target="_blank" rel="noreferrer">
            https://www.brevo.com/de/legal/privacypolicy/
          </Link>
          . <strong>Data Processing Agreement:</strong> Provided by the service
          provider.
        </li>
      </ul>
      <h2 id="m263">Web Analysis, Monitoring and Optimization</h2>
      <p>
        Web analysis is used to evaluate the visitor traffic on our website and may
        include the behaviour, interests or demographic information of users, such
        as age or gender, as pseudonymous values. With the help of web analysis we
        can e.g. recognize, at which time our online services or their functions or
        contents are most frequently used or requested for repeatedly, as well as
        which areas require optimization.
      </p>
      <p>
        In addition to web analysis, we can also use test procedures, e.g. to test
        and optimize different versions of our online services or their components.
      </p>
      <p>
        Unless otherwise stated below, profiles, i.e. data aggregated for a usage
        process, can be created for these purposes and information can be stored in
        a browser or in a terminal device and read from it. The information
        collected includes, in particular, websites visited and elements used there
        as well as technical information such as the browser used, the computer
        system used and information on usage times. If users have agreed to the
        collection of their location data from us or from the providers of the
        services we use, location data may also be processed.
      </p>
      <p>
        Unless otherwise stated below, profiles, that is data summarized for a usage
        process or user, may be created for these purposes and stored in a browser
        or terminal device (so-called "cookies") or similar processes may be used
        for the same purpose. The information collected includes, in particular,
        websites visited and elements used there as well as technical information
        such as the browser used, the computer system used and information on usage
        times. If users have consented to the collection of their location data or
        profiles to us or to the providers of the services we use, these may also be
        processed, depending on the provider.
      </p>
      <p>
        The IP addresses of the users are also stored. However, we use any existing
        IP masking procedure (i.e. pseudonymisation by shortening the IP address) to
        protect the user. In general, within the framework of web analysis, A/B
        testing and optimisation, no user data (such as e-mail addresses or names)
        is stored, but pseudonyms. This means that we, as well as the providers of
        the software used, do not know the actual identity of the users, but only
        the information stored in their profiles for the purposes of the respective
        processes.
      </p>
      <ul className="m-elements">
        <li>
          <strong>Processed data types:</strong> Usage data (e.g. websites visited,
          interest in content, access times). Meta, communication and process data
          (e.g. IP addresses, time information, identification numbers, consent
          status).
        </li>
        <li>
          <strong>Data subjects:</strong> Users (e.g. website visitors, users of
          online services).
        </li>
        <li>
          <strong>Purposes of Processing:</strong> Remarketing; Affiliate Tracking;
          Web Analytics (e.g. access statistics, recognition of returning visitors);
          Profiles with user-related information (Creating user profiles); Provision
          of our online services and usability; Conversion tracking (Measurement of
          the effectiveness of marketing activities); Marketing. Targeting (e.g.
          profiling based on interests and behaviour, use of cookies).
        </li>
        <li>
          <strong>Security measures:</strong> IP Masking (Pseudonymization of the IP
          address).
        </li>
        <li className="">
          <strong>Legal Basis:</strong> Consent (Article 6 (1) (a) GDPR). Legitimate
          Interests (Article 6 (1) (f) GDPR).
        </li>
      </ul>
      <p>
        <strong>
          Further information on processing methods, procedures and services used:
        </strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>Google Analytics 4: </strong>We use Google Analytics to perform
          measurement and analysis of the use of our online services by users based
          on a pseudonymous user identification number. This identification number
          does not contain any unique data, such as names or email addresses. It is
          used to assign analysis information to an end device in order to recognize
          which content users have accessed within one or various usage processes,
          which search terms they have used, have accessed again or have interacted
          with our online services. Likewise, the time of use and its duration are
          stored, as well as the sources of users referring to our online services
          and technical aspects of their end devices and browsers. In the process,
          pseudonymous profiles of users are created with information from the use
          of various devices, and cookies may be used. Google Analytics does not log
          or store individual IP addresses. Analytics does provide coarse
          geo-location data by deriving the following metadata from IP addresses:
          City (and the derived latitude, and longitude of the city), Continent,
          Country, Region, Subcontinent (and ID-based counterparts). For EU-based
          traffic, IP-address data is used solely for geo-location data derivation
          before being immediately discarded. It is not logged, accessible, or used
          for any additional use cases. When Analytics collects measurement data,
          all IP lookups are performed on EU-based servers before forwarding traffic
          to Analytics servers for processing; <strong>Service provider</strong>:
          Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
            GDPR);{" "}
          </span>
          <strong>Website:</strong>{" "}
          <Link
            href="https://marketingplatform.google.com/intl/en/about/analytics/"
            target="_blank" rel="noreferrer"
          >
            https://marketingplatform.google.com/intl/en/about/analytics/
          </Link>
          ; <strong>Privacy Policy:</strong>{" "}
          <Link href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
            https://policies.google.com/privacy
          </Link>
          ; <strong>Data Processing Agreement:</strong>{" "}
          <Link
            href="https://business.safety.google/adsprocessorterms/"
            target="_blank" rel="noreferrer"
          >
            https://business.safety.google/adsprocessorterms/
          </Link>
          ; <strong>Basis for third country transfer:</strong>{" "}
          <span className=""> EU-US Data Privacy Framework (DPF), </span>Standard
          Contractual Clauses (
          <Link
            href="https://business.safety.google/adsprocessorterms"
            target="_blank" rel="noreferrer"
          >
            https://business.safety.google/adsprocessorterms
          </Link>
          ); <strong>Opt-Out:</strong> Opt-Out-Plugin:{" "}
          <Link href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank" rel="noreferrer">
            https://tools.google.com/dlpage/gaoptout?hl=en
          </Link>
          , Settings for the Display of Advertisements:{" "}
          <Link
            href="https://myadcenter.google.com/personalizationoff"
            target="_blank" rel="noreferrer"
          >
            https://myadcenter.google.com/personalizationoff
          </Link>
          . <strong>Further Information:</strong>{" "}
          <Link href="https://business.safety.google/adsservices/" target="_blank" rel="noreferrer">
            https://business.safety.google/adsservices/
          </Link>{" "}
          (Types of processing and data processed).
        </li>
        <li>
          <strong>Google Signals (Google Analytics function): </strong>Google
          signals are session data from sites and apps that Google associates with
          users who have signed in to their Google accounts, and who have turned on
          Ads Personalization. This association of data with these signed-in users
          is used to enable cross-device reporting, cross-device remarketing, and
          cross-device conversion export to Ads. These are the areas where more
          information is gathered when Google Signals is activated (but only for
          users with Ads Personalization enabled): Cross Platform reporting -
          Connection of data about devices and activities from different sessions
          using your User-ID or Google-signals data providing an understanding of
          user behavior at each step of the conversion process, from initial contact
          to conversion and beyond; Remarketing with Google Analytics - Creation of
          remarketing audiences from Google Analytics data, and sharing of those
          audiences with linked advertising accounts; Demographics &amp; Interests:
          Google Analytics collects additional information about demographics and
          interests from users who are signed in to their Google accounts and who
          have turned on Ads Personalization; <strong>Service provider</strong>:
          Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Consent (Article 6 (1) (a) GDPR);{" "}
          </span>
          <strong>Website:</strong>{" "}
          <Link
            href="https://support.google.com/analytics/answer/7532985?hl=en"
            target="_blank" rel="noreferrer"
          >
            https://support.google.com/analytics/answer/7532985?hl=en
          </Link>
          ; <strong>Privacy Policy:</strong>{" "}
          <Link href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
            https://policies.google.com/privacy
          </Link>
          ; <strong>Data Processing Agreement:</strong>{" "}
          <Link
            href="https://business.safety.google/adsprocessorterms"
            target="_blank" rel="noreferrer"
          >
            https://business.safety.google/adsprocessorterms
          </Link>
          ; <strong>Basis for third country transfer:</strong>{" "}
          <span className=""> EU-US Data Privacy Framework (DPF), </span>Standard
          Contractual Clauses (
          <Link
            href="https://business.safety.google/adsprocessorterms"
            target="_blank" rel="noreferrer"
          >
            https://business.safety.google/adsprocessorterms
          </Link>
          ). <strong>Further Information:</strong>{" "}
          <Link href="https://business.safety.google/adsservices/" target="_blank" rel="noreferrer">
            https://business.safety.google/adsservices/
          </Link>{" "}
          (Types of processing and data processed).
        </li>
        <li>
          <strong>Google Analytics Audiences: </strong>We use Google Analytics to
          display ads placed by Google and its partnersonly to users who have shown
          an interest in our online services or who have specific characteristics
          (e.g. interests in specific topics or products determined on the basis of
          the websites visited) that we transmit to Google (so-called "Remarketing
          Audiences" or "Google Analytics Audiences"). With the help of remarketing
          audiences, we also want to ensure that our ads match the potential
          interest of users; <strong>Service provider</strong>: Google Ireland
          Limited, Gordon House, Barrow Street, Dublin 4, Ireland;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Consent (Article 6 (1) (a) GDPR);{" "}
          </span>
          <strong>Website:</strong>{" "}
          <Link href="https://marketingplatform.google.com" target="_blank" rel="noreferrer">
            https://marketingplatform.google.com
          </Link>
          ;{" "}
          <span className="">
            <strong>Legal Basis:</strong>{" "}
            <Link
              href="https://business.safety.google/adsprocessorterms/"
              target="_blank" rel="noreferrer"
            >
              https://business.safety.google/adsprocessorterms/
            </Link>
            ;{" "}
          </span>
          <strong>Privacy Policy:</strong>{" "}
          <Link href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
            https://policies.google.com/privacy
          </Link>
          ; <strong>Data Processing Agreement:</strong>{" "}
          <Link
            href="https://business.safety.google/adsprocessorterms/"
            target="_blank" rel="noreferrer"
          >
            https://business.safety.google/adsprocessorterms/
          </Link>
          ; <strong>Basis for third country transfer:</strong>{" "}
          <span className=""> EU-US Data Privacy Framework (DPF)</span>;{" "}
          <strong>Further Information:</strong> Types of processing and data
          processed:{" "}
          <Link href="https://business.safety.google/adsservices/" target="_blank" rel="noreferrer">
            https://business.safety.google/adsservices/
          </Link>
          . Data Processing Conditions for Google Advertising Products and standard
          contractual clauses for data transfers to third countries:{" "}
          <Link
            href="https://business.safety.google/adsprocessorterms"
            target="_blank" rel="noreferrer"
          >
            https://business.safety.google/adsprocessorterms
          </Link>{" "}
          .
        </li>
        <li>
          <strong>Google Ads and Conversion Tracking: </strong>Online marketing
          process for purposes of placing content and advertisements within the
          provider's advertising network (e.g., in search results, in videos, on web
          pages, etc.) so that they are displayed to users who have a presumed
          interest in the ads. Furthermore, we measure the conversion of the ads,
          i.e. whether the users took them as a reason to interact with the ads and
          make use of the advertised offers (so-called conversion). However, we only
          receive anonymous information and no personal information about individual
          users; <strong>Service provider</strong>: Google Ireland Limited, Gordon
          House, Barrow Street, Dublin 4, Ireland;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Consent (Article 6 (1) (a) GDPR),
            Legitimate Interests (Article 6 (1) (f) GDPR);{" "}
          </span>
          <strong>Website:</strong>{" "}
          <Link href="https://marketingplatform.google.com" target="_blank" rel="noreferrer">
            https://marketingplatform.google.com
          </Link>
          ; <strong>Privacy Policy:</strong>{" "}
          <Link href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
            https://policies.google.com/privacy
          </Link>
          ; <strong>Basis for third country transfer:</strong>{" "}
          <span className=""> EU-US Data Privacy Framework (DPF)</span>;{" "}
          <strong>Further Information:</strong> Types of processing and data
          processed:{" "}
          <Link href="https://business.safety.google/adsservices/" target="_blank" rel="noreferrer">
            https://business.safety.google/adsservices/
          </Link>
          . Google Ads Controller-Controller Data Protection Terms and standard
          contractual clauses for data transfers to third countries:{" "}
          <Link
            href="https://business.safety.google/adscontrollerterms"
            target="_blank" rel="noreferrer"
          >
            https://business.safety.google/adscontrollerterms
          </Link>
          .
        </li>
        <li>
          <strong>Google Ads Remarketing: </strong>Google Remarketing, also known as
          retargeting, is a technology that adds users who use an online service to
          a pseudonymous remarketing list so that users can be shown ads on other
          online services based on their visit to the online service
          <br />; <strong>Service provider</strong>: Google Ireland Limited, Gordon
          House, Barrow Street, Dublin 4, Ireland;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Consent (Article 6 (1) (a) GDPR);{" "}
          </span>
          <strong>Website:</strong>{" "}
          <Link href="https://marketingplatform.google.com" target="_blank" rel="noreferrer">
            https://marketingplatform.google.com
          </Link>
          ; <strong>Privacy Policy:</strong>{" "}
          <Link href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
            https://policies.google.com/privacy
          </Link>
          ; <strong>Basis for third country transfer:</strong>{" "}
          <span className=""> EU-US Data Privacy Framework (DPF)</span>;{" "}
          <strong>Further Information:</strong> Types of processing and data
          processed:{" "}
          <Link href="https://business.safety.google/adsservices/" target="_blank" rel="noreferrer">
            https://business.safety.google/adsservices/
          </Link>
          . Google Ads Controller-Controller Data Protection Terms and standard
          contractual clauses for data transfers to third countries:{" "}
          <Link
            href="https://business.safety.google/adscontrollerterms"
            target="_blank" rel="noreferrer"
          >
            https://business.safety.google/adscontrollerterms
          </Link>
          .
        </li>
      </ul>
      <h2 id="m264">Online Marketing</h2>
      <p>
        We process personal data for the purposes of online marketing, which may
        include in particular the marketing of advertising space or the display of
        advertising and other content (collectively referred to as "Content") based
        on the potential interests of users and the measurement of their
        effectiveness.{" "}
      </p>
      <p>
        For these purposes, so-called user profiles are created and stored in a file
        (so-called "cookie") or similar procedure is used by which the relevant user
        information for the display of the aforementioned content is stored. This
        information may include, for example, content viewed, websites visited,
        online networks used, communication partners and technical information such
        as the browser used, computer system used and information on usage times and
        used functions. If users have consented to the collection of their sideline
        data, these can also be processed.
      </p>
      <p>
        The IP addresses of the users are also stored. However, we use provided IP
        masking procedures (i.e. pseudonymisation by shortening the IP address) to
        ensure the protection of the user's by using a pseudonym. In general, within
        the framework of the online marketing process, no clear user data (such as
        e-mail addresses or names) is secured, but pseudonyms. This means that we,
        as well as the providers of online marketing procedures, do not know the
        actual identity of the users, but only the information stored in their
        profiles.
      </p>
      <p>
        The information in the profiles is usually stored in the cookies or similar
        memorizing procedures. These cookies can later, generally also on other
        websites that use the same online marketing technology, be read and analyzed
        for purposes of content display, as well as supplemented with other data and
        stored on the server of the online marketing technology provider.
      </p>
      <p>
        Exceptionally, clear data can be assigned to the profiles. This is the case,
        for example, if the users are members of a social network whose online
        marketing technology we use and the network links the profiles of the users
        in the aforementioned data. Please note that users may enter into additional
        agreements with the social network providers or other service providers,
        e.g. by consenting as part of a registration process.
      </p>
      <p>
        As a matter of principle, we only gain access to summarised information
        about the performance of our advertisements. However, within the framework
        of so-called conversion measurement, we can check which of our online
        marketing processes have led to a so-called conversion, i.e. to the
        conclusion of a contract with us. The conversion measurement is used alone
        for the performance analysis of our marketing activities.
      </p>
      <p>
        Unless otherwise stated, we kindly ask you to consider that cookies used
        will be stored for a period of two years.
      </p>
      <ul className="m-elements">
        <li>
          <strong>Processed data types:</strong> Content data (e.g. text input,
          photographs, videos); Usage data (e.g. websites visited, interest in
          content, access times); Meta, communication and process data (e.g. IP
          addresses, time information, identification numbers, consent status);
          Event Data (Facebook) ("Event Data" is data that can be transmitted from
          us to Facebook, e.g. via Facebook pixels (via apps or other means) and
          relates to persons or their actions; the data includes, for example,
          information about visits to websites, interactions with content,
          functions, installations of apps, purchases of products, etc.; Event data
          is processed for the purpose of creating target groups for content and
          advertising information (Custom Audiences). Event Data does not include
          the actual content (such as written comments), login information, and
          Contact Information (such as names, email addresses, and phone numbers).
          Event Data is deleted by Facebook after a maximum of two years, the Custom
          Audiences created from them with the deletion of our Facebook account).
        </li>
        <li>
          <strong>Data subjects:</strong> Users (e.g. website visitors, users of
          online services).
        </li>
        <li>
          <strong>Purposes of Processing:</strong> Web Analytics (e.g. access
          statistics, recognition of returning visitors); Targeting (e.g. profiling
          based on interests and behaviour, use of cookies); Conversion tracking
          (Measurement of the effectiveness of marketing activities); Affiliate
          Tracking; Marketing; Profiles with user-related information (Creating user
          profiles); Provision of our online services and usability; Remarketing.
          Clicktracking.
        </li>
        <li>
          <strong>Security measures:</strong> IP Masking (Pseudonymization of the IP
          address).
        </li>
        <li className="">
          <strong>Legal Basis:</strong> Consent (Article 6 (1) (a) GDPR). Legitimate
          Interests (Article 6 (1) (f) GDPR).
        </li>
        <li>
          <strong>Opt-Out: </strong>We refer to the privacy policies of the
          respective service providers and the possibilities for objection
          (so-called "opt-out"). If no explicit opt-out option has been specified,
          it is possible to deactivate cookies in the settings of your browser.
          However, this may restrict the functions of our online offer. We therefore
          recommend the following additional opt-out options, which are offered
          collectively for each area: <br />
          <br />
          a) Europe:{" "}
          <Link href="https://www.youronlinechoices.eu" target="_blank" rel="noreferrer">
            https://www.youronlinechoices.eu
          </Link>
          . <br />
          b) Canada:{" "}
          <Link href="https://www.youradchoices.ca/choices" target="_blank" rel="noreferrer">
            https://www.youradchoices.ca/choices
          </Link>
          . <br />
          c) USA:{" "}
          <Link href="https://www.aboutads.info/choices" target="_blank" rel="noreferrer">
            https://www.aboutads.info/choices
          </Link>
          . <br />
          d) Cross-regional:{" "}
          <Link href="https://optout.aboutads.info" target="_blank" rel="noreferrer">
            https://optout.aboutads.info
          </Link>
          .
        </li>
      </ul>
      <p>
        <strong>
          Further information on processing methods, procedures and services used:
        </strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>Meta Pixel and Custom Audiences (Custom Audiences): </strong>With
          the help of the Meta-Pixel (or equivalent functions, to transfer
          Event-Data or Contact Information via interfaces or other software in
          apps), Meta is on the one hand able to determine the visitors of our
          online services as a target group for the presentation of ads (so-called
          "Meta ads"). Accordingly, we use Meta-Pixels to display Meta ads placed by
          us only to Meta users and within the services of partners cooperating with
          Meta (so-called "audience network"{" "}
          <Link href="https://www.facebook.com/audiencenetwork/" target="_blank" rel="noreferrer">
            https://www.facebook.com/audiencenetwork/
          </Link>{" "}
          ) who have shown an interest in our online services or who have certain
          characteristics (e.g. interests in certain topics or products that are
          determined on the basis of the websites visited) that we transmit to Meta
          (so-called "custom audiences"). With the help of Meta-Pixels, we also want
          to ensure that our Meta ads correspond to the potential interest of users
          and do not appear annoying. The Meta-Pixel also enables us to track the
          effectiveness of Meta ads for statistical and market research purposes by
          showing whether users were referred to our website after clicking on a
          Meta ad (known as "conversion tracking");{" "}
          <strong>Service provider</strong>: Meta Platforms Ireland Limited, Merrion
          Road, Dublin 4, D04 X2K5, Ireland;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Consent (Article 6 (1) (a) GDPR);{" "}
          </span>
          <strong>Website:</strong>{" "}
          <Link href="https://www.facebook.com" target="_blank" rel="noreferrer">
            https://www.facebook.com
          </Link>
          ; <strong>Privacy Policy:</strong>{" "}
          <Link href="https://www.facebook.com/about/privacy" target="_blank" rel="noreferrer">
            https://www.facebook.com/about/privacy
          </Link>
          ; <strong>Data Processing Agreement:</strong>{" "}
          <Link
            href="https://www.facebook.com/legal/terms/dataprocessing"
            target="_blank" rel="noreferrer"
          >
            https://www.facebook.com/legal/terms/dataprocessing
          </Link>
          ; <strong>Basis for third country transfer:</strong>{" "}
          <span className=""> EU-US Data Privacy Framework (DPF), </span>Standard
          Contractual Clauses (
          <Link
            href="https://www.facebook.com/legal/EU_data_transfer_addendum"
            target="_blank" rel="noreferrer"
          >
            https://www.facebook.com/legal/EU_data_transfer_addendum
          </Link>
          ). <strong>Further Information:</strong> User event data, i.e. behavioral
          and interest data, is processed for the purposes of targeted advertising
          and audience building on the basis of the joint controllership agreement
          ("Controller Addendum",{" "}
          <Link
            href="https://www.facebook.com/legal/controller_addendum"
            target="_blank" rel="noreferrer"
          >
            https://www.facebook.com/legal/controller_addendum
          </Link>
          ). The joint controllership is limited to the collection and transfer of
          the data to Meta Platforms Ireland Limited, a company located in the EU.
          Further processing of the data is the sole responsibility of Meta
          Platforms Ireland Limited, which concerns in particular the transfer of
          the data to the parent company Meta Platforms, Inc. in the USA (on the
          basis of standard contractual clauses concluded between Meta Platforms
          Ireland Limited and Meta Platforms, Inc.).
        </li>
        <li>
          <strong>Facebook Ads: </strong>Placement of ads within the Facebook
          platform and analysis of ad results; <strong>Service provider</strong>:
          Meta Platforms Ireland Limited, Merrion Road, Dublin 4, D04 X2K5, Ireland;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
            GDPR);{" "}
          </span>
          <strong>Website:</strong>{" "}
          <Link href="https://www.facebook.com" target="_blank" rel="noreferrer">
            https://www.facebook.com
          </Link>
          ; <strong>Privacy Policy:</strong>{" "}
          <Link href="https://www.facebook.com/about/privacy" target="_blank" rel="noreferrer">
            https://www.facebook.com/about/privacy
          </Link>
          ; <strong>Basis for third country transfer:</strong>{" "}
          <span className=""> EU-US Data Privacy Framework (DPF)</span>;{" "}
          <strong>Opt-Out:</strong> Wir verweisen auf die Datenschutz- und
          Werbeeinstellungen im Profil des Nutzers auf der Facebook-Plattform sowie
          auf das Einwilligungsverfahren von Facebook und die Kontaktmöglichkeiten
          von Facebook zur Ausübung von Informations- und sonstigen
          Betroffenenrechten in der Datenschutzerklärung von Facebook.{" "}
          <strong>Further Information:</strong> User event data, i.e. behavioral and
          interest data, is processed for the purposes of targeted advertising and
          audience building on the basis of the joint controllership agreement
          ("Controller Addendum",{" "}
          <Link
            href="https://www.facebook.com/legal/controller_addendum"
            target="_blank" rel="noreferrer"
          >
            https://www.facebook.com/legal/controller_addendum
          </Link>
          ). The joint controllership is limited to the collection and transfer of
          the data to Meta Platforms Ireland Limited, a company located in the EU.
          Further processing of the data is the sole responsibility of Meta
          Platforms Ireland Limited, which concerns in particular the transfer of
          the data to the parent company Meta Platforms, Inc. in the USA (on the
          basis of standard contractual clauses concluded between Meta Platforms
          Ireland Limited and Meta Platforms, Inc.).
        </li>
        <li>
          <strong>Google Ads and Conversion Tracking: </strong>Online marketing
          process for purposes of placing content and advertisements within the
          provider's advertising network (e.g., in search results, in videos, on web
          pages, etc.) so that they are displayed to users who have a presumed
          interest in the ads. Furthermore, we measure the conversion of the ads,
          i.e. whether the users took them as a reason to interact with the ads and
          make use of the advertised offers (so-called conversion). However, we only
          receive anonymous information and no personal information about individual
          users; <strong>Service provider</strong>: Google Ireland Limited, Gordon
          House, Barrow Street, Dublin 4, Ireland;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Consent (Article 6 (1) (a) GDPR),
            Legitimate Interests (Article 6 (1) (f) GDPR);{" "}
          </span>
          <strong>Website:</strong>{" "}
          <Link href="https://marketingplatform.google.com" target="_blank" rel="noreferrer">
            https://marketingplatform.google.com
          </Link>
          ; <strong>Privacy Policy:</strong>{" "}
          <Link href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
            https://policies.google.com/privacy
          </Link>
          ; <strong>Basis for third country transfer:</strong>{" "}
          <span className=""> EU-US Data Privacy Framework (DPF)</span>;{" "}
          <strong>Further Information:</strong> Types of processing and data
          processed:{" "}
          <Link href="https://business.safety.google/adsservices/" target="_blank" rel="noreferrer">
            https://business.safety.google/adsservices/
          </Link>
          . Google Ads Controller-Controller Data Protection Terms and standard
          contractual clauses for data transfers to third countries:{" "}
          <Link
            href="https://business.safety.google/adscontrollerterms"
            target="_blank" rel="noreferrer"
          >
            https://business.safety.google/adscontrollerterms
          </Link>
          .
        </li>
        <li>
          <strong>Google Ads Remarketing: </strong>Google Remarketing, also known as
          retargeting, is a technology that adds users who use an online service to
          a pseudonymous remarketing list so that users can be shown ads on other
          online services based on their visit to the online service
          <br />; <strong>Service provider</strong>: Google Ireland Limited, Gordon
          House, Barrow Street, Dublin 4, Ireland;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Consent (Article 6 (1) (a) GDPR);{" "}
          </span>
          <strong>Website:</strong>{" "}
          <Link href="https://marketingplatform.google.com" target="_blank" rel="noreferrer">
            https://marketingplatform.google.com
          </Link>
          ; <strong>Privacy Policy:</strong>{" "}
          <Link href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
            https://policies.google.com/privacy
          </Link>
          ; <strong>Basis for third country transfer:</strong>{" "}
          <span className=""> EU-US Data Privacy Framework (DPF)</span>;{" "}
          <strong>Further Information:</strong> Types of processing and data
          processed:{" "}
          <Link href="https://business.safety.google/adsservices/" target="_blank" rel="noreferrer">
            https://business.safety.google/adsservices/
          </Link>
          . Google Ads Controller-Controller Data Protection Terms and standard
          contractual clauses for data transfers to third countries:{" "}
          <Link
            href="https://business.safety.google/adscontrollerterms"
            target="_blank" rel="noreferrer"
          >
            https://business.safety.google/adscontrollerterms
          </Link>
          .
        </li>
        <li>
          <strong>Enhanced Conversions for Google Ads: </strong>When customers click
          on our Google ads and subsequently use the advertised service (so-called
          "conversion"), data entered by the user, such as the email address, name,
          home address or phone number, may be transmitted to Google. However, the
          data is not transmitted clearly, but in the form of a mathematical
          numerical value (so-called "hash value").The hash values are then matched
          with existing Google accounts of users in order to better evaluate as well
          as improve the interaction of users with the advertisements (e.g. clicks
          or views) and thus their performance;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Consent (Article 6 (1) (a) GDPR).{" "}
          </span>
          <strong>Website:</strong>{" "}
          <Link
            href="https://support.google.com/google-ads/answer/9888656"
            target="_blank" rel="noreferrer"
          >
            https://support.google.com/google-ads/answer/9888656
          </Link>
          .
        </li>
        <li>
          <strong>Instagram Ads: </strong>Placement of ads within the Instagram
          platform and analysis of ad results; <strong>Service provider</strong>:
          Meta Platforms Ireland Limited, Merrion Road, Dublin 4, D04 X2K5, Ireland;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Consent (Article 6 (1) (a) GDPR);{" "}
          </span>
          <strong>Website:</strong>{" "}
          <Link href="https://www.instagram.com" target="_blank" rel="noreferrer">
            https://www.instagram.com
          </Link>
          ; <strong>Privacy Policy:</strong>{" "}
          <Link href="https://instagram.com/about/legal/privacy" target="_blank" rel="noreferrer">
            https://instagram.com/about/legal/privacy
          </Link>
          ; <strong>Basis for third country transfer:</strong>{" "}
          <span className=""> EU-US Data Privacy Framework (DPF)</span>;{" "}
          <strong>Opt-Out:</strong> We refer to the data protection and advertising
          settings in the user's profile on the Instagram platform as well as
          Instagram's consent procedure and Instagram's contact options for
          exercising information and other data subject rights in Instagram's
          privacy policy. <strong>Further Information:</strong> User event data,
          i.e. behavioral and interest data, is processed for the purposes of
          targeted advertising and audience building on the basis of the joint
          controllership agreement ("Controller Addendum",{" "}
          <Link
            href="https://www.facebook.com/legal/controller_addendum"
            target="_blank" rel="noreferrer"
          >
            https://www.facebook.com/legal/controller_addendum
          </Link>
          ). The joint controllership is limited to the collection and transfer of
          the data to Meta Platforms Ireland Limited, a company located in the EU.
          Further processing of the data is the sole responsibility of Meta
          Platforms Ireland Limited, which concerns in particular the transfer of
          the data to the parent company Meta Platforms, Inc. in the USA (on the
          basis of standard contractual clauses concluded between Meta Platforms
          Ireland Limited and Meta Platforms, Inc.).
        </li>
        <li>
          <strong>UTM Parameter: </strong>Analysis of sources and user actions based
          on an extension of web addresses referring to us with an additional
          parameter, the "UTM" parameter. For example, a UTM parameter
          "utm_source=platformX &amp;utm_medium=video" can tell us that a person
          clicked the link on platform X within a video. The UTM parameters provide
          information about the source of the link, the medium used (e.g. social
          media, website, newsletter), the type of campaign or the content of the
          campaign (e.g. posting, link, image and video). With the help of this
          information, we can, for example, check our visibility on the Internet or
          the effectiveness of our campaigns;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
            GDPR).
          </span>
        </li>
      </ul>
      <h2 id="m136">Profiles in Social Networks (Social Media)</h2>
      <p>
        We maintain online presences within social networks and process user data in
        this context in order to communicate with the users active there or to offer
        information about us.
      </p>
      <p>
        We would like to point out that user data may be processed outside the
        European Union. This may entail risks for users, e.g. by making it more
        difficult to enforce users' rights.
      </p>
      <p>
        In addition, user data is usually processed within social networks for
        market research and advertising purposes. For example, user profiles can be
        created on the basis of user behaviour and the associated interests of
        users. The user profiles can then be used, for example, to place
        advertisements within and outside the networks which are presumed to
        correspond to the interests of the users. For these purposes, cookies are
        usually stored on the user's computer, in which the user's usage behaviour
        and interests are stored. Furthermore, data can be stored in the user
        profiles independently of the devices used by the users (especially if the
        users are members of the respective networks or will become members later
        on).
      </p>
      <p>
        For a detailed description of the respective processing operations and the
        opt-out options, please refer to the respective data protection declarations
        and information provided by the providers of the respective networks.
      </p>
      <p>
        Also in the case of requests for information and the exercise of rights of
        data subjects, we point out that these can be most effectively pursued with
        the providers. Only the providers have access to the data of the users and
        can directly take appropriate measures and provide information. If you still
        need help, please do not hesitate to contact us.
      </p>
      <ul className="m-elements">
        <li>
          <strong>Processed data types:</strong> Contact data (e.g. e-mail,
          telephone numbers); Content data (e.g. text input, photographs, videos);
          Usage data (e.g. websites visited, interest in content, access times).
          Meta, communication and process data (e.g. IP addresses, time information,
          identification numbers, consent status).
        </li>
        <li>
          <strong>Data subjects:</strong> Users (e.g. website visitors, users of
          online services).
        </li>
        <li>
          <strong>Purposes of Processing:</strong> Contact requests and
          communication; Feedback (e.g. collecting feedback via online form).
          Marketing.
        </li>
        <li className="">
          <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
          GDPR).
        </li>
      </ul>
      <p>
        <strong>
          Further information on processing methods, procedures and services used:
        </strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>Instagram: </strong>Social network;{" "}
          <strong>Service provider</strong>: Meta Platforms Ireland Limited, Merrion
          Road, Dublin 4, D04 X2K5, Ireland;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
            GDPR);{" "}
          </span>
          <strong>Website:</strong>{" "}
          <Link href="https://www.instagram.com" target="_blank" rel="noreferrer">
            https://www.instagram.com
          </Link>
          . <strong>Privacy Policy:</strong>{" "}
          <Link href="https://instagram.com/about/legal/privacy" target="_blank" rel="noreferrer">
            https://instagram.com/about/legal/privacy
          </Link>
          .
        </li>
        <li>
          <strong>Facebook Pages: </strong>Profiles within the social network
          Facebook; <strong>Service provider</strong>: Meta Platforms Ireland
          Limited, Merrion Road, Dublin 4, D04 X2K5, Ireland;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
            GDPR);{" "}
          </span>
          <strong>Website:</strong>{" "}
          <Link href="https://www.facebook.com" target="_blank" rel="noreferrer">
            https://www.facebook.com
          </Link>
          ; <strong>Privacy Policy:</strong>{" "}
          <Link href="https://www.facebook.com/about/privacy" target="_blank" rel="noreferrer">
            https://www.facebook.com/about/privacy
          </Link>
          ; <strong>Basis for third country transfer:</strong>{" "}
          <span className=""> EU-US Data Privacy Framework (DPF), </span>Standard
          Contractual Clauses (
          <Link
            href="https://www.facebook.com/legal/EU_data_transfer_addendum"
            target="_blank" rel="noreferrer"
          >
            https://www.facebook.com/legal/EU_data_transfer_addendum
          </Link>
          ); <strong>Further Information:</strong> We are jointly responsible (so
          called "joint controller") with Meta Platforms Ireland Limited for the
          collection (but not the further processing) of data of visitors to our
          Facebook page. This data includes information about the types of content
          users view or interact with, or the actions they take (see "Things that
          you and others do and provide" in the Facebook Data Policy:{" "}
          <Link href="https://www.facebook.com/policy" target="_blank" rel="noreferrer">
            https://www.facebook.com/policy
          </Link>
          ), and information about the devices used by users (e.g., IP addresses,
          operating system, browser type, language settings, cookie information. see
          "Device Information" in the Facebook Data Policy:{" "}
          <Link href="https://www.facebook.com/policy" target="_blank" rel="noreferrer">
            https://www.facebook.com/policy
          </Link>
          ). As explained in the Facebook Data Policy under "How we use this
          information?" Facebook also collects and uses information to provide
          analytics services, known as "page insights," to site operators to help
          them understand how people interact with their pages and with content
          associated with them. We have concluded a special agreement with Facebook
          ("Information about Page-Insights",{" "}
          <Link
            href="https://www.facebook.com/legal/terms/page_controller_addendum"
            target="_blank" rel="noreferrer"
          >
            https://www.facebook.com/legal/terms/page_controller_addendum
          </Link>
          ), which regulates in particular the security measures that Facebook must
          observe and in which Facebook has agreed to fulfill the rights of the
          persons concerned (i.e. users can send information access or deletion
          requests directly to Facebook). The rights of users (in particular to
          access to information, erasure, objection and complaint to the competent
          supervisory authority) are not restricted by the agreements with Facebook.
          Further information can be found in the "Information about Page Insights"
          (
          <Link
            href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
            target="_blank" rel="noreferrer"
          >
            https://www.facebook.com/legal/terms/information_about_page_insights_data
          </Link>
          ). The joint controllership is limited to the collection and transfer of
          the data to Meta Platforms Ireland Limited, a company located in the EU.
          Further processing of the data is the sole responsibility of Meta
          Platforms Ireland Limited.
        </li>
      </ul>
      <h2 id="m328">Plugins and embedded functions and content</h2>
      <p>
        Within our online services, we integrate functional and content elements
        that are obtained from the servers of their respective providers
        (hereinafter referred to as "third-party providers"). These may, for
        example, be graphics, videos or city maps (hereinafter uniformly referred to
        as "Content").
      </p>
      <p>
        The integration always presupposes that the third-party providers of this
        content process the IP address of the user, since they could not send the
        content to their browser without the IP address. The IP address is therefore
        required for the presentation of these contents or functions. We strive to
        use only those contents, whose respective offerers use the IP address only
        for the distribution of the contents. Third parties may also use so-called
        pixel tags (invisible graphics, also known as "web beacons") for statistical
        or marketing purposes. The "pixel tags" can be used to evaluate information
        such as visitor traffic on the pages of this website. The pseudonymous
        information may also be stored in cookies on the user's device and may
        include technical information about the browser and operating system,
        referring websites, visit times and other information about the use of our
        website, as well as may be linked to such information from other sources.
      </p>
      <ul className="m-elements">
        <li>
          <strong>Processed data types:</strong> Usage data (e.g. websites visited,
          interest in content, access times); Meta, communication and process data
          (e.g. IP addresses, time information, identification numbers, consent
          status); Inventory data (e.g. names, addresses); Contact data (e.g.
          e-mail, telephone numbers). Content data (e.g. text input, photographs,
          videos).
        </li>
        <li>
          <strong>Data subjects:</strong> Users (e.g. website visitors, users of
          online services).
        </li>
        <li>
          <strong>Purposes of Processing:</strong> Provision of our online services
          and usability.
        </li>
        <li className="">
          <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
          GDPR).
        </li>
      </ul>
      <p>
        <strong>
          Further information on processing methods, procedures and services used:
        </strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>
            Integration of third-party software, scripts or frameworks:{" "}
          </strong>
          We incorporate into our online services software which we retrieve from
          servers of other providers (e.g. function libraries which we use for the
          purpose of displaying or user-friendliness of our online services). The
          respective providers collect the user's IP address and can process it for
          the purposes of transferring the software to the user's browser as well as
          for security purposes and for the evaluation and optimisation of their
          services;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
            GDPR).
          </span>
        </li>
        <li>
          <strong>Google Fonts (Provision on own server): </strong>Provision of font
          files for the purpose of a user-friendly presentation of our online
          services; <strong>Service provider</strong>: The Google Fonts are hosted
          on our server, no data is transmitted to Google;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
            GDPR).
          </span>
        </li>
        <li>
          <strong>Google Fonts (from Google Server): </strong>Obtaining fonts (and
          symbols) for the purpose of a technically secure, maintenance-free and
          efficient use of fonts and symbols with regard to timeliness and loading
          times, their uniform presentation and consideration of possible
          restrictions under licensing law. The provider of the fonts is informed of
          the user's IP address so that the fonts can be made available in the
          user's browser. In addition, technical data (language settings, screen
          resolution, operating system, hardware used) are transmitted which are
          necessary for the provision of the fonts depending on the devices used and
          the technical environment. This data may be processed on a server of the
          provider of the fonts in the USA - When visiting our online services,
          users' browsers send their browser HTTP requests to the Google Fonts Web
          API. The Google Fonts Web API provides users with Google Fonts' cascading
          style sheets (CSS) and then with the fonts specified in the CCS. These
          HTTP requests include (1) the IP address used by each user to access the
          Internet, (2) the requested URL on the Google server, and (3) the HTTP
          headers, including the user agent describing the browser and operating
          system versions of the website visitors, as well as the referral URL
          (i.e., the web page where the Google font is to be displayed). IP
          addresses are not logged or stored on Google servers and they are not
          analyzed. The Google Fonts Web API logs details of HTTP requests
          (requested URL, user agent, and referring URL). Access to this data is
          restricted and strictly controlled. The requested URL identifies the font
          families for which the user wants to load fonts. This data is logged so
          that Google can determine how often a particular font family is requested.
          With the Google Fonts Web API, the user agent must match the font that is
          generated for the particular browser type. The user agent is logged
          primarily for debugging purposes and is used to generate aggregate usage
          statistics that measure the popularity of font families. These aggregate
          usage statistics are published on Google Fonts' Analytics page. Finally,
          the referral URL is logged so that the data can be used for production
          maintenance and to generate an aggregate report on top integrations based
          on the number of font requests. Google says it does not use any of the
          information collected by Google Fonts to profile end users or serve
          targeted ads; <strong>Service provider</strong>: Google Ireland Limited,
          Gordon House, Barrow Street, Dublin 4, Ireland;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
            GDPR);{" "}
          </span>
          <strong>Website:</strong>{" "}
          <Link href="https://fonts.google.com/" target="_blank" rel="noreferrer">
            https://fonts.google.com/
          </Link>
          ; <strong>Privacy Policy:</strong>{" "}
          <Link href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
            https://policies.google.com/privacy
          </Link>
          ; <strong>Basis for third country transfer:</strong>{" "}
          <span className=""> EU-US Data Privacy Framework (DPF)</span>.{" "}
          <strong>Further Information:</strong>{" "}
          <Link
            href="https://developers.google.com/fonts/faq/privacy?hl=en"
            target="_blank" rel="noreferrer"
          >
            https://developers.google.com/fonts/faq/privacy?hl=en
          </Link>
          .
        </li>
        <li>
          <strong>YouTube videos: </strong>Video contents;{" "}
          <strong>Service provider</strong>: Google Ireland Limited, Gordon House,
          Barrow Street, Dublin 4, Ireland, , parent company: Google LLC, 1600
          Amphitheatre Parkway, Mountain View, CA 94043, USA;{" "}
          <span className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1) (f)
            GDPR);{" "}
          </span>
          <strong>Website:</strong>{" "}
          <Link href="https://www.youtube.com" target="_blank" rel="noreferrer">
            https://www.youtube.com
          </Link>
          ; <strong>Privacy Policy:</strong>{" "}
          <Link href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
            https://policies.google.com/privacy
          </Link>
          ; <strong>Basis for third country transfer:</strong>{" "}
          <span className=""> EU-US Data Privacy Framework (DPF)</span>.{" "}
          <strong>Opt-Out:</strong> Opt-Out-Plugin:{" "}
          <Link href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank" rel="noreferrer">
            https://tools.google.com/dlpage/gaoptout?hl=en
          </Link>
          , Settings for the Display of Advertisements:{" "}
          <Link
            href="https://myadcenter.google.com/personalizationoff"
            target="_blank" rel="noreferrer"
          >
            https://myadcenter.google.com/personalizationoff
          </Link>
          .
        </li>
      </ul>
      <h2 id="m723">Management, Organization and Utilities</h2>
      <p>
        We use services, platforms and software from other providers (hereinafter
        referred to as " third-party providers") for the purposes of organizing,
        administering, planning and providing our services. When selecting
        third-party providers and their services, we comply with the legal
        requirements.{" "}
      </p>
      <p>
        Within this context, personal data may be processed and stored on the
        servers of third-party providers. This may include various data that we
        process in accordance with this privacy policy. This data may include in
        particular master data and contact data of users, data on processes,
        contracts, other processes and their contents.
      </p>
      <p>
        If users are referred to the third-party providers or their software or
        platforms in the context of communication, business or other relationships
        with us, the third-party provider processing may process usage data and
        metadata that can be processed by them for security purposes, service
        optimisation or marketing purposes. We therefore ask you to read the data
        protection notices of the respective third party providers.
      </p>
      <ul className="m-elements">
        <li>
          <strong>Processed data types:</strong> Content data (e.g. text input,
          photographs, videos); Usage data (e.g. websites visited, interest in
          content, access times). Meta, communication and process data (e.g. IP
          addresses, time information, identification numbers, consent status).
        </li>
        <li>
          <strong>Data subjects:</strong> Communication partner (Recipients of
          e-mails, letters, etc.). Users (e.g. website visitors, users of online
          services).
        </li>
        <li>
          <strong>Purposes of Processing:</strong> Provision of contractual services
          and fulfillment of contractual obligations. Office and organisational
          procedures.
        </li>
      </ul>
      <p>
        <strong>
          Further information on processing methods, procedures and services used:
        </strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>The Noun Project, Inc: </strong>Provider of icons &amp;
          infographics; <strong>Service provider</strong>: 6067 Colgate Ave, Los
          Angeles, CA 90036, USA; <strong>Website:</strong>{" "}
          <Link href="https://thenounproject.com/" target="_blank" rel="noreferrer">
            https://thenounproject.com/
          </Link>
          . <strong>Privacy Policy:</strong>{" "}
          <Link
            href="https://thenounproject.com/legal/privacy-policy/"
            target="_blank" rel="noreferrer"
          >
            https://thenounproject.com/legal/privacy-policy/
          </Link>
          .
        </li>
      </ul>
      <h2 id="m42">Terminology and Definitions</h2>
      <p>
        In this section, you will find an overview of the terminology used in this
        privacy policy. Where the terminology is legally defined, their legal
        definitions apply. The following explanations, however, are primarily
        intended to aid understanding.
      </p>
      <ul className="glossary">
        <li>
          <strong>Affiliate Tracking:</strong> Custom Audiences refers to the
          process of determining target groups for advertising purposes, e.g. the
          display of advertisements. For example, a user's interest in certain
          products or topics on the Internet may be used to conclude that the user
          is interested in advertisements for similar products or the online store
          in which the user viewed the products. "Lookalike Audiences" is the term
          used to describe content that is viewed as suitable by users whose
          profiles or interests presumably correspond to the users for whom the
          profiles were created. For the purposes of creating custom audiences and
          lookalike audiences, cookies and web beacons are typically used.{" "}
        </li>
        <li>
          <strong>Clicktracking:</strong> Clicktracking allows users to keep track
          of their movements within an entire website. Since the results of these
          tests are more accurate if the interaction of the users can be followed
          over a certain period of time (e.g. if a user likes to return), cookies
          are usually stored on the computers of the users for these test purposes.{" "}
        </li>
        <li>
          <strong>Controller:</strong> "Controller" means the natural or legal
          person, public authority, agency or other body which, alone or jointly
          with others, determines the purposes and means of the processing of
          personal data.{" "}
        </li>
        <li>
          <strong>Conversion tracking:</strong> Conversion tracking is a method used
          to evaluate the effectiveness of marketing measures. For this purpose, a
          cookie is usually stored on the devices of the users within the websites
          on which the marketing measures take place and then called up again on the
          target website (e.g. we can thus trace whether the advertisements placed
          by us on other websites were successful).{" "}
        </li>
        <li>
          <strong>Personal Data:</strong> "personal data" means any information
          relating to an identified or identifiable natural person ("data subject");
          an identifiable natural person is one who can be identified, directly or
          indirectly, in particular by reference to an identifier such as a name, an
          identification number, location data, an online identifier or to one or
          more factors specific to the physical, physiological, genetic, mental,
          economic, cultural or social identity of that natural person.{" "}
        </li>
        <li>
          <strong>Processing:</strong> The term "processing" covers a wide range and
          practically every handling of data, be it collection, evaluation, storage,
          transmission or erasure.{" "}
        </li>
        <li>
          <strong>Profiles with user-related information:</strong> The processing of
          "profiles with user-related information", or "profiles" for short,
          includes any kind of automated processing of personal data that consists
          of using these personal data to analyse, evaluate or predict certain
          personal aspects relating to a natural person (depending on the type of
          profiling, this may include different information concerning demographics,
          behaviour and interests, such as interaction with websites and their
          content, etc.) (e.g. interests in certain content or products, click
          behaviour on a website or location). Cookies and web beacons are often
          used for profiling purposes.{" "}
        </li>
        <li>
          <strong>Remarketing:</strong> Remarketing" or "retargeting" is the term
          used, for example, to indicate for advertising purposes which products a
          user is interested in on a website in order to remind the user of these
          products on other websites, e.g. in advertisements.
        </li>
        <li>
          <strong>Targeting:</strong> "Tracking" is the term used when the behaviour
          of users can be traced across several websites. As a rule, behavior and
          interest information with regard to the websites used is stored in cookies
          or on the servers of the tracking technology providers (so-called
          profiling). This information can then be used, for example, to display
          advertisements to users presumably corresponding to their interests.{" "}
        </li>
        <li>
          <strong>Web Analytics:</strong> Web Analytics serves the evaluation of
          visitor traffic of online services and can determine their behavior or
          interests in certain information, such as content of websites. With the
          help of web analytics, website owners, for example, can recognize at what
          time visitors visit their website and what content they are interested in.
          This enables them, for example, to better adapt the content of their
          websites to the needs of their visitors. For the purposes of web analytics
          , pseudonymous cookies and web beacons are often used to recognize
          returning visitors and thus obtain more precise analyses of the use of an
          online service.{" "}
        </li>
      </ul>
    </div>
  )
}

export default DataStatementContent