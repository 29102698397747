import { Placement } from "react-joyride"
import { useState, useEffect } from 'react'
import Joyride, { ACTIONS, EVENTS, STATUS, Step, CallBackProps } from 'react-joyride'
import { getUserGuideStyleOptions } from "./style"
import { Typography, useTheme } from "@mui/material"
import { devlog, sleep } from "../../lib/misc"
import { contentStyle, contentVariant, mainEditorTutorialCompletedStorageKey } from "./misc"

export const steps: Step[] = [
    {
        title: 'Welcome to the Protoslides Editor!',
        content: <Typography variant={contentVariant} style={contentStyle}>Let's find out, how our users create their beautiful slides!</Typography>,
        target: '.editor',
        placement: 'top' as Placement,
        disableBeacon: true
    },
    {
        title: 'Presentation & Slide management',
        target: '.left_drawer_handle',
        placement: 'right-start' as Placement,
        content: <Typography variant={contentVariant}>
            You find all your presentations in the presentation panel on the left.
        </Typography>,
        disableBeacon: true
    },
    {
        title: 'Master management',
        target: '.manage_masters_button',
        placement: 'bottom' as Placement,
        content: <Typography variant={contentVariant} style={contentStyle}>
            <b>Protoslides is built around real Powerpoint Masters</b> (holding color palette, slide layouts & more).
            That means every presentation in Protoslides has a Powerpoint Master assigned to it.<br /><br />
            Based on the Master, the AI-Designer automatically handles color assignment & style for you.
        </Typography>,
        disableBeacon: true
    },
    {
        title: 'Master management',
        target: '.manage_masters_button',
        placement: 'bottom' as Placement,
        content: <Typography variant={contentVariant} style={contentStyle}>
            Of course you can upload your own Powerpoint file to use <b>your own master!</b><br /><br />

            Unlike in Powerpoint, you can <b>easily change the master of a presentation at any time</b>,
            e.g. if you need the slides for a different client.<br /><br />

            When <b>downloading your final slides</b>, you will receive them within your originally uploaded Master pptx-file,
            ready to be distributed to your professional contacts.
        </Typography>,
        disableBeacon: true
    },
    {
        title: 'Master management',
        target: '.manage_masters_button',
        placement: 'bottom' as Placement,
        content: <Typography variant={contentVariant} style={contentStyle}>
            Use the <b>master management</b> to organize your masters. You can:
            <ul>
                <li><b>Upload your own master to use its style and adhere to corporate design</b><br />(an empty pptx file will do).</li>
                <li><b>Or just keep going with the preloaded masters for now.</b></li>
            </ul>
        </Typography>,
        disableBeacon: true
    },
    {
        title: 'Create presentations',
        target: '.create_presi_button',
        placement: 'bottom' as Placement,
        content: <Typography variant={contentVariant} style={contentStyle}>To get started, <b>create a new presentation</b> by clicking the plus icon,<br /> or select one of the preloaded presentations.
        </Typography>,
        disableBeacon: true
    }
]

type TutorialProps = {
    openLeftDrawer: () => void
}

function MainEditorTutorial({ openLeftDrawer }: TutorialProps) {
    const theme = useTheme()
    const [showJoyride, setShowJoyride] = useState<boolean>(false)
    const [stepIndex, setStepIndex] = useState<number>(0)

    const openLeftDrawerWithDelay = async () => {
        await sleep(10)
        openLeftDrawer()
        await sleep(10)
        openLeftDrawer()
        await sleep(100)
        openLeftDrawer()
    }

    const handleJoyrideCallback = async (data: CallBackProps) => {
        const { status, type, action, index } = data
        devlog(data)

        if (action === ACTIONS.NEXT && type === EVENTS.STEP_AFTER) {
            if (index === 0) gtag("event", "tutorial_begin")
            await openLeftDrawerWithDelay()
            setStepIndex(stepIndex + 1)
        }

        if (action === ACTIONS.PREV && type === EVENTS.STEP_AFTER) {
            await openLeftDrawerWithDelay()
            setStepIndex(stepIndex - 1)
        }

        if (action === ACTIONS.SKIP) {
            // Set a flag in local storage to indicate that the tutorial has been completed
            localStorage.setItem(mainEditorTutorialCompletedStorageKey, 'true')
            // Hide Joyride
            setShowJoyride(false)
        }
        if (status === STATUS.FINISHED) {
            gtag("event", "tutorial_complete")
            // Set a flag in local storage to indicate that the tutorial has been completed
            localStorage.setItem(mainEditorTutorialCompletedStorageKey, 'true')
            // Hide Joyride
            setShowJoyride(false)
        }
    }

    useEffect(() => {
        // Check local storage for the flag
        const joyrideCompleted = localStorage.getItem(mainEditorTutorialCompletedStorageKey)

        // Show Joyride only if the tutorial has not been completed
        setShowJoyride(!joyrideCompleted)
    }, [])

    return (
        <Typography variant="body2">
            {showJoyride && (
                <Joyride
                    steps={steps}
                    styles={{ options: getUserGuideStyleOptions(theme) }}
                    continuous
                    showProgress
                    showSkipButton
                    scrollToFirstStep
                    disableOverlay
                    hideCloseButton
                    disableCloseOnEsc
                    run={true}
                    callback={handleJoyrideCallback}
                    stepIndex={stepIndex}
                    disableScrolling
                />
            )}
            {/* Your main component content here */}
        </Typography>
    )
}

export default MainEditorTutorial
