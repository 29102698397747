import { useState, useRef } from 'react'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import PositionSelector from '../helpers/PositionSelector'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Slider from '@mui/material/Slider'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Grid from '@mui/material/Unstable_Grid2'
import TableSelectionRangeGroup from '../pptElements/table1/TableSelectionRangeGroup'
import ProgressBar from '../helpers/ProgressBar'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import { tableSettingsPanelMaxWidth, tableSettingsPositionSelectorDimensions } from '../../lib/style'

interface ProgressSettingsProps {
    currentProgressState: ProgressBarSettings | undefined,
    handleProgressStateChange: (newProgressState: ProgressBarSettings, tableRange: TableSelectionRange | undefined) => void,
    allowedPositions?: PositionKey[]
    allowedSizes?: IconSize[]
    rowCount?: number
    colCount?: number
    rIdx?: number
    cIdx?: number
}

function ProgressSettings({ handleProgressStateChange, currentProgressState, allowedPositions, allowedSizes, rowCount, colCount, cIdx, rIdx }: ProgressSettingsProps) {
    const theme = useTheme()
    const showSelectionIndicator = rowCount !== undefined && colCount !== undefined && rIdx !== undefined && cIdx !== undefined
    if (currentProgressState === undefined) currentProgressState = initialProgressSettings

    //state
    const [progressValue, setProgressValue] = useState<number>(currentProgressState?.value)
    const progressValueRef = useRef<number>()
    progressValueRef.current = progressValue
    const [selectionRange, setSelectionRange] = useState<TableSelectionRange | undefined>(showSelectionIndicator ? { startRow: rIdx, startCol: cIdx, endRow: rIdx, endCol: cIdx } : undefined)

    const handleValueChange = async (event: Event, newValue: number | number[]) => {
        let newValueNumber = newValue as number
        setProgressValue(newValueNumber)
        if (progressValueRef.current) {
            // remember current value to avoid many quick changes and BE calls
            let thisValueNumber = newValue
            // sleep to wait for other quick changes
            await new Promise(r => setTimeout(r, 1000))
            // only update slide state if the current value is stll the same as when the function was called
            if (thisValueNumber === progressValueRef.current.valueOf() && currentProgressState) handleProgressStateChange({ ...currentProgressState, value: newValueNumber }, selectionRange)
        }
    }

    const handlePositionClick = async (newPosition: PositionKey) => {
        if (currentProgressState) {
            // toggle visibility if clicking on the same position
            let newVisible = currentProgressState.visible
            if (newPosition === currentProgressState.position) { newVisible = !newVisible }
            else { newVisible = true }
            handleProgressStateChange({ ...currentProgressState, position: newPosition, visible: newVisible }, selectionRange)
        }
    }

    const handleSizeChange = async (event: SelectChangeEvent) => {
        if (currentProgressState) handleProgressStateChange({ ...currentProgressState, size: Number(event.target.value) }, selectionRange)
    }

    const handleSegmentsChange = async (event: SelectChangeEvent) => {
        if (currentProgressState) handleProgressStateChange({ ...currentProgressState, segments: Number(event.target.value) }, selectionRange)
    }

    return (
        <Grid container spacing={theme.customStyling.panelGridSpacing} sx={{ maxWidth: tableSettingsPanelMaxWidth }}>


            {/* The settings */}
            <Grid xs={12} sm={showSelectionIndicator ? 6 : 12} >
                <div>
                    <Grid container spacing={0} >

                        <Paper elevation={theme.customStyling.panelGridItemElevation} sx={{ ...theme.customStyling.panelGridItemSx }}>

                            {/* The current progress ball */}
                            <Grid xs={showSelectionIndicator ? 6 : 4} >
                                {currentProgressState &&
                                    <Box sx={{ ...theme.customStyling.panelInnerGridItemSx }}>
                                        <Typography variant="button" color='text.disabled' sx={{ ...theme.customStyling.panelGridItemTitleSx }}>Current</Typography>
                                        <Box sx={{ ...tableSettingsPositionSelectorDimensions, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <ProgressBar value={progressValue} segments={currentProgressState.segments} />
                                        </Box>
                                    </Box>}
                            </Grid>

                            {/* The position selector */}
                            <Grid xs={showSelectionIndicator ? 6 : 4} sx={{ pl: 2 }}>
                                {allowedPositions &&
                                    <Box sx={{ ...theme.customStyling.panelInnerGridItemSx }}>
                                        <Typography variant="button" color='text.disabled' sx={{ ...theme.customStyling.panelGridItemTitleSx }}>Position</Typography>
                                        <Box sx={{ ...tableSettingsPositionSelectorDimensions }}>
                                            <PositionSelector visible={currentProgressState.visible} allowedPositions={allowedPositions} currentPosition={currentProgressState.position} handleNewPosition={handlePositionClick}></PositionSelector>
                                        </Box>
                                    </Box>

                                }
                            </Grid>

                            {/* The size selector */}
                            <Grid xs={showSelectionIndicator ? 12 : 4} >
                                {allowedSizes &&
                                    <Box sx={{ ...theme.customStyling.panelInnerGridItemSx, width: 1 }}>
                                        <Typography variant="button" color='text.disabled' sx={{ ...theme.customStyling.panelGridItemTitleSx }}>Size</Typography>
                                        <Select
                                            fullWidth
                                            value={String(currentProgressState.size)}
                                            label=""
                                            size='small'
                                            onChange={handleSizeChange}
                                            sx={{ mt: 0 }}
                                        >
                                            {allowedSizes.map((allowedSize, i: number) => (
                                                <MenuItem key={i} value={String(allowedSize.size)}>{allowedSize.displayName}</MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                }
                            </Grid>
                        </Paper>
                    </Grid>
                </div>
            </Grid>

            {/* The apply range selector */}
            {showSelectionIndicator && selectionRange &&
                <Grid xs={12} sm={6} sx={{ mt: { xs: -6, sm: 0 } }} >
                    <Paper elevation={theme.customStyling.panelGridItemElevation} sx={{ ...theme.customStyling.panelGridItemSx }}>
                        <Box sx={{ ...theme.customStyling.panelInnerGridItemSx }}>
                            <Typography variant="button" color='text.disabled' sx={{ ...theme.customStyling.panelGridItemTitleSx }}>Apply changes to</Typography>
                            <TableSelectionRangeGroup rowCount={rowCount} colCount={colCount} currentRange={selectionRange} handleTableRangeChange={setSelectionRange}
                                rIdx={rIdx} cIdx={cIdx} />
                        </Box>
                    </Paper>
                </Grid>}

            {/* The value slider */}
            <Grid xs={12}>
                <Paper elevation={theme.customStyling.panelGridItemElevation} sx={{ ...theme.customStyling.panelGridItemSx, mt: -6 }} >
                    <Box sx={{ ...theme.customStyling.panelInnerGridItemSx }}>
                        <Typography variant="button" color='text.disabled' sx={{ ...theme.customStyling.panelGridItemTitleSx }}>Adjust Progress Bar Value</Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'no-wrap', alignItems: 'flex-end', width: 1 }}>
                            <Slider
                                sx={{ width: '65%' }}
                                valueLabelDisplay="auto"
                                step={0.05}
                                marks
                                min={0}
                                max={1}
                                value={progressValue}
                                onChange={handleValueChange}
                            />
                            <FormControl sx={{ ml: 4, width: '25%' }}>
                                <InputLabel >Segments</InputLabel>
                                <Select
                                    fullWidth
                                    value={String(currentProgressState.segments)}
                                    label="Segments"
                                    onChange={handleSegmentsChange}
                                    size='small'
                                >
                                    <MenuItem value={String(2)}>2</MenuItem>
                                    <MenuItem value={String(3)}>3</MenuItem>
                                    <MenuItem value={String(4)}>4</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                </Paper>
            </Grid>
        </Grid >

    )
}

export const initialProgressSettings = { visible: false, size: 1.5, position: 'CENTERCENTER' as PositionKey, value: 0.25, segments: 4 }

export default ProgressSettings