import { Link } from 'react-router-dom'
import { Paper, Button, Typography, Chip, Box, styled, Divider } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { paymentPath, securePagesPath } from "../../lib/constants"
import { featureSpecs } from './featureList'
import Feature from './Feature'

type SubscriptionOptionProps = { option: SubscriptionOptionSpec, discount?: number }

const RedStrikethrough = styled('span')({
    position: 'relative',
    '::after': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        borderBottom: '3px solid red',
        bottom: '45%',
        left: 0,
    },
})

const SubscriptionOption = ({ option, discount }: SubscriptionOptionProps) => {
    const theme = useTheme()

    return (
        <Paper elevation={3} sx={{ p: 2, height: 1, textAlign: 'center' }}>
            <Box>
                <Typography variant="h5" gutterBottom color='primary'>
                    {option.name}
                </Typography>
            </Box>
            <Box>
                <Chip label={option.chip} color='success' sx={{ opacity: 0.7 }} />
            </Box>
            <Box sx={{ mt: 4 }}>
                <Typography variant="h4" color='primary'>
                    {discount !== undefined && discount > 0 &&
                        <span style={{ marginRight: '8px' }}>
                            <RedStrikethrough>
                                {Number(option.pricePerIntervalUnit) + discount}
                            </RedStrikethrough></span>}
                    {option.pricePerIntervalUnit} {option.currencySymbol}
                </Typography>
            </Box>
            <Box sx={{ mt: -1 }}>
                <Typography variant="caption" color='primary'>
                    {option.currencyCode} / {option.intervalUnitDisplayText}
                </Typography>
            </Box>
            <Box sx={{ mt: 4 }}>
                <Button
                    component={Link}
                    to={`/${securePagesPath}/${paymentPath}/${option.id}`}
                    variant="contained"
                    color="primary"
                    fullWidth
                >
                    Select
                </Button>
            </Box>
            <Box sx={{ mt: 1, mb: 1 }}>
                <Typography variant="subtitle2" color={theme.palette.text.disabled} gutterBottom>
                    {option.paymentPrice} {option.currencySymbol} every {option.intervalCount > 1 && option.intervalCount} {option.intervalUnitDisplayText}{option.intervalCount > 1 && 's'}
                </Typography>
            </Box>
            <Divider />
            <Box sx={{ width: 1, display: 'flex', flexWrap: 'wrap', mt: 2, textAlign: 'left' }}>
                {featureSpecs.map((text) => (
                    <Feature text={text} />
                ))}
            </Box>
        </Paper>
    )
}

export default SubscriptionOption
