import { useState } from 'react'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Unstable_Grid2'
import { Tooltip } from '@mui/material'
import ExcelPasteListener from '../../helpers/ExcelPasteListener'
import { FaBarsProgress } from 'react-icons/fa6'
import { BiSolidPieChartAlt } from 'react-icons/bi'
import ToggleButton from '@mui/material/ToggleButton'
import TextFieldsIcon from '@mui/icons-material/TextFields'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { TbColorFilter } from 'react-icons/tb'
import _, { lowerCase } from 'lodash'
import { initialHarveySettings } from '../../elements/HarveySettings'
import { initialProgressSettings } from '../../elements/ProgressBarSettings'
import { initialCheckmarkSettings } from '../../elements/CheckMarkSettings'
import { initialColorStateSettings } from '../../elements/ColorStateSettings'

interface ExcelPastePanelProps {
    handleNewExcelTable: (newTable: TableRow[]) => void
}

function ExcelPastePanel({ handleNewExcelTable }: ExcelPastePanelProps) {
    const theme = useTheme()
    const buttonStyle = { width: '25px', height: '25px' }

    const [numericAsHarvey, setNumericAsHarvey] = useState(true)
    const [numericAsProgress, setNumericAsProgress] = useState(false)
    const [numericAsText, setNumericAsText] = useState(false)

    const [boolAsCheckmark, setBoolAsCheckmark] = useState(true)
    const [boolAsText, setBoolAsText] = useState(false)

    const [colorAsColorState, setColorAsColorState] = useState(true)
    const [colorAsText, setColorAsText] = useState(false)

    const handlePaste = async (table: TableRow[]) => {
        const newTable = _.cloneDeep(table)
        for (let rIdx = 0; rIdx < newTable.length; rIdx++) {
            for (let cIdx = 0; cIdx < newTable[rIdx].length; cIdx++) {
                const currentCell = newTable[rIdx][cIdx]
                // Assume we only get one paragraph from Excel
                const currentText = currentCell.textSettings.richText[0].children[0].text
                const value = parsePercentageStringToNumber(currentText)

                // numeric representations
                if (!isNaN(value) && value >= 0 && value <= 1) {
                    currentCell.harveyBallSettings = { ...initialHarveySettings }
                    currentCell.harveyBallSettings.value = value
                    currentCell.progressBarSettings = { ...initialProgressSettings }
                    currentCell.progressBarSettings.value = value
                    if (numericAsHarvey) currentCell.harveyBallSettings.visible = true
                    if (numericAsProgress) currentCell.progressBarSettings.visible = true
                    currentCell.textSettings.visible = numericAsText
                }

                // Bool representations
                if (['yes', 'no', 'y', 'n'].includes(lowerCase(currentText))) {
                    if (boolAsCheckmark) {
                        currentCell.checkMarkSettings = { ...initialCheckmarkSettings }
                        currentCell.checkMarkSettings.checked = ['yes', 'y'].includes(lowerCase(currentText))
                        currentCell.checkMarkSettings.visible = true
                    }
                    currentCell.textSettings.visible = boolAsText
                }

                // Color representations
                if (['red', 'green', 'blue', 'yellow'].includes(lowerCase(currentText))) {
                    if (colorAsColorState) {
                        currentCell.colorStateSettings = { ...initialColorStateSettings }
                        currentCell.colorStateSettings.visible = true
                        if (lowerCase(currentText) === 'red') currentCell.colorStateSettings.color = 'RED'
                        if (lowerCase(currentText) === 'green') currentCell.colorStateSettings.color = 'GREEN'
                        if (lowerCase(currentText) === 'blue') currentCell.colorStateSettings.color = 'BLUE'
                        if (lowerCase(currentText) === 'yellow') currentCell.colorStateSettings.color = 'YELLOW'
                    }
                    currentCell.textSettings.visible = colorAsText
                }
            }
        }
        handleNewExcelTable(newTable)
    }

    return (
        <Grid container spacing={theme.customStyling.panelGridSpacing} sx={{ width: { xs: '500px', sm: '600px' } }}>
            {/* The paste prompt */}
            <Grid xs={6} >
                <Paper elevation={theme.customStyling.panelGridItemElevation} sx={{ ...theme.customStyling.panelGridItemSx }}>
                    <Box sx={{ ...theme.customStyling.panelInnerGridItemSx, width: 1 }}>
                        <Typography variant="button" color='text.disabled' sx={{ ...theme.customStyling.panelGridItemTitleSx, textAlign: 'right' }}>Paste Excel cells</Typography>
                        <ExcelPasteListener handlePaste={handlePaste} />
                    </Box>
                </Paper>
            </Grid>

            {/* The import options */}
            <Grid xs={6} >
                <Paper elevation={theme.customStyling.panelGridItemElevation} sx={{ ...theme.customStyling.panelGridItemSx }}>
                    <Box sx={{ ...theme.customStyling.panelInnerGridItemSx, width: 1 }}>

                        <Typography variant="button" color='text.disabled' sx={{ ...theme.customStyling.panelGridItemTitleSx }}>Import options</Typography>
                        <Box>
                            <Typography variant="body2" >Show numeric values (0..1) as:</Typography>
                            <Tooltip title="Harvey Ball">
                                <ToggleButton onChange={() => { setNumericAsProgress(!numericAsHarvey ? false : numericAsProgress); setNumericAsHarvey(!numericAsHarvey) }} selected={numericAsHarvey} value="">
                                    <BiSolidPieChartAlt style={buttonStyle} />
                                </ToggleButton>
                            </Tooltip>
                            <Tooltip title="Progress Bar">
                                <ToggleButton onChange={() => { setNumericAsHarvey(!numericAsProgress ? false : numericAsHarvey); setNumericAsProgress(!numericAsProgress) }} selected={numericAsProgress} value="" >
                                    <FaBarsProgress style={buttonStyle} />
                                </ToggleButton>
                            </Tooltip>
                            <Tooltip title="Show as text">
                                <ToggleButton onChange={() => { setNumericAsText(!numericAsText) }} sx={{ ml: 1 }} selected={numericAsText} value="">
                                    <TextFieldsIcon style={buttonStyle} />
                                </ToggleButton>
                            </Tooltip>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="body2" >Show "yes/no/y/n" as:</Typography>
                            <Tooltip title="Check mark">
                                <ToggleButton onChange={() => { setBoolAsCheckmark(!boolAsCheckmark) }} selected={boolAsCheckmark} value="">
                                    <CheckCircleOutlineIcon style={buttonStyle} />
                                </ToggleButton>
                            </Tooltip>
                            <Tooltip title="Show as text">
                                <ToggleButton onChange={() => { setBoolAsText(!boolAsText) }} sx={{}} selected={boolAsText} value="">
                                    <TextFieldsIcon style={buttonStyle} />
                                </ToggleButton>
                            </Tooltip>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="body2" >Show "red/green/yellow/blue" as:</Typography>
                            <Tooltip title="Color State">
                                <ToggleButton onChange={() => { setColorAsColorState(!colorAsColorState) }} selected={colorAsColorState} value="">
                                    <TbColorFilter style={buttonStyle} />
                                </ToggleButton>
                            </Tooltip>
                            <Tooltip title="Show as text">
                                <ToggleButton onChange={() => { setColorAsText(!colorAsText) }} sx={{}} selected={colorAsText} value="">
                                    <TextFieldsIcon style={buttonStyle} />
                                </ToggleButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </Paper>
            </Grid>
        </Grid >

    )
}

function parsePercentageStringToNumber(cellText: string) {
    if (typeof cellText !== 'string') {
        return NaN // Return NaN if the value is not a string
    }

    if (cellText.includes('%')) {
        // Remove the percentage symbol, parse the float, and divide by 100
        return parseFloat(cellText.replace(',', '.')) / 100
    } else {
        return parseFloat(cellText.replace(',', '.')) // If it's not a percentage, parse the float
    }
}

export default ExcelPastePanel