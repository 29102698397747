import { useEffect } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Global } from '@emotion/react'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import { useTheme } from '@mui/material/styles'
import { ClickAwayListener } from '@mui/material'
import Typography from '@mui/material/Typography'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import { sleep } from '../../lib/misc'

const temp_elevation = 16
const pers_elevation = 2


interface EdgeDrawerProps {
    children: React.ReactNode, open: boolean, setOpen: (newOpen: boolean) => void, setDrawerLock?: (newLock: boolean) => void, locked?: boolean,
    anchor: "left" | "right", bleeding: number, width: number, behavior: 'persistent' | 'temporary', edgeText: string
}

export default function EdgeDrawer({ children, open, setOpen, anchor, bleeding, width, behavior, edgeText, setDrawerLock, locked }: EdgeDrawerProps) {

    const theme = useTheme()
    const greaterThanSm = useMediaQuery(theme.breakpoints.up("md"))
    const visible = (behavior === 'persistent' && greaterThanSm) || (behavior !== 'persistent' && !greaterThanSm)

    // If breakpoint changes we open and close drawer to prevent handler to 'hang' in the middle of the page
    useEffect(() => {
        const openCloseDrawer = async () => {
            await sleep(10)
            setOpen(true)
            await sleep(100)
            setOpen(false)
        }

        openCloseDrawer()
    }, [greaterThanSm, setOpen])

    return (
        <Box>
            {visible &&
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <Box sx={{ backgroundColor: theme.palette.background.paper }}
                        onMouseEnter={behavior === 'persistent' ? () => setOpen(true) : undefined} onMouseLeave={behavior === 'persistent' ? () => setOpen(false) : undefined}>
                        <Global
                            styles={{
                                '.MuiDrawer-root > .MuiPaper-root': {
                                    width: `calc(${width}px - ${bleeding}px)`,
                                    overflow: 'visible',
                                    borderWidth: 0
                                },
                            }}
                        />

                        <Drawer
                            anchor={anchor}
                            open={open}
                            variant={'persistent'}
                            onClose={() => setOpen(false)}
                            ModalProps={{
                                keepMounted: true,
                            }}
                        //sx={{ display: behavior === 'persistent' ? { xs: 'none', md: 'flex' } : { xs: 'flex', md: 'none' } }}
                        >
                            {/* Children */}
                            <Paper elevation={behavior === 'persistent' ? pers_elevation : temp_elevation}
                                sx={{
                                    display: 'flex', flexGrow: 1, overflow: 'hidden', borderTopLeftRadius: 0, borderTopRightRadius: 0
                                }}>
                                {children}
                            </Paper>

                            {/* Handle */}
                            <Paper elevation={behavior === 'persistent' ? pers_elevation : temp_elevation}
                                sx={anchor === 'left' ?
                                    {
                                        position: 'absolute', right: -bleeding, width: bleeding, borderTopLeftRadius: 0, borderBottomLeftRadius: 0,
                                        visibility: 'visible', WebkitClipPath: "inset(-50px -50px -50px 0px)", ClipPath: "inset(-50px -50px -50px 0px)",
                                        top: 0, height: '200px'
                                    }
                                    :
                                    {
                                        position: 'absolute', left: -bleeding, width: bleeding, borderTopRightRadius: 0, borderBottomRightRadius: 0,
                                        visibility: 'visible', WebkitClipPath: "inset(-50px 0px -50px -50px)", ClipPath: "inset(-50px 0px -50px -50px)",
                                        top: 0, height: '200px'
                                    }
                                }
                                onClick={() => setOpen(!open || !!locked)}
                            >
                                {/* Edgetext */}
                                <Box sx={{ position: 'relative', width: 1, height: 1, cursor: 'pointer', display: 'grid', alignItems: 'center', gridTemplateColumns: '1fr' }} className={anchor === 'left' ? 'left_drawer_handle' : 'right_drawer_handle'}>
                                    <Typography variant="button" color='text.disabled'
                                        sx={{
                                            mt: 1, writingMode: 'vertical-rl', transform: anchor === 'left' ? 'rotate(-180deg)' : undefined,
                                            gridColumnStart: 1, gridRowStart: 1
                                        }}>
                                        {edgeText}
                                    </Typography>
                                    {setDrawerLock &&
                                        <Box sx={{ height: 1, mb: 1, display: 'flex', gridColumnStart: 1, gridRowStart: 1, alignItems: 'flex-end' }}>
                                            {locked ?
                                                <LockIcon color='disabled' onClick={(e) => { setDrawerLock(!locked) }} />
                                                : <LockOpenIcon color='disabled' onClick={(e) => { setDrawerLock(!locked) }} />
                                            }
                                        </Box>
                                    }
                                </Box>

                            </Paper>
                        </Drawer>
                    </Box >
                </ClickAwayListener>
            }
        </Box>
    )
}
