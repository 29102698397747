import Box from '@mui/material/Box'
import { elementDefinitions } from '../pptElements/ElementDefinitions'
import { Element } from './Element'

function ElementList() {
  return (
    <Box sx={{ display: 'flex', overflow: 'hidden', flexGrow: 1, flexDirection: 'column' }}>
      <Box sx={{ width: 1, overflow: 'auto', flexGrow: 1, p: 1 }}>
        {elementDefinitions.map((el: ElementDefinition, i: number) => (
          <Box sx={{ width: 1, mb: 1 }} key={String(i)} >
            <Element el={el} />
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default ElementList