import React from 'react'
import { Grid, Typography, Box, useTheme } from '@mui/material'

interface ProductFeature {
    icon: React.ReactNode
    subtitle: string
    text: string
}

interface ProductFeaturesProps {
    features: ProductFeature[]
}

const ProductFeatures: React.FC<ProductFeaturesProps> = ({ features }) => {
    const theme = useTheme()

    return (
        <Box sx={{ width: 1, height: 1, display: 'flex', justifyContent: "center", alignItems: "center", flexWrap: 'wrap' }}>

            <Grid container spacing={4} sx={{ maxWidth: 1500 }}>
                {features.map((feature, index) => (
                    <Grid key={index} item xs={12} sm={6} md={4} sx={{
                        display: 'flex', justifyContent: "center", alignItems: "flex-start", flexWrap: 'wrap', alignContent: 'flex-start',
                        mt: 8,
                    }}
                    >
                        <Box sx={{
                            display: 'grid', width: 100, height: 100, gridTemplateColumns: '1fr',
                            justifyContent: "center", alignItems: "center", mb: 4
                        }}>
                            <Box sx={{
                                borderRadius: "50%", width: 1, height: 1, bgcolor: "primary.main",
                                opacity: 0.2, gridColumnStart: 1, gridRowStart: 1
                            }}></Box>
                            <Box sx={{
                                color: "primary.main", width: 1, height: 1, gridColumnStart: 1, gridRowStart: 1,
                                display: 'flex', justifyContent: "center", alignItems: "center"
                            }}>
                                <Box sx={{ width: 0.5, height: 0.5 }}>
                                    {feature.icon}
                                </Box>
                            </Box>
                        </Box>

                        <Typography variant="h6" sx={{ width: 1, textAlign: 'center' }}>{feature.subtitle}</Typography>
                        <Typography variant="body1" color={theme.palette.text.secondary} sx={{ width: 1, textAlign: 'center' }}>{feature.text}</Typography>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default ProductFeatures
