import { useState, cloneElement, ReactNode, ReactElement, isValidElement } from 'react'
import Tooltip from '@mui/material/Tooltip'
import { Menu, MenuItem, ToggleButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'

type SecondaryButton = { icon: React.ReactNode; onClick: (event: React.MouseEvent<HTMLElement>) => void; tooltip: string }

interface DropdownButtonProps {
    mainButtonIcon: React.ReactNode
    mainButtonTooltip: string
    secondaryButtons: SecondaryButton[]
    buttonHeight: string
}

function DropdownButton({ mainButtonIcon, mainButtonTooltip, secondaryButtons, buttonHeight }: DropdownButtonProps) {
    const theme = useTheme()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    function styleIcon(mainContent: ReactNode): ReactElement | null {
        if (isValidElement(mainContent)) {
            return cloneElement(
                mainContent as ReactElement,
                { style: { width: buttonHeight, height: buttonHeight, color: theme.palette.action.active } }
            )
        }
        return null
    }

    const handleMainClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleSubClick = (event: React.MouseEvent<HTMLElement>, button: SecondaryButton) => {
        handleClose()
        button.onClick(event)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div>
            <Tooltip title={mainButtonTooltip}>
                <ToggleButton onClick={handleMainClick} style={{ cursor: 'pointer', padding: '7px', borderWidth: '0px' }} value={''}>
                    {styleIcon(mainButtonIcon)}
                </ToggleButton>
            </Tooltip>
            <Menu
                id="menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {secondaryButtons.map((button, index) => (
                    <Tooltip key={index} title={button.tooltip} placement="right">
                        <MenuItem onClick={(event: React.MouseEvent<HTMLElement>) => handleSubClick(event, button)} sx={{ p: 1, mr: 1, ml: 1 }}>
                            {styleIcon(button.icon)}
                        </MenuItem>
                    </Tooltip>
                ))}
            </Menu>
        </div>
    )
}

export default DropdownButton
