import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './store'

// Type definitions
const initialState: Record<string, SubscriptionOptionSpec> = {}
type SubscriptionOptionsPayload = { payload: Record<string, SubscriptionOptionSpec> }

// slice definition
export const subscriptionOptionsSlice = createSlice({
  name: 'subscriptionOptions',
  initialState,
  reducers: {
    setSubscriptionOptions: (state, action: SubscriptionOptionsPayload) => {
      return action.payload
    },
  },
})

//actions
export const { setSubscriptionOptions } = subscriptionOptionsSlice.actions

// selectors
export const getSubscriptionOptions = (state: RootState) => state.subscriptionOptions

export default subscriptionOptionsSlice.reducer
