import { Box, useTheme } from '@mui/material'

type PaletteProps = {
    handlePaletteClick: (xmlKey: string) => void,
    selectedXMLKey?: string | null,
    currentMasterId: string | undefined,
    masters: Masters,
    colorBoxWidth?: number
}

function Palette({ handlePaletteClick, currentMasterId, masters, selectedXMLKey, colorBoxWidth = 20 }: PaletteProps) {
    const theme = useTheme()
    const currentMaster = currentMasterId ? masters[currentMasterId] : undefined

    return (
        <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
            {currentMaster && Object.entries(currentMaster.logicalMaster.themeColors).map(([key, value]) => (
                <Box sx={{
                    width: colorBoxWidth, height: colorBoxWidth, backgroundColor: `#${value}`, mr: 0.7, cursor: 'pointer',
                    ...((selectedXMLKey === key) && { outline: "solid 3px", outlineColor: theme.palette.primary.main }),
                    ...((selectedXMLKey !== key) && { border: "solid 1px", borderColor: theme.palette.text.disabled })
                }}
                    key={key} onClick={() => handlePaletteClick(key)}>
                </Box>
            ))}

        </Box>
    )
}

export default Palette