import React from 'react'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import { hexToRgba } from '../../lib/colors'

interface NeutralCircleButtonProps {
    children: React.ReactNode, size: number
}

function NeutralCircleButton({ children, size }: NeutralCircleButtonProps) {
    const theme = useTheme()
    const bgCol = theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800]

    return (
        <Box sx={{ height: size + 'px', width: size + 'px', display: 'grid', alignItems: 'center', gridTemplateColumns: '1fr' }}>
            <Box sx={{
                height: size + 'px', width: size + 'px', borderRadius: '50%', zIndex: 0,
                backgroundColor: hexToRgba(bgCol, 0.7),
                boxShadow: theme.shadows[0], gridColumnStart: 1, gridRowStart: 1,
                WebkitBackdropFilter: "blur(3px)",
                backdropFilter: "blur(3px)"
            }}>
            </Box>
            <Box sx={{
                height: size + 'px', width: size + 'px', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'visible',
                gridColumnStart: 1, gridRowStart: 1, zIndex: 1
            }}>
                {children}
            </Box>
        </Box>
    )
}

export default NeutralCircleButton