import { styled } from '@mui/material/styles'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'


export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0),
        border: 0,
        '&.Mui-disabled': {
            border: 0,
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}))

// slotProps={{
//     paper: {
//       sx: {
//         backgroundColor: hexToRgba(theme.palette.background.default, 0.7),
//         WebkitBackdropFilter: "blur(20px)",
//         backdropFilter: "blur(20px)"
//       }
//     }
//   }}

export const centeredFullWidthWrappingFlex = { width: 1, display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }

// table component
export const tableSettingsPanelMaxWidth = { xs: '500px', sm: '550px' }
export const tableSettingsPanelMaxWidthNoRangeSelector = { xs: '400px', sm: '450px' }
export const tableSettingsPositionSelectorDimensions = { width: '75px', height: '75px' }