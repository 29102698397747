import { FC } from 'react'
import { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Masters from '../masters/Masters'
import { Box } from '@mui/system'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

interface DialogProps {
  masters: Masters, handleClose: () => void,
  createPresentation: (title: string, masterId: string) => void,
  open: boolean, title: string, width: string | null, closeButtonCaption: string, secondaryButtonCaption: string,
  loadingState: LoadingState
}

const PresentationCreateDialog: FC<DialogProps> = ({ masters, handleClose, createPresentation, open, title, width, closeButtonCaption, loadingState }) => {

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  // local state
  const [masterId, setMasterId] = useState<string | undefined>(undefined)
  const [presentationTitle, setPresentationTitle] = useState("")

  // handle master select click
  const handleMasterSelect = (masterId: string | undefined) => {
    if (masterId) setMasterId(masterId)
  }

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPresentationTitle(event.target.value)
  }

  const handleCreatePresentationClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    var title = presentationTitle
    if (title === "") title = "New Presentation"
    if (masterId) {
      createPresentation(title, masterId)
      handleClose()
    }
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth="xl"
    >
      <DialogTitle id="responsive-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <TextField sx={{ mb: 4 }} label="Presentation title" variant="standard" onChange={handleTitleChange} value={presentationTitle} autoFocus fullWidth />
        <Typography variant="subtitle1" sx={{ mb: 2 }} color={"text.secondary"}>Master</Typography>
        <Masters handleMasterClick={handleMasterSelect} masters={masters} currentMasterId={masterId} mastersInAnalysis={loadingState.MASTERANALYSIS} />
        {width && <Box sx={{ width: width }}></Box>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          {closeButtonCaption}
        </Button>
        <Button onClick={handleCreatePresentationClick}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PresentationCreateDialog