import { useCallback, useMemo, useState } from 'react'
import isHotkey from 'is-hotkey'
import Box from '@mui/material/Box'
import { Editable, withReact, useSlate, Slate, ReactEditor } from 'slate-react'
import { Editor, Transforms, createEditor, Descendant, BaseEditor, Element as SlateElement } from 'slate'
import FormatBoldIcon from '@mui/icons-material/FormatBold'
import FormatItalicIcon from '@mui/icons-material/FormatItalic'
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter'
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight'
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import { withHistory, HistoryEditor } from 'slate-history'
import Divider from '@mui/material/Divider'
import { CgFormatUppercase } from "react-icons/cg"
import ToggleButton from '@mui/material/ToggleButton'
import { StyledToggleButtonGroup } from '../../lib/style'
import { BiFontSize } from "react-icons/bi"
import { Slider, Tooltip, Typography } from '@mui/material'
import NoDragPopover from '../helpers/NoDragPopover'

// const
const LIST_TYPES = ['numbered-list', 'bulleted-list'] as const
const BLOCK_TYPES = ['paragraph', 'list-item', 'numbered-list', 'bulleted-list'] as const
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'] as const
const TEXT_STYLE_TYPES = ['bold', 'italic', 'underline', 'smallCaps'] as const
const HOTKEYS: Record<string, Format> = { 'mod+b': 'bold', 'mod+i': 'italic', 'mod+u': 'underline' }
const toolBarHeight = 35
const iconWidth = 24
const toolbarIconBoxSx = { display: 'flex', justifyContent: 'center', alignItems: 'center', height: `${toolBarHeight}px`, width: `${iconWidth}px`, overflow: 'hidden' }

//types
type ListType = typeof LIST_TYPES[number]
type BlockType = typeof BLOCK_TYPES[number]
type TextAlignType = typeof TEXT_ALIGN_TYPES[number]
type TextStyleType = typeof TEXT_STYLE_TYPES[number]
type Format = ListType | BlockType | TextAlignType | TextStyleType
type RenderElementProps = { attributes: object, children: JSX.Element, element: CustomElement }
type RenderLeafProps = { attributes: object, children: JSX.Element, leaf: CustomText }
type ToolbarButtonInput = { icon: JSX.Element, format: Format }
type CustomText = { text: string, bold?: boolean, italic?: boolean, underline?: boolean, smallCaps?: boolean }
type CustomElement = { type: BlockType, align?: TextAlignType, children: CustomText[] }
declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor & HistoryEditor
    Element: CustomElement
    Text: CustomText
  }
}

type RichTextProps = {
  textAreaHeight: number,
  richText: Descendant[],
  el: LogicalElement,
  textRole: TextRole,
  handleElementUserFontFactorChange: ElementUserFontFactorChangeFunction
  visibilityByFocus?: boolean,
  alwaysVisible?: boolean,
  handleRTChange?: (newRichText: Descendant[]) => void,
  handleRTFocus?: () => void,
  handleRTBlur?: (newRichText: Descendant[]) => void
}

function RichTextField({ textAreaHeight, richText, el, textRole, handleElementUserFontFactorChange, visibilityByFocus, alwaysVisible, handleRTChange, handleRTFocus, handleRTBlur }: RichTextProps) {

  // editor setup
  const renderElement = useCallback((props: JSX.IntrinsicAttributes & RenderElementProps) => <Element {...props} />, [])
  const renderLeaf = useCallback((props: JSX.IntrinsicAttributes & RenderLeafProps) => <Leaf {...props} />, [])
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])
  if (richText.length === 0) richText = [{ type: 'paragraph', children: [{ text: '' }] }]


  // local state
  const [visibleState, setVisibleState] = useState(false)
  const initialUserFontSize = el.userFontFactors ? el.userFontFactors[textRole] : 1
  const [currentUserFontSize, setcurrentUserFontSize] = useState<number>(initialUserFontSize || 1)
  const [fontSliderAnchorEl, setFontSliderAnchorEl] = useState<null | HTMLElement>(null)

  // functions
  const handleFocus = () => {
    if (visibilityByFocus) setVisibleState(true)
    if (handleRTFocus) handleRTFocus()
  }

  const handleBlur = () => {
    if (visibilityByFocus) setVisibleState(false)
    if (handleRTBlur) handleRTBlur(editor.children)
  }

  const handleChange = (value: Descendant[]) => {
    const isAstChange = editor.operations.some(op => 'set_selection' !== op.type)
    if (isAstChange) {
      if (handleRTChange) handleRTChange(value)
    }
  }

  const toggleFontSlider = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    fontSliderAnchorEl === null ? setFontSliderAnchorEl(event.currentTarget) : setFontSliderAnchorEl(null)
  }

  const handleFontSizeSliderChangeCommit = (event: Event | React.SyntheticEvent<Element, Event>, value: number | number[]) => {
    if (typeof value === 'number') {
      handleElementUserFontFactorChange(el, textRole, value)
    }
  }

  const handleFontSizeSliderChange = (event: Event | React.SyntheticEvent<Element, Event>, value: number | number[]) => {
    if (typeof value === 'number') {
      setcurrentUserFontSize(value)
    }
  }

  const formatValueLabel = (value: number) => {
    return `${Math.round(value * 100)} %`
  }

  const effVisibility = (visibleState || !!alwaysVisible)

  return (
    <Box sx={{
      width: 1,
      height: 1,
      boxSizing: 'border-box',
      pt: effVisibility ? `${toolBarHeight}px` : undefined,
      opacity: effVisibility ? 1 : 0,
    }}>
      <Slate
        editor={editor}
        value={richText}
        onChange={handleChange}
        key={JSON.stringify(richText)}
      >
        {effVisibility &&
          <Box sx={{ width: 1, mt: `${-toolBarHeight}px`, mb: 0, overflowX: "auto", scrollbarWidth: "thin" }}>
            <SlateToolbar toggleFontSlider={toggleFontSlider} />
          </Box>}
        <Divider sx={{ mb: 0 }} />
        <Box sx={{
          width: 1, height: 1,
          overflowY: "auto", scrollbarWidth: "thin", p: 1
        }}>
          <Editable
            style={{ height: '100%' }}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder="Enter some rich text…"
            spellCheck
            autoFocus={false}
            onKeyDown={event => {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event)) {
                  event.preventDefault()
                  const mark = HOTKEYS[hotkey]
                  toggleMark(editor, mark)
                }
              }
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </Box>
      </Slate>

      {/* The text size slider  */}
      <NoDragPopover
        open={!!fontSliderAnchorEl}
        anchorEl={fontSliderAnchorEl}
        onClose={() => setFontSliderAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
        transformOrigin={{ vertical: 'top', horizontal: 'center', }}
        slotProps={{ paper: { style: { overflow: 'visible', height: '60px' } }, }}
      >
        <Box sx={{ display: 'flex', flexWrap: 'no-wrap', justifyContent: 'center', alignItems: 'center', p: 2 }}>
          <Typography variant='subtitle2' sx={{ width: 60 }}>{Math.round(currentUserFontSize * 100)} %</Typography>
          <Slider
            value={currentUserFontSize}
            min={0.25}
            max={3}
            step={0.05}
            valueLabelFormat={formatValueLabel}
            onChange={handleFontSizeSliderChange}
            onChangeCommitted={handleFontSizeSliderChangeCommit}
            valueLabelDisplay="auto"
            //marks={[{ value: 1, label: '100%' }]}
            sx={{ width: '200px', ml: 2 }}
          />
        </Box>
      </NoDragPopover>
    </Box>
  )
}

interface SlateToolbarProps {
  toggleFontSlider: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

export const SlateToolbar: React.FC<SlateToolbarProps> = ({ toggleFontSlider }) => {
  return (
    <Box style={{ display: "flex", flexWrap: "nowrap", height: `${toolBarHeight}px` }}>
      <StyledToggleButtonGroup size="small" arial-label="text formatting">
        <Tooltip title="Bold">
          {MarkButton({ format: "bold", icon: <FormatBoldIcon fontSize='medium' /> })}
        </Tooltip>
        <Tooltip title="Italic">
          {MarkButton({ format: "italic", icon: <FormatItalicIcon fontSize='medium' />, })}
        </Tooltip>
        <Tooltip title="Underline">
          {MarkButton({ format: "underline", icon: <FormatUnderlinedIcon fontSize='medium' />, })}
        </Tooltip>
        <Tooltip title="Small caps">
          {MarkButton({ format: "smallCaps", icon: <Box sx={toolbarIconBoxSx}><Box sx={{ height: `${toolBarHeight}px`, width: `${toolBarHeight}px` }}><CgFormatUppercase style={{ height: `${toolBarHeight}px`, width: `${toolBarHeight}px` }} /></Box></Box>, })}
        </Tooltip>
      </StyledToggleButtonGroup>
      <Divider orientation="vertical" sx={{ ml: 0, mr: 0 }} flexItem />
      <StyledToggleButtonGroup size="small" arial-label="text formatting" exclusive>
        <Tooltip title="Align left">
          {BlockButton({ format: "left", icon: <FormatAlignLeftIcon fontSize='medium' /> })}
        </Tooltip>
        <Tooltip title="Align center">
          {BlockButton({ format: "center", icon: <FormatAlignCenterIcon fontSize='medium' /> })}
        </Tooltip>
        <Tooltip title="Align right">
          {BlockButton({ format: "right", icon: <FormatAlignRightIcon fontSize='medium' /> })}
        </Tooltip>
        <Tooltip title="Justify">
          {BlockButton({ format: "justify", icon: <FormatAlignJustifyIcon fontSize='medium' /> })}
        </Tooltip>
      </StyledToggleButtonGroup>
      <Divider orientation="vertical" sx={{ ml: 0, mr: 0 }} flexItem />
      <StyledToggleButtonGroup size="small" arial-label="text formatting" exclusive>
        <Tooltip title="Numbered list">
          {BlockButton({ format: "numbered-list", icon: <FormatListNumberedIcon fontSize='medium' /> })}
        </Tooltip>
        <Tooltip title="Bulleted list">
          {BlockButton({ format: "bulleted-list", icon: <FormatListBulletedIcon fontSize='medium' /> })}
        </Tooltip>
      </StyledToggleButtonGroup>
      <Divider orientation="vertical" sx={{ ml: 0, mr: 0 }} flexItem />
      <StyledToggleButtonGroup size="small" arial-label="text formatting">
        <Tooltip title="Font size">
          <ToggleButton onClick={(event) => toggleFontSlider(event)} size="small" value="" style={{ borderColor: 'transparent' }}>
            <BiFontSize style={{ width: '24px', height: '24px' }} />
          </ToggleButton>
        </Tooltip>
      </StyledToggleButtonGroup>
    </Box>
  )
}

const toggleBlock = (editor: Editor, format: Format) => {
  const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format as TextAlignType) ? 'align' : 'type')
  const isList = LIST_TYPES.includes(format as ListType)

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type as ListType) &&
      !TEXT_ALIGN_TYPES.includes(format as TextAlignType),
    split: true,
  })
  let newProperties: Partial<SlateElement>
  if (TEXT_ALIGN_TYPES.includes(format as TextAlignType)) {
    newProperties = {
      align: isActive ? undefined : format as TextAlignType,
    }
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format as BlockType,
    }
  }
  Transforms.setNodes<SlateElement>(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format as BlockType, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor: Editor, format: Format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
    Editor.addMark(editor, format, false)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor: Editor, format: Format, blockType: 'align' | 'type' = 'type') => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  )

  return !!match
}

const isMarkActive = (editor: Editor, format: Format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format as TextStyleType] === true : false
}

const Element = ({ attributes, children, element }: RenderElementProps) => {
  const style = { textAlign: element.align, marginTop: 0, marginBottom: 8 }
  switch (element.type) {
    // case 'block-quote':
    //   return (
    //     <blockquote style={style} {...attributes}>
    //       {children}
    //     </blockquote>
    //   )
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      )
    // case 'heading-one':
    //   return (
    //     <h1 style={style} {...attributes}>
    //       {children}
    //     </h1>
    //   )
    // case 'heading-two':
    //   return (
    //     <h2 style={style} {...attributes}>
    //       {children}
    //     </h2>
    //   )
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      )
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      )
  }
}

const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  // if (leaf.code) {
  //   children = <code>{children}</code>
  // }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.smallCaps) {
    children = <span style={{ fontVariant: 'small-caps' }}>{children}</span>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

const BlockButton = ({ icon, format }: ToolbarButtonInput) => {
  const editor = useSlate()
  return (
    <ToggleButton
      value={format}
      selected={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      {icon}
    </ToggleButton>
  )
}

const MarkButton = ({ format, icon }: ToolbarButtonInput) => {
  const editor = useSlate()
  return (
    <ToggleButton
      value={format}
      selected={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      {icon}
    </ToggleButton>
  )
}



export default RichTextField