import { Box, Theme, useTheme } from '@mui/material'

type ColConIndicatorProps = {
    col: number,
    con: number
}

function sxFromColCon(prefersDarkMode: boolean, theme: Theme, col: number, con: number, neutral: boolean = false, weaker: number = 1) {
    const mainCol = prefersDarkMode ? theme.palette.primary.light : theme.palette.primary.main
    const fillColor = neutral ? theme.palette.text.disabled : mainCol
    return {
        borderRadius: '30%',
        backgroundColor: fillColor,
        m: 0.2,
        opacity: (0.2 + 0.2 * con) * weaker
    }
}


function ColConIndicator({ col, con }: ColConIndicatorProps) {
    const theme = useTheme()
    const prefersDarkMode = theme.palette.mode === 'dark'

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexWrap: 'wrap' }} >
            <Box sx={{ width: '42%', ...sxFromColCon(prefersDarkMode, theme, col, con, col === 0) }}>
            </Box>
            <Box sx={{ width: '42%', ...sxFromColCon(prefersDarkMode, theme, col, con, col === 0) }}>
            </Box>
            <Box sx={{ width: '42%', ...sxFromColCon(prefersDarkMode, theme, col, con, col === 0, 0.7) }}>
            </Box>
            <Box sx={{ width: '42%', ...sxFromColCon(prefersDarkMode, theme, col, con, col < 2, 0.5) }} >
            </Box>
        </Box>
    )
}

export default ColConIndicator
