import { CreateSubscriptionActions, OnApproveActions, OnApproveData } from '@paypal/paypal-js/types/components/buttons'
import { AmplifyUser } from '@aws-amplify/ui'
import { Box, Typography, CircularProgress } from '@mui/material'
import { PayPalButtons } from '@paypal/react-paypal-js'
import { useTheme } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { getCurrentAuthenticatedUser } from '../../lib/auth'
import { canSubscribe } from '../../lib/auth'
import { profilePath, securePagesPath } from "../../lib/constants"
import { hasValidSubscription } from '../../lib/auth'
import DataStatement from '../legal/DataStatement'
import TermsConditions from '../legal/TermsConditions'

type PaymentProps = { currentUser: AmplifyUser | undefined, subscriptionOption: SubscriptionOptionSpec }

function Payment({ currentUser, subscriptionOption }: PaymentProps) {
    const theme = useTheme()
    let navigate = useNavigate()

    // local state
    const [waitingForApproval, setWaitingForApproval] = useState(0)
    // Data Statement
    const [dataStatementStatus, setDataStatementStatus] = useState(false)
    // Terms Statement
    const [termsStatus, setTermsStatus] = useState(false)

    // Update user on mount and redirect if he cannot subscribe
    useEffect(() => {
        const checkSubscriptionAllowed = async () => {
            const newUser = await getCurrentAuthenticatedUser(true)
            if (!canSubscribe(newUser)) {
                navigate(`/${securePagesPath}/${profilePath}`)
            }
        }
        checkSubscriptionAllowed()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const createSubscription = (data: Record<string, unknown>, actions: CreateSubscriptionActions) => {
        return actions.subscription.create({
            plan_id: subscriptionOption.id,
            custom_id: currentUser?.username
        })
    }

    const handleApproval = async (data: OnApproveData, actions: OnApproveActions) => {
        // send conversion to Google ads and Analytics
        window.gtag('event', 'conversion', {
            'send_to': 'AW-11454548033/M3BLCM7S1oEZEMGQ-tUq', //quasarfox
            'value': subscriptionOption.paymentPrice, 'currency': subscriptionOption.currencyCode,
            'transaction_id': currentUser ? currentUser.username : '',
        })
        window.gtag('event', 'conversion', {
            'send_to': 'AW-404729103/sZaTCNuJ_4IZEI_a_sAB', //skai17
            'value': subscriptionOption.paymentPrice, 'currency': subscriptionOption.currencyCode,
            'transaction_id': currentUser ? currentUser.username : '',
        })
        window.gtag('event', 'purchase', {
            value: subscriptionOption.paymentPrice, currency: subscriptionOption.currencyCode,
            'transaction_id': currentUser ? currentUser.username : '',
        })
        // Refreshing the user until he has the subscription via Webhook
        setWaitingForApproval(1)
        const step = 5000
        const max = 60000
        let elapsed = 0
        let newUser: AmplifyUser | undefined = undefined
        while (elapsed <= max && !hasValidSubscription(newUser)) {
            newUser = await getCurrentAuthenticatedUser(true)
            if (!hasValidSubscription(newUser)) {
                // sleep
                await new Promise(resolve => setTimeout(resolve, step))
                elapsed += step
                setWaitingForApproval(elapsed)
            }
        }
        if (!hasValidSubscription(newUser)) alert('Activation takes longer than usual. If your subscription is not active after 10 minutes and a refresh of the profile page, please contact support.')
        setWaitingForApproval(0)
        navigate(`/${securePagesPath}/${profilePath}`)
    }

    return (
        <Box sx={{ width: 1 }}>
            {waitingForApproval === 0 ?
                <Box>
                    <PayPalButtons createSubscription={createSubscription}
                        onApprove={handleApproval}
                        onClick={() => window.gtag('event', 'begin_checkout', {
                            value: subscriptionOption ? subscriptionOption.paymentPrice : 0,
                            currency: subscriptionOption ? subscriptionOption.currencyCode : 'EUR',
                            items: subscriptionOption ? [subscriptionOption.id] : []
                        })}
                        style={{ color: 'gold', label: 'subscribe' }}
                    />
                    <Typography component='span' variant='body2' color={theme.palette.text.disabled}>
                        By subscribing you accept the{' '}
                    </Typography>
                    <Typography component='span' variant='body2' sx={{ textDecoration: "underline", color: "inherit", cursor: "pointer" }} onClick={() => { setDataStatementStatus(true) }}>
                        Data Privacy Statement
                    </Typography>
                    <Typography component='span' variant='body2' color={theme.palette.text.disabled}>
                        {' '}and the{' '}
                    </Typography>
                    <Typography component='span' variant='body2' sx={{ textDecoration: "underline", color: "inherit", cursor: "pointer" }} onClick={() => { setTermsStatus(true) }}>
                        Terms & Conditions
                    </Typography>
                    <Typography component='span' variant='body2' color={theme.palette.text.disabled}>
                        .
                    </Typography>
                </Box>
                :
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 1, height: 1, flexWrap: 'wrap' }}>
                    <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', mt: 5, mb: 2 }}>
                        <CircularProgress />
                    </Box>
                    <Box sx={{ width: 1, display: 'flex', justifyContent: 'center' }}>
                        <Typography variant="subtitle2" color={theme.palette.text.disabled} sx={{ textAlign: 'center' }}>
                            {waitingForApproval < 10000 && 'Validating your payment...'}
                            {waitingForApproval >= 10000 && waitingForApproval < 20000 && 'Activating your subscription...'}
                            {waitingForApproval >= 20000 && 'This takes a bit longer than usual. Thank you for your patience...'}
                        </Typography>
                    </Box>
                </Box>
            }

            <DataStatement dataStatementStatus={dataStatementStatus} setDataStatementStatus={setDataStatementStatus} />
            <TermsConditions termsStatus={termsStatus} setTermsStatus={setTermsStatus} />
        </Box>
    )
}

export default Payment