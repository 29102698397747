import { useState } from 'react'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Grid from '@mui/material/Unstable_Grid2'
import TableSelectionRangeGroup from './TableSelectionRangeGroup'
import { Checkbox, FormControl, InputLabel, MenuItem } from '@mui/material'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { tableSettingsPanelMaxWidth } from '../../../lib/style'

interface TableCellSettingsProps {
    currentTableCell: TableCell,
    handleTableCellSettingsChange: (newTableCell: TableCell, tableRange: TableSelectionRange | undefined) => void,
    allowedPositions?: PositionKey[]
    allowedSizes?: IconSize[]
    rowCount?: number
    colCount?: number
    rIdx?: number
    cIdx?: number
}

function TableCellSettings({ handleTableCellSettingsChange, currentTableCell, allowedPositions, allowedSizes, rowCount, colCount, cIdx, rIdx }: TableCellSettingsProps) {
    const theme = useTheme()
    const showSelectionIndicator = rowCount !== undefined && colCount !== undefined && rIdx !== undefined && cIdx !== undefined


    //state
    const [selectionRange, setSelectionRange] = useState<TableSelectionRange | undefined>(showSelectionIndicator ? { startRow: rIdx, startCol: cIdx, endRow: rIdx, endCol: cIdx } : undefined)

    const handleRegionChange = async (event: SelectChangeEvent) => {
        if (currentTableCell) handleTableCellSettingsChange({ ...currentTableCell, tableRegion: event.target.value as TableRegion }, selectionRange)
    }

    const handleColSpanChange = async (event: SelectChangeEvent) => {
        if (currentTableCell) handleTableCellSettingsChange({ ...currentTableCell, colspan: Number(event.target.value) }, selectionRange)
    }

    const handleRowSpanChange = async (event: SelectChangeEvent) => {
        if (currentTableCell) handleTableCellSettingsChange({ ...currentTableCell, rowspan: Number(event.target.value) }, selectionRange)
    }

    const handleTextVisibleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (currentTableCell) handleTableCellSettingsChange({ ...currentTableCell, textSettings: { ...currentTableCell.textSettings, visible: event.target.checked } }, selectionRange)
    }

    const handleCellVisibleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (currentTableCell) handleTableCellSettingsChange({ ...currentTableCell, cellVisible: !event.target.checked }, selectionRange)
    }

    return (
        <Grid container spacing={theme.customStyling.panelGridSpacing} sx={{ maxWidth: tableSettingsPanelMaxWidth }}>


            {/* The settings */}
            <Grid xs={12} sm={showSelectionIndicator ? 6 : 12}>
                <Paper elevation={theme.customStyling.panelGridItemElevation} sx={{ ...theme.customStyling.panelGridItemSx }}>
                    <Box sx={{ ...theme.customStyling.panelInnerGridItemSx, width: 1 }}>
                        <Typography variant="button" color='text.disabled' sx={{ ...theme.customStyling.panelGridItemTitleSx, textAlign: 'right' }}>Cell settings</Typography>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox size="small" checked={!currentTableCell.cellVisible} onChange={handleCellVisibleChange} />} label="Cell transparent" />
                            <FormControlLabel control={<Checkbox size="small" checked={currentTableCell.textSettings.visible} onChange={handleTextVisibleChange} />} label="Text visible" />

                        </FormGroup>
                        <FormControl sx={{ width: '100%', mt: 2 }} size="small">
                            <InputLabel >Table region</InputLabel>
                            <Select fullWidth value={currentTableCell.tableRegion} label="Table region" onChange={handleRegionChange}>
                                <MenuItem value={'HEADER1'}>Header 1</MenuItem>
                                <MenuItem value={'HEADER2'}>Header 2</MenuItem>
                                <MenuItem value={'BODY1'}>Body</MenuItem>
                            </Select>
                        </FormControl>
                        <Box sx={{ width: 1, mt: 2, display: 'flex' }}>
                            <Box sx={{ pr: 1, width: '50%' }}>
                                <FormControl fullWidth sx={{}} size="small">
                                    <InputLabel >Column span</InputLabel>
                                    <Select fullWidth value={String(currentTableCell.colspan)} label="Column span" onChange={handleColSpanChange}>
                                        {[...Array(colCount)].map((_, index) => (
                                            <MenuItem key={index} value={index + 1}>{index + 1}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{ pl: 1, width: '50%' }}>
                                <FormControl fullWidth sx={{}} size="small">
                                    <InputLabel >Row span</InputLabel>
                                    <Select fullWidth value={String(currentTableCell.rowspan)} label="Column span" onChange={handleRowSpanChange}>
                                        {[...Array(rowCount)].map((_, index) => (
                                            <MenuItem key={index} value={index + 1}>{index + 1}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Grid>

            {/* The apply range selector */}
            {showSelectionIndicator && selectionRange &&
                <Grid xs={12} sm={6} sx={{ mt: { xs: -6, sm: 0 } }}>
                    <Paper elevation={theme.customStyling.panelGridItemElevation} sx={{ ...theme.customStyling.panelGridItemSx }}>
                        <Box sx={{ ...theme.customStyling.panelInnerGridItemSx, width: 1 }}>
                            <Typography variant="button" color='text.disabled' sx={{ ...theme.customStyling.panelGridItemTitleSx, textAlign: 'right' }}>Apply changes to</Typography>
                            <TableSelectionRangeGroup rowCount={rowCount} colCount={colCount} currentRange={selectionRange} handleTableRangeChange={setSelectionRange}
                                rIdx={rIdx} cIdx={cIdx} />
                        </Box>
                    </Paper>
                </Grid>}

        </Grid >

    )
}

export default TableCellSettings