import { DraggableEvent } from "react-draggable"

export function clickByCoord(clientX: number, clientY: number) {
  var ev = new MouseEvent('mousedown', {
    'view': window,
    'bubbles': true,
    'cancelable': true,
    'clientX': clientX,
    'clientY': clientY
  })

  var el = document.elementFromPoint(clientX, clientY)
  if (el) el.dispatchEvent(ev)
}

export function focusByCoord(clientX: number, clientY: number) {
  var el = document.elementFromPoint(clientX, clientY) as HTMLElement
  if (el) el.focus()
}

export function getMouseCoords(e: DraggableEvent, touch: boolean) {
  var mouseX
  var mouseY
  try {
    if ("clientX" in e) mouseX = e.clientX
    if ("clientY" in e) mouseY = e.clientY
    if (touch) {
      if ("touches" in e && e.touches.length > 0) mouseX = e.touches[0].clientX
      if ("touches" in e && e.touches.length > 0) mouseY = e.touches[0].clientY
    }
  }
  catch (err) { console.log(err) }

  return { x: mouseX, y: mouseY }
}

export function capitalizeFirstLetter(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export function removeTrailingSlash(str: string) {
  if (str.endsWith('/')) {
    return str.slice(0, -1)
  }
  return str
}

export function strBeforeFirstSlash(str: string) {
  // Replace consecutive slashes with a placeholder, split, and then restore them
  const splitParts = str.replace('//', '<<CONSECUTIVE_SLASHES>>').split('/', 1)

  // Restore consecutive slashes in each part
  const result = splitParts.map(part => part.replace('<<CONSECUTIVE_SLASHES>>', '//'))

  return result[0]
}

//sleep
export const sleep = (milliseconds: number) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

//only log when not in production
export function devlog(message?: any, ...optionalParams: any[]) {
  if (process.env.REACT_APP_STAGE !== process.env.REACT_APP_NAME_OF_PROD_STAGE) console.log(...arguments)
}

export function compareVersions(versionA: string, versionB: string): number {
  const splitVersionA = versionA.split('.').map(Number)
  const splitVersionB = versionB.split('.').map(Number)

  for (let i = 0; i < splitVersionA.length; i++) {
    if (splitVersionA[i] > splitVersionB[i]) {
      return 1
    }
    if (splitVersionA[i] < splitVersionB[i]) {
      return -1
    }
  }
  return 0 // Versions are equal
}

export function getObjectHash(obj: any): string {
  const jsonString = JSON.stringify(obj)
  return jsonString
}