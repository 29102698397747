import { setRenderingOptions } from '../store/renderingOptionsSlice'

export const detectImageSupport = async function (dispatch: any, im = []) {
  // type definitions
  type TestImage = { type: string, url: string }
  type SupportDetail = Record<string, boolean>

  // test images
  let images = [
    {
      type: "webp",
      url:
        "data:image/webp;base64,UklGRhwAAABXRUJQVlA4TBAAAAAvAAAAEAfQpv5HmQMR0f8A",
    },
    {
      type: "avif",
      url:
        "data:image/avif;base64,AAAAHGZ0eXBtaWYxAAAAAG1pZjFhdmlmbWlhZgAAAPFtZXRhAAAAAAAAACFoZGxyAAAAAAAAAABwaWN0AAAAAAAAAAAAAAAAAAAAAA5waXRtAAAAAAABAAAAHmlsb2MAAAAABEAAAQABAAAAAAEVAAEAAAAeAAAAKGlpbmYAAAAAAAEAAAAaaW5mZQIAAAAAAQAAYXYwMUltYWdlAAAAAHBpcHJwAAAAUWlwY28AAAAUaXNwZQAAAAAAAAABAAAAAQAAABBwYXNwAAAAAQAAAAEAAAAVYXYxQ4EgAAAKBzgABpAQ0AIAAAAQcGl4aQAAAAADCAgIAAAAF2lwbWEAAAAAAAAAAQABBAECg4QAAAAmbWRhdAoHOAAGkBDQAjITFkAAAEgAAAB5TNw9UxdXU6F6oA == ",
    },
  ].concat(im)

  let imgSupportDetails: SupportDetail = {}

  // image test function
  let defineImage = async function (testImage: TestImage) {
    let img = new Image()
    img.src = testImage.url
    img.onerror = function (err) {
      imgSupportDetails[testImage.type.toLowerCase()] = false
      console.log(
        testImage.type.toUpperCase() + " not supported in this browser",
        err
      )
    }
    img.onload = function (inst) {
      imgSupportDetails[testImage.type.toLowerCase()] = true
      console.log(testImage.type.toUpperCase() + " supported in this browser")
    }
  }

  // test each image
  images.forEach(elm => defineImage(elm))

  // wait until both images have results or timeout
  const timeout = 1000
  const step = 100
  let timeSpent = 0
  await new Promise(r => setTimeout(r, 20))
  while (true) {
    if ("avif" in imgSupportDetails && "webp" in imgSupportDetails) break
    if (timeSpent > timeout) break
    await new Promise(r => setTimeout(r, step))
    timeSpent += step
  }

  if (imgSupportDetails.avif) {
    dispatch(setRenderingOptions({ format: "avif", quality: 50, speed: 9 }))
    return
  }
  if (imgSupportDetails.webp) {
    dispatch(setRenderingOptions({ format: "webp", quality: 50, speed: 8 }))
    return
  }
  dispatch(setRenderingOptions({ format: "jpeg", quality: 50, speed: 8 }))
  return
} 