import RelRnd from '../RelRnd'
import { useState } from "react"
import Box from '@mui/material/Box'
import { Descendant } from 'slate'
import RichTextPopover from '../../text/RichTextPopover'
import { Menu, MenuItem, ToggleButton, Tooltip } from '@mui/material'
import InElementMovableTextBox from '../../helpers/InElementMovableTextBox'
import _ from 'lodash'
import { StyledToggleButtonGroup } from '../../../lib/style'
import { Abc, AddBox } from '@mui/icons-material'
import React from 'react'

type TimelineProps = {
  el: LogicalElement, relRndProps: BasicRelRndProps, elementActive: boolean, elementSelected: boolean,
  updateElementContent: ElementContentChangeFunction
}
const itemTypeOptions: Array<{ itemType: ItemType, displayName: string }> = [
  { itemType: 'TEXT', displayName: 'Text' }, { itemType: 'BULLET', displayName: 'Bullet' }, { itemType: 'NUMBERED', displayName: 'Numbered' }, { itemType: 'LETTERED_UPPER', displayName: 'Lettered' }]//, { itemType: 'ICON', displayName: 'Icon' }]

function Timeline1({ el, relRndProps, elementActive, elementSelected, updateElementContent }: Readonly<TimelineProps>) {

  // localState
  const [frameAnchorEl, setFrameAnchorEl] = useState<null | HTMLElement>(null)
  const elementContent = el.content! as Timeline1Content

  const handleElementTitleTextChange = (newText: Descendant[]) => {
    if (relRndProps.handleElementTitleTextChange) relRndProps.handleElementTitleTextChange(el, newText)
  }

  const handleItemTextChange = (newText: Descendant[], i: number) => {
    if (elementContent && relRndProps.currentSlideId && !_.isEqual(elementContent.items[i].richTextBox.richText, newText)) {
      let newItems = [...elementContent.items]
      let newContent = { ...elementContent }
      let newRichTextBox = { ...newContent.items[i].richTextBox, richText: newText }
      newContent.items = newItems
      newContent.items[i] = { ...newContent.items[i], richTextBox: newRichTextBox }
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "IMMEDIATE_ELEMENT_CONTENT_TYPING")
    }
  }

  const handleItemBoxChange = (newBox: ElementBox, i: number) => {
    if (elementContent && relRndProps.currentSlideId && !_.isEqual(elementContent.items[i].richTextBox.textBox, newBox)) {
      let newItems = [...elementContent.items]
      let newContent = { ...elementContent }
      let newRichTextBox = { ...newContent.items[i].richTextBox, textBox: newBox }
      newContent.items = newItems
      newContent.items[i] = { ...newContent.items[i], richTextBox: newRichTextBox }
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "APPEARANCE_CHANGE")
    }
  }


  const addItem = () => {
    if (elementContent && relRndProps.currentSlideId) {
      let newItems = [...elementContent.items]
      let newContent = { ...elementContent }
      newContent.items = newItems
      newContent.items.push({ richTextBox: { richText: newItems.slice(-1)[0].richTextBox.richText } })
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "APPEARANCE_CHANGE")
    }
  }

  const deleteItem = (i: number) => {
    if (elementContent && relRndProps.currentSlideId && elementContent.items.length > 1) {
      console.log('del')
      let newItems = [...elementContent.items]
      let newContent = { ...elementContent }
      newContent.items = newItems
      newContent.items.splice(i, 1)
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "APPEARANCE_CHANGE")
    }
  }

  type ItemTypeChangeFunction = (newItemType: ItemType) => void
  const handleItemTypeChange: ItemTypeChangeFunction = (newItemType) => {
    if (elementContent && relRndProps.currentSlideId) {
      let newContent: Timeline1Content = { ...elementContent, itemType: newItemType }
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "APPEARANCE_CHANGE")
    }
  }

  const elementSpecificPanel = (
    <StyledToggleButtonGroup size="small" arial-label="element specific options">
      <Tooltip title="Set item type">
        <ToggleButton selected={false} onClick={(event) => setFrameAnchorEl(event.currentTarget)} color="primary" size="small" value="">
          <Abc fontSize="medium" />
        </ToggleButton>
      </Tooltip>
      <Tooltip title="Add timeline item">
        <ToggleButton selected={false} color="primary" onClick={(event) => addItem()} value="">
          <AddBox />
        </ToggleButton>
      </Tooltip>
    </StyledToggleButtonGroup>
  )

  return (
    <RelRnd {...relRndProps} el={el} elementSelected={elementSelected} elementActive={elementActive} elementSpecificPanel={elementSpecificPanel}>
      <Box sx={{
        width: 1, height: 1, display: "flex", justifyContent: "center", flexWrap: "wrap", alignContent: "flex-start",
        alignItems: "flex-start", position: "relative"
      }} key={JSON.stringify(elementContent)}>
        {/* the element title */}
        {el.title?.showTitle && el.title?.richTextBox &&
          <RichTextPopover el={el} relRndProps={relRndProps} richTextBox={el.title?.richTextBox}
            handleBlur={handleElementTitleTextChange} textRole='TITLE' />}

        {elementContent.items.map((item, i: number) => (
          <React.Fragment key={String(i) + JSON.stringify(item)}>
            <InElementMovableTextBox handleTextChange={(newText: Descendant[]) => handleItemTextChange(newText, i)} richTextBox={item.richTextBox}
              el={el} relRndProps={relRndProps} textRole='BODY1' handleBoxChange={(newBox: ElementBox) => handleItemBoxChange(newBox, i)}
              enableResizing={{ top: false, right: true, bottom: false, left: false, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
              handleDelete={() => deleteItem(i)} />
          </React.Fragment>
        ))}

      </Box>

      {/* Timeline item options */}
      <Menu
        anchorEl={frameAnchorEl}
        open={!!frameAnchorEl}
        onClose={() => setFrameAnchorEl(null)}
        MenuListProps={{
          role: 'listbox',
        }}
      >
        {itemTypeOptions.map((option, index) => (
          <MenuItem
            key={option.displayName}
            selected={option.itemType === elementContent.itemType}
            onClick={(event) => { handleItemTypeChange(option.itemType); setFrameAnchorEl(null) }}
          >
            {option.displayName}
          </MenuItem>
        ))}
      </Menu>

    </RelRnd>
  )
}

export default Timeline1
