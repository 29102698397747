import { convertRemToPixels } from "./font"

export const calcAbsElementDimensions = (stageSize: Dimensions, elBox: ElementBox) => {
  return { width: stageSize.width * elBox.width, height: stageSize.height * elBox.height }
}

export const calcWeightedTextFieldRowCounts = (stageSize: Dimensions, elBox: ElementBox, items: string[], verticalPadding: number) => {
  // calc basic values
  const absElementDimensions = calcAbsElementDimensions(stageSize, elBox)
  const availableSpace = absElementDimensions.height - verticalPadding * 2
  const totalItemCount = items.length
  const availableRows = (availableSpace - 17 * totalItemCount) / 23
  const totalCharCount = items.reduce((partialSum, a) => partialSum + a.length, 0)


  // loop the items and set their possible rows count
  let maxRowIndex = 0
  let itemRows = []
  for (const item of items) {
    let rows = Math.max(Math.floor(availableRows * item.length / totalCharCount), 1)
    itemRows.push(rows)
    if (rows > maxRowIndex) {
      maxRowIndex = itemRows.length - 1
    }
  }

  // if the row sum is bigger than the available rows, reduce the biggest item
  const arraySumReducer = (accumulator: number, curr: number) => accumulator + curr
  const maxArrayIndexReducer = (iMax: number, x: number, i: number, arr: number[]) => x > arr[iMax] ? i : iMax
  while (itemRows.reduce(arraySumReducer) > availableRows && Math.max(...itemRows) > 1) {
    itemRows[itemRows.reduce(maxArrayIndexReducer, 0)] -= 1
  }


  return itemRows
}

export const calcWeightedTextFieldRowCount = (stageSize: Dimensions, elBox: ElementBox, charCount: number, totalCharCount: number, totalItemCount: number, verticalPadding: number) => {
  const absElementDimensions = calcAbsElementDimensions(stageSize, elBox)
  const availableSpace = absElementDimensions.height - verticalPadding
  const availableRows = (availableSpace - 17 * totalItemCount) / 23
  return Math.max(Math.floor(availableRows * charCount / totalCharCount), 1)
}

export const dragDistance = (x1: number, y1: number, x2: number, y2: number) => {
  return (Math.abs(x1 - x2) + Math.abs(y1 - y2) > 5)
}

export function getPopoverDimensions(richTextBox: RichTextBoxType, relRndProps: BasicRelRndProps) {
  const textCellWidth = richTextBox.textBox ? relRndProps.parentSize.width * richTextBox.textBox.width : 0
  const textCellHeight = richTextBox.textBox ? relRndProps.parentSize.height * richTextBox.textBox.height : 0
  const popoverWidth = Math.max(textCellWidth, convertRemToPixels(16), 427) + 'px'
  const popoverHeight = Math.max(textCellHeight, convertRemToPixels(9)) + 'px'
  return { popoverWidth, popoverHeight }
}