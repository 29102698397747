import { Box, useTheme } from '@mui/material'
import { hexToRgba } from '../../lib/colors'

const arcHeight = 60

function SectionBackground() {
    const theme = useTheme()
    return (
        <Box sx={{
            display: 'grid', width: 1, height: 1, gridTemplateColumns: '1fr', zIndex: -100, alignItems: 'flex-end', overflow: 'visible'
        }}>
            {/* The bg gradient */}
            <Box sx={{
                width: 1, height: 1, gridColumnStart: 1, gridRowStart: 1,
                backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.default}, ${hexToRgba(theme.palette.primary.main, theme.customStyling.paleBgColorOpacity)} calc(100% - 60px))`
            }}>
            </Box>

            {/* The bg arc */}
            <Box sx={{ gridColumnStart: 1, gridRowStart: 1 }}>
                <svg preserveAspectRatio="none" style={{ display: 'block', height: `${arcHeight}px`, width: '100%' }} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                    <path fill={theme.palette.background.default} d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"></path>
                </svg>
            </Box>

        </Box>
    )
}

export default SectionBackground