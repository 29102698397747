import { Box, Button, Typography, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { TypeAnimation } from 'react-type-animation'
import { editorPath, securePagesPath } from '../../../lib/constants'
import { AmplifyUser } from '@aws-amplify/ui'

type HeroTextTablesProps = { currentUser: AmplifyUser | undefined }

function HeroTextTables({ currentUser }: HeroTextTablesProps) {
    const theme = useTheme()
    let navigate = useNavigate()
    const loggedIn = currentUser !== undefined
    const xs = 0.8
    const md = 1

    function handlyTryNowClick() {
        if (loggedIn) {
            window.gtag("event", "select_content", { content_type: "cta_click_landing_tables_logged_in" })
            navigate("/" + securePagesPath + '/' + editorPath)
        }
        else {
            window.gtag("event", "select_content", { content_type: "cta_click_landing_tables_trial" })
            navigate("/" + editorPath)
        }
    }

    return (
        <Box>
            <Box >
                <Box sx={{
                    maxWidth: 450,
                    fontSize: {
                        'xs': `${theme.customStyling.heroHeaderFontSize * xs}em`,
                        'md': `${theme.customStyling.heroHeaderFontSize * md}em`
                    }
                }}>
                    <Typography variant="h6" component="span" color={theme.palette.text.primary}
                        sx={{ fontSize: 'inherit' }}>
                        Create beautiful <br /> Powerpoint tables <br />
                    </Typography >
                    <Typography variant="h6" component="span" color={theme.palette.primary.main} sx={{ fontSize: 'inherit' }}>
                        <Box component="span">
                            <TypeAnimation
                                cursor={true}
                                sequence={[' fast. 🚀', 1500, ' with style. ✨', 1500, ' with confidence. 💪', 1500]}
                                wrapper="span"
                                repeat={Infinity}
                            />
                        </Box>
                    </Typography>
                </Box>

                <Box sx={{
                    mt: 4, mb: 4,
                    fontSize: {
                        'xs': `${theme.customStyling.heroSubtitleFontSize * xs}em`,
                        'md': `${theme.customStyling.heroSubtitleFontSize * md}em`
                    }
                }}>
                    <Typography variant="body1" component="span" color={theme.palette.text.disabled}
                        sx={{ mb: 8, fontSize: 'inherit' }} >
                        Protoslides makes you shine when it counts. Create presentations {' '}
                    </Typography >
                    <Typography variant="body1" component="span" color={theme.palette.text.disabled}
                        sx={{ mb: 8, fontSize: 'inherit', borderBottom: `0px solid ${theme.palette.primary.main}` }} >
                        with beautiful tables{' '}
                    </Typography >
                    <Typography variant="body1" component="span" color={theme.palette.text.disabled}
                        sx={{ mb: 8, fontSize: 'inherit' }} >
                        within minutes.
                    </Typography >
                </Box>

            </Box>
            <Button size='large' variant="contained" sx={{ width: { xs: 1, sm: 'auto' } }} onClick={handlyTryNowClick}>
                {loggedIn ? 'Go to editor' : 'Try now!'}
            </Button>
            {!loggedIn &&
                <Typography variant="body2" color={theme.palette.text.disabled}
                    sx={{ width: { xs: 1, sm: 'auto' }, mt: 1, textAlign: { xs: 'center', sm: 'left' } }} >
                    (No login required)
                </Typography >
            }
        </Box>
    )
}

export default HeroTextTables