import React from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

interface HarveyBallProps {
    value: number // A value between 0 and 1
}

const HarveyBall: React.FC<HarveyBallProps> = ({ value }) => {
    const theme = useTheme()

    const harveyBallStyle: React.CSSProperties = {
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden', // Hide the overflowing part of the pie
        borderRadius: '50%', // Make it a circle
        border: `6px solid ${theme.palette.primary.main}`,
        boxShadow: `0px 0px 0px 1px ${theme.palette.primary.main} inset`
    }

    const conicGradientStyle: React.CSSProperties = {
        width: '100%',
        height: '100%',
        background: `conic-gradient(${theme.palette.primary.main} ${value * 100}%, transparent ${value * 100}%)`,
    }

    const textContainerStyle: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',

    }

    return (
        <Box sx={harveyBallStyle}>
            <div style={conicGradientStyle}>
                <Box sx={textContainerStyle}>
                    <Box sx={{
                        width: '45%', height: '45%', borderRadius: '50%', backgroundColor: theme.palette.primary.main,
                        display: 'flex', justifyContent: 'center', alignItems: 'center'
                    }}>
                        <Typography variant="body2" >
                            {Math.round(value * 100)}%
                        </Typography>
                    </Box>
                </Box>
            </div>
        </Box>
    )
}

export default HarveyBall
