import Box from '@mui/material/Box'
import { ToggleButton } from '@mui/material'
import ColConIndicator from './ColConIndicator'
import Grid from '@mui/material/Unstable_Grid2'

interface ColConButtonProps {
    handleColConChange: (col: number, con: number) => void,
    currentCol: number | undefined,
    currentCon: number | undefined,
    width: number,
}

function ColConButtons({ handleColConChange, width, currentCol, currentCon }: ColConButtonProps) {

    const cols = [2, 1, 0]
    const cons = [0, 1, 2, 3]

    return (
        <Grid container spacing={0} sx={{ width: width * 4 }}>
            {cols.map((col) =>
                cons.map((con) => (
                    <Grid xs={3} sx={{}}>
                        <ToggleButton sx={{ width: width + 'px', height: width + 'px', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }} value={''}
                            selected={(currentCol === col && currentCon === con)} onClick={() => handleColConChange(col, con)}>
                            <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', pb: 0 }}>
                                <ColConIndicator col={col} con={con} />
                            </Box>
                        </ToggleButton>
                    </Grid>
                )))}
        </Grid>
    )
}

export default ColConButtons