import { useState } from 'react'
import Box from '@mui/material/Box'
import { ContentPaste, ContentCopy, Style, MoreVert, DeleteForever, Download } from '@mui/icons-material'
import ContextMenu from '../helpers/ContextMenu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import NeutralCircleButton from '../helpers/NeutralCircleButton'
import { hasValidSubscription, getCurrentAuthenticatedUser } from '../../lib/auth'
import { AmplifyUser } from '@aws-amplify/ui'
import DownloadDeniedDialog from '../helpers/DownloadDeniedDialog'


type SlideActionProps = {
  handleDeleteSlideClick: IdClickFunction, openLayout: Function,
  currentSlideId: string | undefined,
  handleSlideDownload: (slideId: string | undefined, presentationId: string | undefined) => void,
  currentPresentation: SinglePresentation,
  handleSlideCopy: IdClickFunction,
  handleSlidePaste?: () => void,
  handleSlideDuplicate: () => void
}


export default function SlideActions({ handleDeleteSlideClick, openLayout, currentSlideId, handleSlideDownload, currentPresentation, handleSlideCopy, handleSlidePaste, handleSlideDuplicate }: SlideActionProps) {

  // local state
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [subscriptionHintOpen, setSubscriptionHintOpen] = useState(false)
  const [currentUser, setCurrentUser] = useState<AmplifyUser | undefined>(undefined)
  const menuOpen = Boolean(menuAnchorEl)

  //functions
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setMenuAnchorEl(null)
  }

  const onDownload = async () => {
    const newCurrentUser = await getCurrentAuthenticatedUser()
    setCurrentUser(newCurrentUser)
    if (hasValidSubscription(newCurrentUser)) {
      handleSlideDownload(currentSlideId, currentPresentation?.presentationId)
      window.gtag("event", "select_content", { content_type: "slide_download_granted" })
    }
    else {
      setSubscriptionHintOpen(true)
      window.gtag("event", "select_content", { content_type: "slide_download_denied" })
    }
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }} >

      {/* Menu */}
      <ContextMenu transformOrigin={{ horizontal: 'left', vertical: 'bottom' }} anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        handleClose={handleMenuClose} menuAnchorEl={menuAnchorEl} menuOpen={menuOpen} pointerPosition='left' bottomPointer={true}
      >
        <MenuItem onClick={() => openLayout()}>
          <ListItemIcon >
            <Style fontSize="small" />
          </ListItemIcon>
          <ListItemText>Layout</ListItemText>
          {/* <Typography variant="body2" color="text.secondary" pl={2}>
            ⌘L
          </Typography> */}
        </MenuItem>
        <MenuItem onClick={onDownload}>
          <ListItemIcon>
            <Download fontSize="small" />
          </ListItemIcon>
          <ListItemText>Download</ListItemText>
          {/* <Typography variant="body2" color="text.secondary" pl={2}>
            ⌘D
          </Typography> */}
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleSlideCopy(currentSlideId)}>
          <ListItemIcon>
            <ContentCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText>Copy</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleSlidePaste ? () => handleSlidePaste() : undefined} disabled={!handleSlidePaste}>
          <ListItemIcon>
            <ContentPaste fontSize="small" />
          </ListItemIcon>
          <ListItemText>Paste</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleSlideDuplicate}>
          <ListItemIcon>
            <ContentCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleDeleteSlideClick(currentSlideId)}>
          <ListItemIcon >
            <DeleteForever fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText>Delete Slide</ListItemText>
        </MenuItem>
      </ContextMenu>

      <NeutralCircleButton size={25}>
        <Tooltip title="Slide options">
          <IconButton onClick={handleMenuClick}
            size="small" >
            <MoreVert fontSize="small" />
          </IconButton>
        </Tooltip>
      </NeutralCircleButton>

      <DownloadDeniedDialog currentUser={currentUser} setSubscriptionHintOpen={setSubscriptionHintOpen} subscriptionHintOpen={subscriptionHintOpen} />

    </Box>
  )
}