import { useRef, type CSSProperties, type FC } from 'react'
import { useDrop } from 'react-dnd'
import { useTheme } from '@mui/material/styles'
import { ItemTypes } from '../../lib/init'

const style: CSSProperties = {
  height: '100%',
  width: '100%',
  opacity: 0,
  position: "absolute",
  top: 0,
  left: 0,
}

type ElementDropProps = {
  handleElementDrop: (el: ElementDefinition, x?: number, y?: number) => void
}

export const ElementDropTarget: FC<ElementDropProps> = ({ handleElementDrop }) => {
  const theme = useTheme()
  const boundingBox = useRef<DOMRect | null>(null)

  const handleDrop = (item: ElementDefinition, x?: number, y?: number) => handleElementDrop(item, x, y)

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.ELEMENT,
    drop: (item, monitor) => {
      const offset = monitor.getClientOffset()
      let x = undefined
      let y = undefined
      if (offset && boundingBox.current) {
        x = (offset.x - boundingBox.current.x) / boundingBox.current.width
        y = (offset.y - boundingBox.current.y) / boundingBox.current.height
      }
      handleDrop(item as ElementDefinition, x, y)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))


  function combinedRef(el: HTMLDivElement | null) {
    drop(el)
    if (el) {
      boundingBox.current = el.getBoundingClientRect()
    }
  }

  const isActive = canDrop && isOver
  let backgroundColor = theme.palette.primary.main
  let opacity = 0
  let zIndex = 0
  if (isActive) {
    backgroundColor = theme.palette.primary.main
    opacity = 0.2
    zIndex = 2
  } else if (canDrop) {
    backgroundColor = theme.palette.primary.dark
    opacity = 0.2
    zIndex = 2
  }

  return (
    <div ref={combinedRef} style={{ ...style, backgroundColor: backgroundColor, opacity: opacity, zIndex: zIndex }} />
  )
}
