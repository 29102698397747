import { useState, useRef } from 'react'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import PositionSelector from '../helpers/PositionSelector'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Slider from '@mui/material/Slider'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Grid from '@mui/material/Unstable_Grid2'
import TableSelectionRangeGroup from '../pptElements/table1/TableSelectionRangeGroup'
import HarveyBall from '../helpers/HarveyBall'
import { tableSettingsPanelMaxWidth, tableSettingsPositionSelectorDimensions } from '../../lib/style'

interface HarveySettingsProps {
    currentHarveyState: HarveyBallSettings | undefined,
    handleHarveyStateChange: (newHarveyState: HarveyBallSettings, tableRange: TableSelectionRange | undefined) => void,
    allowedPositions?: PositionKey[]
    allowedSizes?: IconSize[]
    rowCount?: number
    colCount?: number
    rIdx?: number
    cIdx?: number
}

function HarveySettings({ handleHarveyStateChange, currentHarveyState, allowedPositions, allowedSizes, rowCount, colCount, cIdx, rIdx }: HarveySettingsProps) {
    const theme = useTheme()
    const showSelectionIndicator = rowCount !== undefined && colCount !== undefined && rIdx !== undefined && cIdx !== undefined
    if (currentHarveyState === undefined) currentHarveyState = initialHarveySettings

    //state
    const [harveyValue, setHarveyValue] = useState<number>(currentHarveyState?.value)
    const harveyValueRef = useRef<number>()
    harveyValueRef.current = harveyValue
    const [selectionRange, setSelectionRange] = useState<TableSelectionRange | undefined>(showSelectionIndicator ? { startRow: rIdx, startCol: cIdx, endRow: rIdx, endCol: cIdx } : undefined)

    const handleValueChange = async (event: Event, newValue: number | number[]) => {
        let newValueNumber = newValue as number
        setHarveyValue(newValueNumber)
        if (harveyValueRef.current) {
            // remember current value to avoid many quick changes and BE calls
            let thisValueNumber = newValue
            // sleep to wait for other quick changes
            await new Promise(r => setTimeout(r, 1000))
            // only update slide state if the current value is stll the same as when the function was called
            if (thisValueNumber === harveyValueRef.current.valueOf() && currentHarveyState) handleHarveyStateChange({ ...currentHarveyState, value: newValueNumber }, selectionRange)
        }
    }

    const handlePositionClick = async (newPosition: PositionKey) => {
        if (currentHarveyState) {
            // toggle visibility if clicking on the same position
            let newVisible = currentHarveyState.visible
            if (newPosition === currentHarveyState.position) { newVisible = !newVisible }
            else { newVisible = true }
            handleHarveyStateChange({ ...currentHarveyState, position: newPosition, visible: newVisible }, selectionRange)
        }
    }

    const handleSizeChange = async (event: SelectChangeEvent) => {
        if (currentHarveyState) handleHarveyStateChange({ ...currentHarveyState, size: Number(event.target.value) }, selectionRange)
    }

    return (
        <Grid container spacing={theme.customStyling.panelGridSpacing} sx={{ maxWidth: tableSettingsPanelMaxWidth }}>


            {/* The settings */}
            <Grid xs={12} sm={showSelectionIndicator ? 6 : 12} >
                <div>
                    <Grid container spacing={0} >

                        <Paper elevation={theme.customStyling.panelGridItemElevation} sx={{ ...theme.customStyling.panelGridItemSx }}>

                            {/* The current harvey ball */}
                            <Grid xs={showSelectionIndicator ? 6 : 4} >
                                {currentHarveyState &&
                                    <Box sx={{ ...theme.customStyling.panelInnerGridItemSx }}>
                                        <Typography variant="button" color='text.disabled' sx={{ ...theme.customStyling.panelGridItemTitleSx }}>Current</Typography>
                                        <Box sx={{ ...tableSettingsPositionSelectorDimensions }}>
                                            <HarveyBall value={harveyValue} />
                                        </Box>
                                    </Box>}
                            </Grid>

                            {/* The position selector */}
                            <Grid xs={showSelectionIndicator ? 6 : 4} sx={{ pl: 2 }}>
                                {allowedPositions &&
                                    <Box sx={{ ...theme.customStyling.panelInnerGridItemSx }}>
                                        <Typography variant="button" color='text.disabled' sx={{ ...theme.customStyling.panelGridItemTitleSx }}>Position</Typography>
                                        <Box sx={{ ...tableSettingsPositionSelectorDimensions }}>
                                            <PositionSelector visible={currentHarveyState.visible} allowedPositions={allowedPositions} currentPosition={currentHarveyState.position} handleNewPosition={handlePositionClick}></PositionSelector>
                                        </Box>
                                    </Box>

                                }
                            </Grid>

                            {/* The size selector */}
                            <Grid xs={showSelectionIndicator ? 12 : 4} >
                                {allowedSizes &&
                                    <Box sx={{ ...theme.customStyling.panelInnerGridItemSx }}>
                                        <Typography variant="button" color='text.disabled' sx={{ ...theme.customStyling.panelGridItemTitleSx }}>Size</Typography>
                                        <Select
                                            fullWidth
                                            labelId="icon-size-selection"
                                            value={String(currentHarveyState.size)}
                                            size='small'
                                            label=""
                                            onChange={handleSizeChange}
                                            sx={{ mt: 0 }}
                                        >
                                            {allowedSizes.map((allowedSize, i: number) => (
                                                <MenuItem key={i} value={String(allowedSize.size)}>{allowedSize.displayName}</MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                }
                            </Grid>
                        </Paper>
                    </Grid>
                </div>
            </Grid>

            {/* The apply range selector */}
            {showSelectionIndicator && selectionRange &&
                <Grid xs={12} sm={6} sx={{ mt: { xs: -6, sm: 0 } }} >
                    <Paper elevation={theme.customStyling.panelGridItemElevation} sx={{ ...theme.customStyling.panelGridItemSx }}>
                        <Box sx={{ ...theme.customStyling.panelInnerGridItemSx, width: 1 }}>
                            <Typography variant="button" color='text.disabled' sx={{ ...theme.customStyling.panelGridItemTitleSx, textAlign: 'right' }}>Apply changes to</Typography>
                            <TableSelectionRangeGroup rowCount={rowCount} colCount={colCount} currentRange={selectionRange} handleTableRangeChange={setSelectionRange}
                                rIdx={rIdx} cIdx={cIdx} />
                        </Box>
                    </Paper>
                </Grid>}

            {/* The value slider */}
            <Grid xs={12} >
                <Paper elevation={theme.customStyling.panelGridItemElevation} sx={{ ...theme.customStyling.panelGridItemSx, mt: -6 }} >
                    <Box sx={{ ...theme.customStyling.panelInnerGridItemSx }}>
                        <Typography variant="button" color='text.disabled' sx={{ ...theme.customStyling.panelGridItemTitleSx }}>Adjust Harvey Ball Value</Typography>
                        <Slider
                            valueLabelDisplay="auto"
                            step={0.05}
                            marks
                            min={0}
                            max={1}
                            value={harveyValue}
                            onChange={handleValueChange}
                        />
                    </Box>
                </Paper>
            </Grid>
        </Grid >

    )
}

export const initialHarveySettings = { visible: false, size: 2, position: 'CENTERCENTER' as PositionKey, value: 0.25 }

export default HarveySettings