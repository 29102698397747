import { framePaddingNarrow } from "../../../lib/constants"

export const table1Presets: Record<string, ElementPreset> = {
    PROJECT_OVERVIEW_7x6:
    {
        displayName: 'Project overview (7 x 6)',
        titleText: 'Project overview 2024',
        type: 'TABLE1',
        presetGroup: 'TABLE_GENERAL',
        framePadding: framePaddingNarrow,
        "box": {
            "height": 0.6306814614801554,
            "left": 0.0678226657030059,
            "top": 0.23925745820372674,
            "width": 0.8673780487804879
        },
        "content": {
            "rows": [
                [
                    {
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "iconSource": {
                                "id": "NP_5780204",
                                "provider": "NP",
                                "providerId": "5780204",
                                "thumbnailUrl": "https://static.thenounproject.com/png/5780204-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 2,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Project",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Description",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Staffing",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": true,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "position": "CENTERCENTER",
                            "size": 2,
                            "value": 0.4,
                            "visible": false
                        },
                        "progressBarSettings": {
                            "position": "CENTERCENTER",
                            "segments": 4,
                            "size": 1,
                            "value": 0.7,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Progress",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": true,
                        "checkMarkSettings": {
                            "checked": false,
                            "position": "CENTERCENTER",
                            "size": 1.5,
                            "visible": false
                        },
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Funding",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.0552373869932925,
                            "left": 0.7123405511811024,
                            "top": 0.3243642461358997,
                            "width": 0.1340296095800525
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Next Steps",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.0552373869932925,
                            "left": 0.8558631889763779,
                            "top": 0.3243642461358997,
                            "width": 0.0680871062992126
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Risk",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.07160279965004375,
                            "left": 0.07907258858267717,
                            "top": 0.39647812773403324,
                            "width": 0.15285515091863516
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.1978922244094488,
                                "top": 0.4100573053368329,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_712676",
                                "provider": "NP",
                                "providerId": "712676",
                                "thumbnailUrl": "https://static.thenounproject.com/png/712676-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER2",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Sales Platform",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.07160279965004375,
                            "left": 0.24142076771653542,
                            "top": 0.39647812773403324,
                            "width": 0.17852591863517062
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.07160279965004375,
                            "left": 0.42943971456692914,
                            "top": 0.39647812773403324,
                            "width": 0.0817044783464567
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.45779195374015746,
                                "top": 0.40424795858850976,
                                "width": 0.025
                            },
                            "position": "CENTERCENTER",
                            "size": 2,
                            "value": 0.6,
                            "visible": true
                        },
                        "progressBarSettings": {
                            "position": "CENTERCENTER",
                            "segments": 4,
                            "size": 1.5,
                            "value": 0.6,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "text": "60%"
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.07160279965004375,
                            "left": 0.5206372211286089,
                            "top": 0.39647812773403324,
                            "width": 0.09628969816272966
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.040740740740740744,
                                "left": 0.48528842683727036,
                                "top": 0.3135028069407991,
                                "width": 0.022916666666666665
                            },
                            "position": "CENTERCENTER",
                            "size": 2,
                            "value": 0.1,
                            "visible": false
                        },
                        "progressBarSettings": {
                            "box": {
                                "height": 0.022222222222222223,
                                "left": 0.5437820702099737,
                                "top": 0.4153590696996209,
                                "width": 0.05
                            },
                            "position": "CENTERCENTER",
                            "segments": 4,
                            "size": 1,
                            "value": 0.1,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "text": "10%"
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.07160279965004375,
                            "left": 0.6264199475065617,
                            "top": 0.39647812773403324,
                            "width": 0.07642757545931758
                        },
                        "cellVisible": true,
                        "checkMarkSettings": {
                            "box": {
                                "height": 0.03333333333333333,
                                "left": 0.6552587352362205,
                                "top": 0.40980351414406535,
                                "width": 0.01875
                            },
                            "checked": true,
                            "position": "CENTERCENTER",
                            "size": 1.5,
                            "visible": true
                        },
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "text": "y"
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.07160279965004375,
                            "left": 0.7123405511811024,
                            "top": 0.39647812773403324,
                            "width": 0.1340296095800525
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "children": [
                                                {
                                                    "bold": false,
                                                    "italic": false,
                                                    "smallCaps": false,
                                                    "text": "At vero eos et accusam et justo",
                                                    "underline": false
                                                }
                                            ],
                                            "type": "list-item"
                                        }
                                    ],
                                    "type": "bulleted-list"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.07160279965004375,
                            "left": 0.8558631889763779,
                            "top": 0.39647812773403324,
                            "width": 0.0680871062992126
                        },
                        "cellVisible": true,
                        "colorStateSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.8774067421259842,
                                "top": 0.40424795858850976,
                                "width": 0.025
                            },
                            "color": "GREEN",
                            "position": "CENTERCENTER",
                            "size": 2,
                            "visible": true
                        },
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "text": "green"
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.07160279965004375,
                            "left": 0.07907258858267717,
                            "top": 0.48495742198891806,
                            "width": 0.15285515091863516
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.1978922244094488,
                                "top": 0.4985365995917177,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_4819366",
                                "provider": "NP",
                                "providerId": "4819366",
                                "thumbnailUrl": "https://static.thenounproject.com/png/4819366-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER2",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Marketing",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.07160279965004375,
                            "left": 0.24142076771653542,
                            "top": 0.48495742198891806,
                            "width": 0.17852591863517062
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.07160279965004375,
                            "left": 0.42943971456692914,
                            "top": 0.48495742198891806,
                            "width": 0.0817044783464567
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.45779195374015746,
                                "top": 0.4927272528433946,
                                "width": 0.025
                            },
                            "position": "CENTERCENTER",
                            "size": 2,
                            "value": 0.2,
                            "visible": true
                        },
                        "progressBarSettings": {
                            "position": "CENTERCENTER",
                            "segments": 4,
                            "size": 1.5,
                            "value": 0.2,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "text": "0,2"
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.07160279965004375,
                            "left": 0.5206372211286089,
                            "top": 0.48495742198891806,
                            "width": 0.09628969816272966
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.040740740740740744,
                                "left": 0.48528842683727036,
                                "top": 0.410914661708953,
                                "width": 0.022916666666666665
                            },
                            "position": "CENTERCENTER",
                            "size": 2,
                            "value": 0.4,
                            "visible": false
                        },
                        "progressBarSettings": {
                            "box": {
                                "height": 0.022222222222222223,
                                "left": 0.5437820702099737,
                                "top": 0.5038383639545057,
                                "width": 0.05
                            },
                            "position": "CENTERCENTER",
                            "segments": 4,
                            "size": 1,
                            "value": 0.4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "text": "40%"
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.07160279965004375,
                            "left": 0.6264199475065617,
                            "top": 0.48495742198891806,
                            "width": 0.07642757545931758
                        },
                        "cellVisible": true,
                        "checkMarkSettings": {
                            "box": {
                                "height": 0.03333333333333333,
                                "left": 0.6552587352362205,
                                "top": 0.4982828083989501,
                                "width": 0.01875
                            },
                            "checked": true,
                            "position": "CENTERCENTER",
                            "size": 1.5,
                            "visible": true
                        },
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "text": "y"
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.07160279965004375,
                            "left": 0.7123405511811024,
                            "top": 0.48495742198891806,
                            "width": 0.1340296095800525
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "children": [
                                                {
                                                    "bold": false,
                                                    "italic": false,
                                                    "smallCaps": false,
                                                    "text": "At vero eos et accusam et justo",
                                                    "underline": false
                                                }
                                            ],
                                            "type": "list-item"
                                        }
                                    ],
                                    "type": "bulleted-list"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.07160279965004375,
                            "left": 0.8558631889763779,
                            "top": 0.48495742198891806,
                            "width": 0.0680871062992126
                        },
                        "cellVisible": true,
                        "colorStateSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.8774067421259842,
                                "top": 0.4927272528433946,
                                "width": 0.025
                            },
                            "color": "YELLOW",
                            "position": "CENTERCENTER",
                            "size": 2,
                            "visible": true
                        },
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "text": "Yellow"
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.10360279965004375,
                            "left": 0.07907258858267717,
                            "top": 0.5734367162438029,
                            "width": 0.15285515091863516
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.1978922244094488,
                                "top": 0.6030158938466025,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_6237941",
                                "provider": "NP",
                                "providerId": "6237941",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6237941-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER2",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Storage Optimization",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.10360279965004375,
                            "left": 0.24142076771653542,
                            "top": 0.5734367162438029,
                            "width": 0.17852591863517062
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.10360279965004375,
                            "left": 0.42943971456692914,
                            "top": 0.5734367162438029,
                            "width": 0.0817044783464567
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.45779195374015746,
                                "top": 0.5972065470982794,
                                "width": 0.025
                            },
                            "position": "CENTERCENTER",
                            "size": 2,
                            "value": 0.8,
                            "visible": true
                        },
                        "progressBarSettings": {
                            "position": "CENTERCENTER",
                            "segments": 4,
                            "size": 1.5,
                            "value": 0.8,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "text": "80%"
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.10360279965004375,
                            "left": 0.5206372211286089,
                            "top": 0.5734367162438029,
                            "width": 0.09628969816272966
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.040740740740740744,
                                "left": 0.48528842683727036,
                                "top": 0.508326516477107,
                                "width": 0.022916666666666665
                            },
                            "position": "CENTERCENTER",
                            "size": 2,
                            "value": 0.7,
                            "visible": false
                        },
                        "progressBarSettings": {
                            "box": {
                                "height": 0.022222222222222223,
                                "left": 0.5437820702099737,
                                "top": 0.6083176582093905,
                                "width": 0.05
                            },
                            "position": "CENTERCENTER",
                            "segments": 4,
                            "size": 1,
                            "value": 0.7,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "text": "0,7"
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.10360279965004375,
                            "left": 0.6264199475065617,
                            "top": 0.5734367162438029,
                            "width": 0.07642757545931758
                        },
                        "cellVisible": true,
                        "checkMarkSettings": {
                            "box": {
                                "height": 0.03333333333333333,
                                "left": 0.6552587352362205,
                                "top": 0.6027621026538349,
                                "width": 0.01875
                            },
                            "checked": false,
                            "position": "CENTERCENTER",
                            "size": 1.5,
                            "visible": true
                        },
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "text": "No"
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.10360279965004375,
                            "left": 0.7123405511811024,
                            "top": 0.5734367162438029,
                            "width": 0.1340296095800525
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "children": [
                                                {
                                                    "bold": false,
                                                    "italic": false,
                                                    "smallCaps": false,
                                                    "text": "At vero eos et accusam et justo",
                                                    "underline": false
                                                }
                                            ],
                                            "type": "list-item"
                                        }
                                    ],
                                    "type": "bulleted-list"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.10360279965004375,
                            "left": 0.8558631889763779,
                            "top": 0.5734367162438029,
                            "width": 0.0680871062992126
                        },
                        "cellVisible": true,
                        "colorStateSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.8774067421259842,
                                "top": 0.5972065470982794,
                                "width": 0.025
                            },
                            "color": "YELLOW",
                            "position": "CENTERCENTER",
                            "size": 2,
                            "visible": true
                        },
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "text": "RED"
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.07160279965004375,
                            "left": 0.07907258858267717,
                            "top": 0.6939160104986877,
                            "width": 0.15285515091863516
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.1978922244094488,
                                "top": 0.7074951881014873,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_5281880",
                                "provider": "NP",
                                "providerId": "5281880",
                                "thumbnailUrl": "https://static.thenounproject.com/png/5281880-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER2",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Phase 2",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.07160279965004375,
                            "left": 0.24142076771653542,
                            "top": 0.6939160104986877,
                            "width": 0.17852591863517062
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.07160279965004375,
                            "left": 0.42943971456692914,
                            "top": 0.6939160104986877,
                            "width": 0.0817044783464567
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.45779195374015746,
                                "top": 0.7016858413531641,
                                "width": 0.025
                            },
                            "position": "CENTERCENTER",
                            "size": 2,
                            "value": 0.8,
                            "visible": true
                        },
                        "progressBarSettings": {
                            "position": "CENTERCENTER",
                            "segments": 4,
                            "size": 1.5,
                            "value": 1,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "text": "100%"
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.07160279965004375,
                            "left": 0.5206372211286089,
                            "top": 0.6939160104986877,
                            "width": 0.09628969816272966
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.040740740740740744,
                                "left": 0.48528842683727036,
                                "top": 0.605738371245261,
                                "width": 0.022916666666666665
                            },
                            "position": "CENTERCENTER",
                            "size": 2,
                            "value": 0.8,
                            "visible": false
                        },
                        "progressBarSettings": {
                            "box": {
                                "height": 0.022222222222222223,
                                "left": 0.5437820702099737,
                                "top": 0.7127969524642753,
                                "width": 0.05
                            },
                            "position": "CENTERCENTER",
                            "segments": 4,
                            "size": 1,
                            "value": 0.8,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "text": "80%"
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.07160279965004375,
                            "left": 0.6264199475065617,
                            "top": 0.6939160104986877,
                            "width": 0.07642757545931758
                        },
                        "cellVisible": true,
                        "checkMarkSettings": {
                            "box": {
                                "height": 0.03333333333333333,
                                "left": 0.6552587352362205,
                                "top": 0.7072413969087198,
                                "width": 0.01875
                            },
                            "checked": true,
                            "position": "CENTERCENTER",
                            "size": 1.5,
                            "visible": true
                        },
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "text": "YES"
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.07160279965004375,
                            "left": 0.7123405511811024,
                            "top": 0.6939160104986877,
                            "width": 0.1340296095800525
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "children": [
                                                {
                                                    "bold": false,
                                                    "italic": false,
                                                    "smallCaps": false,
                                                    "text": "At vero eos et accusam et justo",
                                                    "underline": false
                                                }
                                            ],
                                            "type": "list-item"
                                        }
                                    ],
                                    "type": "bulleted-list"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.07160279965004375,
                            "left": 0.8558631889763779,
                            "top": 0.6939160104986877,
                            "width": 0.0680871062992126
                        },
                        "cellVisible": true,
                        "colorStateSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.8774067421259842,
                                "top": 0.7016858413531641,
                                "width": 0.025
                            },
                            "color": "GREEN",
                            "position": "CENTERCENTER",
                            "size": 2,
                            "visible": true
                        },
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "text": "red"
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.07160279965004375,
                            "left": 0.07907258858267717,
                            "top": 0.7823953047535724,
                            "width": 0.15285515091863516
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.1978922244094488,
                                "top": 0.7959744823563721,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_6139795",
                                "provider": "NP",
                                "providerId": "6139795",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6139795-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER2",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Rebranding",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.07160279965004375,
                            "left": 0.24142076771653542,
                            "top": 0.7823953047535724,
                            "width": 0.17852591863517062
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.07160279965004375,
                            "left": 0.42943971456692914,
                            "top": 0.7823953047535724,
                            "width": 0.0817044783464567
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.45779195374015746,
                                "top": 0.790165135608049,
                                "width": 0.025
                            },
                            "position": "CENTERCENTER",
                            "size": 2,
                            "value": 0.21,
                            "visible": true
                        },
                        "progressBarSettings": {
                            "position": "CENTERCENTER",
                            "segments": 4,
                            "size": 1.5,
                            "value": 0.21,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "text": "0,21"
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.07160279965004375,
                            "left": 0.5206372211286089,
                            "top": 0.7823953047535724,
                            "width": 0.09628969816272966
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.040740740740740744,
                                "left": 0.48528842683727036,
                                "top": 0.703150226013415,
                                "width": 0.022916666666666665
                            },
                            "position": "CENTERCENTER",
                            "size": 2,
                            "value": 0.3,
                            "visible": false
                        },
                        "progressBarSettings": {
                            "box": {
                                "height": 0.022222222222222223,
                                "left": 0.5437820702099737,
                                "top": 0.8012762467191601,
                                "width": 0.05
                            },
                            "position": "CENTERCENTER",
                            "segments": 4,
                            "size": 1,
                            "value": 0.3,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "text": "30%"
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.07160279965004375,
                            "left": 0.6264199475065617,
                            "top": 0.7823953047535724,
                            "width": 0.07642757545931758
                        },
                        "cellVisible": true,
                        "checkMarkSettings": {
                            "box": {
                                "height": 0.03333333333333333,
                                "left": 0.6552587352362205,
                                "top": 0.7957206911636046,
                                "width": 0.01875
                            },
                            "checked": false,
                            "position": "CENTERCENTER",
                            "size": 1.5,
                            "visible": true
                        },
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "text": "n"
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.07160279965004375,
                            "left": 0.7123405511811024,
                            "top": 0.7823953047535724,
                            "width": 0.1340296095800525
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "children": [
                                                {
                                                    "bold": false,
                                                    "italic": false,
                                                    "smallCaps": false,
                                                    "text": "At vero eos et accusam et justo",
                                                    "underline": false
                                                }
                                            ],
                                            "type": "list-item"
                                        }
                                    ],
                                    "type": "bulleted-list"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.07160279965004375,
                            "left": 0.8558631889763779,
                            "top": 0.7823953047535724,
                            "width": 0.0680871062992126
                        },
                        "cellVisible": true,
                        "colorStateSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.8774067421259842,
                                "top": 0.790165135608049,
                                "width": 0.025
                            },
                            "color": "GREEN",
                            "position": "CENTERCENTER",
                            "size": 2,
                            "visible": true
                        },
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "text": "green"
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ]
            ]
        }
    },
    BOXES_WITH_ARROWS_2:
    {
        displayName: '2 boxes',
        hideInPresetMenu: false,
        type: 'TABLE1',
        presetGroup: 'TABLE_BOXFLOW',
        framePadding: framePaddingNarrow,
        "box": {
            "height": 0.479101367733852,
            "left": 0.09018037377334223,
            "top": 0.26352829917798515,
            "width": 0.6364329268292683
        },
        "content": {
            "rows": [
                [
                    {
                        "box": {
                            "height": 0.10533333333333333,
                            "left": 0.10143036417322834,
                            "top": 0.28352828813065034,
                            "width": 0.2378155347769029
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.29487089895013124,
                                "top": 0.3139727325750948,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_1058533",
                                "provider": "NP",
                                "providerId": "1058533",
                                "thumbnailUrl": "https://static.thenounproject.com/png/1058533-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER2",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Header",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.43910119568387285,
                            "left": 0.35159555446194224,
                            "top": 0.28352828813065034,
                            "width": 0.11535744750656168
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.3842742782152231,
                                "top": 0.4436344415281423,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_2695615",
                                "provider": "NP",
                                "providerId": "2695615",
                                "thumbnailUrl": "https://static.thenounproject.com/png/2695615-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Header",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.10533333333333333,
                            "left": 0.479302657480315,
                            "top": 0.28352828813065034,
                            "width": 0.2360604494750656
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.6709881069553806,
                                "top": 0.3139727325750948,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_961638",
                                "provider": "NP",
                                "providerId": "961638",
                                "thumbnailUrl": "https://static.thenounproject.com/png/961638-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER2",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Header",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.311812919218431,
                            "left": 0.10143036417322834,
                            "top": 0.41081656459609217,
                            "width": 0.2378155347769029
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.2015881315616798,
                                "top": 0.6192961504811898,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "BOTTOMCENTER",
                            "size": 3,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.18508665518372702,
                                "top": 0.5830199766695829,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "BOTTOMCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.311812919218431,
                            "left": 0.479302657480315,
                            "top": 0.41081656459609217,
                            "width": 0.2360604494750656
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.5785828822178478,
                                "top": 0.6192961504811898,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_961638",
                                "provider": "NP",
                                "providerId": "961638",
                                "thumbnailUrl": "https://static.thenounproject.com/png/961638-200.png"
                            },
                            "position": "BOTTOMCENTER",
                            "size": 3,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ]
            ]
        }
    },
    SITUATION_COMPLICATION_RESOLUTION:
    {
        displayName: 'Situation, Complication, Resolution (3 boxes)',
        hideInPresetMenu: false,
        type: 'TABLE1',
        presetGroup: 'TABLE_BOXFLOW',
        framePadding: framePaddingNarrow,
        "box": {
            "height": 0.47950358377832875,
            "left": 0.09017738299865966,
            "top": 0.2635389118067221,
            "width": 0.8046068308181096
        },
        "content": {
            "rows": [
                [
                    {
                        "box": {
                            "height": 0.10533333333333333,
                            "left": 0.10063303805774278,
                            "top": 0.27366666666666667,
                            "width": 0.2024883530183727
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.25874639107611547,
                                "top": 0.3041111111111111,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_1058533",
                                "provider": "NP",
                                "providerId": "1058533",
                                "thumbnailUrl": "https://static.thenounproject.com/png/1058533-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER2",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Situation",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.43950335374744826,
                            "left": 0.3154823654855643,
                            "top": 0.27366666666666667,
                            "width": 0.06821038385826772
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.32458755741469814,
                                "top": 0.43397389909594636,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_2695615",
                                "provider": "NP",
                                "providerId": "2695615",
                                "thumbnailUrl": "https://static.thenounproject.com/png/2695615-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Header",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.10533333333333333,
                            "left": 0.39605372375328085,
                            "top": 0.27366666666666667,
                            "width": 0.18912245734908137
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.5408011811023622,
                                "top": 0.3041111111111111,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_961638",
                                "provider": "NP",
                                "providerId": "961638",
                                "thumbnailUrl": "https://static.thenounproject.com/png/961638-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER2",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Complication",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.43950335374744826,
                            "left": 0.597537155511811,
                            "top": 0.27366666666666667,
                            "width": 0.06821038385826772
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.6066423474409449,
                                "top": 0.43397389909594636,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_2695615",
                                "provider": "NP",
                                "providerId": "2695615",
                                "thumbnailUrl": "https://static.thenounproject.com/png/2695615-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Header",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.10533333333333333,
                            "left": 0.6781085137795275,
                            "top": 0.27366666666666667,
                            "width": 0.20463115157480316
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.8383646653543307,
                                "top": 0.3041111111111111,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_954052",
                                "provider": "NP",
                                "providerId": "954052",
                                "thumbnailUrl": "https://static.thenounproject.com/png/954052-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Resolution",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.312194954797317,
                            "left": 0.10063303805774278,
                            "top": 0.4009750656167979,
                            "width": 0.2024883530183727
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.18312721456692913,
                                "top": 0.6098366870807815,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "BOTTOMCENTER",
                            "size": 3,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Provide a fact-based, unambiguous explanation of the current situation. ",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                },
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Your target audience already knows and accepts the facts.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.18508665518372702,
                                "top": 0.5830199766695829,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "BOTTOMCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.312194954797317,
                            "left": 0.39605372375328085,
                            "top": 0.4009750656167979,
                            "width": 0.18912245734908137
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.4718649524278215,
                                "top": 0.6098366870807815,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_961638",
                                "provider": "NP",
                                "providerId": "961638",
                                "thumbnailUrl": "https://static.thenounproject.com/png/961638-200.png"
                            },
                            "position": "BOTTOMCENTER",
                            "size": 3,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Explain why action is required. What has changed and what aspects need to be taken care of? What is the impact?",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.18508665518372702,
                                "top": 0.5830199766695829,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "BOTTOMCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.312194954797317,
                            "left": 0.6781085137795275,
                            "top": 0.4009750656167979,
                            "width": 0.20463115157480316
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.7616740895669292,
                                "top": 0.6098366870807815,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_954052",
                                "provider": "NP",
                                "providerId": "954052",
                                "thumbnailUrl": "https://static.thenounproject.com/png/954052-200.png"
                            },
                            "position": "BOTTOMCENTER",
                            "size": 3,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Derive a proposal on how to resolve the complication.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                },
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Add supporting points or a comparison of solution options on subsequent slides.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ]
            ]
        }
    },
    BOXES_WITH_ARROWS_4:
    {
        displayName: '4 boxes',
        hideInPresetMenu: false,
        type: 'TABLE1',
        presetGroup: 'TABLE_BOXFLOW',
        framePadding: framePaddingNarrow,
        "box": {
            "height": 0.4791676067189085,
            "left": 0.04854793739760023,
            "top": 0.26354218369539967,
            "width": 0.8951426368542791
        },
        "content": {
            "rows": [
                [
                    {
                        "box": {
                            "height": 0.06857072032662584,
                            "left": 0.04854790026246719,
                            "top": 0.26354214056576264,
                            "width": 0.1684933562992126
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.18300574146981627,
                                "top": 0.2756052785068533,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_1058533",
                                "provider": "NP",
                                "providerId": "1058533",
                                "thumbnailUrl": "https://static.thenounproject.com/png/1058533-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Situation",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.4791673957421989,
                            "left": 0.23051779855643045,
                            "top": 0.26354214056576264,
                            "width": 0.058985400262467194
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.24126049868766405,
                                "top": 0.4697925051035287,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_2695615",
                                "provider": "NP",
                                "providerId": "2695615",
                                "thumbnailUrl": "https://static.thenounproject.com/png/2695615-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": true
                        },
                        "rowspan": 2,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Header",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.06857072032662584,
                            "left": 0.3029797408136483,
                            "top": 0.26354214056576264,
                            "width": 0.15737139107611547
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.42631561679790025,
                                "top": 0.2756052785068533,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_961638",
                                "provider": "NP",
                                "providerId": "961638",
                                "thumbnailUrl": "https://static.thenounproject.com/png/961638-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Complication",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.4791673957421989,
                            "left": 0.4738276738845144,
                            "top": 0.26354214056576264,
                            "width": 0.058985400262467194
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.48457037401574804,
                                "top": 0.4697925051035287,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_2695615",
                                "provider": "NP",
                                "providerId": "2695615",
                                "thumbnailUrl": "https://static.thenounproject.com/png/2695615-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": true
                        },
                        "rowspan": 2,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Header",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.06857072032662584,
                            "left": 0.5462896161417323,
                            "top": 0.26354214056576264,
                            "width": 0.17027641076115485
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.6825305118110236,
                                "top": 0.2756052785068533,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_954052",
                                "provider": "NP",
                                "providerId": "954052",
                                "thumbnailUrl": "https://static.thenounproject.com/png/954052-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Resolution",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.4791673957421989,
                            "left": 0.7300425688976377,
                            "top": 0.26354214056576264,
                            "width": 0.058985400262467194
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.7407852690288714,
                                "top": 0.4697925051035287,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_2695615",
                                "provider": "NP",
                                "providerId": "2695615",
                                "thumbnailUrl": "https://static.thenounproject.com/png/2695615-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": true
                        },
                        "rowspan": 2,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.06857072032662584,
                            "left": 0.8025045111548557,
                            "top": 0.26354214056576264,
                            "width": 0.14118569553805774
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.9096546916010498,
                                "top": 0.2756052785068533,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_193001",
                                "provider": "NP",
                                "providerId": "193001",
                                "thumbnailUrl": "https://static.thenounproject.com/png/193001-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Next Steps",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.38663837853601635,
                            "left": 0.04854790026246719,
                            "top": 0.35607115777194515,
                            "width": 0.1684933562992126
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.1140445784120735,
                                "top": 0.657757509477982,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "BOTTOMCENTER",
                            "size": 3,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Provide a fact-based, unambiguous explanation of the current situation. ",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                },
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Your target audience already knows and accepts the facts.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.18508665518372702,
                                "top": 0.5830199766695829,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "BOTTOMCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.38663837853601635,
                            "left": 0.3029797408136483,
                            "top": 0.35607115777194515,
                            "width": 0.15737139107611547
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.36291543635170603,
                                "top": 0.657757509477982,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_961638",
                                "provider": "NP",
                                "providerId": "961638",
                                "thumbnailUrl": "https://static.thenounproject.com/png/961638-200.png"
                            },
                            "position": "BOTTOMCENTER",
                            "size": 3,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Explain why action is required. What has changed and what aspects need to be taken care of? What is the impact?",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.18508665518372702,
                                "top": 0.5830199766695829,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "BOTTOMCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.38663837853601635,
                            "left": 0.5462896161417323,
                            "top": 0.35607115777194515,
                            "width": 0.17027641076115485
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.6126778215223098,
                                "top": 0.657757509477982,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_954052",
                                "provider": "NP",
                                "providerId": "954052",
                                "thumbnailUrl": "https://static.thenounproject.com/png/954052-200.png"
                            },
                            "position": "BOTTOMCENTER",
                            "size": 3,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Derive a proposal on how to resolve the complication.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                },
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Add supporting points or a comparison of solution options on subsequent slides.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.6860416666666667,
                                "top": 0.6380901137357831,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_954052",
                                "provider": "NP",
                                "providerId": "954052",
                                "thumbnailUrl": "https://static.thenounproject.com/png/954052-200.png"
                            },
                            "position": "BOTTOMCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.38663837853601635,
                            "left": 0.8025045111548557,
                            "top": 0.35607115777194515,
                            "width": 0.14118569553805774
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.8543473589238845,
                                "top": 0.657757509477982,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_193001",
                                "provider": "NP",
                                "providerId": "193001",
                                "thumbnailUrl": "https://static.thenounproject.com/png/193001-200.png"
                            },
                            "position": "BOTTOMCENTER",
                            "size": 3,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "children": [
                                                {
                                                    "bold": true,
                                                    "italic": false,
                                                    "smallCaps": false,
                                                    "text": "Task 1 ",
                                                    "underline": false
                                                },
                                                {
                                                    "bold": false,
                                                    "italic": false,
                                                    "smallCaps": false,
                                                    "text": "with additional detail",
                                                    "underline": false
                                                }
                                            ],
                                            "type": "list-item"
                                        },
                                        {
                                            "children": [
                                                {
                                                    "bold": true,
                                                    "italic": false,
                                                    "smallCaps": false,
                                                    "text": "Task 2",
                                                    "underline": false
                                                },
                                                {
                                                    "bold": false,
                                                    "italic": false,
                                                    "smallCaps": false,
                                                    "text": " with additional detail",
                                                    "underline": false
                                                }
                                            ],
                                            "type": "list-item"
                                        },
                                        {
                                            "children": [
                                                {
                                                    "bold": true,
                                                    "italic": false,
                                                    "smallCaps": false,
                                                    "text": "Task 3",
                                                    "underline": false
                                                },
                                                {
                                                    "bold": false,
                                                    "italic": false,
                                                    "smallCaps": false,
                                                    "text": " with additional detail",
                                                    "underline": false
                                                }
                                            ],
                                            "type": "list-item"
                                        },
                                        {
                                            "children": [
                                                {
                                                    "bold": true,
                                                    "italic": false,
                                                    "smallCaps": false,
                                                    "text": "Task 4",
                                                    "underline": false
                                                },
                                                {
                                                    "bold": false,
                                                    "italic": false,
                                                    "smallCaps": false,
                                                    "text": " with additional detail",
                                                    "underline": false
                                                }
                                            ],
                                            "type": "list-item"
                                        },
                                        {
                                            "children": [
                                                {
                                                    "text": ""
                                                }
                                            ],
                                            "type": "bulleted-list"
                                        }
                                    ],
                                    "type": "bulleted-list"
                                }
                            ],
                            "visible": true
                        }
                    }
                ]
            ]
        }
    },
    EMPTY_TABLE_3x4:
    {
        displayName: 'Empty table (3 x 4)',
        hideInPresetMenu: false,
        type: 'TABLE1',
        presetGroup: 'TABLE_GENERAL',
        framePadding: framePaddingNarrow,
        "box": {
            "height": 0.5594226258603836,
            "left": 0.1851562394036187,
            "top": 0.3017234997701128,
            "width": 0.6302083333333334
        },
        "content": {
            "rows": [
                [
                    {
                        "box": {
                            "height": 0.06857101195683873,
                            "left": 0.1981423474409449,
                            "top": 0.3950994459025955,
                            "width": 0.1501465715223097
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.3142533218503937,
                                "top": 0.40716272965879263,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_698181",
                                "provider": "NP",
                                "providerId": "698181",
                                "thumbnailUrl": "https://static.thenounproject.com/png/698181-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Header",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.06857101195683873,
                            "left": 0.35997592683727037,
                            "top": 0.3950994459025955,
                            "width": 0.24799991797900262
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Header",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.06857101195683873,
                            "left": 0.6196628526902888,
                            "top": 0.3950994459025955,
                            "width": 0.18618774606299213
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Header",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.10915791776027997,
                            "left": 0.1981423474409449,
                            "top": 0.48444736074657335,
                            "width": 0.1501465715223097
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.3142533218503937,
                                "top": 0.5168040974044911,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_698181",
                                "provider": "NP",
                                "providerId": "698181",
                                "thumbnailUrl": "https://static.thenounproject.com/png/698181-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER2",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Header",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.10915791776027997,
                            "left": 0.35997592683727037,
                            "top": 0.48444736074657335,
                            "width": 0.24799991797900262
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.10915791776027997,
                            "left": 0.6196628526902888,
                            "top": 0.48444736074657335,
                            "width": 0.18618774606299213
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "children": [
                                                {
                                                    "bold": false,
                                                    "italic": false,
                                                    "smallCaps": false,
                                                    "text": "At vero eos et accusam et justo",
                                                    "underline": false
                                                }
                                            ],
                                            "type": "list-item"
                                        }
                                    ],
                                    "type": "bulleted-list"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.10915791776027997,
                            "left": 0.1981423474409449,
                            "top": 0.6143821813939924,
                            "width": 0.1501465715223097
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.3142533218503937,
                                "top": 0.6467389180519102,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_698181",
                                "provider": "NP",
                                "providerId": "698181",
                                "thumbnailUrl": "https://static.thenounproject.com/png/698181-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER2",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Header",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.10915791776027997,
                            "left": 0.35997592683727037,
                            "top": 0.6143821813939924,
                            "width": 0.24799991797900262
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.10915791776027997,
                            "left": 0.6196628526902888,
                            "top": 0.6143821813939924,
                            "width": 0.18618774606299213
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "children": [
                                                {
                                                    "bold": false,
                                                    "italic": false,
                                                    "smallCaps": false,
                                                    "text": "At vero eos et accusam et justo",
                                                    "underline": false
                                                }
                                            ],
                                            "type": "list-item"
                                        }
                                    ],
                                    "type": "bulleted-list"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.10915791776027997,
                            "left": 0.1981423474409449,
                            "top": 0.7443170020414115,
                            "width": 0.1501465715223097
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.3142533218503937,
                                "top": 0.7766737386993292,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_698181",
                                "provider": "NP",
                                "providerId": "698181",
                                "thumbnailUrl": "https://static.thenounproject.com/png/698181-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER2",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Header",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.10915791776027997,
                            "left": 0.35997592683727037,
                            "top": 0.7443170020414115,
                            "width": 0.24799991797900262
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.10915791776027997,
                            "left": 0.6196628526902888,
                            "top": 0.7443170020414115,
                            "width": 0.18618774606299213
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "children": [
                                                {
                                                    "bold": false,
                                                    "italic": false,
                                                    "smallCaps": false,
                                                    "text": "At vero eos et accusam et justo",
                                                    "underline": false
                                                }
                                            ],
                                            "type": "list-item"
                                        }
                                    ],
                                    "type": "bulleted-list"
                                }
                            ],
                            "visible": true
                        }
                    }
                ]
            ]
        }
    },
    HARVEY_BALL_W_TEXT_1x1:
    {
        displayName: 'Harvey Ball with optional subtext (1 x 1)',
        hideInPresetMenu: false,
        type: 'TABLE1',
        presetGroup: 'TABLE_SINGLE_HARVEY',
        "box": {
            "height": 0.24095285366436542,
            "left": 0.5384991130372976,
            "top": 0.4049422515462815,
            "width": 0.20971472629144178
        },
        "content": {
            "rows": [
                [
                    {
                        "box": {
                            "height": 0.0978331875182269,
                            "left": 0.5384990977690288,
                            "top": 0.40494211140274133,
                            "width": 0.20971464895013123
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.6183564222440945,
                                "top": 0.40716433362496357,
                                "width": 0.05
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "value": 0.75,
                            "visible": true
                        },
                        "rowspan": 1,
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                },
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.051512102653834935,
                            "left": 0.5384990977690288,
                            "top": 0.5148228346456692,
                            "width": 0.20971464895013123
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "74%",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.06751210265383494,
                            "left": 0.5384990977690288,
                            "top": 0.5783824730242053,
                            "width": 0.20971464895013123
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Of world's population have access to safely managed drinking water",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ]
            ]
        }
    },
    ICON_W_TEXT_BOTTOM_1x1:
    {
        displayName: 'Text below',
        hideInPresetMenu: false,
        type: 'TABLE1',
        presetGroup: 'TABLE_SINGLE_ICON',
        "box": {
            "height": 0.30180679379844344,
            "left": 0.10894310183641387,
            "top": 0.3691155790284774,
            "width": 0.22332317073170732
        },
        "content": {
            "rows": [
                [
                    {
                        "box": {
                            "height": 0.13122193059200934,
                            "left": 0.12019307742782152,
                            "top": 0.3891154855643045,
                            "width": 0.2008231627296588
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.1111111111111111,
                                "left": 0.18935465879265093,
                                "top": 0.3991708953047536,
                                "width": 0.0625
                            },
                            "iconSource": {
                                "id": "NP_6058360",
                                "provider": "NP",
                                "providerId": "6058360",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6058360-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "visible": true
                        },
                        "rowspan": 1,
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                },
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.043313065033537476,
                            "left": 0.12019307742782152,
                            "top": 0.5334276757072033,
                            "width": 0.2008231627296588
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Production Progress",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.06109084281131525,
                            "left": 0.12019307742782152,
                            "top": 0.5898310002916302,
                            "width": 0.2008231627296588
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "In 2024 we anticipate a 10% increase in production volume.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ]
            ]
        }
    },
    ICON_W_TEXT_TOP_1x1:
    {
        displayName: 'Text above',
        hideInPresetMenu: false,
        type: 'TABLE1',
        presetGroup: 'TABLE_SINGLE_ICON',
        "box": {
            "height": 0.29503982532762635,
            "left": 0.10894310183641387,
            "top": 0.3691155790284774,
            "width": 0.22332317073170732
        },
        "content": {
            "rows": [
                [
                    {
                        "box": {
                            "height": 0.03833304170312044,
                            "left": 0.1394308562992126,
                            "top": 0.4462589676290464,
                            "width": 0.2233231627296588
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.05555555555555555,
                                "left": 0.235467437664042,
                                "top": 0.44903667249927093,
                                "width": 0.03125
                            },
                            "iconSource": {
                                "id": "NP_6058360",
                                "provider": "NP",
                                "providerId": "6058360",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6058360-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Production Progress",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.06168810148731409,
                            "left": 0.1394308562992126,
                            "top": 0.4983288130650335,
                            "width": 0.2233231627296588
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.3189728510498688,
                                "top": 0.49608311461067367,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_6058360",
                                "provider": "NP",
                                "providerId": "6058360",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6058360-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "In 2024 we anticipate a 10% increase in production volume.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.14724365704286965,
                            "left": 0.1394308562992126,
                            "top": 0.5737537182852144,
                            "width": 0.2233231627296588
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.12222222222222222,
                                "left": 0.216717437664042,
                                "top": 0.5840145086030912,
                                "width": 0.06875
                            },
                            "iconSource": {
                                "id": "NP_6058360",
                                "provider": "NP",
                                "providerId": "6058360",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6058360-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "visible": true
                        },
                        "rowspan": 1,
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                },
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ]
            ]
        }
    },
    ICON_W_TEXT_RIGHT_1x1:
    {
        displayName: 'Text right',
        hideInPresetMenu: false,
        type: 'TABLE1',
        presetGroup: 'TABLE_SINGLE_ICON',
        "box": {
            "height": 0.18541493610038903,
            "left": 0.10894310183641387,
            "top": 0.3691155790284774,
            "width": 0.2728658536585366
        },
        "content": {
            "rows": [
                [
                    {
                        "box": {
                            "height": 0.18541440653251676,
                            "left": 0.08379060039370079,
                            "top": 0.41513094196558764,
                            "width": 0.12749885170603675
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.13333333333333333,
                                "left": 0.11004002624671916,
                                "top": 0.43536213181685623,
                                "width": 0.075
                            },
                            "iconSource": {
                                "id": "NP_6058360",
                                "provider": "NP",
                                "providerId": "6058360",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6058360-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "visible": true
                        },
                        "rowspan": 3,
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                },
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.0552373869932925,
                            "left": 0.21650418307086614,
                            "top": 0.41513094196558764,
                            "width": 0.14015214895013123
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.1111111111111111,
                                "left": 0.24973499015748032,
                                "top": 0.41694867308253136,
                                "width": 0.0625
                            },
                            "iconSource": {
                                "id": "NP_6058360",
                                "provider": "NP",
                                "providerId": "6058360",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6058360-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Production Progress",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.07777777777777778,
                                "left": 0.16264837598425197,
                                "top": 0.4788799212598425,
                                "width": 0.04375
                            },
                            "position": "CENTERRIGHT",
                            "size": 6,
                            "value": 0.75,
                            "visible": false
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.1250492125984252,
                                "top": 0.4957710702828813,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_6058360",
                                "provider": "NP",
                                "providerId": "6058360",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6058360-200.png"
                            },
                            "position": "TOPCENTER",
                            "size": 6,
                            "visible": false
                        },
                        "rowspan": 1,
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                },
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.12090638670166229,
                            "left": 0.21650418307086614,
                            "top": 0.4796389617964421,
                            "width": 0.14015214895013123
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.1111111111111111,
                                "left": 0.24973499015748032,
                                "top": 0.41694867308253136,
                                "width": 0.0625
                            },
                            "iconSource": {
                                "id": "NP_6058360",
                                "provider": "NP",
                                "providerId": "6058360",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6058360-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "In 2024 we anticipate a 10% increase in production volume.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ]
            ]
        }
    },
    ICON_W_TEXT_LEFT_1x1:
    {
        displayName: 'Text left',
        hideInPresetMenu: false,
        type: 'TABLE1',
        presetGroup: 'TABLE_SINGLE_ICON',
        "box": {
            "height": 0.18541493610038903,
            "left": 0.10894310183641387,
            "top": 0.3691155790284774,
            "width": 0.29496951219512196
        },
        "content": {
            "rows": [
                [
                    {
                        "box": {
                            "height": 0.05366652085156022,
                            "left": 0.10513205380577428,
                            "top": 0.4164842519685039,
                            "width": 0.13852846128608923
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.1111111111111111,
                                "left": 0.24973499015748032,
                                "top": 0.41694867308253136,
                                "width": 0.0625
                            },
                            "iconSource": {
                                "id": "NP_6058360",
                                "provider": "NP",
                                "providerId": "6058360",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6058360-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Production Progress",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.18541455234762322,
                            "left": 0.24887524606299213,
                            "top": 0.4164842519685039,
                            "width": 0.15122621391076116
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.13333333333333333,
                                "left": 0.2869883530183727,
                                "top": 0.4425248614756489,
                                "width": 0.075
                            },
                            "iconSource": {
                                "id": "NP_6058360",
                                "provider": "NP",
                                "providerId": "6058360",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6058360-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "visible": true
                        },
                        "rowspan": 3,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.12247739865850102,
                            "left": 0.10513205380577428,
                            "top": 0.47942140565762614,
                            "width": 0.13852846128608923
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.1111111111111111,
                                "left": 0.24973499015748032,
                                "top": 0.41694867308253136,
                                "width": 0.0625
                            },
                            "iconSource": {
                                "id": "NP_6058360",
                                "provider": "NP",
                                "providerId": "6058360",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6058360-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "In 2024 we anticipate a 10% increase in production volume.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.1111111111111111,
                                "left": 0.24973499015748032,
                                "top": 0.41694867308253136,
                                "width": 0.0625
                            },
                            "iconSource": {
                                "id": "NP_6058360",
                                "provider": "NP",
                                "providerId": "6058360",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6058360-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ]
            ]
        }
    },
    PROGRESS_BAR_W_TEXT_1x1:
    {
        displayName: 'Progress bar with optional subtext (1 x 1)',
        hideInPresetMenu: false,
        type: 'TABLE1',
        presetGroup: 'TABLE_SINGLE_PROGRESS',
        "box": {
            "height": 0.24482350688202797,
            "left": 0.3241955146153213,
            "top": 0.41105120461194933,
            "width": 0.23561199675763309
        },
        "content": {
            "rows": [
                [
                    {
                        "box": {
                            "height": 0.06449985418489355,
                            "left": 0.3354454560367454,
                            "top": 0.4310511811023622,
                            "width": 0.21311195866141733
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.10555555555555556,
                                "left": 0.15239739173228348,
                                "top": 0.3753529090113736,
                                "width": 0.059375
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "value": 0.75,
                            "visible": false
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.12222222222222222,
                                "left": 0.2274099819553806,
                                "top": 0.3752185039370079,
                                "width": 0.06875
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "visible": false
                        },
                        "progressBarSettings": {
                            "box": {
                                "height": 0.03333333333333333,
                                "left": 0.4045014353674541,
                                "top": 0.43438451443569553,
                                "width": 0.075
                            },
                            "position": "TOPCENTER",
                            "segments": 4,
                            "size": 2,
                            "value": 0.65,
                            "visible": true
                        },
                        "rowspan": 1,
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                },
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.044170457859434235,
                            "left": 0.3354454560367454,
                            "top": 0.5057920676582094,
                            "width": 0.21311195866141733
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Production progress",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.07567060367454068,
                            "left": 0.3354454560367454,
                            "top": 0.5602035578885972,
                            "width": 0.21311195866141733
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Last month the department already ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "reached the September goal of 75%",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " - The forecast anticipates the fulfillment of this year's target.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ]
            ]
        }
    },
    ICON_ROW_3x1:
    {
        displayName: 'Icon row (3 x 1)',
        hideInPresetMenu: false,
        type: 'TABLE1',
        presetGroup: 'TABLE_ICONGRID',
        framePadding: framePaddingNarrow,
        "box": {
            "height": 0.30045340010428007,
            "left": 0.10486533002155583,
            "top": 0.2150768214478537,
            "width": 0.801829268292683
        },
        "content": {
            "equalColWidths": true,
            "rows": [
                [
                    {
                        "box": {
                            "height": 0.10233304170312044,
                            "left": 0.1048653215223097,
                            "top": 0.21507669874599009,
                            "width": 0.2613384186351706
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.10555555555555556,
                                "left": 0.15239739173228348,
                                "top": 0.3753529090113736,
                                "width": 0.059375
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "value": 0.75,
                            "visible": false
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.210534530839895,
                                "top": 0.22179877515310586,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                },
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.10233304170312044,
                            "left": 0.3751107283464567,
                            "top": 0.21507669874599009,
                            "width": 0.2613384186351706
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.10555555555555556,
                                "left": 0.15239739173228348,
                                "top": 0.3753529090113736,
                                "width": 0.059375
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "value": 0.75,
                            "visible": false
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.480779937664042,
                                "top": 0.22179877515310586,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_6288641",
                                "provider": "NP",
                                "providerId": "6288641",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6288641-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                },
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.10233304170312044,
                            "left": 0.6453561351706036,
                            "top": 0.21507669874599009,
                            "width": 0.2613384186351706
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.10555555555555556,
                                "left": 0.15239739173228348,
                                "top": 0.3753529090113736,
                                "width": 0.059375
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "value": 0.75,
                            "visible": false
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.751025344488189,
                                "top": 0.22179877515310586,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_961539",
                                "provider": "NP",
                                "providerId": "961539",
                                "thumbnailUrl": "https://static.thenounproject.com/png/961539-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                },
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.05328550597841936,
                            "left": 0.1048653215223097,
                            "top": 0.33324438611840185,
                            "width": 0.2613384186351706
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Timber production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.05328550597841936,
                            "left": 0.3751107283464567,
                            "top": 0.33324438611840185,
                            "width": 0.2613384186351706
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Timber production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.05328550597841936,
                            "left": 0.6453561351706036,
                            "top": 0.33324438611840185,
                            "width": 0.2613384186351706
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Timber production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.12940594925634297,
                            "left": 0.1048653215223097,
                            "top": 0.4023645377661126,
                            "width": 0.2613384186351706
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "The ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "United States",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " are world leader producing 298.7 million cubic meters of wood & timber in 2020",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.12940594925634297,
                            "left": 0.3751107283464567,
                            "top": 0.4023645377661126,
                            "width": 0.2613384186351706
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "The ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "United States",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " are world leader producing 298.7 million cubic meters of wood & timber in 2020",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.12940594925634297,
                            "left": 0.6453561351706036,
                            "top": 0.4023645377661126,
                            "width": 0.2613384186351706
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "The ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "United States",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " are world leader producing 298.7 million cubic meters of wood & timber in 2020",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ]
            ]
        }
    },
    ICON_ROW_2x1:
    {
        displayName: 'Icon row (2 x 1)',
        hideInPresetMenu: false,
        type: 'TABLE1',
        presetGroup: 'TABLE_ICONGRID',
        framePadding: framePaddingNarrow,
        "box": {
            "height": 0.30045340010428007,
            "left": 0.10486533002155583,
            "top": 0.2150768214478537,
            "width": 0.5373475609756098
        },
        "content": {
            "equalColWidths": true,
            "rows": [
                [
                    {
                        "box": {
                            "height": 0.11657072032662584,
                            "left": 0.1048653215223097,
                            "top": 0.21507669874599009,
                            "width": 0.396689468503937
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.10555555555555556,
                                "left": 0.15239739173228348,
                                "top": 0.3753529090113736,
                                "width": 0.059375
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "value": 0.75,
                            "visible": false
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.2782100557742782,
                                "top": 0.22891761446485856,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                },
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.11657072032662584,
                            "left": 0.5100050032808399,
                            "top": 0.21507669874599009,
                            "width": 0.396689468503937
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.10555555555555556,
                                "left": 0.15239739173228348,
                                "top": 0.3753529090113736,
                                "width": 0.059375
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "value": 0.75,
                            "visible": false
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.6833497375328084,
                                "top": 0.22891761446485856,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_6288641",
                                "provider": "NP",
                                "providerId": "6288641",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6288641-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                },
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.05757276173811607,
                            "left": 0.1048653215223097,
                            "top": 0.3466700204141149,
                            "width": 0.396689468503937
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Timber production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.05757276173811607,
                            "left": 0.5100050032808399,
                            "top": 0.3466700204141149,
                            "width": 0.396689468503937
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Timber production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.09626407115777194,
                            "left": 0.1048653215223097,
                            "top": 0.41926538349372994,
                            "width": 0.396689468503937
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "The ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "United States",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " are world leader producing 298.7 million cubic meters of wood & timber in 2020",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.09626407115777194,
                            "left": 0.5100050032808399,
                            "top": 0.41926538349372994,
                            "width": 0.396689468503937
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "The ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "United States",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " are world leader producing 298.7 million cubic meters of wood & timber in 2020",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ]
            ]
        }
    },
    ICON_COLUMN_1x3:
    {
        displayName: 'Icon column (1 x 3)',
        hideInPresetMenu: false,
        type: 'TABLE1',
        presetGroup: 'TABLE_ICONGRID',
        framePadding: framePaddingNarrow,
        "box": {
            "height": 0.7051181146591436,
            "left": 0.10486533002155583,
            "top": 0.2150768214478537,
            "width": 0.27210365853658536
        },
        "content": {
            "equalColWidths": true,
            "rows": [
                [
                    {
                        "box": {
                            "height": 0.08677748614756489,
                            "left": 0.1048653215223097,
                            "top": 0.21507669874599009,
                            "width": 0.2789633366141732
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.10555555555555556,
                                "left": 0.15239739173228348,
                                "top": 0.3753529090113736,
                                "width": 0.059375
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "value": 0.75,
                            "visible": false
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.07407407407407407,
                                "left": 0.223513656496063,
                                "top": 0.22142840478273548,
                                "width": 0.041666666666666664
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                },
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.03836920384951881,
                            "left": 0.1048653215223097,
                            "top": 0.31467439486730825,
                            "width": 0.2789633366141732
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Timber production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.0716700204141149,
                            "left": 0.1048653215223097,
                            "top": 0.3658638086905803,
                            "width": 0.2789633366141732
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "The ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "United States",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " are world leader producing 298.7 million cubic meters of wood & timber in 2020",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.08948031496062993,
                            "left": 0.1048653215223097,
                            "top": 0.4503540390784485,
                            "width": 0.2789633366141732
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.07407407407407407,
                                "left": 0.223513656496063,
                                "top": 0.45805715952172643,
                                "width": 0.041666666666666664
                            },
                            "iconSource": {
                                "id": "NP_5680200",
                                "provider": "NP",
                                "providerId": "5680200",
                                "thumbnailUrl": "https://static.thenounproject.com/png/5680200-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.03836920384951881,
                            "left": 0.1048653215223097,
                            "top": 0.5526545640128318,
                            "width": 0.2789633366141732
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Seaweed production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.0716700204141149,
                            "left": 0.1048653215223097,
                            "top": 0.6038439778361038,
                            "width": 0.2789633366141732
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Asia is producing 97% of world's seaweed ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "led by China ",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "producing 20 million tonnes in 2019",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.08948031496062993,
                            "left": 0.1048653215223097,
                            "top": 0.688334208223972,
                            "width": 0.2789633366141732
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.07407407407407407,
                                "left": 0.223513656496063,
                                "top": 0.6960373286672499,
                                "width": 0.041666666666666664
                            },
                            "iconSource": {
                                "id": "NP_6230428",
                                "provider": "NP",
                                "providerId": "6230428",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6230428-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.03836920384951881,
                            "left": 0.1048653215223097,
                            "top": 0.7906347331583552,
                            "width": 0.2789633366141732
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Rum production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.07836920384951881,
                            "left": 0.1048653215223097,
                            "top": 0.8418241469816273,
                            "width": 0.2789633366141732
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "While most famous rum brands originate from Caribbean countries, ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Italy",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " surprises as the world rum export nation number 1",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ]
            ]
        }
    },
    ICON_COLUMN_1x2:
    {
        displayName: 'Icon column (1 x 2)',
        hideInPresetMenu: false,
        type: 'TABLE1',
        presetGroup: 'TABLE_ICONGRID',
        framePadding: framePaddingNarrow,
        "box": {
            "height": 0.479101367733852,
            "left": 0.10486533002155583,
            "top": 0.2150768214478537,
            "width": 0.27210365853658536
        },
        "content": {
            "equalColWidths": true,
            "rows": [
                [
                    {
                        "box": {
                            "height": 0.11657072032662584,
                            "left": 0.1048653215223097,
                            "top": 0.21507669874599009,
                            "width": 0.272103592519685
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.10555555555555556,
                                "left": 0.15239739173228348,
                                "top": 0.3753529090113736,
                                "width": 0.059375
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "value": 0.75,
                            "visible": false
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.21591711778215222,
                                "top": 0.22891761446485856,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                },
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.06356561679790027,
                            "left": 0.1048653215223097,
                            "top": 0.3492752989209682,
                            "width": 0.272103592519685
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Timber production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.1375217264508603,
                            "left": 0.1048653215223097,
                            "top": 0.4304687955672208,
                            "width": 0.272103592519685
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "The ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "United States",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " are world leader producing 298.7 million cubic meters of wood & timber in 2020",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.1248989501312336,
                            "left": 0.1048653215223097,
                            "top": 0.5856184018664333,
                            "width": 0.272103592519685
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.21591711778215222,
                                "top": 0.6036234324876057,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_5680200",
                                "provider": "NP",
                                "providerId": "5680200",
                                "thumbnailUrl": "https://static.thenounproject.com/png/5680200-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.06356561679790027,
                            "left": 0.1048653215223097,
                            "top": 0.7281452318460192,
                            "width": 0.272103592519685
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Seaweed production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.11085505978419365,
                            "left": 0.1048653215223097,
                            "top": 0.8093387284922718,
                            "width": 0.272103592519685
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Asia is producing 97% of world's seaweed ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "led by China ",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "producing 20 million tonnes in 2019",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ]
            ]
        }
    },
    ICON_GRID_3x3:
    {
        displayName: 'Icon grid (3 x 3)',
        hideInPresetMenu: false,
        type: 'TABLE1',
        presetGroup: 'TABLE_ICONGRID',
        framePadding: framePaddingNarrow,
        "box": {
            "height": 0.7051181146591436,
            "left": 0.10486533002155583,
            "top": 0.2150768214478537,
            "width": 0.801829268292683
        },
        "content": {
            "equalColWidths": true,
            "rows": [
                [
                    {
                        "box": {
                            "height": 0.0789997083697871,
                            "left": 0.11611532152230972,
                            "top": 0.23507669874599008,
                            "width": 0.25530610236220475
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.10555555555555556,
                                "left": 0.15239739173228348,
                                "top": 0.3753529090113736,
                                "width": 0.059375
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "value": 0.75,
                            "visible": false
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.22501837270341207,
                                "top": 0.2412432195975503,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                },
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.0789997083697871,
                            "left": 0.37812680446194225,
                            "top": 0.23507669874599008,
                            "width": 0.25530610236220475
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.10555555555555556,
                                "left": 0.15239739173228348,
                                "top": 0.3753529090113736,
                                "width": 0.059375
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "value": 0.75,
                            "visible": false
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.48702985564304463,
                                "top": 0.2412432195975503,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_6288641",
                                "provider": "NP",
                                "providerId": "6288641",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6288641-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                },
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.0789997083697871,
                            "left": 0.6401382874015749,
                            "top": 0.23507669874599008,
                            "width": 0.25530610236220475
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.10555555555555556,
                                "left": 0.15239739173228348,
                                "top": 0.3753529090113736,
                                "width": 0.059375
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "value": 0.75,
                            "visible": false
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.7490413385826772,
                                "top": 0.2412432195975503,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_961539",
                                "provider": "NP",
                                "providerId": "961539",
                                "thumbnailUrl": "https://static.thenounproject.com/png/961539-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                },
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.03634354039078449,
                            "left": 0.11611532152230972,
                            "top": 0.3259970836978711,
                            "width": 0.25530610236220475
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Timber production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.03634354039078449,
                            "left": 0.37812680446194225,
                            "top": 0.3259970836978711,
                            "width": 0.25530610236220475
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Timber production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.03634354039078449,
                            "left": 0.6401382874015749,
                            "top": 0.3259970836978711,
                            "width": 0.25530610236220475
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Timber production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.06760804899387576,
                            "left": 0.11611532152230972,
                            "top": 0.3742613006707495,
                            "width": 0.25530610236220475
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "The ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "United States",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " are world leader producing 298.7 million cubic meters of wood & timber in 2020",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.06760804899387576,
                            "left": 0.37812680446194225,
                            "top": 0.3742613006707495,
                            "width": 0.25530610236220475
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "The ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "United States",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " are world leader producing 298.7 million cubic meters of wood & timber in 2020",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.06760804899387576,
                            "left": 0.6401382874015749,
                            "top": 0.3742613006707495,
                            "width": 0.25530610236220475
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "The ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "United States",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " are world leader producing 298.7 million cubic meters of wood & timber in 2020",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.08234354039078448,
                            "left": 0.11611532152230972,
                            "top": 0.45379002624671916,
                            "width": 0.25530610236220475
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.22501837270341207,
                                "top": 0.4616284631087781,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_5680200",
                                "provider": "NP",
                                "providerId": "5680200",
                                "thumbnailUrl": "https://static.thenounproject.com/png/5680200-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.08234354039078448,
                            "left": 0.37812680446194225,
                            "top": 0.45379002624671916,
                            "width": 0.25530610236220475
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.48702985564304463,
                                "top": 0.4616284631087781,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_5770290",
                                "provider": "NP",
                                "providerId": "5770290",
                                "thumbnailUrl": "https://static.thenounproject.com/png/5770290-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.08234354039078448,
                            "left": 0.6401382874015749,
                            "top": 0.45379002624671916,
                            "width": 0.25530610236220475
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.7490413385826772,
                                "top": 0.4616284631087781,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_6285732",
                                "provider": "NP",
                                "providerId": "6285732",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6285732-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.03634354039078449,
                            "left": 0.11611532152230972,
                            "top": 0.5480542432195975,
                            "width": 0.25530610236220475
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Seaweed production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.03634354039078449,
                            "left": 0.37812680446194225,
                            "top": 0.5480542432195975,
                            "width": 0.25530610236220475
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Seaweed production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.03634354039078449,
                            "left": 0.6401382874015749,
                            "top": 0.5480542432195975,
                            "width": 0.25530610236220475
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Seaweed production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.06760804899387576,
                            "left": 0.11611532152230972,
                            "top": 0.5963184601924759,
                            "width": 0.25530610236220475
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Asia is producing 97% of world's seaweed ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "led by China ",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "producing 20 million tonnes in 2019",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.06760804899387576,
                            "left": 0.37812680446194225,
                            "top": 0.5963184601924759,
                            "width": 0.25530610236220475
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Asia is producing 97% of world's seaweed ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "led by China ",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "producing 20 million tonnes in 2019",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.06760804899387576,
                            "left": 0.6401382874015749,
                            "top": 0.5963184601924759,
                            "width": 0.25530610236220475
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Asia is producing 97% of world's seaweed ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "led by China ",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "producing 20 million tonnes in 2019",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.08234354039078448,
                            "left": 0.11611532152230972,
                            "top": 0.6758471857684456,
                            "width": 0.25530610236220475
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.22501837270341207,
                                "top": 0.6836856226305045,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_6230428",
                                "provider": "NP",
                                "providerId": "6230428",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6230428-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.08234354039078448,
                            "left": 0.37812680446194225,
                            "top": 0.6758471857684456,
                            "width": 0.25530610236220475
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.48702985564304463,
                                "top": 0.6836856226305045,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_845009",
                                "provider": "NP",
                                "providerId": "845009",
                                "thumbnailUrl": "https://static.thenounproject.com/png/845009-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.08234354039078448,
                            "left": 0.6401382874015749,
                            "top": 0.6758471857684456,
                            "width": 0.25530610236220475
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.7490413385826772,
                                "top": 0.6836856226305045,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_4514764",
                                "provider": "NP",
                                "providerId": "4514764",
                                "thumbnailUrl": "https://static.thenounproject.com/png/4514764-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.03634354039078449,
                            "left": 0.11611532152230972,
                            "top": 0.770111402741324,
                            "width": 0.25530610236220475
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Rum production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.03634354039078449,
                            "left": 0.37812680446194225,
                            "top": 0.770111402741324,
                            "width": 0.25530610236220475
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Rum production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.03634354039078449,
                            "left": 0.6401382874015749,
                            "top": 0.770111402741324,
                            "width": 0.25530610236220475
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Rum production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.07234354039078449,
                            "left": 0.11611532152230972,
                            "top": 0.8183756197142024,
                            "width": 0.25530610236220475
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "While most famous rum brands originate from Caribbean countries, ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Italy",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " surprises as the world rum export nation number 1",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.07234354039078449,
                            "left": 0.37812680446194225,
                            "top": 0.8183756197142024,
                            "width": 0.25530610236220475
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "While most famous rum brands originate from Caribbean countries, ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Italy",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " surprises as the world rum export nation number 1",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.07234354039078449,
                            "left": 0.6401382874015749,
                            "top": 0.8183756197142024,
                            "width": 0.25530610236220475
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "While most famous rum brands originate from Caribbean countries, ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Italy",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " surprises as the world rum export nation number 1",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ]
            ]
        }
    },
    ICON_GRID_3x2:
    {
        displayName: 'Icon grid (3 x 2)',
        hideInPresetMenu: false,
        type: 'TABLE1',
        presetGroup: 'TABLE_ICONGRID',
        framePadding: framePaddingNarrow,
        "box": {
            "height": 0.5318837218062255,
            "left": 0.10486533002155583,
            "top": 0.2150768214478537,
            "width": 0.801829268292683
        },
        "content": {
            "equalColWidths": true,
            "rows": [
                [
                    {
                        "box": {
                            "height": 0.0978331875182269,
                            "left": 0.1048653215223097,
                            "top": 0.21507669874599009,
                            "width": 0.26244225721784775
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.10555555555555556,
                                "left": 0.15239739173228348,
                                "top": 0.3753529090113736,
                                "width": 0.059375
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "value": 0.75,
                            "visible": false
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.2110864501312336,
                                "top": 0.2172989209682123,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                },
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.0978331875182269,
                            "left": 0.37455872703412074,
                            "top": 0.21507669874599009,
                            "width": 0.26244225721784775
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.10555555555555556,
                                "left": 0.15239739173228348,
                                "top": 0.3753529090113736,
                                "width": 0.059375
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "value": 0.75,
                            "visible": false
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.4807798556430446,
                                "top": 0.2172989209682123,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_6288641",
                                "provider": "NP",
                                "providerId": "6288641",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6288641-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                },
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.0978331875182269,
                            "left": 0.6442521325459317,
                            "top": 0.21507669874599009,
                            "width": 0.26244225721784775
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.10555555555555556,
                                "left": 0.15239739173228348,
                                "top": 0.3753529090113736,
                                "width": 0.059375
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "value": 0.75,
                            "visible": false
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.7504732611548557,
                                "top": 0.2172989209682123,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_961539",
                                "provider": "NP",
                                "providerId": "961539",
                                "thumbnailUrl": "https://static.thenounproject.com/png/961539-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                },
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.042367308253135026,
                            "left": 0.1048653215223097,
                            "top": 0.3258008165645961,
                            "width": 0.26244225721784775
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Timber production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.042367308253135026,
                            "left": 0.37455872703412074,
                            "top": 0.3258008165645961,
                            "width": 0.26244225721784775
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Timber production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.042367308253135026,
                            "left": 0.6442521325459317,
                            "top": 0.3258008165645961,
                            "width": 0.26244225721784775
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Timber production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.09845902595508894,
                            "left": 0.1048653215223097,
                            "top": 0.3810590551181102,
                            "width": 0.26244225721784775
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "The ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "United States",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " are world leader producing 298.7 million cubic meters of wood & timber in 2020",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.09845902595508894,
                            "left": 0.37455872703412074,
                            "top": 0.3810590551181102,
                            "width": 0.26244225721784775
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "The ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "United States",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " are world leader producing 298.7 million cubic meters of wood & timber in 2020",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.09845902595508894,
                            "left": 0.6442521325459317,
                            "top": 0.3810590551181102,
                            "width": 0.26244225721784775
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "The ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "United States",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " are world leader producing 298.7 million cubic meters of wood & timber in 2020",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.09836730825313503,
                            "left": 0.1048653215223097,
                            "top": 0.4924090113735783,
                            "width": 0.26244225721784775
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.2110864501312336,
                                "top": 0.4948982939632546,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_5680200",
                                "provider": "NP",
                                "providerId": "5680200",
                                "thumbnailUrl": "https://static.thenounproject.com/png/5680200-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.09836730825313503,
                            "left": 0.37455872703412074,
                            "top": 0.4924090113735783,
                            "width": 0.26244225721784775
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.4807798556430446,
                                "top": 0.4948982939632546,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_5770290",
                                "provider": "NP",
                                "providerId": "5770290",
                                "thumbnailUrl": "https://static.thenounproject.com/png/5770290-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.09836730825313503,
                            "left": 0.6442521325459317,
                            "top": 0.4924090113735783,
                            "width": 0.26244225721784775
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.7504732611548557,
                                "top": 0.4948982939632546,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_6285732",
                                "provider": "NP",
                                "providerId": "6285732",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6285732-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.042367308253135026,
                            "left": 0.1048653215223097,
                            "top": 0.6036672499270924,
                            "width": 0.26244225721784775
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Seaweed production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.042367308253135026,
                            "left": 0.37455872703412074,
                            "top": 0.6036672499270924,
                            "width": 0.26244225721784775
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Seaweed production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.042367308253135026,
                            "left": 0.6442521325459317,
                            "top": 0.6036672499270924,
                            "width": 0.26244225721784775
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Seaweed production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.07179235928842229,
                            "left": 0.1048653215223097,
                            "top": 0.6589254884806066,
                            "width": 0.26244225721784775
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Asia is producing 97% of world's seaweed ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "led by China ",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "producing 20 million tonnes in 2019",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.07179235928842229,
                            "left": 0.37455872703412074,
                            "top": 0.6589254884806066,
                            "width": 0.26244225721784775
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Asia is producing 97% of world's seaweed ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "led by China ",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "producing 20 million tonnes in 2019",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.07179235928842229,
                            "left": 0.6442521325459317,
                            "top": 0.6589254884806066,
                            "width": 0.26244225721784775
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Asia is producing 97% of world's seaweed ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "led by China ",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "producing 20 million tonnes in 2019",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ]
            ]
        }
    },
    ICON_GRID_2x3:
    {
        displayName: 'Icon grid (2 x 3)',
        hideInPresetMenu: false,
        type: 'TABLE1',
        presetGroup: 'TABLE_ICONGRID',
        framePadding: framePaddingNarrow,
        "box": {
            "height": 0.7051181146591436,
            "left": 0.10486533002155583,
            "top": 0.2150768214478537,
            "width": 0.5396341463414634
        },
        "content": {
            "equalColWidths": true,
            "rows": [
                [
                    {
                        "box": {
                            "height": 0.09455526392534266,
                            "left": 0.1048653215223097,
                            "top": 0.21507669874599009,
                            "width": 0.3973088910761155
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.10555555555555556,
                                "left": 0.15239739173228348,
                                "top": 0.3753529090113736,
                                "width": 0.059375
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "value": 0.75,
                            "visible": false
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.08148148148148149,
                                "left": 0.2806031003937008,
                                "top": 0.2216135899679207,
                                "width": 0.04583333333333333
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                },
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.09455526392534266,
                            "left": 0.5093855807086615,
                            "top": 0.21507669874599009,
                            "width": 0.3973088910761155
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.10555555555555556,
                                "left": 0.15239739173228348,
                                "top": 0.3753529090113736,
                                "width": 0.059375
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "value": 0.75,
                            "visible": false
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.08148148148148149,
                                "left": 0.6851233595800525,
                                "top": 0.2216135899679207,
                                "width": 0.04583333333333333
                            },
                            "iconSource": {
                                "id": "NP_6288641",
                                "provider": "NP",
                                "providerId": "6288641",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6288641-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                },
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.038791338582677165,
                            "left": 0.1048653215223097,
                            "top": 0.322452172645086,
                            "width": 0.3973088910761155
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Timber production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.038791338582677165,
                            "left": 0.5093855807086615,
                            "top": 0.322452172645086,
                            "width": 0.3973088910761155
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Timber production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.07162540099154273,
                            "left": 0.1048653215223097,
                            "top": 0.3740637212015165,
                            "width": 0.3973088910761155
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "The ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "United States",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " are world leader producing 298.7 million cubic meters of wood & timber in 2020",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.07162540099154273,
                            "left": 0.5093855807086615,
                            "top": 0.3740637212015165,
                            "width": 0.3973088910761155
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "The ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "United States",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " are world leader producing 298.7 million cubic meters of wood & timber in 2020",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.0950135608048994,
                            "left": 0.1048653215223097,
                            "top": 0.4585093321668125,
                            "width": 0.3973088910761155
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.08148148148148149,
                                "left": 0.2806031003937008,
                                "top": 0.4652753718285214,
                                "width": 0.04583333333333333
                            },
                            "iconSource": {
                                "id": "NP_5680200",
                                "provider": "NP",
                                "providerId": "5680200",
                                "thumbnailUrl": "https://static.thenounproject.com/png/5680200-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.0950135608048994,
                            "left": 0.5093855807086615,
                            "top": 0.4585093321668125,
                            "width": 0.3973088910761155
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.08148148148148149,
                                "left": 0.6851233595800525,
                                "top": 0.4652753718285214,
                                "width": 0.04583333333333333
                            },
                            "iconSource": {
                                "id": "NP_5770290",
                                "provider": "NP",
                                "providerId": "5770290",
                                "thumbnailUrl": "https://static.thenounproject.com/png/5770290-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.038791338582677165,
                            "left": 0.1048653215223097,
                            "top": 0.5663431029454652,
                            "width": 0.3973088910761155
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Seaweed production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.038791338582677165,
                            "left": 0.5093855807086615,
                            "top": 0.5663431029454652,
                            "width": 0.3973088910761155
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Seaweed production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.07162540099154273,
                            "left": 0.1048653215223097,
                            "top": 0.6179546515018955,
                            "width": 0.3973088910761155
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Asia is producing 97% of world's seaweed ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "led by China ",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "producing 20 million tonnes in 2019",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.07162540099154273,
                            "left": 0.5093855807086615,
                            "top": 0.6179546515018955,
                            "width": 0.3973088910761155
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Asia is producing 97% of world's seaweed ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "led by China ",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "producing 20 million tonnes in 2019",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.0950135608048994,
                            "left": 0.1048653215223097,
                            "top": 0.7024002624671916,
                            "width": 0.3973088910761155
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.08148148148148149,
                                "left": 0.2806031003937008,
                                "top": 0.7091663021289005,
                                "width": 0.04583333333333333
                            },
                            "iconSource": {
                                "id": "NP_6230428",
                                "provider": "NP",
                                "providerId": "6230428",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6230428-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.0950135608048994,
                            "left": 0.5093855807086615,
                            "top": 0.7024002624671916,
                            "width": 0.3973088910761155
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.08148148148148149,
                                "left": 0.6851233595800525,
                                "top": 0.7091663021289005,
                                "width": 0.04583333333333333
                            },
                            "iconSource": {
                                "id": "NP_845009",
                                "provider": "NP",
                                "providerId": "845009",
                                "thumbnailUrl": "https://static.thenounproject.com/png/845009-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.038791338582677165,
                            "left": 0.1048653215223097,
                            "top": 0.8102340332458443,
                            "width": 0.3973088910761155
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Rum production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.038791338582677165,
                            "left": 0.5093855807086615,
                            "top": 0.8102340332458443,
                            "width": 0.3973088910761155
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Rum production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.05834689413823272,
                            "left": 0.1048653215223097,
                            "top": 0.8618455818022747,
                            "width": 0.3973088910761155
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "While most famous rum brands originate from Caribbean countries, ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Italy",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " surprises as the world rum export nation number 1",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.05834689413823272,
                            "left": 0.5093855807086615,
                            "top": 0.8618455818022747,
                            "width": 0.3973088910761155
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "While most famous rum brands originate from Caribbean countries, ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Italy",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " surprises as the world rum export nation number 1",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ]
            ]
        }
    },
    ICON_GRID_2x2:
    {
        displayName: 'Icon grid (2 x 2)',
        hideInPresetMenu: false,
        type: 'TABLE1',
        presetGroup: 'TABLE_ICONGRID',
        framePadding: framePaddingNarrow,
        "box": {
            "height": 0.4642140370980543,
            "left": 0.10486533002155583,
            "top": 0.2150768214478537,
            "width": 0.5396341463414634
        },
        "content": {
            "equalColWidths": true,
            "rows": [
                [
                    {
                        "box": {
                            "height": 0.07122193059200933,
                            "left": 0.1048653215223097,
                            "top": 0.21507669874599009,
                            "width": 0.26691568241469815
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.10555555555555556,
                                "left": 0.15239739173228348,
                                "top": 0.3753529090113736,
                                "width": 0.059375
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "value": 0.75,
                            "visible": false
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.05925925925925926,
                                "left": 0.22165649606299212,
                                "top": 0.22105803441236513,
                                "width": 0.03333333333333333
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                },
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.07122193059200933,
                            "left": 0.37758366141732286,
                            "top": 0.21507669874599009,
                            "width": 0.26691568241469815
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.10555555555555556,
                                "left": 0.15239739173228348,
                                "top": 0.3753529090113736,
                                "width": 0.059375
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "value": 0.75,
                            "visible": false
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.05925925925925926,
                                "left": 0.49437483595800524,
                                "top": 0.22105803441236513,
                                "width": 0.03333333333333333
                            },
                            "iconSource": {
                                "id": "NP_6288641",
                                "provider": "NP",
                                "providerId": "6288641",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6288641-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                },
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.03261358996792068,
                            "left": 0.1048653215223097,
                            "top": 0.29661446485855936,
                            "width": 0.26691568241469815
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Timber production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.03261358996792068,
                            "left": 0.37758366141732286,
                            "top": 0.29661446485855936,
                            "width": 0.26691568241469815
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Timber production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.05943219597550306,
                            "left": 0.1048653215223097,
                            "top": 0.33954389034703997,
                            "width": 0.26691568241469815
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "The ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "United States",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " are world leader producing 298.7 million cubic meters of wood & timber in 2020",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.05943219597550306,
                            "left": 0.37758366141732286,
                            "top": 0.33954389034703997,
                            "width": 0.26691568241469815
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "The ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "United States",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " are world leader producing 298.7 million cubic meters of wood & timber in 2020",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.07350247885680956,
                            "left": 0.1048653215223097,
                            "top": 0.4092919218431029,
                            "width": 0.26691568241469815
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.05925925925925926,
                                "left": 0.22165649606299212,
                                "top": 0.4164135316418781,
                                "width": 0.03333333333333333
                            },
                            "iconSource": {
                                "id": "NP_5680200",
                                "provider": "NP",
                                "providerId": "5680200",
                                "thumbnailUrl": "https://static.thenounproject.com/png/5680200-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.07350247885680956,
                            "left": 0.37758366141732286,
                            "top": 0.4092919218431029,
                            "width": 0.26691568241469815
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.05925925925925926,
                                "left": 0.49437483595800524,
                                "top": 0.4164135316418781,
                                "width": 0.03333333333333333
                            },
                            "iconSource": {
                                "id": "NP_5770290",
                                "provider": "NP",
                                "providerId": "5770290",
                                "thumbnailUrl": "https://static.thenounproject.com/png/5770290-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.03261358996792068,
                            "left": 0.1048653215223097,
                            "top": 0.49311023622047245,
                            "width": 0.26691568241469815
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Seaweed production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.03261358996792068,
                            "left": 0.37758366141732286,
                            "top": 0.49311023622047245,
                            "width": 0.26691568241469815
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Seaweed production",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.05943219597550306,
                            "left": 0.1048653215223097,
                            "top": 0.536039661708953,
                            "width": 0.26691568241469815
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Asia is producing 97% of world's seaweed ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "led by China ",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "producing 20 million tonnes in 2019",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.05943219597550306,
                            "left": 0.37758366141732286,
                            "top": 0.536039661708953,
                            "width": 0.26691568241469815
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Asia is producing 97% of world's seaweed ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "led by China ",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "producing 20 million tonnes in 2019",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.09444444444444444,
                                "left": 0.1956657029199475,
                                "top": 0.15380847185768445,
                                "width": 0.053125
                            },
                            "iconSource": {
                                "id": "NP_924212",
                                "provider": "NP",
                                "providerId": "924212",
                                "thumbnailUrl": "https://static.thenounproject.com/png/924212-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ]
            ]
        }
    },
    TOPIC_ICON_TOP_4:
    {
        displayName: '4 detail boxes',
        hideInPresetMenu: false,
        type: 'TABLE1',
        presetGroup: 'TABLE_TOPIC_ICON_TOP',
        framePadding: framePaddingNarrow,
        "box": {
            "height": 0.637457705493543,
            "left": 0.03980672652992206,
            "top": 0.2283753357478832,
            "width": 0.9054805401111994
        },
        "content": {
            "rows": [
                [
                    {
                        "box": {
                            "height": 0.20099970836978712,
                            "left": 0.051056676509186355,
                            "top": 0.23709288422280547,
                            "width": 0.8829803149606299
                        },
                        "cellVisible": false,
                        "colspan": 4,
                        "iconSettings": {
                            "box": {
                                "height": 0.13333333333333333,
                                "left": 0.4550468339895013,
                                "top": 0.2549260717410324,
                                "width": 0.075
                            },
                            "iconSource": {
                                "id": "NP_1998140",
                                "provider": "NP",
                                "providerId": "1998140",
                                "thumbnailUrl": "https://static.thenounproject.com/png/1998140-200.png"
                            },
                            "position": "TOPCENTER",
                            "size": 6,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Our Offer To You",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.13333333333333333,
                                "left": 0.4944334809711286,
                                "top": 0.2944145523476232,
                                "width": 0.075
                            },
                            "iconSource": {
                                "id": "NP_1998140",
                                "provider": "NP",
                                "providerId": "1998140",
                                "thumbnailUrl": "https://static.thenounproject.com/png/1998140-200.png"
                            },
                            "position": "TOPCENTER",
                            "size": 6,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Our Offer To You",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.7810242372047244,
                                "top": 0.2899701079031788,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_1998140",
                                "provider": "NP",
                                "providerId": "1998140",
                                "thumbnailUrl": "https://static.thenounproject.com/png/1998140-200.png"
                            },
                            "position": "TOPCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Header",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.7810242372047244,
                                "top": 0.2899701079031788,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_1998140",
                                "provider": "NP",
                                "providerId": "1998140",
                                "thumbnailUrl": "https://static.thenounproject.com/png/1998140-200.png"
                            },
                            "position": "TOPCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Header",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.08155745115193934,
                            "left": 0.051056676509186355,
                            "top": 0.4679654418197725,
                            "width": 0.8829803149606299
                        },
                        "cellVisible": false,
                        "colspan": 4,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.4737968339895013,
                                "top": 0.47541083406240886,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_2827458",
                                "provider": "NP",
                                "providerId": "2827458",
                                "thumbnailUrl": "https://static.thenounproject.com/png/2827458-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Header",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.5131834809711286,
                                "top": 0.5212240449110528,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_2827458",
                                "provider": "NP",
                                "providerId": "2827458",
                                "thumbnailUrl": "https://static.thenounproject.com/png/2827458-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Header",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.6932013205380577,
                                "top": 0.5415327354913969,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_2980284",
                                "provider": "NP",
                                "providerId": "2980284",
                                "thumbnailUrl": "https://static.thenounproject.com/png/2980284-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Header",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.6932013205380577,
                                "top": 0.5415327354913969,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_2980284",
                                "provider": "NP",
                                "providerId": "2980284",
                                "thumbnailUrl": "https://static.thenounproject.com/png/2980284-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Header",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.08689078448527267,
                            "left": 0.051056676509186355,
                            "top": 0.5793957421988918,
                            "width": 0.19613902559055119
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.21716453412073491,
                                "top": 0.600618912219306,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_983729",
                                "provider": "NP",
                                "providerId": "983729",
                                "thumbnailUrl": "https://static.thenounproject.com/png/983729-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Project Control, Project Management",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.08689078448527267,
                            "left": 0.26399917979002624,
                            "top": 0.5793957421988918,
                            "width": 0.1782263779527559
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.41219438976377953,
                                "top": 0.600618912219306,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_943165",
                                "provider": "NP",
                                "providerId": "943165",
                                "thumbnailUrl": "https://static.thenounproject.com/png/943165-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Construction Management",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.08689078448527267,
                            "left": 0.4590290354330709,
                            "top": 0.5793957421988918,
                            "width": 0.2190887467191601
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.6480866141732283,
                                "top": 0.600618912219306,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_6426359",
                                "provider": "NP",
                                "providerId": "6426359",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6426359-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "User And Tenant Coordination",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.08689078448527267,
                            "left": 0.6949212598425197,
                            "top": 0.5793957421988918,
                            "width": 0.2391157316272966
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.9040058234908136,
                                "top": 0.600618912219306,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_973293",
                                "provider": "NP",
                                "providerId": "973293",
                                "thumbnailUrl": "https://static.thenounproject.com/png/973293-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Technical Inventory Check",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.1383909303003791,
                            "left": 0.051056676509186355,
                            "top": 0.6961593759113445,
                            "width": 0.19613902559055119
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.24704232283464567,
                                "top": 0.7360269028871391,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_698181",
                                "provider": "NP",
                                "providerId": "698181",
                                "thumbnailUrl": "https://static.thenounproject.com/png/698181-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Versatile project management services, from ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "classic project contro",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "l and project management to BIM management.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.1383909303003791,
                            "left": 0.26399917979002624,
                            "top": 0.6961593759113445,
                            "width": 0.1782263779527559
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Implementation of your ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "real estate projects",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " in a cost-, time- and quality-oriented manner.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.1383909303003791,
                            "left": 0.4590290354330709,
                            "top": 0.6961593759113445,
                            "width": 0.2190887467191601
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Consulting, management and ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "controlling services",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " for leasing, conversion/expansion and return of rental space through to move management.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.1383909303003791,
                            "left": 0.6949212598425197,
                            "top": 0.6961593759113445,
                            "width": 0.2391157316272966
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Technical examination, assessment and advice on the current condition, maintenance requirements and revitalization/redevelopment potential of ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "existing properties.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ]
            ]
        }
    },
    TOPIC_ICON_TOP_3:
    {
        displayName: '3 detail boxes',
        hideInPresetMenu: false,
        type: 'TABLE1',
        presetGroup: 'TABLE_TOPIC_ICON_TOP',
        framePadding: framePaddingNarrow,
        "box": {
            "height": 0.637457705493543,
            "left": 0.03980672652992206,
            "top": 0.2283753357478832,
            "width": 0.9054805401111994
        },
        "content": {
            "rows": [
                [
                    {
                        "box": {
                            "height": 0.2152373869932925,
                            "left": 0.051056676509186355,
                            "top": 0.2582474482356372,
                            "width": 0.8829803969816273
                        },
                        "cellVisible": false,
                        "colspan": 3,
                        "iconSettings": {
                            "box": {
                                "height": 0.13333333333333333,
                                "left": 0.455046875,
                                "top": 0.2831994750656168,
                                "width": 0.075
                            },
                            "iconSource": {
                                "id": "NP_1998140",
                                "provider": "NP",
                                "providerId": "1998140",
                                "thumbnailUrl": "https://static.thenounproject.com/png/1998140-200.png"
                            },
                            "position": "TOPCENTER",
                            "size": 6,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Our Offer To You",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.13333333333333333,
                                "left": 0.4944334809711286,
                                "top": 0.2944145523476232,
                                "width": 0.075
                            },
                            "iconSource": {
                                "id": "NP_1998140",
                                "provider": "NP",
                                "providerId": "1998140",
                                "thumbnailUrl": "https://static.thenounproject.com/png/1998140-200.png"
                            },
                            "position": "TOPCENTER",
                            "size": 6,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Our Offer To You",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.08888888888888889,
                                "left": 0.7810242372047244,
                                "top": 0.2899701079031788,
                                "width": 0.05
                            },
                            "iconSource": {
                                "id": "NP_1998140",
                                "provider": "NP",
                                "providerId": "1998140",
                                "thumbnailUrl": "https://static.thenounproject.com/png/1998140-200.png"
                            },
                            "position": "TOPCENTER",
                            "size": 4,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Header",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.1076289005540974,
                            "left": 0.051056676509186355,
                            "top": 0.5033576844561096,
                            "width": 0.8829803969816273
                        },
                        "cellVisible": false,
                        "colspan": 3,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.473796875,
                                "top": 0.523838801399825,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_2827458",
                                "provider": "NP",
                                "providerId": "2827458",
                                "thumbnailUrl": "https://static.thenounproject.com/png/2827458-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Header",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.5131834809711286,
                                "top": 0.5212240449110528,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_2827458",
                                "provider": "NP",
                                "providerId": "2827458",
                                "thumbnailUrl": "https://static.thenounproject.com/png/2827458-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Header",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.6932013205380577,
                                "top": 0.5415327354913969,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_2980284",
                                "provider": "NP",
                                "providerId": "2980284",
                                "thumbnailUrl": "https://static.thenounproject.com/png/2980284-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Header",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.08096223388743073,
                            "left": 0.051056676509186355,
                            "top": 0.640859434237387,
                            "width": 0.280721374671916
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.29774253608923884,
                                "top": 0.6591183289588801,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_983729",
                                "provider": "NP",
                                "providerId": "983729",
                                "thumbnailUrl": "https://static.thenounproject.com/png/983729-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Project Control, Project Management",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.08096223388743073,
                            "left": 0.3485815288713911,
                            "top": 0.640859434237387,
                            "width": 0.2550841535433071
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.5696301673228347,
                                "top": 0.6591183289588801,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_943165",
                                "provider": "NP",
                                "providerId": "943165",
                                "thumbnailUrl": "https://static.thenounproject.com/png/943165-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Construction Management",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.08096223388743073,
                            "left": 0.6204691601049869,
                            "top": 0.640859434237387,
                            "width": 0.3135679133858268
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.9000015583989501,
                                "top": 0.6591183289588801,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_6426359",
                                "provider": "NP",
                                "providerId": "6426359",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6426359-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "User And Tenant Coordination",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.10401020705745115,
                            "left": 0.051056676509186355,
                            "top": 0.7516945173519977,
                            "width": 0.280721374671916
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.24704232283464567,
                                "top": 0.7360269028871391,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_698181",
                                "provider": "NP",
                                "providerId": "698181",
                                "thumbnailUrl": "https://static.thenounproject.com/png/698181-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Versatile project management services, from ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "classic project contro",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "l and project management to BIM management.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.10401020705745115,
                            "left": 0.3485815288713911,
                            "top": 0.7516945173519977,
                            "width": 0.2550841535433071
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Implementation of your ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "real estate projects",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " in a cost-, time- and quality-oriented manner.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.10401020705745115,
                            "left": 0.6204691601049869,
                            "top": 0.7516945173519977,
                            "width": 0.3135679133858268
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Consulting, management and ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "controlling services",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " for leasing, conversion/expansion and return of rental space through to move management.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ]
            ]
        }
    },
    TOPIC_ICON_TOP_2:
    {
        displayName: '2 detail boxes',
        hideInPresetMenu: false,
        type: 'TABLE1',
        presetGroup: 'TABLE_TOPIC_ICON_TOP',
        framePadding: framePaddingNarrow,
        "box": {
            "height": 0.637457705493543,
            "left": 0.03980672652992206,
            "top": 0.2283753357478832,
            "width": 0.9054805401111994
        },
        "content": {
            "rows": [
                [
                    {
                        "box": {
                            "height": 0.2152373869932925,
                            "left": 0.051056676509186355,
                            "top": 0.2695298920968212,
                            "width": 0.8829803969816273
                        },
                        "cellVisible": false,
                        "colspan": 2,
                        "iconSettings": {
                            "box": {
                                "height": 0.13333333333333333,
                                "left": 0.455046875,
                                "top": 0.2944819189268008,
                                "width": 0.075
                            },
                            "iconSource": {
                                "id": "NP_1998140",
                                "provider": "NP",
                                "providerId": "1998140",
                                "thumbnailUrl": "https://static.thenounproject.com/png/1998140-200.png"
                            },
                            "position": "TOPCENTER",
                            "size": 6,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Our Offer To You",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.13333333333333333,
                                "left": 0.4944334809711286,
                                "top": 0.2944145523476232,
                                "width": 0.075
                            },
                            "iconSource": {
                                "id": "NP_1998140",
                                "provider": "NP",
                                "providerId": "1998140",
                                "thumbnailUrl": "https://static.thenounproject.com/png/1998140-200.png"
                            },
                            "position": "TOPCENTER",
                            "size": 6,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Our Offer To You",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.1165177894429863,
                            "left": 0.051056676509186355,
                            "top": 0.5146401283172937,
                            "width": 0.8829803969816273
                        },
                        "cellVisible": false,
                        "colspan": 2,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.473796875,
                                "top": 0.5395656897054535,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_2827458",
                                "provider": "NP",
                                "providerId": "2827458",
                                "thumbnailUrl": "https://static.thenounproject.com/png/2827458-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Header",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.5131834809711286,
                                "top": 0.5212240449110528,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_2827458",
                                "provider": "NP",
                                "providerId": "2827458",
                                "thumbnailUrl": "https://static.thenounproject.com/png/2827458-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Header",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.08985112277631963,
                            "left": 0.051056676509186355,
                            "top": 0.6610307669874599,
                            "width": 0.4538108595800525
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.4708320209973753,
                                "top": 0.6837341061533975,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_983729",
                                "provider": "NP",
                                "providerId": "983729",
                                "thumbnailUrl": "https://static.thenounproject.com/png/983729-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Project Control, Project Management",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.08985112277631963,
                            "left": 0.5216710137795275,
                            "top": 0.6610307669874599,
                            "width": 0.4123660597112861
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.9000015583989501,
                                "top": 0.6837341061533975,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_943165",
                                "provider": "NP",
                                "providerId": "943165",
                                "thumbnailUrl": "https://static.thenounproject.com/png/943165-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Construction Management",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.08623242927967338,
                            "left": 0.051056676509186355,
                            "top": 0.7807547389909595,
                            "width": 0.4538108595800525
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.24704232283464567,
                                "top": 0.7360269028871391,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_698181",
                                "provider": "NP",
                                "providerId": "698181",
                                "thumbnailUrl": "https://static.thenounproject.com/png/698181-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Versatile project management services, from ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "classic project contro",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "l and project management to BIM management.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.08623242927967338,
                            "left": 0.5216710137795275,
                            "top": 0.7807547389909595,
                            "width": 0.4123660597112861
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Implementation of your ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "real estate projects",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " in a cost-, time- and quality-oriented manner.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ]
            ]
        }
    },
    TOPIC_ICON_TOP_1:
    {
        displayName: '1 detail box',
        hideInPresetMenu: false,
        type: 'TABLE1',
        presetGroup: 'TABLE_TOPIC_ICON_TOP',
        framePadding: framePaddingNarrow,
        "box": {
            "height": 0.637457705493543,
            "left": 0.03980672652992206,
            "top": 0.2283753357478832,
            "width": 0.9054805401111994
        },
        "content": {
            "rows": [
                [
                    {
                        "box": {
                            "height": 0.2152373869932925,
                            "left": 0.04867380249343832,
                            "top": 0.2695298920968212,
                            "width": 0.8829803969816273
                        },
                        "cellVisible": false,
                        "colspan": 2,
                        "iconSettings": {
                            "box": {
                                "height": 0.13333333333333333,
                                "left": 0.45266400098425197,
                                "top": 0.2944819189268008,
                                "width": 0.075
                            },
                            "iconSource": {
                                "id": "NP_1998140",
                                "provider": "NP",
                                "providerId": "1998140",
                                "thumbnailUrl": "https://static.thenounproject.com/png/1998140-200.png"
                            },
                            "position": "TOPCENTER",
                            "size": 6,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Our Offer To You",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.1165177894429863,
                            "left": 0.04867380249343832,
                            "top": 0.5146401283172937,
                            "width": 0.8829803969816273
                        },
                        "cellVisible": false,
                        "colspan": 2,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.47141400098425196,
                                "top": 0.5395656897054535,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_2827458",
                                "provider": "NP",
                                "providerId": "2827458",
                                "thumbnailUrl": "https://static.thenounproject.com/png/2827458-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Header",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.08985112277631963,
                            "left": 0.04867380249343832,
                            "top": 0.6610307669874599,
                            "width": 0.4538108595800525
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.4684491469816273,
                                "top": 0.6837341061533975,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_983729",
                                "provider": "NP",
                                "providerId": "983729",
                                "thumbnailUrl": "https://static.thenounproject.com/png/983729-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Project Control, Project Management",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "box": {
                            "height": 0.08623242927967338,
                            "left": 0.04867380249343832,
                            "top": 0.7807547389909595,
                            "width": 0.4538108595800525
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.24704232283464567,
                                "top": 0.7360269028871391,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_698181",
                                "provider": "NP",
                                "providerId": "698181",
                                "thumbnailUrl": "https://static.thenounproject.com/png/698181-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Versatile project management services, from ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "classic project contro",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "l and project management to BIM management.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ]
            ]
        }
    },
    TOPIC_ICON_LEFT_4:
    {
        displayName: '4 detail boxes',
        hideInPresetMenu: false,
        type: 'TABLE1',
        presetGroup: 'TABLE_TOPIC_ICON_LEFT',
        framePadding: framePaddingNarrow,
        "box": {
            "height": 0.6442153984217897,
            "left": 0.08865598352943979,
            "top": 0.2500169339420962,
            "width": 0.8048780487804879
        },
        "content": {
            "rows": [
                [
                    {
                        "box": {
                            "height": 0.6042143482064742,
                            "left": 0.11133882874015748,
                            "top": 0.2686634587343249,
                            "width": 0.17551788057742782
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.13333333333333333,
                                "left": 0.16159776902887138,
                                "top": 0.5018540390784485,
                                "width": 0.075
                            },
                            "iconSource": {
                                "id": "NP_98766",
                                "provider": "NP",
                                "providerId": "98766",
                                "thumbnailUrl": "https://static.thenounproject.com/png/98766-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "visible": true
                        },
                        "rowspan": 8,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.6042143482064742,
                            "left": 0.2953534284776903,
                            "top": 0.2686634587343249,
                            "width": 0.12410982611548556
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.33865834153543306,
                                "top": 0.5351873724117818,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_2827426",
                                "provider": "NP",
                                "providerId": "2827426",
                                "thumbnailUrl": "https://static.thenounproject.com/png/2827426-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": true
                        },
                        "rowspan": 8,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.05433304170312044,
                            "left": 0.4279599737532808,
                            "top": 0.2686634587343249,
                            "width": 0.4657567257217848
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.863685531496063,
                                "top": 0.2736077573636629,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_6426802",
                                "provider": "NP",
                                "providerId": "6426802",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6426802-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Stimulation",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.05,
                                "left": 0.25591920931758533,
                                "top": 0.3984454651501896,
                                "width": 0.028125
                            },
                            "iconSource": {
                                "id": "NP_98766",
                                "provider": "NP",
                                "providerId": "98766",
                                "thumbnailUrl": "https://static.thenounproject.com/png/98766-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.23402009514435695,
                                "top": 0.5186113298337708,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.06494925634295713,
                            "left": 0.4279599737532808,
                            "top": 0.3381017789442986,
                            "width": 0.4657567257217848
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.03888888888888889,
                                "left": 0.6492726377952756,
                                "top": 0.3936939340915719,
                                "width": 0.021875
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "BOTTOMCENTER",
                            "size": 2,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Coffee's caffeine content",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " stimulates the central nervous ",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "system, enhancing alertness and focus by blocking adenosine, a neurotransmitter associated with sleepiness.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.05,
                                "left": 0.25591920931758533,
                                "top": 0.468404636920385,
                                "width": 0.028125
                            },
                            "iconSource": {
                                "id": "NP_98766",
                                "provider": "NP",
                                "providerId": "98766",
                                "thumbnailUrl": "https://static.thenounproject.com/png/98766-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.23402009514435695,
                                "top": 0.5186113298337708,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.06144911052785069,
                            "left": 0.4279599737532808,
                            "top": 0.41815631379410906,
                            "width": 0.4657567257217848
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.863685531496063,
                                "top": 0.42665864683581217,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_1519956",
                                "provider": "NP",
                                "providerId": "1519956",
                                "thumbnailUrl": "https://static.thenounproject.com/png/1519956-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Metabolism Boost",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.040740740740740744,
                                "left": 0.2496222933070866,
                                "top": 0.5474854913969087,
                                "width": 0.022916666666666665
                            },
                            "position": "CENTERCENTER",
                            "size": 2,
                            "value": 0.25,
                            "visible": false
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.1,
                                "left": 0.19615702919947506,
                                "top": 0.5337580927384077,
                                "width": 0.05625
                            },
                            "iconSource": {
                                "id": "NP_98766",
                                "provider": "NP",
                                "providerId": "98766",
                                "thumbnailUrl": "https://static.thenounproject.com/png/98766-200.png"
                            },
                            "position": "TOPCENTER",
                            "size": 6,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Benefits of coffee",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.23402009514435695,
                                "top": 0.5186113298337708,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.06494925634295713,
                            "left": 0.4279599737532808,
                            "top": 0.4947107028288131,
                            "width": 0.4657567257217848
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.6477101377952756,
                                "top": 0.5639537766112569,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "BOTTOMCENTER",
                            "size": 2,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Caffeine ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "increases metabolic rate",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": ", aiding fat breakdown and temporarily boosting physical performance by releasing adrenaline.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.05,
                                "left": 0.25591920931758533,
                                "top": 0.6083229804607757,
                                "width": 0.028125
                            },
                            "iconSource": {
                                "id": "NP_98766",
                                "provider": "NP",
                                "providerId": "98766",
                                "thumbnailUrl": "https://static.thenounproject.com/png/98766-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.23402009514435695,
                                "top": 0.5186113298337708,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.06144911052785069,
                            "left": 0.4279599737532808,
                            "top": 0.5747652376786235,
                            "width": 0.4657567257217848
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.863685531496063,
                                "top": 0.5832675707203266,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_1039038",
                                "provider": "NP",
                                "providerId": "1039038",
                                "thumbnailUrl": "https://static.thenounproject.com/png/1039038-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Mood Enhancement",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.05,
                                "left": 0.25591920931758533,
                                "top": 0.6782821522309711,
                                "width": 0.028125
                            },
                            "iconSource": {
                                "id": "NP_98766",
                                "provider": "NP",
                                "providerId": "98766",
                                "thumbnailUrl": "https://static.thenounproject.com/png/98766-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.23402009514435695,
                                "top": 0.5186113298337708,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.06494925634295713,
                            "left": 0.4279599737532808,
                            "top": 0.6513196267133275,
                            "width": 0.4657567257217848
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.05555555555555555,
                                "left": 0.6445851377952756,
                                "top": 0.7571914552347623,
                                "width": 0.03125
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "BOTTOMCENTER",
                            "size": 2,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Coffee may elevate mood by triggering the release of neurotransmitters like ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "dopamine and serotonin",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": ", promoting feelings of well-being and reducing the risk of depression.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.05,
                                "left": 0.25591920931758533,
                                "top": 0.7482413240011665,
                                "width": 0.028125
                            },
                            "iconSource": {
                                "id": "NP_98766",
                                "provider": "NP",
                                "providerId": "98766",
                                "thumbnailUrl": "https://static.thenounproject.com/png/98766-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.23402009514435695,
                                "top": 0.5186113298337708,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.06144911052785069,
                            "left": 0.4279599737532808,
                            "top": 0.731374161563138,
                            "width": 0.4657567257217848
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.863685531496063,
                                "top": 0.7398764946048411,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_4737076",
                                "provider": "NP",
                                "providerId": "4737076",
                                "thumbnailUrl": "https://static.thenounproject.com/png/4737076-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Digestive Stimulation",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.05,
                                "left": 0.25591920931758533,
                                "top": 0.8182004957713619,
                                "width": 0.028125
                            },
                            "iconSource": {
                                "id": "NP_98766",
                                "provider": "NP",
                                "providerId": "98766",
                                "thumbnailUrl": "https://static.thenounproject.com/png/98766-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.23402009514435695,
                                "top": 0.5186113298337708,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.06494925634295713,
                            "left": 0.4279599737532808,
                            "top": 0.807928550597842,
                            "width": 0.4657567257217848
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.05555555555555555,
                                "left": 0.6445851377952756,
                                "top": 0.7571914552347623,
                                "width": 0.03125
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "BOTTOMCENTER",
                            "size": 2,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Coffee can ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "promote bowel movements",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " due to its caffeine content, acting as a mild laxative and potentially aiding in alleviating constipation in some individuals.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ]
            ]
        }
    },
    TOPIC_ICON_LEFT_3:
    {
        displayName: '3 detail boxes',
        hideInPresetMenu: false,
        type: 'TABLE1',
        presetGroup: 'TABLE_TOPIC_ICON_LEFT',
        framePadding: framePaddingNarrow,
        "box": {
            "height": 0.6442153984217897,
            "left": 0.08865598352943979,
            "top": 0.2500169339420962,
            "width": 0.8048780487804879
        },
        "content": {
            "rows": [
                [
                    {
                        "box": {
                            "height": 0.6042142023913677,
                            "left": 0.0999059219160105,
                            "top": 0.2700169145523476,
                            "width": 0.17496120406824148
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.13333333333333333,
                                "left": 0.14988652395013125,
                                "top": 0.4996480023330417,
                                "width": 0.075
                            },
                            "iconSource": {
                                "id": "NP_98766",
                                "provider": "NP",
                                "providerId": "98766",
                                "thumbnailUrl": "https://static.thenounproject.com/png/98766-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "visible": true
                        },
                        "rowspan": 7,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.6042142023913677,
                            "left": 0.28457767388451444,
                            "top": 0.2700169145523476,
                            "width": 0.12371620734908137
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.3276857775590551,
                                "top": 0.5329813356663751,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_2827426",
                                "provider": "NP",
                                "providerId": "2827426",
                                "thumbnailUrl": "https://static.thenounproject.com/png/2827426-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": true
                        },
                        "rowspan": 7,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.06857072032662584,
                            "left": 0.41800442913385827,
                            "top": 0.2700169145523476,
                            "width": 0.4642794455380577
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.8482483595800525,
                                "top": 0.2820800524934383,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_6426802",
                                "provider": "NP",
                                "providerId": "6426802",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6426802-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Stimulation",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.05,
                                "left": 0.25591920931758533,
                                "top": 0.3984454651501896,
                                "width": 0.028125
                            },
                            "iconSource": {
                                "id": "NP_98766",
                                "provider": "NP",
                                "providerId": "98766",
                                "thumbnailUrl": "https://static.thenounproject.com/png/98766-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.23402009514435695,
                                "top": 0.5186113298337708,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.06575692621755613,
                            "left": 0.41800442913385827,
                            "top": 0.3558508311461067,
                            "width": 0.4642794455380577
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.03888888888888889,
                                "left": 0.6492726377952756,
                                "top": 0.3936939340915719,
                                "width": 0.021875
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "BOTTOMCENTER",
                            "size": 2,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Coffee's caffeine content",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " stimulates the central nervous ",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "system, enhancing alertness and focus by blocking adenosine, a neurotransmitter associated with sleepiness.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.05,
                                "left": 0.25591920931758533,
                                "top": 0.468404636920385,
                                "width": 0.028125
                            },
                            "iconSource": {
                                "id": "NP_98766",
                                "provider": "NP",
                                "providerId": "98766",
                                "thumbnailUrl": "https://static.thenounproject.com/png/98766-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.23402009514435695,
                                "top": 0.5186113298337708,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.06937561971420239,
                            "left": 0.41800442913385827,
                            "top": 0.43887095363079615,
                            "width": 0.4642794455380577
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.8482483595800525,
                                "top": 0.45133654126567513,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_1519956",
                                "provider": "NP",
                                "providerId": "1519956",
                                "thumbnailUrl": "https://static.thenounproject.com/png/1519956-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Metabolism Boost",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.040740740740740744,
                                "left": 0.2496222933070866,
                                "top": 0.5474854913969087,
                                "width": 0.022916666666666665
                            },
                            "position": "CENTERCENTER",
                            "size": 2,
                            "value": 0.25,
                            "visible": false
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.1,
                                "left": 0.19615702919947506,
                                "top": 0.5337580927384077,
                                "width": 0.05625
                            },
                            "iconSource": {
                                "id": "NP_98766",
                                "provider": "NP",
                                "providerId": "98766",
                                "thumbnailUrl": "https://static.thenounproject.com/png/98766-200.png"
                            },
                            "position": "TOPCENTER",
                            "size": 6,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Benefits of coffee",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.23402009514435695,
                                "top": 0.5186113298337708,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.06575692621755613,
                            "left": 0.41800442913385827,
                            "top": 0.5255097696121318,
                            "width": 0.4642794455380577
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.6477101377952756,
                                "top": 0.5639537766112569,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "BOTTOMCENTER",
                            "size": 2,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Caffeine ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "increases metabolic rate",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": ", aiding fat breakdown and temporarily boosting physical performance by releasing adrenaline.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.05,
                                "left": 0.25591920931758533,
                                "top": 0.6083229804607757,
                                "width": 0.028125
                            },
                            "iconSource": {
                                "id": "NP_98766",
                                "provider": "NP",
                                "providerId": "98766",
                                "thumbnailUrl": "https://static.thenounproject.com/png/98766-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.23402009514435695,
                                "top": 0.5186113298337708,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.06937561971420239,
                            "left": 0.41800442913385827,
                            "top": 0.6085298920968212,
                            "width": 0.4642794455380577
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.8482483595800525,
                                "top": 0.6209954797317002,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_1039038",
                                "provider": "NP",
                                "providerId": "1039038",
                                "thumbnailUrl": "https://static.thenounproject.com/png/1039038-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Mood Enhancement",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.05,
                                "left": 0.25591920931758533,
                                "top": 0.6782821522309711,
                                "width": 0.028125
                            },
                            "iconSource": {
                                "id": "NP_98766",
                                "provider": "NP",
                                "providerId": "98766",
                                "thumbnailUrl": "https://static.thenounproject.com/png/98766-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.23402009514435695,
                                "top": 0.5186113298337708,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.0924235928842228,
                            "left": 0.41800442913385827,
                            "top": 0.6951687080781569,
                            "width": 0.4642794455380577
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.05555555555555555,
                                "left": 0.6445851377952756,
                                "top": 0.7571914552347623,
                                "width": 0.03125
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "BOTTOMCENTER",
                            "size": 2,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Coffee may elevate mood by triggering the release of neurotransmitters like ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "dopamine and serotonin",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": ", promoting feelings of well-being and reducing the risk of depression.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ]
            ]
        }
    },
    TOPIC_ICON_LEFT_2:
    {
        displayName: '2 detail boxes',
        hideInPresetMenu: false,
        type: 'TABLE1',
        presetGroup: 'TABLE_TOPIC_ICON_LEFT',
        framePadding: framePaddingNarrow,
        "box": {
            "height": 0.47368779295719826,
            "left": 0.08865598352943979,
            "top": 0.2500169339420962,
            "width": 0.8048780487804879
        },
        "content": {
            "rows": [
                [
                    {
                        "box": {
                            "height": 0.6042146398366871,
                            "left": 0.0999059219160105,
                            "top": 0.2700169145523476,
                            "width": 0.1747646817585302
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.13333333333333333,
                                "left": 0.1497882627952756,
                                "top": 0.4996482210557014,
                                "width": 0.075
                            },
                            "iconSource": {
                                "id": "NP_98766",
                                "provider": "NP",
                                "providerId": "98766",
                                "thumbnailUrl": "https://static.thenounproject.com/png/98766-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "visible": true
                        },
                        "rowspan": 5,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.6042146398366871,
                            "left": 0.28826541994750654,
                            "top": 0.2700169145523476,
                            "width": 0.12357726377952756
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.33130405183727035,
                                "top": 0.5329815543890347,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_2827426",
                                "provider": "NP",
                                "providerId": "2827426",
                                "thumbnailUrl": "https://static.thenounproject.com/png/2827426-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": true
                        },
                        "rowspan": 5,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.06857072032662584,
                            "left": 0.4254375,
                            "top": 0.2700169145523476,
                            "width": 0.45684629265091864
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.8482482775590551,
                                "top": 0.2820800524934383,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_6426802",
                                "provider": "NP",
                                "providerId": "6426802",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6426802-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Stimulation",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.05,
                                "left": 0.25591920931758533,
                                "top": 0.3984454651501896,
                                "width": 0.028125
                            },
                            "iconSource": {
                                "id": "NP_98766",
                                "provider": "NP",
                                "providerId": "98766",
                                "thumbnailUrl": "https://static.thenounproject.com/png/98766-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.23402009514435695,
                                "top": 0.5186113298337708,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.12793307086614172,
                            "left": 0.4254375,
                            "top": 0.3627561971420239,
                            "width": 0.45684629265091864
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.03888888888888889,
                                "left": 0.6492726377952756,
                                "top": 0.3936939340915719,
                                "width": 0.021875
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "BOTTOMCENTER",
                            "size": 2,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Coffee's caffeine content",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " stimulates the central nervous ",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "system, enhancing alertness and focus by blocking adenosine, a neurotransmitter associated with sleepiness.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.05,
                                "left": 0.25591920931758533,
                                "top": 0.468404636920385,
                                "width": 0.028125
                            },
                            "iconSource": {
                                "id": "NP_98766",
                                "provider": "NP",
                                "providerId": "98766",
                                "thumbnailUrl": "https://static.thenounproject.com/png/98766-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.23402009514435695,
                                "top": 0.5186113298337708,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.10488509769612132,
                            "left": 0.4254375,
                            "top": 0.5148578302712161,
                            "width": 0.45684629265091864
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.8482482775590551,
                                "top": 0.5450781568970545,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_1519956",
                                "provider": "NP",
                                "providerId": "1519956",
                                "thumbnailUrl": "https://static.thenounproject.com/png/1519956-200.png"
                            },
                            "position": "CENTERRIGHT",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Metabolism Boost",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "harveyBallSettings": {
                            "box": {
                                "height": 0.040740740740740744,
                                "left": 0.2496222933070866,
                                "top": 0.5474854913969087,
                                "width": 0.022916666666666665
                            },
                            "position": "CENTERCENTER",
                            "size": 2,
                            "value": 0.25,
                            "visible": false
                        },
                        "iconSettings": {
                            "box": {
                                "height": 0.1,
                                "left": 0.19615702919947506,
                                "top": 0.5337580927384077,
                                "width": 0.05625
                            },
                            "iconSource": {
                                "id": "NP_98766",
                                "provider": "NP",
                                "providerId": "98766",
                                "thumbnailUrl": "https://static.thenounproject.com/png/98766-200.png"
                            },
                            "position": "TOPCENTER",
                            "size": 6,
                            "visible": false
                        },
                        "rowspan": 2,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "center",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Benefits of coffee",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.23402009514435695,
                                "top": 0.5186113298337708,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.10126640419947507,
                            "left": 0.4254375,
                            "top": 0.6439114902303879,
                            "width": 0.45684629265091864
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.6477101377952756,
                                "top": 0.5639537766112569,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "BOTTOMCENTER",
                            "size": 2,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Caffeine ",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "increases metabolic rate",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": ", aiding fat breakdown and temporarily boosting physical performance by releasing adrenaline.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ]
            ]
        }
    },
    TOPIC_ICON_LEFT_1:
    {
        displayName: '1 detail box',
        hideInPresetMenu: false,
        type: 'TABLE1',
        presetGroup: 'TABLE_TOPIC_ICON_LEFT',
        framePadding: framePaddingNarrow,
        "box": {
            "height": 0.3924841713073928,
            "left": 0.08865598352943979,
            "top": 0.2500169339420962,
            "width": 0.8048780487804879
        },
        "content": {
            "rows": [
                [
                    {
                        "box": {
                            "height": 0.40526625838436864,
                            "left": 0.0999059219160105,
                            "top": 0.2700169145523476,
                            "width": 0.17578141404199474
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.13333333333333333,
                                "left": 0.15029662893700788,
                                "top": 0.40598337707786525,
                                "width": 0.075
                            },
                            "iconSource": {
                                "id": "NP_98766",
                                "provider": "NP",
                                "providerId": "98766",
                                "thumbnailUrl": "https://static.thenounproject.com/png/98766-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 6,
                            "visible": true
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.40526625838436864,
                            "left": 0.2870853838582677,
                            "top": 0.2700169145523476,
                            "width": 0.12429625984251969
                        },
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.33048351377952756,
                                "top": 0.4393167104111986,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_2827426",
                                "provider": "NP",
                                "providerId": "2827426",
                                "thumbnailUrl": "https://static.thenounproject.com/png/2827426-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": true
                        },
                        "rowspan": 2,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": false
                        }
                    },
                    {
                        "box": {
                            "height": 0.10533333333333333,
                            "left": 0.4227796916010499,
                            "top": 0.2700169145523476,
                            "width": 0.4595041010498688
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.044444444444444446,
                                "left": 0.8353775426509187,
                                "top": 0.30046135899679205,
                                "width": 0.025
                            },
                            "iconSource": {
                                "id": "NP_6426802",
                                "provider": "NP",
                                "providerId": "6426802",
                                "thumbnailUrl": "https://static.thenounproject.com/png/6426802-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 2,
                            "visible": true
                        },
                        "rowspan": 1,
                        "tableRegion": "HEADER1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Stimulation",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ],
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.05,
                                "left": 0.25591920931758533,
                                "top": 0.3984454651501896,
                                "width": 0.028125
                            },
                            "iconSource": {
                                "id": "NP_98766",
                                "provider": "NP",
                                "providerId": "98766",
                                "thumbnailUrl": "https://static.thenounproject.com/png/98766-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.06666666666666667,
                                "left": 0.23402009514435695,
                                "top": 0.5186113298337708,
                                "width": 0.0375
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "CENTERCENTER",
                            "size": 3,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    },
                    {
                        "box": {
                            "height": 0.279669728783902,
                            "left": 0.4227796916010499,
                            "top": 0.39561344415281424,
                            "width": 0.4595041010498688
                        },
                        "cellVisible": true,
                        "colspan": 1,
                        "iconSettings": {
                            "box": {
                                "height": 0.03888888888888889,
                                "left": 0.6492726377952756,
                                "top": 0.3936939340915719,
                                "width": 0.021875
                            },
                            "iconSource": {
                                "id": "NP_3786306",
                                "provider": "NP",
                                "providerId": "3786306",
                                "thumbnailUrl": "https://static.thenounproject.com/png/3786306-200.png"
                            },
                            "position": "BOTTOMCENTER",
                            "size": 2,
                            "visible": false
                        },
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Coffee's caffeine content",
                                            "underline": false
                                        },
                                        {
                                            "bold": true,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": " stimulates the central nervous ",
                                            "underline": false
                                        },
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "system, enhancing alertness and focus by blocking adenosine, a neurotransmitter associated with sleepiness.",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ]
            ]
        }
    },
    TEXT_1x1:
    {
        displayName: 'Text',
        hideInPresetMenu: false,
        type: 'TABLE1',
        presetGroup: 'TABLE_SINGLE_TEXT',
        "box": {
            "left": 0.16732056409042612,
            "top": 0.34434010703290535,
            "width": 0.1926765475152572,
            "height": 0.15787844789439098,
        },
        "content": {
            "rows": [
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "left",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Type your text...",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ]
            ]
        }
    },
    TITLE_1x1:
    {
        displayName: 'Title',
        hideInPresetMenu: false,
        type: 'TABLE1',
        presetGroup: 'TABLE_SINGLE_TEXT',
        "box": {
            "left": 0.027826217744489703,
            "top": 0.05489628589318854,
            "width": 0.932868352223191,
            "height": 0.13001754532479257,
        },
        "content": {
            "rows": [
                [
                    {
                        "cellVisible": false,
                        "colspan": 1,
                        "rowspan": 1,
                        "tableRegion": "BODY1",
                        "textSettings": {
                            "richText": [
                                {
                                    "align": "left",
                                    "children": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "smallCaps": false,
                                            "text": "Title...",
                                            "underline": false
                                        }
                                    ],
                                    "type": "paragraph"
                                }
                            ],
                            "visible": true
                        }
                    }
                ]
            ]
        },
        "userFontFactors": {
            "BODY1": 2.2
        }
    }
}