import Stage from './Stage'
import PresentationSelectionPanel from './PresentationSelectionPanel'
import { useEffect, useState, useRef } from 'react'
import { loadSlides } from '../../lib/slideHandling'
import { useAppSelector, useAppDispatch } from '../../store/hooks'
import { getSinglePresentation } from '../../store/presentationsSlice'
import { getRenderingOptions } from '../../store/renderingOptionsSlice'
import { getCurrentPresentationId, getCurrentSlideId } from '../../store/presentationSelectionSlice'
import { getSlides } from '../../store/slidesSlice'
import ElementList from '../elements/ElementList'
import EdgeDrawer from './EdgeDrawer'
import Box from '@mui/material/Box'
import HeaderToolbar from '../containers/HeaderToolbar'
import { AmplifyUser } from '@aws-amplify/ui'
import { useNavigate, useLocation } from "react-router-dom"
import { securePagesPath, editorPath, profilePath } from '../../lib/constants'
import { Backdrop, Paper, Typography, useMediaQuery, useTheme } from '@mui/material'
import TopSettingsPanel from './TopSettingsPanel'
import InfoIcon from '@mui/icons-material/Info'
import { useOnlineStatus } from '../helpers/UseOnlineStatus'
import WifiOffIcon from '@mui/icons-material/WifiOff'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import MainEditorTutorial from '../userguide/MainEditorTutorial'
import MainElementTutorial from '../userguide/MainElementTutorial'
import { mainEditorTutorialCompletedStorageKey, slidePanelTutorialCompletedStorageKey } from '../userguide/misc'

const pulsating = { animation: 'pulsate ease-in-out 2s infinite', '@keyframes pulsate': { '0%': { opacity: 1, }, '50%': { opacity: 0.5, }, '100%': { opacity: 1, }, }, }

// Holding the drawers, header toolbar & stage
// Responsible for loading the No Login session data if we don't have a logged in user,
// Redirect to the secure pages if we have a user 
// React on change of currentPresentation & load slides

type EditorProps = { currentUser: AmplifyUser | undefined }
function Editor({ currentUser }: EditorProps) {
  const slidePanelTutorialCompleted = !!localStorage.getItem(slidePanelTutorialCompletedStorageKey)
  const mainEditorTutorialCompleted = !!localStorage.getItem(mainEditorTutorialCompletedStorageKey)

  //////////// state access //////////////////
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const isOnline = useOnlineStatus()

  const theme = useTheme()
  const currentUserRef = useRef<AmplifyUser | undefined>()
  currentUserRef.current = currentUser

  // slide stuff
  const renderingOptions = useAppSelector(getRenderingOptions)
  const currentPresentationId = useAppSelector(getCurrentPresentationId)
  const currentSlideId = useAppSelector(getCurrentSlideId)
  const currentPresentation = useAppSelector((state) => getSinglePresentation(state, { presentationId: currentPresentationId }))
  const slides = useAppSelector(getSlides)
  // local state for drawers
  const [leftDrawerOpen, setLeftDrawerOpen] = useState(false)
  const [rightDrawerOpen, setRightDrawerOpen] = useState(false)
  const [leftMobileDrawerOpen, setLeftMobileDrawerOpen] = useState(false)
  const [rightMobileDrawerOpen, setRightMobileDrawerOpen] = useState(false)
  const [leftDrawerLocked, setLeftDrawerLocked] = useState(true)
  const [rightDrawerLocked, setRightDrawerLocked] = useState(true)

  const userIsCommonWriter = !!process.env.REACT_APP_USER_IS_COMMON_WRITER_ATTR_NAME && !!currentUser?.attributes &&
    !!(currentUser?.attributes['custom:' + process.env.REACT_APP_USER_IS_COMMON_WRITER_ATTR_NAME] === 'true')


  // onMount hook
  useEffect(() => {
    // if we have a user we rerout to the secured pages if we are not there yet
    if (currentUser !== undefined && !location.pathname.includes(securePagesPath)) navigate("/" + securePagesPath + '/' + editorPath)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // on user change
  useEffect(() => {
    // if we have a user we rerout to the secured pages if we are not there yet
    if (currentUser !== undefined && !location.pathname.includes(securePagesPath)) navigate("/" + securePagesPath + '/' + editorPath)
  }, [currentUser]) // eslint-disable-line react-hooks/exhaustive-deps

  // on current Presentation change
  useEffect(() => {
    loadSlides(dispatch, slides, currentPresentationId, currentPresentation, renderingOptions)
  }, [currentPresentationId]) // eslint-disable-line react-hooks/exhaustive-deps

  const drawerBleeding = 25
  const drawerWidth = 200

  const greaterThanSm = useMediaQuery(theme.breakpoints.up("md"))
  const effLeftDrawerOpen = greaterThanSm && (leftDrawerOpen || leftDrawerLocked)
  const effRightDrawerOpen = greaterThanSm && (rightDrawerOpen || rightDrawerLocked)

  return (
    <Box width={1} className='editor'>

      {/* normal drawers */}
      <EdgeDrawer open={leftDrawerOpen || leftDrawerLocked} setOpen={setLeftDrawerOpen} anchor='left' bleeding={drawerBleeding}
        width={drawerWidth} behavior='persistent' edgeText={'Slides'} setDrawerLock={setLeftDrawerLocked} locked={leftDrawerLocked}>
        <PresentationSelectionPanel userIsCommonWriter={userIsCommonWriter} />
      </EdgeDrawer>
      <EdgeDrawer open={rightDrawerOpen || rightDrawerLocked} setOpen={setRightDrawerOpen} anchor='right' bleeding={drawerBleeding}
        width={drawerWidth} behavior='persistent' edgeText={'Elements'} setDrawerLock={setRightDrawerLocked} locked={rightDrawerLocked}>
        <ElementList />
      </EdgeDrawer>

      {/* narrow mobile drawers */}
      <EdgeDrawer open={leftMobileDrawerOpen} setOpen={setLeftMobileDrawerOpen} anchor='left' bleeding={drawerBleeding}
        width={drawerWidth} behavior='temporary' edgeText={'Slides'}>
        <PresentationSelectionPanel userIsCommonWriter={userIsCommonWriter} />
      </EdgeDrawer>
      <EdgeDrawer open={rightMobileDrawerOpen} setOpen={setRightMobileDrawerOpen} anchor='right' bleeding={drawerBleeding}
        width={drawerWidth} behavior='temporary' edgeText={'Elements'} >
        <ElementList />
      </EdgeDrawer>

      <Box ml={effLeftDrawerOpen ? drawerWidth + 'px' : drawerBleeding + 'px'}
        mr={effRightDrawerOpen ? drawerWidth + 'px' : drawerBleeding + 'px'}
        sx={{ display: 'flex', flexWrap: 'wrap', transition: "margin-left 0.3s ease 0s, margin-right 0.3s ease 0s" }}>

        {/* Header */}
        <HeaderToolbar currentUser={currentUser} extraHoriPadding={false}>{
          <TopSettingsPanel />
        }</HeaderToolbar>

        {/* common writer warning */}
        {userIsCommonWriter &&
          <Paper sx={{
            width: 1, backgroundColor: theme.palette.mode === 'dark' ? theme.palette.error.dark : theme.palette.error.light,
            height: '25px', textAlign: 'center', m: 1, mt: 0
          }}>
            User is common writer!
          </Paper>
        }

        {/* Trial editor warning */}
        {!location.pathname.includes(securePagesPath) &&
          <Paper sx={{
            width: 1, backgroundColor: theme.palette.mode === 'dark' ? theme.palette.info.dark : theme.palette.info.light,
            textAlign: 'center', m: 1, mt: 0, p: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-start'
          }}>
            <InfoIcon fontSize="large" sx={{ mr: 2 }} />
            <Typography variant='body2' sx={{ textAlign: 'left' }}>
              <b>Welcome to our Trial Editor:</b> Feel free to play around without login. We'll delete your entered data here after a few days. So make sure to{' '}
              <span onClick={() => navigate("/" + securePagesPath + '/' + profilePath)} style={{ cursor: 'pointer', textDecoration: 'underline', color: theme.palette.primary.dark }}>
                <b>sign in</b>
              </span> {' '}to your account for real work and secure, permanent data storage.
            </Typography>
          </Paper>
        }

        {/* Offline backdrop */}
        <Backdrop open={!isOnline} sx={{
          display: 'flex', flexWrap: 'wrap', alignItems: 'center', alignContent: 'center', p: 2,
          justifyContent: 'center', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1,
          WebkitBackdropFilter: theme.customStyling.blur, backdropFilter: theme.customStyling.blur
        }}>
          <Box sx={{ ...pulsating, width: 1, textAlign: 'center', fontSize: '100px' }}>
            <WifiOffIcon sx={{ fontSize: "inherit", mr: 2 }} /><CloudOffIcon sx={{ fontSize: "inherit", ml: 2 }} />
          </Box>
          <Typography variant='h4' sx={{ width: 1, textAlign: 'center' }} gutterBottom>You're offline 😟</Typography>
          <Typography variant='body1' sx={{ width: 1, textAlign: 'center' }}>Please reconnect. Protoslides needs to talk to our servers to create your slides.</Typography>
        </Backdrop>


        {/* Slide */}
        <Box sx={{ width: 1, pl: 1, pr: 1 }}>
          <Stage />
        </Box>
        {currentSlideId &&
          <Typography variant='subtitle2' sx={{ width: 1, textAlign: 'center', color: theme.palette.text.disabled, pl: 1, pr: 1 }}>
            Slide preview. Download for full quality!
          </Typography>}
      </Box>

      <MainEditorTutorial openLeftDrawer={() => { setLeftMobileDrawerOpen(true); setLeftDrawerLocked(true) }} />
      {(slidePanelTutorialCompleted && mainEditorTutorialCompleted && currentPresentationId && currentSlideId) &&
        <MainElementTutorial openRightDrawer={() => { setRightMobileDrawerOpen(true); setRightDrawerLocked(true) }} />}
    </Box>
  )
}

export default Editor
