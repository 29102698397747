import { useState } from 'react'
import { RiFileExcel2Line } from 'react-icons/ri'
import { paragraphFromText } from '../../lib/text'
import { Box, TextField, Snackbar } from '@mui/material'
import _ from 'lodash'
import { useTheme } from '@mui/material/styles'



interface ExcelPasteListenerProps {
    handlePaste: (newTable: TableRow[]) => void
}



function ExcelPasteListener({ handlePaste }: ExcelPasteListenerProps) {
    const theme = useTheme()
    const [snackbarState, setSnackbarState] = useState({ message: '', open: false })

    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }
        setSnackbarState({ open: false, message: '' })
    }

    const onPaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
        const clipboardData = e.clipboardData || (window as any).clipboardData
        if (clipboardData && clipboardData.types.includes('text/html')) {
            const htmlData = clipboardData.getData('text/html')

            const parser = new DOMParser()
            const doc = parser.parseFromString(htmlData, 'text/html')
            const styleClasses = extractStyleClasses(doc)

            // Find all table rows
            const tableRows = doc.querySelectorAll('tr')

            //Loop through the HTML table, populating the cells
            if (tableRows.length > 0) {
                // initialize the final table structure
                const { nCols } = getTableDimensions(tableRows)
                const parsedTableData: TableCell[][] = [new Array(nCols).fill(null)]

                tableRows.forEach((row, rowIndex) => {
                    const cells = row.querySelectorAll('td, th')

                    cells.forEach((cell) => {
                        const { targetRowIdx, targetColIdx } = findNextTargetCell(parsedTableData)
                        const parsedCell = parseCell(cell, styleClasses)
                        // Place the cell in the correct position in the final table structure and fill the covered/spanned cells
                        for (let i = 0; i < (parsedCell.rowspan || 1); i++) {
                            for (let j = 0; j < (parsedCell.colspan || 1); j++) {
                                while (parsedTableData[targetRowIdx + i] === undefined) parsedTableData.push(new Array(nCols).fill(null))
                                if (i === 0 && j === 0) parsedTableData[targetRowIdx + i][targetColIdx + j] = parsedCell
                                else {
                                    parsedTableData[targetRowIdx + i][targetColIdx + j] = inititalCell
                                    // give the cells in the left & top row the table region of the span cell
                                    if (targetRowIdx === 0 || targetColIdx === 0) parsedTableData[targetRowIdx + i][targetColIdx + j].tableRegion = parsedCell.tableRegion
                                }
                            }
                        }
                    })
                })
                handlePaste(finalizeTableRegions(parsedTableData))
            } else setSnackbarState({ open: true, message: 'No valid table data found in clipboard.' })
        } else { setSnackbarState({ open: true, message: 'No valid table data found in clipboard.' }) }
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 1 }}>
            <Box sx={{ width: 1, height: 1, textAlign: 'center' }}>
                <RiFileExcel2Line style={{ width: '60%', height: '60%', color: theme.palette.action.active }} />
                <TextField sx={{ mt: 1, mb: 2 }} label="Paste here..." variant="standard" onPaste={onPaste} value={''} />
            </Box>

            <Snackbar
                open={snackbarState.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarState.message}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
        </Box>
    )
}

function parseCell(htmlCell: Element, classDefinitions?: Record<string, string>) {
    // Look up the font-weight based on the class definition
    let tableRegion: TableRegion = 'BODY1'
    if (classDefinitions) {
        const className = htmlCell.getAttribute('class')
        if (className && classDefinitions[className]) {
            const fontWeight = classDefinitions[className]
            if (fontWeight === '700') tableRegion = 'HEADER1'
        }
    }
    const cellText = htmlCell.textContent || ''
    const rowspan = htmlCell.getAttribute('rowspan')
    const colspan = htmlCell.getAttribute('colspan')
    const cellData: TableCell = {
        textSettings: { richText: paragraphFromText(cellText), visible: true },
        rowspan: rowspan ? parseInt(rowspan) : 1,
        colspan: colspan ? parseInt(colspan) : 1,
        tableRegion: tableRegion,
        cellVisible: true,
    }
    return cellData
}

function getTableDimensions(tableRows: NodeListOf<HTMLTableRowElement>) {
    // calc number of cells in first row respecting colspan
    const cells = tableRows[0].querySelectorAll('td, th')
    let nCols = 0
    cells.forEach((cell) => {
        if (cell) {
            const parsedCell = parseCell(cell)
            nCols += parsedCell.colspan || 1
        }
    })

    // calc number of cells in first col respecting rowspan
    let nRows = 0
    tableRows.forEach((row) => {
        const cells = row.querySelectorAll('td, th')
        if (cells[0]) {
            const parsedCell = parseCell(cells[0])
            nRows += parsedCell.rowspan || 1
        }
    })
    return { nRows: nRows, nCols: nCols }
}

function findNextTargetRow(parsedTableData: TableCell[][], targetColIdx: number) {
    let targetRowIdx = 0
    while (parsedTableData[targetRowIdx][targetColIdx]) {
        targetRowIdx++
        if (parsedTableData[targetRowIdx] === undefined) { targetRowIdx++; break }
    }
    return targetRowIdx
}

function findNextTargetCell(parsedTableData: TableCell[][]) {
    let targetRowIdx = parsedTableData.length
    let targetColIdx = 0
    parsedTableData[0].forEach((cell, colIndex) => {
        const currentColTargetRowIdx = findNextTargetRow(parsedTableData, colIndex)
        if (currentColTargetRowIdx < targetRowIdx) {
            targetColIdx = colIndex
            targetRowIdx = currentColTargetRowIdx
        }
    })
    return { targetRowIdx: targetRowIdx, targetColIdx: targetColIdx }
}

const inititalCell: TableCell = {
    textSettings: { richText: paragraphFromText(''), visible: true },
    rowspan: 1,
    colspan: 1,
    tableRegion: 'BODY1',
    cellVisible: true,
}

function extractStyleClasses(doc: Document) {
    const headElement = doc.querySelector('head')
    const classDefinitions: Record<string, string> = {}

    if (headElement) {
        const styleElements = headElement.querySelectorAll('style')

        styleElements.forEach((styleElement) => {
            const styleText = styleElement.textContent
            if (styleText) {
                const matches = styleText.match(/\.xl\d+\s*{[^}]*font-weight:\s*(\w+);/g)
                if (matches) {
                    matches.forEach((match) => {
                        //const parts = match.split(' ')
                        //const className = parts[0].slice(1) // Remove the leading dot
                        const classNameMatches = match.match(/\.xl\d+/)
                        const fontWeightMatch = match.match(/font-weight:\s*(\w+);/)
                        if (fontWeightMatch && classNameMatches) {
                            const fontWeight = fontWeightMatch[1]
                            classDefinitions[classNameMatches[0].substring(1)] = fontWeight //substring to remove the leading dot
                        }
                    })
                }
            }
        })
    }
    return classDefinitions
}

function finalizeTableRegions(inputParsedTableData: TableCell[][]) {
    let parsedTableData = _.cloneDeep(inputParsedTableData)

    let oldColspan: number | undefined = undefined
    let oldRowspan: number | undefined = undefined
    let bodyStartColIndex = 1
    let bodyStartRowIndex = 1

    //loop first column to check if all cells are header cells and have the same colspan
    parsedTableData.forEach((row, rowIndex) => {
        if (row[0].tableRegion !== 'HEADER1' || (oldColspan !== undefined && row[0].colspan !== oldColspan)) {
            bodyStartColIndex = 0
        }
        oldColspan = row[0].colspan
    })

    //loop first row to check if all cells are header cells and have the same rowspan
    parsedTableData[0].forEach((cell, colIndex) => {
        if (cell.tableRegion !== 'HEADER1' || (oldRowspan !== undefined && cell.rowspan !== oldRowspan)) {
            bodyStartRowIndex = 0
        }
        oldRowspan = cell.rowspan
    })

    // loop again to set cellRegion in left header
    if (bodyStartColIndex === 1) {
        parsedTableData.forEach((row, rowIndex) => {
            row[0].tableRegion = 'HEADER2'
        })
    } else {
        parsedTableData.forEach((row, rowIndex) => {
            row[0].tableRegion = 'BODY1'
        })
    }

    // loop again to set cellRegion in top header
    if (bodyStartRowIndex === 1) {
        parsedTableData[0].forEach((cell, colIndex) => {
            cell.tableRegion = 'HEADER1'
        })
    } else {
        parsedTableData[0].forEach((cell, colIndex) => {
            if (colIndex >= bodyStartColIndex)
                cell.tableRegion = 'BODY1'
        })
    }

    // loop the rest of the table to reset all the body cells that might have had bold text
    parsedTableData.forEach((row, rowIndex) => {
        row.forEach((cell, colIndex) => {
            if (rowIndex > bodyStartRowIndex && colIndex > bodyStartColIndex) cell.tableRegion = 'BODY1'
        })
    })
    return parsedTableData
}

export default ExcelPasteListener
