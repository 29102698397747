import type { FC } from 'react'
import { useDrag } from 'react-dnd'
import { Box, Divider, Paper, Tooltip, Typography, useTheme } from '@mui/material'
import { ItemTypes } from '../../lib/init'
import { hexToRgba } from '../../lib/colors'
import React from 'react'

export interface ElementProps {
  el: ElementDefinition
}

export const Element: FC<ElementProps> = function Element({ el }) {
  const theme = useTheme()
  const prefersDarkMode = theme.palette.mode === 'dark'
  const backgroundOpacity = prefersDarkMode ? 0.2 : 0.15
  const defaultFontSize = 70 * (el.fontSizeCorrection ? el.fontSizeCorrection : 1)
  const height = '70px'

  const [, drag] = useDrag(() => ({
    type: ItemTypes.ELEMENT,
    item: el,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }))

  return (
    <Paper ref={drag} color={"error"} elevation={1}
      sx={{
        width: 1, height: 1, display: "grid", justifyContent: "center", alignItems: "center", gridTemplateColumns: '1fr',
        fontSize: `${defaultFontSize}px`, cursor: 'move',
        background: `linear-gradient(to right, ${hexToRgba(theme.palette.primary.main, backgroundOpacity)}, ${theme.palette.background.default}, ${hexToRgba(theme.palette.primary.main, 0.05)})`,
        transition: 'font-size 0.3s ease-in-out',
        '&:hover': {
          fontSize: `${defaultFontSize * 1.2}px`, // Increase font size on hover
        },
      }}>
      {/* The background icon */}
      <Box sx={{
        display: 'flex', width: 1, height: height, gridColumnStart: 1, gridRowStart: 1, justifyContent: "center", alignItems: "center",
        zIndex: 0, color: hexToRgba(theme.palette.primary.main, backgroundOpacity), fontSize: `${defaultFontSize * 2}px`, overflow: 'hidden', pl: '60%', pt: '40%',
      }}>
        {el.icon}
      </Box>

      {/* The blurry background window */}
      <Box sx={{
        display: 'flex', width: 1, height: 1, gridColumnStart: 1, gridRowStart: 1,
        backgroundColor: hexToRgba(theme.palette.background.default, 0.1),
        WebkitBackdropFilter: "blur(2px)",
        backdropFilter: "blur(2px)",
        zIndex: 1
      }}>
      </Box>

      {/* The front icon box */}
      <Tooltip componentsProps={{
        tooltip: { sx: { bgcolor: 'transparent', maxWidth: "none" } },
      }} title={<React.Fragment>
        <Paper sx={{ bgcolor: theme.palette.background.default, boxShadow: theme.shadows[4], overflow: 'hidden' }}>
          <Box sx={{ bgcolor: hexToRgba(theme.palette.text.primary, 0.0) }}>
            <Box sx={{ display: 'flex', width: 1, height: 1, fontSize: `${defaultFontSize * (el.zoomFactor || 1)}px`, overflow: 'hidden', mb: 1, p: 1, color: theme.palette.primary.main, justifyContent: 'center' }}>
              {el.icon}
            </Box>
            <Divider />
            <Typography sx={{ textAlign: 'center', width: 1, p: 1, color: theme.palette.text.secondary }} variant='subtitle2'>
              {el.tooltip}
            </Typography>
          </Box>
        </Paper>
      </React.Fragment>} placement='left' >
        <Box sx={{
          display: 'flex', width: 1, height: 1, gridColumnStart: 1, gridRowStart: 1, justifyContent: "center",
          alignItems: "center", zIndex: 2, color: theme.palette.primary.main, overflow: 'hidden'
        }}>
          {el.icon}
        </Box>
      </Tooltip>
      <Box sx={{ bgcolor: hexToRgba(theme.palette.primary.main, 0.2) }}>
        <Typography sx={{ textAlign: 'center', width: 1, color: theme.palette.text.secondary }} variant='subtitle2'>
          {el.title}
        </Typography>
      </Box>
    </Paper>
  )
}