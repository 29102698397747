import { useState } from 'react'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import PositionSelector from '../helpers/PositionSelector'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Grid from '@mui/material/Unstable_Grid2'
import TableSelectionRangeGroup from '../pptElements/table1/TableSelectionRangeGroup'
import { tableSettingsPanelMaxWidth, tableSettingsPositionSelectorDimensions } from '../../lib/style'

interface ColorSettingsProps {
    currentColorState: ColorStateSettings | undefined,
    handleColorStateChange: (newColorState: ColorStateSettings, tableRange: TableSelectionRange | undefined) => void,
    allowedPositions?: PositionKey[]
    allowedSizes?: IconSize[]
    rowCount?: number
    colCount?: number
    rIdx?: number
    cIdx?: number
}

function ColorStateSettings({ handleColorStateChange, currentColorState, allowedPositions, allowedSizes, rowCount, colCount, cIdx, rIdx }: ColorSettingsProps) {
    const theme = useTheme()
    const showSelectionIndicator = rowCount !== undefined && colCount !== undefined && rIdx !== undefined && cIdx !== undefined
    if (currentColorState === undefined) currentColorState = initialColorStateSettings
    let currentHexColor = theme.palette.info.main
    if (currentColorState.color === 'RED') currentHexColor = theme.palette.error.main
    if (currentColorState.color === 'YELLOW') currentHexColor = theme.palette.warning.light
    if (currentColorState.color === 'GREEN') currentHexColor = theme.palette.success.main

    //state
    const [selectionRange, setSelectionRange] = useState<TableSelectionRange | undefined>(showSelectionIndicator ? { startRow: rIdx, startCol: cIdx, endRow: rIdx, endCol: cIdx } : undefined)
    const [colorMenuAnchorEl, setColorMenuAnchorEl] = useState<null | HTMLElement>(null)


    const handleColorChange = async (color: BaseColor) => {
        if (currentColorState) handleColorStateChange({ ...currentColorState, color: color }, selectionRange)
        setColorMenuAnchorEl(null)
    }

    const handlePositionClick = async (newPosition: PositionKey) => {
        if (currentColorState) {
            // toggle visibility if clicking on the same position
            let newVisible = currentColorState.visible
            if (newPosition === currentColorState.position) { newVisible = !newVisible }
            else { newVisible = true }
            handleColorStateChange({ ...currentColorState, position: newPosition, visible: newVisible }, selectionRange)
        }
    }

    const handleSizeChange = async (event: SelectChangeEvent) => {
        if (currentColorState) handleColorStateChange({ ...currentColorState, size: Number(event.target.value) }, selectionRange)
    }

    return (
        <Grid container spacing={theme.customStyling.panelGridSpacing} sx={{ maxWidth: tableSettingsPanelMaxWidth }}>


            {/* The settings */}
            <Grid xs={12} sm={showSelectionIndicator ? 6 : 12} >
                <div>
                    <Grid container spacing={0} >

                        <Paper elevation={theme.customStyling.panelGridItemElevation} sx={{ ...theme.customStyling.panelGridItemSx }}>

                            {/* The current color ball */}
                            <Grid xs={showSelectionIndicator ? 6 : 4} >
                                {currentColorState &&
                                    <Box sx={{ ...theme.customStyling.panelInnerGridItemSx }}>
                                        <Typography variant="button" color='text.disabled' sx={{ ...theme.customStyling.panelGridItemTitleSx }}>Current</Typography>
                                        <Box sx={{ ...tableSettingsPositionSelectorDimensions, display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                                            <Box onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => setColorMenuAnchorEl(event.currentTarget)}
                                                sx={{ width: '60%', height: '60%', borderRadius: '50%', backgroundColor: currentHexColor, cursor: 'pointer', mt: 2 }} />

                                            <Menu anchorEl={colorMenuAnchorEl} open={!!colorMenuAnchorEl} onClose={() => setColorMenuAnchorEl(null)} >
                                                <MenuItem onClick={() => handleColorChange('RED')}>Red</MenuItem>
                                                <MenuItem onClick={() => handleColorChange('GREEN')}>Green</MenuItem>
                                                <MenuItem onClick={() => handleColorChange('BLUE')}>Blue</MenuItem>
                                                <MenuItem onClick={() => handleColorChange('YELLOW')}>Yellow</MenuItem>
                                            </Menu>
                                        </Box>
                                    </Box>}
                            </Grid>

                            {/* The position selector */}
                            <Grid xs={showSelectionIndicator ? 6 : 4} sx={{ pl: 2 }}>
                                {allowedPositions &&
                                    <Box sx={{ ...theme.customStyling.panelInnerGridItemSx }}>
                                        <Typography variant="button" color='text.disabled' sx={{ ...theme.customStyling.panelGridItemTitleSx }}>Position</Typography>
                                        <Box sx={tableSettingsPositionSelectorDimensions}>
                                            <PositionSelector visible={currentColorState.visible} allowedPositions={allowedPositions} currentPosition={currentColorState.position} handleNewPosition={handlePositionClick}></PositionSelector>
                                        </Box>
                                    </Box>

                                }
                            </Grid>

                            {/* The size selector */}
                            <Grid xs={showSelectionIndicator ? 12 : 4} >
                                {allowedSizes &&
                                    <Box sx={{ ...theme.customStyling.panelInnerGridItemSx }}>
                                        <Typography variant="button" color='text.disabled' sx={{ ...theme.customStyling.panelGridItemTitleSx }}>Size</Typography>
                                        <Select
                                            fullWidth
                                            labelId="icon-size-selection"
                                            value={String(currentColorState.size)}
                                            size='small'
                                            label=""
                                            onChange={handleSizeChange}
                                            sx={{ mt: 0 }}
                                        >
                                            {allowedSizes.map((allowedSize, i: number) => (
                                                <MenuItem key={i} value={String(allowedSize.size)}>{allowedSize.displayName}</MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                }
                            </Grid>
                        </Paper>
                    </Grid>
                </div>
            </Grid>

            {/* The apply range selector */}
            {showSelectionIndicator && selectionRange &&
                <Grid xs={12} sm={6} sx={{ mt: { xs: -6, sm: 0 } }} >
                    <Paper elevation={theme.customStyling.panelGridItemElevation} sx={{ ...theme.customStyling.panelGridItemSx }}>
                        <Box sx={{ ...theme.customStyling.panelInnerGridItemSx, width: 1 }}>
                            <Typography variant="button" color='text.disabled' sx={{ ...theme.customStyling.panelGridItemTitleSx, textAlign: 'right' }}>Apply changes to</Typography>
                            <TableSelectionRangeGroup rowCount={rowCount} colCount={colCount} currentRange={selectionRange} handleTableRangeChange={setSelectionRange}
                                rIdx={rIdx} cIdx={cIdx} />
                        </Box>
                    </Paper>
                </Grid>}

        </Grid >

    )
}

export const initialColorStateSettings = { visible: false, size: 2, position: 'CENTERCENTER' as PositionKey, color: 'BLUE' as BaseColor }

export default ColorStateSettings