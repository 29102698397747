import React from 'react'
import Popover, { PopoverProps } from '@mui/material/Popover'
import { noDragInitClassName, noDragInitInnerTextBoxClassName } from '../../lib/constants'

const NoDragPopover: React.FC<PopoverProps> = (props) => {
    return (
        <Popover {...props} className={`${noDragInitClassName} ${noDragInitInnerTextBoxClassName}`}>
            {props.children}
        </Popover>
    )
}

export default NoDragPopover