import { loremIpsum } from "lorem-ipsum"
import { Descendant } from 'slate'
import { capitalizeFirstLetter } from '../lib/misc'

export const paragraphFromText = (txt: string): Descendant[] => {
    return [{ type: 'paragraph', children: [{ text: txt }] }]
}

export const richLoremParagraph = (words: number): Descendant[] => {
    return paragraphFromText(capitalizeFirstLetter(loremIpsum({ count: words, units: "words" })) + '.')
}

export const plainLoremText = (words: number): string => {
    return capitalizeFirstLetter(loremIpsum({ count: words, units: "words" })) + '.'
}