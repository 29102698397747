import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import SlideExample1 from '../../../img/slide_example1.jpg'
import AutoIconGridDark from '../../../img/auto_icon_grid_dark.jpg'
import PsTutor3BoxDark from '../../../img/ps_tutor_3box_dark.jpg'
import PsIconTableDarkEarth from '../../../img/ps_icon_table_dark_earth.jpg'
import PsTutorExTableDark from '../../../img/ps_tutor_ex_table_dark.jpg'
import PsTutor3BoxDarkGrid from '../../../img/ps_tutor_3box_dark_grid.jpg'
import SoMeProjectTableWithIcons from '../../../img/some_project_table_and_icons.jpg'
import PsProjectTableWithIcons from '../../../img/ps_project_table_and_icons_plain.jpg'
import SoMeOverview from '../../../img/some_overview.jpg'

import { Box } from '@mui/material'

export default function QuiltedImageListTables() {

    return (
        <Box sx={{ width: 1 }}>
            <ImageList
                sx={{ width: 1, transform: 'rotate(-20deg)', mt: 0, overflow: 'visible', opacity: 1 }}
                variant="quilted"
                cols={4}
                gap={16}
            >
                {itemData.map((item, count) => (
                    <ImageListItem
                        key={count}
                        cols={item.cols || 1}
                        rows={item.rows || 1}
                    >
                        <img
                            src={item.img}
                            alt={item.title}
                            style={{ boxShadow: '5px 5px 10px 5px rgba(0,0,0,0.3)' }}
                            loading="lazy"
                        />

                    </ImageListItem>
                ))}
            </ImageList>
        </Box>
    )
}

const itemData = [
    {
        img: PsTutor3BoxDarkGrid,
        title: 'Presentation Slide representing situation, complication, resolution with blurry grid background',
        rows: 2,
        cols: 2,
    },
    {
        img: PsTutor3BoxDark,
        title: 'Presentation Slide representing situation, complication, resolution',
    },
    {
        img: SlideExample1,
        title: 'Slide Example 1',
    },
    {
        img: PsTutorExTableDark,
        title: 'Slide with project overview table',
    },
    {
        img: PsTutor3BoxDarkGrid,
        title: 'Presentation Slide representing situation, complication, resolution with blurry grid background',
    },
    {
        img: PsIconTableDarkEarth,
        title: 'Slide with icon table',
    },
    {
        img: SoMeOverview,
        title: 'Presentation slide showing three icons with text',
    },
    {
        img: SlideExample1,
        title: 'Slide Example 1',
        rows: 2,
        cols: 2,
    },
    {
        img: PsTutorExTableDark,
        title: 'Slide with project overview table',
        rows: 2,
        cols: 2,
    },
    {
        img: PsTutor3BoxDark,
        title: 'Presentation Slide representing situation, complication, resolution',
    },
    {
        img: PsProjectTableWithIcons,
        title: 'Presentation slide showing project overview table',
    },
    {
        img: SoMeProjectTableWithIcons,
        title: 'Social Media presentation slide showing project overview table',
    },
    {
        img: AutoIconGridDark,
        title: 'Icon table slide with car background',
        rows: 2,
        cols: 2,
    },
]