import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './store'

// Type definitions
const initialState: ClipboardType = {
  logicalElement: undefined,
  logicalSlide: undefined
}
type ClipboardPayload = { payload: ClipboardType }

// slice definition
export const clipboardSlice = createSlice({
  name: 'clipboard',
  initialState,
  reducers: {
    setClipboard: (state, action: ClipboardPayload) => {
      return action.payload
    },
  },
})

//actions
export const { setClipboard } = clipboardSlice.actions

// selectors
export const getClipboard = (state: RootState) => state.clipboard

export default clipboardSlice.reducer
