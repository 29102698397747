export const fontSizeToFactor = (slideHeight: number | undefined, fontSizePt: number | undefined) => {
    if (slideHeight && fontSizePt) {
        return (fontSizePt * 12700) / slideHeight
    } else return undefined
}
export const factorToFontSize = (slideHeight: number | undefined, factor: number | undefined) => {
    if (slideHeight && factor) {
        return Math.round(factor * slideHeight / 12700)
    } else return undefined
}

export const getFontSizeValues = (currentMaster: SingleMaster | undefined, currentPresentation: SinglePresentation | undefined) => {
    const currentFontSizePt = factorToFontSize(currentMaster?.logicalMaster.slideHeight, currentPresentation?.elementBodyFontFactor)
    const defaultFontFactor = fontSizeToFactor(6858000, 12)
    const minFontSizePt = factorToFontSize(currentMaster?.logicalMaster.slideHeight, defaultFontFactor ? defaultFontFactor / 2 : undefined)
    const maxFontSizePt = factorToFontSize(currentMaster?.logicalMaster.slideHeight, defaultFontFactor ? defaultFontFactor * 6 : undefined)
    return { currentFontSizePt: currentFontSizePt, defaultFontFactor: defaultFontFactor, minFontSizePt: minFontSizePt, maxFontSizePt: maxFontSizePt }
}

export function convertRemToPixels(rem: number) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
}