import Box from '@mui/material/Box'
import { Typography, ToggleButton } from '@mui/material'
import TableSelectionIndicator from './TableSelectionIndicator'
import _ from 'lodash'

interface tableSelectionRangeGroupProps {
    handleTableRangeChange: (newRange: TableSelectionRange) => void,
    rowCount: number
    colCount: number
    cIdx: number
    rIdx: number
    currentRange: TableSelectionRange
}

function TableSelectionRangeGroup({ rowCount, colCount, currentRange, handleTableRangeChange, rIdx, cIdx }: tableSelectionRangeGroupProps) {
    const cellRange = { startRow: rIdx, startCol: cIdx, endRow: rIdx, endCol: cIdx }
    const rowRange = { startRow: rIdx, startCol: 0, endRow: rIdx, endCol: colCount - 1 }
    const colRange = { startRow: 0, startCol: cIdx, endRow: rowCount - 1, endCol: cIdx }
    const fullTableRange = { startRow: 0, startCol: 0, endRow: rowCount - 1, endCol: colCount - 1 }

    return (
        <Box sx={{ width: '200px', height: '150px', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            <ToggleButton sx={{ width: '50%', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }} value={''}
                selected={_.isEqual(currentRange, cellRange)} onClick={() => handleTableRangeChange(cellRange)}>
                <Box sx={{ width: '70%', height: '70%', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', pb: 0.5 }}>
                    <TableSelectionIndicator numRows={rowCount} numCols={colCount} selectedRange={cellRange} />
                </Box>
                <Typography variant="subtitle2" color='text.disabled' sx={{ textAlign: 'center', width: 1 }}>Cell</Typography>
            </ToggleButton>
            <ToggleButton sx={{ width: '50%', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }} value={''}
                selected={_.isEqual(currentRange, rowRange)} onClick={() => handleTableRangeChange(rowRange)}>
                <Box sx={{ width: '70%', height: '70%', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', pb: 0.5 }}>
                    <TableSelectionIndicator numRows={rowCount} numCols={colCount}
                        selectedRange={rowRange} />
                </Box>
                <Typography variant="subtitle2" color='text.disabled' sx={{ textAlign: 'center', width: 1 }}>Row</Typography>
            </ToggleButton>

            <ToggleButton sx={{ width: '50%', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }} value={''}
                selected={_.isEqual(currentRange, colRange)} onClick={() => handleTableRangeChange(colRange)}>
                <Box sx={{ width: '70%', height: '70%', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', pb: 0.5 }}>
                    <TableSelectionIndicator numRows={rowCount} numCols={colCount}
                        selectedRange={colRange} />
                    <Typography variant="subtitle2" color='text.disabled' sx={{ textAlign: 'center', width: 1 }}>Column</Typography>
                </Box>
            </ToggleButton>
            <ToggleButton sx={{ width: '50%', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }} value={''}
                selected={_.isEqual(currentRange, fullTableRange)} onClick={() => handleTableRangeChange(fullTableRange)}>
                <Box sx={{ width: '70%', height: '70%', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', pb: 0.5 }}>
                    <TableSelectionIndicator numRows={rowCount} numCols={colCount}
                        selectedRange={fullTableRange} />
                    <Typography variant="subtitle2" color='text.disabled' sx={{ textAlign: 'center', width: 1 }}>Table</Typography>
                </Box>
            </ToggleButton >
        </Box>
    )
}

export default TableSelectionRangeGroup