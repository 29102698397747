
import * as React from 'react'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import InfoIcon from '@mui/icons-material/Info'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'


type PresentationTypographyProps = {
    handleFontSizeModeChange: FontSizeModeChangeFunction,
    currentFontSizeMode: FontSizeMode | undefined,
    handlePresentationBaseFontSizeChange: NumberVoidFunction,
    currentFontSizePt: number | undefined,
    minFontSizePt: number | undefined,
    maxFontSizePt: number | undefined
}

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 500,
    },
})


function PresentationTypography({ handleFontSizeModeChange, currentFontSizeMode, handlePresentationBaseFontSizeChange, currentFontSizePt,
    minFontSizePt, maxFontSizePt }: PresentationTypographyProps) {

    let fontSizesListPt: number[] = []
    if (minFontSizePt && maxFontSizePt) {
        for (let i = minFontSizePt; i < maxFontSizePt; i++) {
            fontSizesListPt.push(i)
        }
    }

    const handleFontSizeModeSelect = (event: SelectChangeEvent) => {
        handleFontSizeModeChange(event.target.value as FontSizeMode)
    }
    const handleFontSizeSelect = (event: SelectChangeEvent) => {
        handlePresentationBaseFontSizeChange(event.target.value as unknown as number)
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 1 }}>
            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                <FormControl fullWidth>
                    <InputLabel >Font size mode</InputLabel>
                    <Select
                        value={currentFontSizeMode}
                        label="Font size mode"
                        onChange={handleFontSizeModeSelect}
                    >
                        <MenuItem value={'RELATIVETOPRESENTATIONHEIGHT'}>Consistent through presentation</MenuItem>
                        <MenuItem value={'OPTIMIZEDBYSLIDE'}>Maximized by slide</MenuItem>
                        <MenuItem value={'OPTIMIZEDBYELEMENT'}>Maximized by element</MenuItem>
                    </Select>
                </FormControl>
                <CustomWidthTooltip title={<React.Fragment>
                    <Typography color="inherit" variant="subtitle2">{"Presentation font size calculation options:"}<br /><br /></Typography>
                    <b><u>{"Consistent through presentation:"}</u></b> {"The body text of the elements of your slides will try to use the font size specified here. This is the recommended default."}<br /><br />
                    <b><u>{"Maximized by slide: "}</u></b>{"The font size is maximized by slide to achieve a consistent impression in one slide, but variations between slides."}<br /><br />
                    <b><u>{"Maximized by element: "}</u></b> {"The font size is maximized by element, potentially using different font sizes within one slide."}<br /><br />
                    {"In general, if space is limited, elements might be forced to use a smaller font size than desired. Headers and titles might use a larger font size than the text body depending on the chosen style. All font sizes can also be adjusted for each element in the element's settings."}
                </React.Fragment>}>
                    <InfoIcon fontSize='medium' color='disabled' sx={{ flexGrow: 1, ml: 1, mr: 4 }} />
                </CustomWidthTooltip>
            </Box >

            {currentFontSizePt && currentFontSizeMode === 'RELATIVETOPRESENTATIONHEIGHT' &&
                <Box sx={{ flexGrow: 1 }}>
                    <FormControl fullWidth>
                        <InputLabel >Text body font size</InputLabel>
                        <Select
                            value={String(currentFontSizePt)}
                            label="Text body font size"
                            onChange={handleFontSizeSelect}
                        >
                            {fontSizesListPt.map(i => (
                                <MenuItem value={String(i)} key={String(i)}>{i}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>
                </Box>
            }
        </Box >
    )
}

export default PresentationTypography