import { setSubscriptionOptions } from '../store/subscriptionOptionSlice'
import { getBillingPlanCall } from './restCalls'

export const loadSubscriptionPlans = async (dispatch: any) => {
    var planIdsToOffer: string[]
    var otherPlansToLoad: string[]
    if (process.env.REACT_APP_STAGE === process.env.REACT_APP_NAME_OF_PROD_STAGE) {
        //planIdsToOffer = ['P-0YM25124MH876754DMUIXL4I', 'P-04978317X37800357MVGGAQQ', 'P-9PM92809LR3522351MVGGBSY'] // normal price
        planIdsToOffer = ['P-468965743R4646715MWWRHVA', 'P-4VY66052VB5787028MWWRIMY', 'P-17N1774715619232NMWWRJOA'] // Discounted 5€
        otherPlansToLoad = []
    } else {
        planIdsToOffer = ['P-9WP439509G520810BMUI2KCY', 'P-38587840EK885471SMUI2HAI', 'P-77X68366H0869435FMUI2KYI']
        otherPlansToLoad = ['P-9EV58178Y7864172XMUNQNHI']
    }

    var promises: Promise<any>[] = []
    var loadedSpecs: Record<string, SubscriptionOptionSpec> = {}

    for (const planId of [...planIdsToOffer, ...otherPlansToLoad]) {
        promises.push(getBillingPlanCall(planId))
    }

    for (const p of promises) {
        const { status, billingPlan } = await p
        if (status === 200) {
            billingPlan as SubscriptionOptionSpec
            billingPlan.offerForSale = planIdsToOffer.includes(billingPlan.id)
            loadedSpecs[billingPlan.id] = billingPlan
        }
    }

    dispatch(setSubscriptionOptions(loadedSpecs))
}


