import React from 'react'
import Paper from '@mui/material/Paper'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'

interface TableCellProps {
    selected: boolean
}

const TableCell: React.FC<TableCellProps> = ({ selected }) => {
    const theme = useTheme()
    const cellStyle: React.CSSProperties = {
        flex: '1',
        backgroundColor: selected ? theme.palette.primary.main : 'white',
        border: '1px solid #ccc',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }

    return <Paper elevation={0} style={cellStyle} />
}

interface TableSelectionIndicatorProps {
    numRows: number
    numCols: number
    selectedRange: TableSelectionRange
}

const TableSelectionIndicator: React.FC<TableSelectionIndicatorProps> = ({ numRows, numCols, selectedRange }) => {
    const rows = []

    for (let row = 0; row < numRows; row++) {
        const cells = []
        for (let col = 0; col < numCols; col++) {
            const selected =
                row >= selectedRange.startRow &&
                row <= selectedRange.endRow &&
                col >= selectedRange.startCol &&
                col <= selectedRange.endCol

            cells.push(<TableCell key={col} selected={selected} />)
        }
        rows.push(
            <div key={row} style={{ display: 'flex', flex: '1' }}>
                {cells}
            </div>
        )
    }

    return <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1', width: 1, height: 1 }}>{rows}</Box>
}

export default TableSelectionIndicator
