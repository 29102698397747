import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ContrastIcon from '@mui/icons-material/Contrast'
import { Paper, Box, Grow, ToggleButton, Divider, Tooltip, Menu, MenuItem, useTheme, ListSubheader, ListItemIcon, ListItemText } from '@mui/material'
import { useState } from 'react'
import TitleIcon from '@mui/icons-material/Title'
import { StyledToggleButtonGroup } from '../../lib/style'
import StyleSelection from '../presentations/StyleSelection'
import ShapeLineIcon from '@mui/icons-material/ShapeLine'
import ColConButtons from './ColConButtons'
import PopoverContent from '../helpers/PopoverContent'
import { BsBookmarkStar } from "react-icons/bs"
import { elementPresetsLists } from '../pptElements/ElementDefinitions'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CropDinIcon from '@mui/icons-material/CropDin'
import { framePaddingMedium, framePaddingNarrow, framePaddingWide } from '../../lib/constants'
import NoDragPopover from '../helpers/NoDragPopover'
import { ArrowDownward, ArrowUpward, MoreVert } from '@mui/icons-material'

type ElementSettingsProps = {
  handleRemoveElement: IdClickFunction,
  elementActive: boolean, el: LogicalElement,
  handleSetElementColCon: ElementColConFunction,
  handleElementTitleVisibilityChange: ElementTitleVisibilityChangeFunction,
  elementSpecificPanel?: React.ReactNode
  handleElementStyleChange: ElementBasicStyleChangeFunction
  handlePresetChange: ElementPresetChangeFunction
  handleElementCopy: ElementCopyFunction
  handleElementFramePaddingChange: ElementFramePaddingChangeFunction
  handleElementZIndexChange: ElementZIndexFunction
}

const frameOptions = [{ framePadding: null, displayName: 'None' }, { framePadding: framePaddingNarrow, displayName: 'Narrow' }, { framePadding: framePaddingMedium, displayName: 'Medium' }, { framePadding: framePaddingWide, displayName: 'Wide' }]

function ElementSettingsPanel({ handleRemoveElement, elementActive, el, handleSetElementColCon, handleElementTitleVisibilityChange, elementSpecificPanel,
  handleElementStyleChange, handlePresetChange, handleElementCopy, handleElementFramePaddingChange, handleElementZIndexChange }: Readonly<ElementSettingsProps>) {
  const theme = useTheme()

  //local state
  const [colConAnchorEl, setColConAnchorEl] = useState<null | HTMLElement>(null)
  const [presetsAnchorEl, setPresetsAnchorEl] = useState<null | HTMLElement>(null)
  const [frameAnchorEl, setFrameAnchorEl] = useState<null | HTMLElement>(null)
  const [stylePopoverAnchorEl, setStylePopoverAnchorEl] = useState<EventTarget & HTMLElement | null>(null)
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState<null | HTMLElement>(null)

  //functions
  const handleColConOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setColConAnchorEl(event.currentTarget)
  }
  const handleColConChange = (col: number, con: number) => {
    if (handleSetElementColCon !== undefined) handleSetElementColCon(el, col, con)
  }

  const handleTitleChange = () => {
    if (el.slideId && handleElementTitleVisibilityChange) {
      handleElementTitleVisibilityChange(el)
    }
  }

  const handleBasicStyleChange: BasicStyleChangeFunction = (newStyle) => {
    if (el.slideId && handleElementStyleChange) {
      handleElementStyleChange(el.slideId, el.elementId, newStyle)
    }
  }

  return (
    <Box sx={{
      display: "flex", justifyContent: "center", flexWrap: "nowrap", alignContent: "flex-start", alignItems: "flex-start",
    }}>
      <Grow in={elementActive} unmountOnExit>
        <Paper sx={{ display: "flex", flexWrap: "nowrap", p: 1, bottom: 'calc(100% + 8px)', position: "absolute" }}>
          <StyledToggleButtonGroup size="small" arial-label="common element options">
            <Tooltip title="Load preset">
              <ToggleButton onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => setPresetsAnchorEl(event.currentTarget)} size="small" value="">
                <BsBookmarkStar style={{ width: '20px', height: '20px', color: theme.palette.action.active }} />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Set color & contrast">
              <ToggleButton onClick={handleColConOpen} size="small" value="">
                <ContrastIcon fontSize="medium" />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Set element style">
              <ToggleButton selected={!!el.appearance?.basicStyle} onClick={(event) => setStylePopoverAnchorEl(event.currentTarget)} size="small" value="" color="primary">
                <ShapeLineIcon fontSize="medium" />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Set element frame">
              <ToggleButton selected={!!el.framePadding} onClick={(event) => setFrameAnchorEl(event.currentTarget)} color="primary" size="small" value="">
                <CropDinIcon fontSize="medium" />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Toggle element title">
              <ToggleButton selected={!!el.title?.showTitle} onClick={() => handleTitleChange()} color="primary" size="small" value="">
                <TitleIcon fontSize="medium" />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="More options">
              <ToggleButton selected={false} onClick={(event) => setMoreMenuAnchorEl(event.currentTarget)} color="primary" size="small" value="">
                <MoreVert fontSize="medium" />
              </ToggleButton>
            </Tooltip>
          </StyledToggleButtonGroup>
          {elementSpecificPanel && <Divider orientation="vertical" sx={{ ml: 1, mr: 1 }} flexItem />}
          {elementSpecificPanel && elementSpecificPanel}
        </Paper>
      </Grow>


      {/* Dialogs */}
      {/* Element Style */}
      <NoDragPopover open={!!stylePopoverAnchorEl} anchorEl={stylePopoverAnchorEl} onClose={() => setStylePopoverAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <PopoverContent maxWidth={800} title='Select style for current element'>
          <StyleSelection handleStyleChange={handleBasicStyleChange} currentPresentationStyle={el.appearance?.basicStyle} allowNull={true} />
        </PopoverContent>
      </NoDragPopover>

      {/* Col Con  */}
      <NoDragPopover open={!!colConAnchorEl} anchorEl={colConAnchorEl} onClose={() => setColConAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <PopoverContent title='Set color & contrast'>
          <ColConButtons currentCol={el.appearance?.color} currentCon={el.appearance?.contrast} width={75} handleColConChange={handleColConChange} />
        </PopoverContent>
      </NoDragPopover>

      {/* Presets */}
      <Menu
        id="basic-menu"
        anchorEl={presetsAnchorEl}
        open={!!presetsAnchorEl}
        onClose={() => setPresetsAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <ListSubheader>Presets</ListSubheader>
        {/* Only offer allowed presets & presets of the same preset group */}
        {elementPresetsLists[el.elementType].filter((preset) => !preset.hideInPresetMenu).filter(
          (preset) => (!el.presetGroup || (!!el.presetGroup && el.presetGroup === preset.presetGroup))).map((preset, index) => (
            <MenuItem onClick={() => { handlePresetChange(el, preset); setPresetsAnchorEl(null) }} key={index}>
              {preset.displayName}
            </MenuItem>
          ))}
      </Menu>

      {/* Frame setting */}
      <Menu
        anchorEl={frameAnchorEl}
        open={!!frameAnchorEl}
        onClose={() => setFrameAnchorEl(null)}
        MenuListProps={{
          role: 'listbox',
        }}
      >
        {frameOptions.map((option, index) => (
          <MenuItem
            key={option.displayName}
            selected={option.framePadding === el.framePadding}
            onClick={(event) => { handleElementFramePaddingChange(el, option.framePadding); setFrameAnchorEl(null) }}
          >
            {option.displayName}
          </MenuItem>
        ))}
      </Menu>

      {/* Menu */}
      <Menu onClose={() => setMoreMenuAnchorEl(null)} anchorEl={moreMenuAnchorEl} open={!!moreMenuAnchorEl}>
        <MenuItem onClick={() => { handleElementZIndexChange(el, 'FRONT'); setMoreMenuAnchorEl(null) }}>
          <ListItemIcon>
            <ArrowUpward fontSize="small" />
          </ListItemIcon>
          <ListItemText>To front</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => { handleElementZIndexChange(el, 'BACK');; setMoreMenuAnchorEl(null) }}>
          <ListItemIcon>
            <ArrowDownward fontSize="small" />
          </ListItemIcon>
          <ListItemText>To back</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => { handleElementCopy(el);; setMoreMenuAnchorEl(null) }}>
          <ListItemIcon>
            <ContentCopyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Copy</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={(event) => { handleRemoveElement(el.elementId); setMoreMenuAnchorEl(null) }}>
          <ListItemIcon >
            <DeleteForeverIcon color="error" fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default ElementSettingsPanel