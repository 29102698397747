import React from 'react'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'

interface SquareLineProps {
    segments: number
    value: number
}

const SquareLine: React.FC<SquareLineProps> = ({ segments, value }) => {
    const theme = useTheme()

    const squareContainerStyle = {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start', // Align squares at the top
        '--segments': segments, // Set custom property
    } as React.CSSProperties // Use "as" to assert the type

    const squareStyle: React.CSSProperties = {
        width: 'calc(100% / var(--segments))',
        paddingBottom: 'calc(100% / var(--segments))',
        marginRight: '5px',
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: 'transparent',
    }

    return (
        <div style={squareContainerStyle}>
            {[...Array(segments)].map((_, index) => (
                <Box
                    key={index}
                    sx={
                        value >= (index + 1) * (1 / (segments + 1))
                            ? { ...squareStyle, backgroundColor: theme.palette.primary.main }
                            : squareStyle
                    }
                ></Box>
            ))}
        </div>
    )
}

export default SquareLine
