import { useState, useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import SearchIcon from '@mui/icons-material/Search'
import { searchIconsCall } from '../../lib/restCalls'
import CircularProgress from '@mui/material/CircularProgress'
import { useTheme } from '@mui/material/styles'
import PositionSelector from '../helpers/PositionSelector'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Grid from '@mui/material/Unstable_Grid2'
import { tableCellDefaultIconSettings } from '../pptElements/ElementDefinitions'
import TableSelectionRangeGroup from '../pptElements/table1/TableSelectionRangeGroup'
import { tableSettingsPanelMaxWidth, tableSettingsPanelMaxWidthNoRangeSelector, tableSettingsPositionSelectorDimensions } from '../../lib/style'

interface IconSettingsProps {
    currentIconState: TableCellIconSettings | undefined,
    handleIconStateChange: (newIconState: TableCellIconSettings, tableRange: TableSelectionRange | undefined) => void,
    allowedPositions?: PositionKey[]
    allowedSizes?: IconSize[]
    rowCount?: number
    colCount?: number
    rIdx?: number
    cIdx?: number
}

function IconSettings({ handleIconStateChange, currentIconState, allowedPositions, allowedSizes, rowCount, colCount, cIdx, rIdx }: IconSettingsProps) {
    const theme = useTheme()
    const showSelectionIndicator = rowCount !== undefined && colCount !== undefined && rIdx !== undefined && cIdx !== undefined

    //state
    const [iconList, setIconList] = useState<IconSourceType[]>([])
    const [selectionRange, setSelectionRange] = useState<TableSelectionRange | undefined>(showSelectionIndicator ? { startRow: rIdx, startCol: cIdx, endRow: rIdx, endCol: cIdx } : undefined)
    const [searchValue, setSearchValue] = useState("")
    const [loading, setLoadingValue] = useState(false)
    const searchValueRef = useRef<string>()
    searchValueRef.current = searchValue
    if (currentIconState === undefined) currentIconState = tableCellDefaultIconSettings


    // hooks
    useEffect(() => {
        // Add event listener when component mounts
        window.addEventListener('keydown', handleKeyPress)

        // Remove event listener when component unmounts
        return () => {
            window.removeEventListener('keydown', handleKeyPress)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    //functions
    const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleIconSearch()
        }
    }

    const handleIconSearch = async () => {
        if (searchValueRef.current) {
            setLoadingValue(true)
            setIconList([])
            const { data, status } = await searchIconsCall(searchValueRef.current)
            if (status === 200 && data !== null && data !== undefined) {
                setIconList(data)
            }
            setLoadingValue(false)
        }
    }

    const handleIconClick = async (item: IconSourceType) => {
        if (currentIconState) handleIconStateChange({ ...currentIconState, iconSource: item }, selectionRange)
    }

    const handlePositionClick = async (newPosition: PositionKey) => {
        if (currentIconState) {
            // toggle visibility if clicking on the same position
            let newVisible = currentIconState.visible
            if (newPosition === currentIconState.position) { newVisible = !newVisible }
            else { newVisible = true }
            handleIconStateChange({ ...currentIconState, position: newPosition, visible: newVisible }, selectionRange)
        }
    }

    const handleSizeChange = async (event: SelectChangeEvent) => {
        if (currentIconState) handleIconStateChange({ ...currentIconState, size: Number(event.target.value) }, selectionRange)
    }

    return (
        <Grid container spacing={theme.customStyling.panelGridSpacing} sx={{ maxWidth: showSelectionIndicator ? tableSettingsPanelMaxWidth : tableSettingsPanelMaxWidthNoRangeSelector }}>


            {/* The settings */}
            <Grid xs={12} sm={showSelectionIndicator ? 6 : 12} >
                <div>
                    <Grid container spacing={0} >

                        <Paper elevation={theme.customStyling.panelGridItemElevation} sx={{ ...theme.customStyling.panelGridItemSx }}>

                            {/* The current icon */}
                            <Grid xs={showSelectionIndicator ? 6 : 4} >
                                {currentIconState.iconSource &&
                                    <Box sx={{ ...theme.customStyling.panelInnerGridItemSx }}>
                                        <Typography variant="button" color='text.disabled' sx={{ ...theme.customStyling.panelGridItemTitleSx }}>Current</Typography>
                                        <Box sx={tableSettingsPositionSelectorDimensions}>
                                            <img style={{ width: '100%', height: '100%', filter: theme.palette.mode === 'light' ? '' : 'invert(1)' }}
                                                src={currentIconState.iconSource.thumbnailUrl} alt="" loading="lazy" />
                                        </Box>
                                    </Box>}
                            </Grid>

                            {/* The position selector */}
                            <Grid xs={showSelectionIndicator ? 6 : 4} sx={{ pl: 2 }}>
                                {allowedPositions &&
                                    <Box sx={{ ...theme.customStyling.panelInnerGridItemSx }}>
                                        <Typography variant="button" color='text.disabled' sx={{ ...theme.customStyling.panelGridItemTitleSx }}>Position</Typography>
                                        <Box sx={tableSettingsPositionSelectorDimensions}>
                                            <PositionSelector visible={currentIconState.visible} allowedPositions={allowedPositions} currentPosition={currentIconState.position} handleNewPosition={handlePositionClick}></PositionSelector>
                                        </Box>
                                    </Box>

                                }
                            </Grid>

                            {/* The size selector */}
                            <Grid xs={showSelectionIndicator ? 12 : 4} >
                                {allowedSizes &&
                                    <Box sx={{ ...theme.customStyling.panelInnerGridItemSx }}>
                                        <Typography variant="button" color='text.disabled' sx={{ ...theme.customStyling.panelGridItemTitleSx }}>Size</Typography>
                                        <Select
                                            fullWidth
                                            labelId="icon-size-selection"
                                            value={String(currentIconState.size)}
                                            label=""
                                            size='small'
                                            onChange={handleSizeChange}
                                            onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => event.stopPropagation()}
                                            onMouseDown={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => event.stopPropagation()}
                                            sx={{ mt: 0 }}
                                        >
                                            {allowedSizes.map((allowedSize, i: number) => (
                                                <MenuItem key={i} value={String(allowedSize.size)}>{allowedSize.displayName}</MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                }
                            </Grid>
                        </Paper>
                    </Grid>
                </div>
            </Grid>

            {/* The apply range selector */}
            {showSelectionIndicator && selectionRange &&
                <Grid xs={12} sm={6} sx={{ mt: { xs: -6, sm: 0 } }} >
                    <Paper elevation={theme.customStyling.panelGridItemElevation} sx={{ ...theme.customStyling.panelGridItemSx }}>
                        <Box sx={{ ...theme.customStyling.panelInnerGridItemSx }}>
                            <Typography variant="button" color='text.disabled' sx={{ ...theme.customStyling.panelGridItemTitleSx, textAlign: 'right' }}>Apply changes to</Typography>
                            <TableSelectionRangeGroup rowCount={rowCount} colCount={colCount} currentRange={selectionRange} handleTableRangeChange={setSelectionRange}
                                rIdx={rIdx} cIdx={cIdx} />
                        </Box>
                    </Paper>
                </Grid>}

            {/* The search */}
            <Grid xs={12}>
                <Paper elevation={theme.customStyling.panelGridItemElevation} sx={{ ...theme.customStyling.panelGridItemSx, justifyContent: 'center', mt: -6 }} >
                    {/* The search prompt */}
                    <Box sx={{ ...theme.customStyling.panelInnerGridItemSx, height: undefined }}>
                        <Typography variant="button" color='text.disabled' sx={{ ...theme.customStyling.panelGridItemTitleSx }}>Search</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', width: 1, mt: -1 }}>
                            <TextField variant="standard" label="" value={searchValue} fullWidth
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setSearchValue(event.target.value)
                                }} />
                            <SearchIcon sx={{ color: 'action.active', ml: 1, cursor: 'pointer' }} onClick={() => handleIconSearch()} />
                        </Box>
                    </Box>

                    {/* The search results */}
                    {loading && <CircularProgress />}
                    <Box sx={{ overflow: 'auto', maxHeight: '200px' }}>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'flex-start' }} >
                            {iconList.map((item) => (
                                <Box key={item.id} sx={{ m: 1, cursor: 'pointer' }} onClick={() => handleIconClick(item)}>
                                    <img style={{ width: '50px', height: '50px', filter: theme.palette.mode === 'light' ? '' : 'invert(1)' }}
                                        src={item.thumbnailUrl} alt="" loading="lazy" />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Paper>
            </Grid>
        </Grid >

    )
}

export default IconSettings