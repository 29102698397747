import { Box, Snackbar, ToggleButton, Tooltip } from '@mui/material'
import { StyledToggleButtonGroup } from '../../lib/style'
import { getClipboard } from '../../store/clipboardSlice'
import { ContentPaste, Redo, Undo } from '@mui/icons-material'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getCurrentSlideId } from '../../store/presentationSelectionSlice'
import { useHistContext } from '../helpers/UseHistory'
import { handleAutoAlign, handleElementPaste } from '../../lib/slideHandling'
import { useHotkeys } from 'react-hotkeys-hook'
import { globalHotKeyScope } from '../../lib/constants'
import { MdAutoAwesomeMosaic } from "react-icons/md"
import { getLogicalSlide } from '../../store/slidesSlice'
import { useState } from 'react'

function TopSettingsPanel() {
    const { histActions, histState } = useHistContext()
    const dispatch = useAppDispatch()
    const clipboard = useAppSelector(getClipboard)
    const currentSlideId = useAppSelector(getCurrentSlideId)
    const logicalSlide = useAppSelector((state) => getLogicalSlide(state, { slideId: currentSlideId }))
    const pastePossible = currentSlideId && clipboard.logicalElement

    //local state
    const [aligningElements, setAligningElements] = useState(false)
    const autoAlignPossible = currentSlideId && logicalSlide && !aligningElements && Object.keys(logicalSlide.rootElement.childElements).length > 0

    useHotkeys('mod+z', () => { if (histActions.canUndo) histActions.undo() }, { scopes: globalHotKeyScope }, [histActions])
    useHotkeys('mod+shift+z', () => { if (histActions.canRedo) histActions.redo() }, { scopes: globalHotKeyScope }, [histActions])

    const handleAutoAlignClick = async () => {
        setAligningElements(true)
        await handleAutoAlign(dispatch, logicalSlide, currentSlideId, histActions)
        setAligningElements(false)
    }

    return (
        <Box>
            <StyledToggleButtonGroup size="small" arial-label="Top level buttons">
                <Tooltip title="Paste element">
                    <span>
                        <ToggleButton onClick={() => handleElementPaste(dispatch, currentSlideId, clipboard, histActions)}
                            size="small" value="" disabled={!pastePossible} style={{ borderColor: 'transparent' }}>
                            <ContentPaste style={{ color: pastePossible ? "action" : 'disabled' }} fontSize="medium" />
                        </ToggleButton>
                    </span>
                </Tooltip>
                <Tooltip title={histActions.canUndo ? `Undo "${histState.present.stepTitle}"` : "Undo"}>
                    <span>
                        <ToggleButton onClick={histActions.undo} size="small" value="" disabled={!histActions.canUndo} style={{ borderColor: 'transparent' }}>
                            <Undo style={{ color: histActions.canUndo ? "action" : 'disabled' }} fontSize="medium" />
                        </ToggleButton>
                    </span>
                </Tooltip>
                <Tooltip title={histActions.canRedo ? `Redo "${histState.future[0].stepTitle}"` : "Redo"}>
                    <span>
                        <ToggleButton onClick={histActions.redo} size="small" value="" disabled={!histActions.canRedo} style={{ borderColor: 'transparent' }}>
                            <Redo style={{ color: histActions.canRedo ? "action" : 'disabled' }} fontSize="medium" />
                        </ToggleButton>
                    </span>
                </Tooltip>
                <Tooltip title={"Auto-align elements"}>
                    <span>
                        <ToggleButton onClick={handleAutoAlignClick} size="small" value="" disabled={!autoAlignPossible} style={{ borderColor: 'transparent' }}>
                            <MdAutoAwesomeMosaic style={{ width: '24px', height: '24px' }} />
                        </ToggleButton>
                    </span>
                </Tooltip>
            </StyledToggleButtonGroup>

            <Snackbar open={aligningElements} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                message="Aligning elements. This might take a few seconds..." />


        </Box>
    )
}

export default TopSettingsPanel