import { useState } from "react"
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useNavigate } from "react-router-dom"
import Avatar from '@mui/material/Avatar'
import PersonIcon from '@mui/icons-material/Person'
import { useTheme } from '@mui/material/styles'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Logout from '@mui/icons-material/Logout'
import Login from '@mui/icons-material/Login'
import ContextMenu from '../helpers/ContextMenu'
import { AmplifyUser } from '@aws-amplify/ui'
import { securePagesPath, editorPath, profilePath } from "../../lib/constants"
import { performLogout } from "../../lib/auth"
import { useAppDispatch } from '../../store/hooks'
import { Wysiwyg } from "@mui/icons-material"
import { useHistContext } from "../helpers/UseHistory"

type HeaderToolbarProps = {
  currentUser: AmplifyUser | undefined,
  extraHoriPadding: boolean, maxWidth?: number
}

function HeaderToolbar({ currentUser, children, extraHoriPadding, maxWidth }: React.PropsWithChildren<HeaderToolbarProps>) {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  let navigate = useNavigate()
  const { histActions } = useHistContext()
  const basePadding = 1
  const horiPadding = !!extraHoriPadding ? basePadding + 2 : basePadding


  // state
  const [menuAnchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(menuAnchorEl)


  // user
  const email = currentUser?.attributes?.email
  const loggedIn = currentUser !== undefined

  // functions
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  function handleLogoClick() {
    navigate("/")
  }
  function handleLoginClick() {
    navigate("/" + securePagesPath + '/' + editorPath)
  }
  function handleProfileClick() {
    navigate("/" + securePagesPath + '/' + profilePath)
  }
  function handleEditorClick() {
    if (loggedIn) navigate("/" + securePagesPath + '/' + editorPath)
    else navigate("/" + editorPath)
  }
  function handleLogoutClick() {
    performLogout(dispatch, histActions)
  }

  return (
    // Full width with white bg
    <Box sx={{
      display: 'flex', flexGrow: 1, width: 1, height: theme.customStyling.headerHeight, justifyContent: 'center', alignItems: 'center',
      backgroundColor: theme.palette.background.default
    }}>

      {/* Container with padding and maxWidth*/}
      <Box sx={{
        display: 'flex', flexGrow: 1, width: 1, maxWidth: maxWidth, height: theme.customStyling.headerHeight,
        pt: basePadding, pb: basePadding, pl: horiPadding, pr: horiPadding, justifyContent: 'space-between', alignItems: 'center',
      }}>

        <Box onClick={handleLogoClick} sx={{ display: 'flex', alignItems: 'center', cursor: "pointer", }}>
          <Typography variant="h6" component="div" sx={{
            backgroundColor: theme.palette.primary.main, borderRadius: '10px', borderTopRightRadius: '0px', borderBottomLeftRadius: '0px',
            display: 'flex', justifyContent: 'center', alignItems: 'center', fontStyle: 'italic',
            color: theme.palette.background.default, pl: 0.5, pr: 0.8, pb: 0.3, pt: 0.3, flexGrow: 1, fontWeight: 1000
          }} className={'main_logo'}>
            P
          </Typography>
          <Typography variant="h6" component="div" sx={{
            display: { xs: 'none', sm: 'flex' }, justifyContent: 'center', alignItems: 'center',
            color: theme.palette.primary.main, flexGrow: 1, ml: 1
          }} >
            proto<b>slides</b>
          </Typography>
        </Box>

        {children}

        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={menuOpen ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? 'true' : undefined}
        >
          <Avatar onClick={undefined}>{email !== undefined ? email.slice(0, 2) : <PersonIcon />}</Avatar>
        </IconButton>

        {/* menu */}
        <ContextMenu transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          handleClose={handleMenuClose} menuAnchorEl={menuAnchorEl} menuOpen={menuOpen} pointerPosition='right'
        >
          {loggedIn &&
            <MenuItem onClick={handleProfileClick}>
              <Avatar /> My account
            </MenuItem>
          }
          <MenuItem onClick={handleEditorClick}>
            <ListItemIcon>
              <Wysiwyg fontSize="small" />
            </ListItemIcon>
            {loggedIn ? 'Editor' : 'Trial Editor'}
          </MenuItem>
          <Divider />
          {loggedIn ?
            <MenuItem onClick={handleLogoutClick}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
            :
            <MenuItem onClick={handleLoginClick}>
              <ListItemIcon>
                <Login fontSize="small" />
              </ListItemIcon>
              Login
            </MenuItem>
          }
        </ContextMenu>
      </Box>
    </Box>
  )
}

export default HeaderToolbar