import { Box } from '@mui/system'
import { Typography } from '@mui/material'

interface PopoverContentProps { title: string, children: React.ReactNode, maxWidth?: number, width?: number }

function PopoverContent({ children, title, maxWidth, width }: PopoverContentProps) {

  return (
    <Box sx={{ p: 2, maxWidth: maxWidth, width: width }}>
      <Typography variant="button" color='text.disabled' gutterBottom>
        {title}
      </Typography>
      <Box sx={{ mt: 2 }}>
        {children}
      </Box>
    </Box>
  )
}

export default PopoverContent