import RelRnd from '../RelRnd'
import Box from '@mui/material/Box'
import { Descendant } from 'slate'
import RichTextPopover from '../../text/RichTextPopover'

type TitleProps = {
  el: LogicalElement, relRndProps: BasicRelRndProps, elementActive: boolean,
  elementSelected: boolean, handleTitleChange: (newTitle: Descendant[]) => void
}

function Title({ el, relRndProps, elementActive, elementSelected, handleTitleChange }: Readonly<TitleProps>) {

  let noOptionsRelRndProps = { ...relRndProps, showElementPanel: false }

  return (
    <RelRnd {...noOptionsRelRndProps} el={el} elementSelected={elementSelected} elementActive={elementActive} staticElement={true}>
      <Box sx={{
        width: el.box.width * relRndProps.parentSize.width, height: el.box.height * relRndProps.parentSize.height,
        display: "flex", justifyContent: "center", flexWrap: "wrap", alignContent: "flex-start",
        alignItems: "flex-start", position: "relative"
      }}>
        {el.title?.richTextBox?.richText &&
          <RichTextPopover el={el} relRndProps={relRndProps} richTextBox={{ richText: el.title?.richTextBox?.richText, textBox: el.box }}
            handleBlur={handleTitleChange} textRole='TITLE' />
        }

      </Box>
    </RelRnd>
  )
}

export default Title
