import { Box, Typography } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

type FeatureProps = { text: string }

function Feature({ text }: FeatureProps) {
    return (
        <Box sx={{ width: 1, display: 'flex', flexWrap: 'no-wrap', justifyContent: 'flex-start', alignContent: 'center' }}>
            <CheckCircleIcon fontSize="small" color='success' />
            <Typography variant="body2" gutterBottom sx={{ ml: 1 }}>
                {text}
            </Typography>
        </Box>
    )
}

export default Feature