import { useCallback } from 'react'
import { useDropzone, Accept } from 'react-dropzone'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

type DropzoneProps = { accept: Accept, handleFile: (file: File) => void }

function FileDropzone({ accept, handleFile }: Readonly<DropzoneProps>) {

  const theme = useTheme()
  const dropZoneStyle = {
    flex: 1,
    width: 1,
    height: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    textAlign: 'center',
    padding: '20px',
    borderWidth: 2,
    borderColor: theme.palette.text.disabled,
    borderStyle: 'dashed',
    cursor: 'pointer',
    opacity: '0.8'
  }

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file: File) => {
      handleFile(file)
    })

  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: accept, maxFiles: 1 })

  return (
    <Paper sx={dropZoneStyle} elevation={0}  {...getRootProps()}>
      <input {...getInputProps()} />
      <Typography variant='body2' color='text.disabled' sx={{ width: 1, display: 'flex', justifyContent: 'center' }}>
        Drag 'n' drop *.pptx file or click to add master
      </Typography>
      <Box sx={{ width: 1, justifyContent: 'center', display: 'flex' }}>
        <AddCircleIcon fontSize="large" sx={{ color: theme.palette.text.disabled }} />
      </Box >
    </Paper>
  )
}

export default FileDropzone