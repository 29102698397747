import Box from '@mui/material/Box'
import { basicStyleOptions } from '../../lib/init'
import StylePresetButtons from './StylePresetButtons'


type StyleSelectionProps = {
  currentPresentationStyle: BasicStyle | undefined | null,
  handleStyleChange: BasicStyleChangeFunction,
  allowNull?: boolean
}

function StyleSelection({ currentPresentationStyle, handleStyleChange, allowNull }: StyleSelectionProps) {

  // all basicStyle option names
  var basicStyleNames: string[] = []
  for (const bs of basicStyleOptions) {
    basicStyleNames.push(bs.displayName)
  }

  return (
    <Box sx={{ minWidth: 100, maxWidth: 400 }}>
      <StylePresetButtons width={100} handleStyleChange={handleStyleChange} currentStyle={currentPresentationStyle} availableStyles={basicStyleOptions} allowNull={!!allowNull} />
    </Box>
  )
}

export default StyleSelection