import React, { useState, useEffect } from 'react'
import { Box, Typography, TextField, TypographyProps } from '@mui/material'

interface EditableTextProps {
    text: string
    variant: TypographyProps['variant']
    onBlur: (newText: string) => void
    sx?: Record<string, any>
    inputFontSize?: string
}

const EditableText: React.FC<EditableTextProps> = ({ text, variant, onBlur, sx, inputFontSize = '1em' }) => {
    const [isEditing, setEditing] = useState(false)
    const [initialText, setInitialText] = useState(text)
    const [editedText, setEditedText] = useState(text)

    useEffect(() => {
        setInitialText(text)
        setEditedText(text)
    }, [text])

    const handleClick = () => {
        setEditing(true)
    }

    const handleBlur = () => {
        setEditing(false)
        onBlur(editedText)
    }

    const handleCancel = () => {
        setEditing(false)
        setEditedText(initialText)
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditedText(event.target.value)
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Escape') {
            handleCancel()
        }
    }

    return (
        <Box sx={{ width: 1 }}>
            {isEditing ? (
                <TextField
                    value={editedText}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    autoFocus
                    fullWidth
                    variant="standard"
                    size='small'
                    inputProps={{ style: { fontSize: inputFontSize } }}
                />
            ) : (
                <Typography
                    variant={variant}
                    onClick={handleClick}
                    sx={{ textAlign: 'left', cursor: 'text', width: 1, boxSizing: 'border-box', m: 0, p: 0, ...sx }}
                >
                    {text}
                </Typography>
            )}
        </Box>
    )
}

export default EditableText
