import React, { useState, useEffect, useContext } from "react"

const OnlineStatusContext = React.createContext(true)

type Props = {
    children: React.ReactNode
}

export const OnlineStatusProvider: React.FC<Props> = ({ children }) => {
    const [onlineStatus, setOnlineStatus] = useState<boolean>(typeof navigator !== "undefined" ? navigator.onLine : false)

    const setOnline = () => setOnlineStatus(true)
    const setOffline = () => setOnlineStatus(false)

    useEffect(() => {
        window.addEventListener("offline", setOffline)
        window.addEventListener("online", setOnline)

        return () => {
            window.removeEventListener("offline", setOffline)
            window.removeEventListener("online", setOnline)
        }
    }, [])

    return <OnlineStatusContext.Provider value={onlineStatus}>{children}</OnlineStatusContext.Provider>
}

export const useOnlineStatus = () => {
    const store = useContext(OnlineStatusContext)
    return store
}