import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import slidesReducer from './slidesSlice'
import displayControlsReducer from './displayControlsSlice'
import renderingOptionsSlice from './renderingOptionsSlice'
import presentationSelectionSlice from './presentationSelectionSlice'
import presentationsSlice from './presentationsSlice'
import mastersSlice from './mastersSlice'
import subscriptionOptionSlice from './subscriptionOptionSlice'
import clipboardSlice from './clipboardSlice'
import loadingStateSlice from './loadingStateSlice'

export const store = configureStore({
  reducer: {
    slides: slidesReducer,
    displayControls: displayControlsReducer,
    renderingOptions: renderingOptionsSlice,
    presentationSelection: presentationSelectionSlice,
    presentations: presentationsSlice,
    masters: mastersSlice,
    subscriptionOptions: subscriptionOptionSlice,
    clipboard: clipboardSlice,
    loadingState: loadingStateSlice
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>