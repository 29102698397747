import React from 'react'
import { Box, Theme, useTheme } from '@mui/material'

type StyleIndicatorProps = {
    basicStyle: BasicStyle,
}

function sxFromStyleVariant(bsv: BasicStyleVariant, theme: Theme, neutral: boolean = false) {
    const fillColor = neutral ? theme.palette.text.disabled : theme.palette.primary.main
    return {
        border: (bsv.lineStyle === 'SOLID' && bsv.lineWidth > 0) ? '2px solid' : '0px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: bsv.shapeType === 'ROUNDED' ? '30%' : '0px',
        backgroundColor: bsv.fillStyle === 'SOLID' ? fillColor : 'transparent',
        m: 0.2,
        opacity: 0.8
    }
}


function StyleIndicator({ basicStyle }: StyleIndicatorProps) {
    const theme = useTheme()

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexWrap: 'wrap' }} >
            <Box sx={{ width: '44%', ...sxFromStyleVariant(basicStyle.header1, theme) }}>
            </Box>
            <Box sx={{ width: '44%', ...sxFromStyleVariant(basicStyle.header1, theme) }}>
            </Box>
            <Box sx={{ width: '44%', ...sxFromStyleVariant(basicStyle.header2, theme) }}>
            </Box>
            <Box sx={{ width: '44%', ...sxFromStyleVariant(basicStyle.body1, theme, true) }} >
            </Box>
        </Box>
    )
}

export default StyleIndicator
