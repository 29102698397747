import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box } from '@mui/material'

type ImpressumProps = { impressumStatus: boolean, setImpressumStatus: (s: boolean) => void }

function Impressum({ impressumStatus, setImpressumStatus }: ImpressumProps) {
  return (
    <Box>
      <Dialog
        open={impressumStatus}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">About us</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>
              <p>Quasarfox OÜ (Limited)<br />
                Sepapaja 6<br />
                15551 Tallin</p>

              <p>Estonia<br />
                e-Business Register: 16257111</p>
              <p><strong>Represented by:</strong><br />
                Dr. Kai Schatto<br /></p>
              <p><strong>Management board:</strong><br />
                Dr. Kai Schatto</p>
              <br />
              <h3>Contact</h3>
              <p>
                E-Mail: protoslides@quasarfox.com</p>
              <br />
              <h3>VAT</h3>
              <p>
                EE102385649</p>
              <br />
              <h3>EU Dispute Resolution</h3>
              <p>The European Commission provides a platform for online dispute resolution (ODR): <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> You can find our email address above in the imprint.</p>
              <br />
              <h3>Consumer Dispute Resolution/Universal Conciliation Body</h3>
              <p>We are not willing or obliged to participate in dispute resolution procedures before a consumer arbitration board.</p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setImpressumStatus(false) }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default Impressum
