import { useState } from 'react'
import '@aws-amplify/ui-react/styles.css'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import PaletteIcon from '@mui/icons-material/Palette'
import SimpleCloseDialog from './helpers/SimpleCloseDialog'
import PresentationCreateDialog from './presentations/PresentationCreateDialog'
import Masters from './masters/Masters'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Tooltip from '@mui/material/Tooltip'
import { useTheme } from '@mui/material'


type GlobalActionsProps = {
  handleDeleteMaster: IdClickFunction, masters: Masters,
  handleNewMasterFile: (file: File) => void, createPresentation: (title: string, masterId: string) => void,
  loadingState: LoadingState, handleDefaultLayoutChange?: (masterId: string, defaultLayout: number) => void,
  handleMasterMainColChange: (masterId: string, primXmlKey: string | null, neuXmlKey: string | null) => void,
}
function GlobalActions({ handleDeleteMaster, masters, handleNewMasterFile, createPresentation, loadingState, handleDefaultLayoutChange, handleMasterMainColChange }: GlobalActionsProps) {
  const theme = useTheme()

  // local state
  const [masterDialogOpen, setMasterDialogOpen] = useState(false)
  const [presentationCreateDialogOpen, setPresentationCreateDialogOpen] = useState(false)

  return (
    <Box width={1}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <IconButton onClick={(event) => { setPresentationCreateDialogOpen(true) }} sx={{}} size="medium" className='create_presi_button'>
          <Tooltip title="Create presentation">
            <AddCircleIcon fontSize="medium" sx={{ color: theme.palette.primary.main }} />
          </Tooltip>
        </IconButton>
        <IconButton onClick={(event) => { setMasterDialogOpen(true) }} sx={{}} size="medium" className='manage_masters_button'>
          <Tooltip title="Manage masters">
            <PaletteIcon fontSize="medium" sx={{ color: theme.palette.primary.main }} />
          </Tooltip>
        </IconButton>
        <SimpleCloseDialog handleClose={() => setMasterDialogOpen(false)} open={masterDialogOpen} title={"Manage your masters"} width={"50vw"} buttonCaption={"Close"}>
          <Masters handleMasterDelete={handleDeleteMaster} masters={masters} handleNewMasterFile={handleNewMasterFile}
            mastersInAnalysis={loadingState.MASTERANALYSIS} handleDefaultLayoutChange={handleDefaultLayoutChange} handleMasterMainColChange={handleMasterMainColChange} />
        </SimpleCloseDialog>
        <PresentationCreateDialog open={presentationCreateDialogOpen} handleClose={() => setPresentationCreateDialogOpen(false)} masters={masters} loadingState={loadingState}
          createPresentation={createPresentation} title={"Create new presentation"} width={"50vw"} closeButtonCaption={"Cancel"} secondaryButtonCaption={"Create Presentation"} />
      </Box>
    </Box>
  )
}

export default GlobalActions