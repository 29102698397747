import { createSlice, createSelector } from '@reduxjs/toolkit'
import { RootState } from './store'

// Type definitions
const initialState: Masters = {}
type MastersPayload = { payload: Masters }
type PresentationActionSingleMaster = { payload: MasterIndex & { singleMaster: SingleMaster } }
type PresentationActionMasterIndex = { payload: MasterIndex }

// slice definition
export const mastersSlice = createSlice({
  name: 'masters',
  initialState,
  reducers: {
    setMasters: (state, action: MastersPayload) => {
      return action.payload
    },
    setSingleMaster: (state, action: PresentationActionSingleMaster) => {
      if (action.payload.masterId) state[action.payload.masterId] = action.payload.singleMaster
    },
    removeSingleMaster: (state, action: PresentationActionMasterIndex) => {
      if (action.payload.masterId) delete state[action.payload.masterId]
    }
  },
})

//actions
export const { setMasters, setSingleMaster, removeSingleMaster } = mastersSlice.actions

// selectors
// TODO: Look into correct selector structure for multiple component usage of same selector (https://react-redux.js.org/api/hooks)
export const getMasters = (state: RootState) => state.masters
export const getSingleMaster = createSelector(
  [(state: RootState, props: MasterIndex) => state.masters,
  (state: RootState, props: MasterIndex) => props.masterId],
  (masters, masterId) => ((masterId && masterId in masters) ? masters[masterId] : undefined)
)

export default mastersSlice.reducer
