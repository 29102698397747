import { useEffect, useRef, useState } from 'react'
import { Box, Paper, PopoverOrigin, useTheme } from '@mui/material'
import { noDragInitClassName, noDragInitInnerTextBoxClassName } from '../../lib/constants'
import { Rnd, RndResizeCallback } from 'react-rnd'
import { DraggableData, DraggableEvent } from "react-draggable"
import { Descendant } from 'slate'
import NoDragPopover from './NoDragPopover'
import { getPopoverDimensions } from '../../lib/sizes'
import RichTextField from '../text/RichTextField'
import { Clear, DragIndicator } from '@mui/icons-material'
import _ from 'lodash'

interface InElementMovableTextBoxProps {
    richTextBox: RichTextBoxType,
    relRndProps: BasicRelRndProps,
    el: LogicalElement,
    handleTextChange: (newText: Descendant[]) => void,
    handleBoxChange: (newBox: ElementBox) => void,
    handleDelete?: () => void,
    textRole: TextRole,
    enableResizing: EnableResizeDirection,
}

const anchorOrigin: PopoverOrigin = { vertical: 'center', horizontal: 'center' }
const transformOrigin: PopoverOrigin = { vertical: 'center', horizontal: 'center' }

function InElementMovableTextBox({ richTextBox, relRndProps, el, handleTextChange, textRole, handleBoxChange, enableResizing, handleDelete }: Readonly<InElementMovableTextBoxProps>) {

    const rndRef = useRef<Rnd>(null)
    const [textBoxAnchorEl, setTextBoxAnchorEl] = useState<EventTarget & HTMLElement | null>(null)
    const theme = useTheme()

    // This useEffect is required for correct positioning of the inner Rnd:
    // Firefox requires a slightly delayed setting of updatePosition/Size, as rndRef.current is undefined otherwise
    // Chrome requires setting the position via updatePosition instead of directly via Rnd props
    useEffect(() => {
        const timer = setTimeout(() => {
            if (rndRef.current && richTextBox.textBox) {
                const innerElementBox = getAbsInnerElementBox(el, richTextBox.textBox, relRndProps)
                rndRef.current.updatePosition({ x: innerElementBox.left, y: innerElementBox.top })
                rndRef.current.updateSize({ width: innerElementBox.width, height: innerElementBox.height })
            }
        }, 50)
        return () => clearTimeout(timer)
    }, [richTextBox.textBox, el, relRndProps])

    if (!richTextBox.textBox) return null

    const { popoverWidth, popoverHeight } = getPopoverDimensions(richTextBox, relRndProps)

    const handleDragStop = async (e: DraggableEvent, d: DraggableData) => {
        if (!richTextBox.textBox) return
        // handle the final box position change
        const newElementBox = {
            left: d.x / relRndProps.parentSize.width / el.box.width,
            top: d.y / relRndProps.parentSize.height / el.box.height,
            width: richTextBox.textBox.width,
            height: richTextBox.textBox.height
        }
        if (!_.isEqual(newElementBox, richTextBox.textBox)) {
            handleBoxChange(newElementBox)
        }
    }

    const handleResizeStop: RndResizeCallback = async (e, direction, ref, delta, position) => {
        // handle the final box size change
        const newElementBox = {
            left: position.x / relRndProps.parentSize.width / el.box.width,
            top: position.y / relRndProps.parentSize.height / el.box.height,
            width: parseFloat(ref.style.width) / relRndProps.parentSize.width / el.box.width,
            height: parseFloat(ref.style.height) / relRndProps.parentSize.height / el.box.height
        }
        if (!_.isEqual(newElementBox, richTextBox.textBox)) {
            handleBoxChange(newElementBox)
        }
    }

    return (
        < Rnd
            className={noDragInitClassName}
            dragHandleClassName='inner-textbox-drag-handle'
            ref={rndRef}
            bounds='#stage'
            onDragStop={(e: DraggableEvent, d: DraggableData) => { handleDragStop(e, d) }}
            onResizeStop={handleResizeStop}
            style={{}}
            disableDragging={false}
            enableResizing={enableResizing}
            cancel={"." + noDragInitInnerTextBoxClassName} // this selector denies dragging and thus allows touch events when components are used within react-rnd 
        >
            <Paper elevation={0} sx={{
                position: "relative", width: 1, height: 1, top: 0, left: 0,
                border: '1px solid', borderColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800], backgroundColor: 'transparent'
            }}>
                <Box onClick={(event) => setTextBoxAnchorEl(event.currentTarget)}
                    sx={{ width: 1, height: 1, cursor: 'text' }} />

                <NoDragPopover open={!!textBoxAnchorEl} anchorEl={textBoxAnchorEl} onClose={() => setTextBoxAnchorEl(null)}
                    anchorOrigin={anchorOrigin} transformOrigin={transformOrigin}>
                    <Box sx={{
                        width: popoverWidth, height: popoverHeight, borderRadius: theme.customStyling.relRndBoarderRadius,
                        borderStyle: "solid", borderWidth: "2px", borderColor: theme.palette.primary.main,
                        backgroundColor: theme.palette.background.paper, overflow: 'hidden'
                    }}>
                        {richTextBox.richText && richTextBox.textBox &&
                            <RichTextField richText={richTextBox.richText} alwaysVisible={true} el={el} textRole={textRole}
                                handleElementUserFontFactorChange={relRndProps.handleElementUserFontFactorChange}
                                handleRTBlur={(newText: Descendant[]) => { handleTextChange(newText) }}
                                textAreaHeight={relRndProps.parentSize.height * richTextBox.textBox.height} visibilityByFocus={true} />
                        }
                    </Box>
                </NoDragPopover>
            </Paper>

            {/* The drag indicator */}
            <div style={{ position: 'absolute', top: 0, left: -25, cursor: 'move' }} className="inner-textbox-drag-handle">
                <DragIndicator fontSize="small" />
            </div>

            {/* The delete button */}
            {handleDelete &&
                <div style={{ position: 'absolute', top: 0, right: -25, cursor: 'pointer' }}>
                    <Clear fontSize="small" onClick={() => handleDelete()} />
                </div>
            }

        </Rnd >

    )
}

export default InElementMovableTextBox

function getAbsInnerElementBox(el: LogicalElement, textBox: ElementBox, relRndProps: BasicRelRndProps): ElementBox {
    // Movable boxes have coordinates relative to the containing Element and NOT relative to the slide to enable scaling with the Element
    const relScaledWidth = el.box.width * textBox.width
    const relScaledHeight = el.box.height * textBox.height
    const relScaledLeft = el.box.width * textBox.left
    const relScaledTop = el.box.height * textBox.top

    const absScaledWidth = relRndProps.parentSize.width * relScaledWidth
    const absScaledHeight = relRndProps.parentSize.height * relScaledHeight
    const absScaledInElementLeft = relRndProps.parentSize.width * relScaledLeft
    const absScaledInElementTop = relRndProps.parentSize.height * relScaledTop
    return { left: absScaledInElementLeft, width: absScaledWidth, height: absScaledHeight, top: absScaledInElementTop }
}
