import { Placement } from "react-joyride"
import { useState, useEffect } from 'react'
import Joyride, { ACTIONS, EVENTS, STATUS, Step, CallBackProps } from 'react-joyride'
import { getUserGuideStyleOptions } from "./style"
import { Typography, useTheme } from "@mui/material"
import { devlog, sleep } from "../../lib/misc"
import { contentStyle, contentVariant, mainElementTutorialCompletedStorageKey } from "./misc"

export const steps: Step[] = [
    {
        title: 'Smart Slide Elements',
        content: <Typography variant={contentVariant} style={contentStyle}>
            Let's get started with Protoslide's <b>Smart Slide Elements</b>, which help you design your slide.
        </Typography>,
        target: '.non_title_element',
        placement: 'right' as Placement,
        disableBeacon: true
    },
    {
        title: 'Move slide elements',
        content: <Typography variant={contentVariant} style={contentStyle}>
            You can move around the existing slide elements in the editor by drag & drop or resize them by dragging the edges.
            Note, that slide titles are fixed.
        </Typography>,
        target: '.non_title_element',
        placement: 'right' as Placement,
        disableBeacon: true
    },
    {
        title: 'Add new slide elements',
        content: <Typography variant={contentVariant} style={contentStyle}>
            You can pull desired Smart Elements from the right panel into your slide.
        </Typography>,
        target: '.right_drawer_handle',
        placement: 'left' as Placement,
        disableBeacon: true
    },
    {
        title: 'Edit slide elements',
        content: <Typography variant={contentVariant} style={contentStyle}>
            To change Smart Elements, click/touch the element. Then click on text or icons to modify the content.
        </Typography>,
        target: '.non_title_element',
        placement: 'right' as Placement,
        disableBeacon: true
    }
]

type TutorialProps = {
    openRightDrawer: () => void
}

function MainElementTutorial({ openRightDrawer }: TutorialProps) {
    const theme = useTheme()
    const [showJoyride, setShowJoyride] = useState<boolean>(false)
    const [stepIndex, setStepIndex] = useState<number>(0)

    const openRightDrawerWithDelay = async () => {
        await sleep(10)
        openRightDrawer()
        await sleep(10)
        openRightDrawer()
        await sleep(100)
        openRightDrawer()
    }

    const handleJoyrideCallback = async (data: CallBackProps) => {
        const { status, type, action, index } = data
        devlog(data)

        if (action === ACTIONS.NEXT && type === EVENTS.STEP_AFTER) {
            if (index === 0) gtag("event", "tutorial_begin")
            if (index === 1) await openRightDrawerWithDelay()
            setStepIndex(stepIndex + 1)
        }

        if (action === ACTIONS.PREV && type === EVENTS.STEP_AFTER) {
            if (index === 3) await openRightDrawerWithDelay()
            setStepIndex(stepIndex - 1)
        }

        if (action === ACTIONS.SKIP) {
            // Set a flag in local storage to indicate that the tutorial has been completed
            localStorage.setItem(mainElementTutorialCompletedStorageKey, 'true')
            // Hide Joyride
            setShowJoyride(false)
        }
        if (status === STATUS.FINISHED) {
            gtag("event", "tutorial_complete")
            // Set a flag in local storage to indicate that the tutorial has been completed
            localStorage.setItem(mainElementTutorialCompletedStorageKey, 'true')
            // Hide Joyride
            setShowJoyride(false)
        }
    }

    useEffect(() => {
        // Check local storage for the flag
        const joyrideCompleted = localStorage.getItem(mainElementTutorialCompletedStorageKey)

        // Show Joyride only if the tutorial has not been completed
        setShowJoyride(!joyrideCompleted)
    }, [])

    return (
        <Typography variant="body2">
            {showJoyride && (
                <Joyride
                    steps={steps}
                    styles={{ options: getUserGuideStyleOptions(theme) }}
                    continuous
                    showProgress
                    showSkipButton
                    scrollToFirstStep
                    disableOverlay
                    hideCloseButton
                    disableCloseOnEsc
                    run={true}
                    callback={handleJoyrideCallback}
                    stepIndex={stepIndex}
                    disableScrolling
                />
            )}
            {/* Your main component content here */}
        </Typography>
    )
}

export default MainElementTutorial
