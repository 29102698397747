import { useState } from 'react'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import PositionSelector from '../helpers/PositionSelector'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Grid from '@mui/material/Unstable_Grid2'
import TableSelectionRangeGroup from '../pptElements/table1/TableSelectionRangeGroup'
import { Checkbox } from '@mui/material'
import { tableSettingsPanelMaxWidth, tableSettingsPositionSelectorDimensions } from '../../lib/style'

interface CheckMarkSettingsProps {
    currentCheckMarkSettings: CheckMarkSettings | undefined,
    handleCheckMarkSettingsChange: (newCheckMark: CheckMarkSettings, tableRange: TableSelectionRange | undefined) => void,
    allowedPositions?: PositionKey[]
    allowedSizes?: IconSize[]
    rowCount?: number
    colCount?: number
    rIdx?: number
    cIdx?: number
}

function CheckMarkSettings({ handleCheckMarkSettingsChange, currentCheckMarkSettings, allowedPositions, allowedSizes, rowCount, colCount, cIdx, rIdx }: CheckMarkSettingsProps) {
    const theme = useTheme()
    const showSelectionIndicator = rowCount !== undefined && colCount !== undefined && rIdx !== undefined && cIdx !== undefined
    if (currentCheckMarkSettings === undefined) currentCheckMarkSettings = initialCheckmarkSettings


    //state
    const [selectionRange, setSelectionRange] = useState<TableSelectionRange | undefined>(showSelectionIndicator ? { startRow: rIdx, startCol: cIdx, endRow: rIdx, endCol: cIdx } : undefined)

    const handleCheckMarkChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (currentCheckMarkSettings) handleCheckMarkSettingsChange({ ...currentCheckMarkSettings, checked: event.target.checked }, selectionRange)
    }

    const handlePositionClick = async (newPosition: PositionKey) => {
        if (currentCheckMarkSettings) {
            // toggle visibility if clicking on the same position
            let newVisible = currentCheckMarkSettings.visible
            if (newPosition === currentCheckMarkSettings.position) { newVisible = !newVisible }
            else { newVisible = true }
            handleCheckMarkSettingsChange({ ...currentCheckMarkSettings, position: newPosition, visible: newVisible }, selectionRange)
        }
    }

    const handleSizeChange = async (event: SelectChangeEvent) => {
        if (currentCheckMarkSettings) handleCheckMarkSettingsChange({ ...currentCheckMarkSettings, size: Number(event.target.value) }, selectionRange)
    }

    return (
        <Grid container spacing={theme.customStyling.panelGridSpacing} sx={{ maxWidth: tableSettingsPanelMaxWidth }}>


            {/* The settings */}
            <Grid xs={12} sm={showSelectionIndicator ? 6 : 12} >
                <div>
                    <Grid container spacing={0} >

                        <Paper elevation={theme.customStyling.panelGridItemElevation} sx={{ ...theme.customStyling.panelGridItemSx }}>

                            {/* The current checkMark */}
                            <Grid xs={showSelectionIndicator ? 6 : 4} >
                                {currentCheckMarkSettings &&
                                    <Box sx={{ ...theme.customStyling.panelInnerGridItemSx }}>
                                        <Typography variant="button" color='text.disabled' sx={{ ...theme.customStyling.panelGridItemTitleSx }}>Current</Typography>
                                        <Box sx={{ ...tableSettingsPositionSelectorDimensions, display: 'flex', justifyContent: 'center', flexWrap: 'wrap', alignItems: 'center' }}>
                                            <Checkbox checked={currentCheckMarkSettings.checked} onChange={handleCheckMarkChange} sx={{ '& .MuiSvgIcon-root': { fontSize: 56 } }} />
                                        </Box>
                                    </Box>}
                            </Grid>

                            {/* The position selector */}
                            <Grid xs={showSelectionIndicator ? 6 : 4} sx={{ pl: 2 }}>
                                {allowedPositions &&
                                    <Box sx={{ ...theme.customStyling.panelInnerGridItemSx }}>
                                        <Typography variant="button" color='text.disabled' sx={{ ...theme.customStyling.panelGridItemTitleSx }}>Position</Typography>
                                        <Box sx={tableSettingsPositionSelectorDimensions}>
                                            <PositionSelector visible={currentCheckMarkSettings.visible} allowedPositions={allowedPositions} currentPosition={currentCheckMarkSettings.position} handleNewPosition={handlePositionClick}></PositionSelector>
                                        </Box>
                                    </Box>

                                }
                            </Grid>

                            {/* The size selector */}
                            <Grid xs={showSelectionIndicator ? 12 : 4} >
                                {allowedSizes &&
                                    <Box sx={{ ...theme.customStyling.panelInnerGridItemSx }}>
                                        <Typography variant="button" color='text.disabled' sx={{ ...theme.customStyling.panelGridItemTitleSx }}>Size</Typography>
                                        <Select
                                            fullWidth
                                            labelId="icon-size-selection"
                                            value={String(currentCheckMarkSettings.size)}
                                            size='small'
                                            label=""
                                            onChange={handleSizeChange}
                                            sx={{ mt: 0 }}
                                        >
                                            {allowedSizes.map((allowedSize, i: number) => (
                                                <MenuItem key={i} value={String(allowedSize.size)}>{allowedSize.displayName}</MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                }
                            </Grid>
                        </Paper>
                    </Grid>
                </div>
            </Grid>

            {/* The apply range selector */}
            {showSelectionIndicator && selectionRange &&
                <Grid xs={12} sm={6} sx={{ mt: { xs: -6, sm: 0 } }} >
                    <Paper elevation={theme.customStyling.panelGridItemElevation} sx={{ ...theme.customStyling.panelGridItemSx }}>
                        <Box sx={{ ...theme.customStyling.panelInnerGridItemSx, width: 1 }}>
                            <Typography variant="button" color='text.disabled' sx={{ ...theme.customStyling.panelGridItemTitleSx, textAlign: 'right' }}>Apply changes to</Typography>
                            <TableSelectionRangeGroup rowCount={rowCount} colCount={colCount} currentRange={selectionRange} handleTableRangeChange={setSelectionRange}
                                rIdx={rIdx} cIdx={cIdx} />
                        </Box>
                    </Paper>
                </Grid>}

        </Grid >

    )
}

export const initialCheckmarkSettings = { visible: false, size: 2, position: 'CENTERCENTER' as PositionKey, checked: true }

export default CheckMarkSettings