import React, { createContext, useContext, useReducer, useCallback } from 'react'
import { devlog } from '../../lib/misc'

const HistContext = createContext<{ histState: State; histActions: Actions } | undefined>(
  undefined
)

enum ActionType {
  Undo = 'UNDO',
  Redo = 'REDO',
  Set = 'SET',
  Reset = 'RESET',
}

type HistoryStep = {
  do: Function | undefined,
  undo: Function | undefined,
  stepTitle: string
}

export interface Actions {
  set: (newPresent: HistoryStep, checkpoint?: boolean) => void
  reset: (newPresent: HistoryStep) => void
  undo: () => void
  redo: () => void
  canUndo: boolean
  canRedo: boolean
}

interface Action {
  type: ActionType
  historyCheckpoint?: boolean
  newPresent?: HistoryStep
}

export interface State {
  past: HistoryStep[]
  present: HistoryStep
  future: HistoryStep[]
}

const initialState = {
  past: [],
  present: null,
  future: [],
}

export const initialPresent = { do: undefined, undo: undefined, stepTitle: "" }

type Options = {
  useCheckpoints?: boolean
}

const useHist = (initialPresent: HistoryStep, opts: Options = {}): [State, Actions] => {
  const { useCheckpoints }: Options = {
    useCheckpoints: false,
    ...opts,
  }

  const reducer = (histState: State, action: Action): State => {
    const { past, present, future } = histState

    switch (action.type) {
      case ActionType.Undo: {
        if (past.length === 0) {
          return histState
        }

        if (present.undo) present.undo()
        const previous = past[past.length - 1]
        const newPast = past.slice(0, past.length - 1)

        return {
          past: newPast,
          present: previous,
          future: [present, ...future],
        }
      }

      case ActionType.Redo: {
        if (future.length === 0) {
          return histState
        }
        const next = future[0]
        const newFuture = future.slice(1)
        if (next.do) next.do()

        return {
          past: [...past, present],
          present: next,
          future: newFuture,
        }
      }

      case ActionType.Set: {
        const isNewCheckpoint = useCheckpoints
          ? !!action.historyCheckpoint
          : true
        const { newPresent } = action

        if ((newPresent === present) || !newPresent || !newPresent.do || !newPresent.undo) {
          return histState
        }

        newPresent.do()
        devlog([...past, present], newPresent)

        return {
          past: isNewCheckpoint === false ? past : [...past, present],
          present: newPresent,
          future: [],
        }
      }

      case ActionType.Reset: {
        const { newPresent } = action

        if (!newPresent) {
          return histState
        }

        return {
          past: [],
          present: newPresent,
          future: [],
        }
      }

      default:
        return histState
    }
  }

  const [histState, dispatch] = useReducer(reducer, {
    ...initialState,
    present: initialPresent,
  }) as [State, React.Dispatch<Action>]

  const canUndo = histState.past.length !== 0
  const canRedo = histState.future.length !== 0

  const undo = useCallback(() => {
    if (canUndo) {
      dispatch({ type: ActionType.Undo })
    }
  }, [canUndo])

  const redo = useCallback(() => {
    if (canRedo) {
      dispatch({ type: ActionType.Redo })
    }
  }, [canRedo])

  const set = useCallback((newPresent: HistoryStep, checkpoint = false) => {
    dispatch({
      type: ActionType.Set,
      newPresent,
      historyCheckpoint: checkpoint,
    })
  }, [])

  const reset = useCallback((newPresent: HistoryStep) => {
    dispatch({ type: ActionType.Reset, newPresent })
  }, [])

  return [histState, { set, reset, undo, redo, canUndo, canRedo }]
}


const HistoryProvider = ({ children }: { children: React.ReactNode }) => {
  const [histState, histActions] = useHist(initialPresent)

  return (
    <HistContext.Provider value={{ histState, histActions }}>
      {children}
    </HistContext.Provider>
  )
}

const useHistContext = () => {
  const context = useContext(HistContext)

  if (!context) {
    throw new Error('useUndoContext must be used within an UndoProvider')
  }

  return context
}

export { HistoryProvider, useHistContext, HistContext as UndoContext };

