import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { Box } from '@mui/system'

interface SubCancelDialogProps { handleClose: () => void, open: boolean, width: string | null, handleCancel: () => void }

function SubCancelDialog({ handleClose, open, width, handleCancel }: SubCancelDialogProps) {

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth="xl"
    >
      <DialogTitle id="responsive-dialog-title">
        Confirm cancelation
      </DialogTitle>
      <DialogContent>
        Do you really want to cancel your subscription?
        {width && <Box sx={{ width: width }}></Box>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Keep subscription
        </Button>
        <Button color="error" onClick={() => handleCancel()}>
          Cancel subscription
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SubCancelDialog