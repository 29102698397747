import Box from '@mui/material/Box'
import MiniSlide from '../helpers/MiniSlide'


type PresentationListProps = { handlePresiClick: IdClickFunction, presentations: Presentations, slides: Slides, currentPresentationId: string | undefined }
function PresentationList({ handlePresiClick, presentations, slides, currentPresentationId }: PresentationListProps) {


  const presentationArray = (Object.values(presentations) as Array<SinglePresentation>)

  return (
    <Box sx={{ width: 1, overflow: 'auto', p: 1 }}>
      {presentationArray.map((presi: SinglePresentation, i: number) => (
        <Box sx={{ width: 1, mb: 1 }} key={String(i)} >
          <MiniSlide
            onClickFunction={handlePresiClick}
            title={presi.title}
            thisId={presi.presentationId}
            selectedId={currentPresentationId}
            renderDetail={(presi.slideIds.length > 0 && presi.slideIds[0] in slides) ? slides[presi.slideIds[0]].renderDetail : undefined} />
        </Box>
      ))}
    </Box>
  )
}

export default PresentationList
