import SubscriptionOptions from "../profile/SubscriptionOptions"
import HeaderToolbar from "./HeaderToolbar"
import { AmplifyUser } from '@aws-amplify/ui'
import { Box, IconButton, Tooltip, Typography, useTheme } from "@mui/material"
import { canSubscribe, getCurrentUserEmail } from "../../lib/auth"
import ActiveSubscription from "../profile/ActiveSubscription"
import { useEffect } from 'react'
import { getCurrentAuthenticatedUser } from "../../lib/auth"
import { devlog } from "../../lib/misc"
import { ArrowBack } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import { editorPath, securePagesPath } from "../../lib/constants"

type UserProfileProps = { currentUser: AmplifyUser | undefined }

function UserProfile({ currentUser }: UserProfileProps) {
  const userCanSubscribe = canSubscribe(currentUser)
  const email = getCurrentUserEmail(currentUser)
  const theme = useTheme()
  const navigate = useNavigate()

  // Update user on mount
  useEffect(() => {
    getCurrentAuthenticatedUser(true)
    devlog(currentUser)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={{ width: 1 }}>
      <HeaderToolbar currentUser={currentUser} extraHoriPadding={true}>{ }</HeaderToolbar>

      {/* {email &&
        <Typography variant="h4" gutterBottom color={theme.palette.primary.main} sx={{ width: 1, textAlign: 'center', mt: 4 }}>
          Hello, {email.split('@')[0]}!
        </Typography>} */}

      <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'nowrap', width: 1, mt: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'nowrap', width: 1, alignItems: 'center', mb: -2, maxWidth: 1000, minWidth: '60vw' }}>
          <Tooltip title={"Go to editor"}>
            <IconButton onClick={() => navigate("/" + securePagesPath + '/' + editorPath)} size="medium">
              <ArrowBack />
            </IconButton>
          </Tooltip>
          <Typography variant="h6" gutterBottom color={theme.palette.text.disabled} width={1} ml={1} mt={0.5}>
            {userCanSubscribe ? 'Continue free trial' : 'To Editor'}
          </Typography>
        </Box>
      </Box>

      {userCanSubscribe ?
        <Box sx={{ width: 1, p: 8 }}>
          <SubscriptionOptions />
        </Box>
        :
        <Box sx={{ width: 1, p: 8 }}>
          <ActiveSubscription currentUser={currentUser} />
        </Box>
      }
    </Box >
  )
}

export default UserProfile