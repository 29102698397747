import Box from '@mui/material/Box'
import { ToggleButton, Tooltip, useTheme } from '@mui/material'
import _ from 'lodash'
import StyleIndicator from './StyleIndicator'
import NotInterestedIcon from '@mui/icons-material/NotInterested'

interface StylePresetButtonProps {
    handleStyleChange: BasicStyleChangeFunction,
    availableStyles: BasicStyle[],
    currentStyle: BasicStyle | undefined | null,
    width: number,
    allowNull: boolean
}

function StylePresetButtons({ handleStyleChange, availableStyles, currentStyle, width, allowNull }: StylePresetButtonProps) {
    const theme = useTheme()

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            {allowNull &&
                <Tooltip title={'Use presentation style'}>
                    <ToggleButton sx={{ width: width + 'px', height: width + 'px', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }} value={''}
                        selected={_.isEqual(currentStyle, null)} onClick={() => handleStyleChange(null)}>
                        <Box sx={{ width: '70%', height: '70%', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', pb: 0.5 }}>
                            <NotInterestedIcon style={{ width: '100%', height: '100%', opacity: 0.8, color: theme.palette.text.disabled }} />
                        </Box>
                        {/* <Typography variant="subtitle2" color='text.disabled' sx={{ textAlign: 'center', width: 1 }}>{buttonStyle.displayName}</Typography> */}
                    </ToggleButton>
                </Tooltip>
            }
            {availableStyles.map((buttonStyle, i: number) => (
                <Tooltip key={i} title={buttonStyle.displayName}>
                    <ToggleButton sx={{ width: width + 'px', height: width + 'px', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }} value={''}
                        selected={_.isEqual(currentStyle, buttonStyle)} onClick={() => handleStyleChange(buttonStyle)}>
                        <Box sx={{ width: '95%', height: '95%', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', pb: 0.5 }}>
                            <StyleIndicator basicStyle={buttonStyle} />
                        </Box>
                        {/* <Typography variant="subtitle2" color='text.disabled' sx={{ textAlign: 'center', width: 1 }}>{buttonStyle.displayName}</Typography> */}
                    </ToggleButton>
                </Tooltip>
            ))}
        </Box>
    )
}

export default StylePresetButtons