import { FC } from "react"
import UserProfile from './UserProfile'
import Editor from './Editor'
import { Authenticator } from '@aws-amplify/ui-react'
import { Route, Routes, useLocation } from 'react-router-dom'
import '@aws-amplify/ui-react/styles.css'
import { AmplifyUser } from '@aws-amplify/ui'
import { editorPath, paymentPath, profilePath, securePagesPath } from "../../lib/constants"
import HeaderToolbar from '../containers/HeaderToolbar'
import PaymentPage from "../profile/PaymentPage"
import { Box, useTheme } from "@mui/material"
import QuiltedImageListBackground from "../helpers/QuiltedImageListBackground"
// import signInImgLight from '../img/signin_light.jpg'
// import signInImgDark from '../img/signin_dark.jpg'
// import { useTheme } from '@mui/material/styles'

interface Props { currentUser: AmplifyUser | undefined }

const SecuredPages: FC<Props> = ({ currentUser }) => {
  const location = useLocation()
  const theme = useTheme()
  const loginMaskVisible = currentUser === undefined && location.pathname.includes(securePagesPath)
  // let login_image = undefined
  // if (loginMaskVisible) {
  //   if (theme.palette.mode === 'light') login_image = signInImgLight
  //   else login_image = signInImgDark
  // }

  return (
    <div>
      {loginMaskVisible &&
        <HeaderToolbar currentUser={currentUser} extraHoriPadding={true}>{ }</HeaderToolbar>
      }

      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'no-wrap', position: 'relative', width: 1 }}>

        {/* {loginMaskVisible && signInImgLight !== undefined &&
          <Box sx={{ width: '50%', maxHeight: '500px', display: { xs: 'none', md: 'flex' } }}>
            <img src={login_image} alt="Login" style={{ width: '100%', objectFit: 'cover' }} />
          </Box>} */}

        {/* <Box sx={{ width: '50%' }}> */}
        <Authenticator loginMechanisms={['email']}
          socialProviders={['facebook', 'google']}
        >
          {({ signOut, user }) => (
            <Routes>
              <Route path={profilePath} element={<UserProfile currentUser={currentUser} />} />
              <Route path={editorPath} element={<Editor currentUser={currentUser} />} />
              <Route path={paymentPath + '/*'} element={<PaymentPage currentUser={currentUser} />} />
            </Routes>
          )}
        </Authenticator>

        {/* </Box> */}
        <Box sx={{
          width: '90%', zIndex: -1, position: 'absolute', left: '10%',
          top: loginMaskVisible ? '-150px' : `calc(${theme.customStyling.headerHeight}px - 150px)`, opacity: 0.1
        }}>
          <QuiltedImageListBackground />
        </Box>
      </Box>
    </div>
  )
}

export default SecuredPages
