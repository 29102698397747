export function toLongHex(hexcolor: string) {

  let longHex
  if (hexcolor.length === 4) {
    longHex = "#" + hexcolor.slice(1, 4).split('').map(function (hex) {
      return hex + hex
    }).join('')
  } else if (hexcolor.length === 3) {
    longHex = "#" + hexcolor.split('').map(function (hex) {
      return hex + hex
    }).join('')
  } else longHex = hexcolor

  return longHex
}

// blend two hex colors together by an amount
export function blendColors(colorA: string, colorB: string, amount: number) {

  let safeColA = toLongHex(colorA)
  let safeColB = toLongHex(colorB)

  const [rA, gA, bA] = safeColA.match(/\w\w/g)!.map((c) => parseInt(c, 16))
  const [rB, gB, bB] = safeColB.match(/\w\w/g)!.map((c) => parseInt(c, 16))

  const r = Math.round(rA + (rB - rA) * amount).toString(16).padStart(2, '0')
  const g = Math.round(gA + (gB - gA) * amount).toString(16).padStart(2, '0')
  const b = Math.round(bA + (bB - bA) * amount).toString(16).padStart(2, '0')
  return '#' + r + g + b
}

export function hexToRgba(hex: string, a: number) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ?
    "rgba(" + String(parseInt(result[1], 16)) + ","
    + String(parseInt(result[2], 16)) + ","
    + String(parseInt(result[3], 16)) + "," + String(a) + ")"
    : undefined
}

