import RelRnd from '.././RelRnd'
import { useState, useEffect } from "react"
import { Box, Tooltip, ToggleButton, Skeleton, PopoverOrigin, ClickAwayListener, Tabs, Tab } from '@mui/material'
import { Descendant } from 'slate'
import _ from 'lodash'
import { StyledToggleButtonGroup } from '../../../lib/style'
import { RiFileExcel2Line, RiInsertColumnLeft, RiInsertColumnRight, RiInsertRowTop, RiInsertRowBottom, RiDeleteColumn, RiDeleteRow } from 'react-icons/ri'
import { MdOutlineSettingsApplications } from 'react-icons/md'
import { FaBarsProgress } from 'react-icons/fa6'
import { BiSolidPieChartAlt } from 'react-icons/bi'
import { TbColorFilter } from 'react-icons/tb'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { useTheme } from '@mui/material/styles'
import DropdownButton from '../../helpers/DropdownButton'
import InElementBox from '../../helpers/InElementBox'
import { hexToRgba } from '../../../lib/colors'
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon'
import IconSettings from '../../elements/IconSettings'
import HarveySettings from '../../elements/HarveySettings'
import ProgressSettings from '../../elements/ProgressBarSettings'
import ColorStateSettings from '../../elements/ColorStateSettings'
import CheckMarkSettings from '../../elements/CheckMarkSettings'
import TableCellSettings from './TableCellSettings'
import ExcelPastePanel from './ExcelPastePanel'
import RichTextPopover from '../../text/RichTextPopover'
import NoDragPopover from '../../helpers/NoDragPopover'

type Table1Props = {
  el: LogicalElement, relRndProps: BasicRelRndProps, elementActive: boolean, elementSelected: boolean,
  updateElementContent: ElementContentChangeFunction
}
type CellIndex = { rIdx: number, cIdx: number } | undefined
const currentCellIdentifierString = 'currentCellBox'
const tabIconStyle = { width: '25px', height: '25px' }
const tabStyle = { minWidth: { xs: 60, sm: 60 } }

function Table1({ el, relRndProps, elementActive, elementSelected, updateElementContent }: Readonly<Table1Props>) {
  const theme = useTheme()

  // localState
  const [activeItem, setActiveItem] = useState<CellIndex>({ rIdx: 0, cIdx: 0 })
  const [excelPasteAnchorEl, setExcelPasteAnchorEl] = useState<EventTarget & HTMLElement | null>(null)
  const [tableCellSettingsPopoverAnchorEl, setTableCellSettingsPopoverAnchorEl] = useState<EventTarget & HTMLElement | null>(null)
  const [cellSettingsTabValue, setCellSettingsTabValue] = useState(0)

  const currentCellIdentifier = currentCellIdentifierString + String(activeItem?.rIdx) + String(activeItem?.cIdx)

  // props
  const elementContent = el.content! as Table1Content
  const rows = elementContent.rows
  let currentCell: TableCell | undefined = undefined
  if (rows && activeItem && rows[activeItem.rIdx] && rows[activeItem.rIdx][activeItem.cIdx]) currentCell = rows[activeItem.rIdx][activeItem.cIdx]

  // Reset the selected cell on exit
  useEffect(() => {
    //if (!elementActive && activeItem !== undefined) setActiveItem(undefined)
  }, [activeItem, elementActive])

  // calc popover anchor
  let anchorOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'left' }
  let transformOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'right' }
  if (currentCell?.box) {
    const box = currentCell.box
    if (box.left > 1 - box.left - box.width) {
      anchorOrigin.horizontal = 'left'
      transformOrigin.horizontal = 'right'
    } else {
      anchorOrigin.horizontal = 'right'
      transformOrigin.horizontal = 'left'
    }
    if (box.top > 1 - box.top - box.height) {
      anchorOrigin.vertical = 'top'
      transformOrigin.vertical = 'bottom'
    } else {
      anchorOrigin.vertical = 'bottom'
      transformOrigin.vertical = 'top'
    }
  }


  // function getDefaultCell(rIdx: number, cIdx: number): TableCell {
  //   let defaultCell: TableCell = {
  //     textSettings: { visible: true, richText: richLoremParagraph(3) }, cellVisible: true, rowspan: 1, colspan: 1,
  //   }
  //   try {
  //     const currentCell = rows[rIdx][cIdx]
  //     defaultCell = { ...defaultCell, tableRegion: currentCell.tableRegion ? currentCell.tableRegion : 'BODY1' }
  //     return defaultCell
  //   } catch { return defaultCell }
  // }

  const handleCellSettingsTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCellSettingsTabValue(newValue)
  }

  function addRow(after: boolean) {
    if (activeItem && relRndProps.currentSlideId && elementContent) {
      const newRowIndex = after ? activeItem?.rIdx + 1 : activeItem?.rIdx
      const newRow: TableCell[] = Array(rows[activeItem?.rIdx].length)
        .fill(null)
        .map((cell, cIdx) => cell = { ...rows[activeItem.rIdx][cIdx] })

      const newTable = [...rows]
      newTable.splice(newRowIndex, 0, newRow)
      let newContent = { ...elementContent }
      newContent.rows = newTable
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "APPEARANCE_CHANGE")
    }
  }

  function deleteCurrentRow() {
    if (activeItem && relRndProps.currentSlideId && elementContent) {
      if (activeItem?.rIdx >= 0 && activeItem?.rIdx < rows.length && rows.length > 1) {
        const newTable = [...rows]
        newTable.splice(activeItem?.rIdx, 1) // Remove the current row
        let newContent = { ...elementContent }
        newContent.rows = newTable
        updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "APPEARANCE_CHANGE")
        if (activeItem?.rIdx >= newTable.length) {
          // Adjust the current active row index if needed
          setActiveItem({ rIdx: newTable.length - 1, cIdx: activeItem?.cIdx })
        }
      }
    }
  }

  function addColumn(after: boolean) {
    if (activeItem && relRndProps.currentSlideId && elementContent) {
      const newTable = rows.map((row, rowIndex) => {
        const newRow = [...row]
        const newCell = { ...rows[rowIndex][activeItem.cIdx] }

        if (after) {
          newRow.splice(activeItem?.cIdx + 1, 0, newCell)
        } else {
          newRow.splice(activeItem?.cIdx, 0, newCell)
        }
        return newRow
      })
      let newContent = { ...elementContent }
      newContent.rows = newTable
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "APPEARANCE_CHANGE")
    }
  }

  function deleteCurrentColumn() {
    if (activeItem && relRndProps.currentSlideId && elementContent) {
      if (activeItem?.cIdx >= 0 && rows.length > 0 && rows[0].length > 1) {
        const newTable = rows.map((row) => {
          if (activeItem?.cIdx < row.length) {
            const newRow = [...row]
            newRow.splice(activeItem?.cIdx, 1) // Remove the current column
            return newRow
          } else {
            return row
          }
        })
        let newContent = { ...elementContent }
        newContent.rows = newTable
        updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "APPEARANCE_CHANGE")
      }
    }
  }

  const handleCellTextChange = (newText: Descendant[], idx: CellIndex) => {
    if (elementContent && relRndProps.currentSlideId && idx && !_.isEqual(elementContent.rows[idx.rIdx][idx.cIdx].textSettings.richText, newText)) {
      let newContent = _.cloneDeep(elementContent)
      newContent.rows[idx.rIdx][idx.cIdx] = { ...newContent.rows[idx.rIdx][idx.cIdx], textSettings: { ...newContent.rows[idx.rIdx][idx.cIdx].textSettings, richText: newText } }
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "IMMEDIATE_ELEMENT_CONTENT_TYPING")
    }
  }

  const handleExcelPaste = (newRows: TableRow[]) => {
    if (elementContent && relRndProps.currentSlideId && !_.isEqual(elementContent.rows, newRows)) {
      let newContent = { ...elementContent }
      newContent.rows = newRows
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "IMMEDIATE_ELEMENT_CONTENT_TYPING")
      setExcelPasteAnchorEl(null)
      window.gtag("event", "select_content", { content_type: "table_paste_excel" })
    }
  }

  const handleCellActivation = (myIndex: CellIndex, newIndex: CellIndex) => {
    // click away is only allowed if the current active index is the index of the ClickAwayActor/Listener
    if (_.isEqual(myIndex, activeItem) && newIndex === undefined) setActiveItem(undefined)
    if (newIndex !== undefined) setActiveItem(newIndex)
  }

  const handleIconStateChange = (newIconSettings: TableCellIconSettings, range: TableSelectionRange | undefined) => {
    if (elementContent && relRndProps.currentSlideId && !_.isEqual(currentCell?.iconSettings, newIconSettings) && currentCell && activeItem) {
      if (range === undefined) range = { startRow: activeItem.rIdx, startCol: activeItem.cIdx, endRow: activeItem.rIdx, endCol: activeItem.cIdx }
      const sizeChanged = currentCell?.iconSettings?.size !== newIconSettings.size
      const positionChanged = currentCell?.iconSettings?.position !== newIconSettings.position
      const visibleChanged = currentCell?.iconSettings?.visible !== newIconSettings.visible
      const iconSourceChanged = !_.isEqual(currentCell?.iconSettings?.iconSource, newIconSettings.iconSource)
      let newContent = _.cloneDeep(elementContent)
      for (let rIdx = range.startRow; rIdx <= range.endRow; rIdx++) {
        for (let cIdx = range.startCol; cIdx <= range.endCol; cIdx++) {
          if (!newContent.rows[rIdx][cIdx].iconSettings) newContent.rows[rIdx][cIdx].iconSettings = { ...newIconSettings, visible: false }
          let loopIconSettings = newContent.rows[rIdx][cIdx].iconSettings
          if (loopIconSettings) {
            if (!loopIconSettings) loopIconSettings = { ...newIconSettings }
            if (sizeChanged) loopIconSettings.size = newIconSettings.size
            if (positionChanged) loopIconSettings.position = newIconSettings.position
            if (visibleChanged || positionChanged) loopIconSettings.visible = newIconSettings.visible
            if (iconSourceChanged) loopIconSettings.iconSource = newIconSettings.iconSource
          }
        }
      }
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "APPEARANCE_CHANGE")
    }
  }

  const handleHarveyStateChange = (newHarveySettings: HarveyBallSettings, range: TableSelectionRange | undefined) => {
    if (elementContent && relRndProps.currentSlideId && !_.isEqual(currentCell?.harveyBallSettings, newHarveySettings) && currentCell && activeItem) {
      if (range === undefined) range = { startRow: activeItem.rIdx, startCol: activeItem.cIdx, endRow: activeItem.rIdx, endCol: activeItem.cIdx }
      const sizeChanged = currentCell?.harveyBallSettings?.size !== newHarveySettings.size
      const positionChanged = currentCell?.harveyBallSettings?.position !== newHarveySettings.position
      const visibleChanged = currentCell?.harveyBallSettings?.visible !== newHarveySettings.visible
      const valueChanged = currentCell?.harveyBallSettings?.value !== newHarveySettings.value
      let newContent = _.cloneDeep(elementContent)
      for (let rIdx = range.startRow; rIdx <= range.endRow; rIdx++) {
        for (let cIdx = range.startCol; cIdx <= range.endCol; cIdx++) {
          if (!newContent.rows[rIdx][cIdx].harveyBallSettings) newContent.rows[rIdx][cIdx].harveyBallSettings = { ...newHarveySettings, visible: false }
          let loopHarveySettings = newContent.rows[rIdx][cIdx].harveyBallSettings
          if (loopHarveySettings) {
            if (!loopHarveySettings) loopHarveySettings = { ...newHarveySettings }
            if (sizeChanged) loopHarveySettings.size = newHarveySettings.size
            if (positionChanged) loopHarveySettings.position = newHarveySettings.position
            if (visibleChanged || positionChanged) loopHarveySettings.visible = newHarveySettings.visible
            if (valueChanged) loopHarveySettings.value = newHarveySettings.value
          }
        }
      }
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "APPEARANCE_CHANGE")
    }
  }

  const handleProgressStateChange = (newProgressSettings: ProgressBarSettings, range: TableSelectionRange | undefined) => {
    if (elementContent && relRndProps.currentSlideId && !_.isEqual(currentCell?.progressBarSettings, newProgressSettings) && currentCell && activeItem) {
      if (range === undefined) range = { startRow: activeItem.rIdx, startCol: activeItem.cIdx, endRow: activeItem.rIdx, endCol: activeItem.cIdx }
      const sizeChanged = currentCell?.progressBarSettings?.size !== newProgressSettings.size
      const positionChanged = currentCell?.progressBarSettings?.position !== newProgressSettings.position
      const visibleChanged = currentCell?.progressBarSettings?.visible !== newProgressSettings.visible
      const valueChanged = currentCell?.progressBarSettings?.value !== newProgressSettings.value
      const segmentsChanged = currentCell?.progressBarSettings?.segments !== newProgressSettings.segments
      let newContent = _.cloneDeep(elementContent)
      for (let rIdx = range.startRow; rIdx <= range.endRow; rIdx++) {
        for (let cIdx = range.startCol; cIdx <= range.endCol; cIdx++) {
          if (!newContent.rows[rIdx][cIdx].progressBarSettings) newContent.rows[rIdx][cIdx].progressBarSettings = { ...newProgressSettings, visible: false }
          let loopProgressSettings = newContent.rows[rIdx][cIdx].progressBarSettings
          if (loopProgressSettings) {
            if (!loopProgressSettings) loopProgressSettings = { ...newProgressSettings }
            if (sizeChanged) loopProgressSettings.size = newProgressSettings.size
            if (positionChanged) loopProgressSettings.position = newProgressSettings.position
            if (visibleChanged || positionChanged) loopProgressSettings.visible = newProgressSettings.visible
            if (valueChanged) loopProgressSettings.value = newProgressSettings.value
            if (segmentsChanged) loopProgressSettings.segments = newProgressSettings.segments
          }
        }
      }
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "APPEARANCE_CHANGE")
    }
  }

  const handleColorStateChange = (newColorSettings: ColorStateSettings, range: TableSelectionRange | undefined) => {
    if (elementContent && relRndProps.currentSlideId && !_.isEqual(currentCell?.colorStateSettings, newColorSettings) && currentCell && activeItem) {
      if (range === undefined) range = { startRow: activeItem.rIdx, startCol: activeItem.cIdx, endRow: activeItem.rIdx, endCol: activeItem.cIdx }
      const sizeChanged = currentCell?.colorStateSettings?.size !== newColorSettings.size
      const positionChanged = currentCell?.colorStateSettings?.position !== newColorSettings.position
      const visibleChanged = currentCell?.colorStateSettings?.visible !== newColorSettings.visible
      const colorChanged = currentCell?.colorStateSettings?.color !== newColorSettings.color
      let newContent = _.cloneDeep(elementContent)
      for (let rIdx = range.startRow; rIdx <= range.endRow; rIdx++) {
        for (let cIdx = range.startCol; cIdx <= range.endCol; cIdx++) {
          if (!newContent.rows[rIdx][cIdx].colorStateSettings) newContent.rows[rIdx][cIdx].colorStateSettings = { ...newColorSettings, visible: false }
          let loopColorStateSettings = newContent.rows[rIdx][cIdx].colorStateSettings
          if (loopColorStateSettings) {
            if (!loopColorStateSettings) loopColorStateSettings = { ...newColorSettings }
            if (sizeChanged) loopColorStateSettings.size = newColorSettings.size
            if (positionChanged) loopColorStateSettings.position = newColorSettings.position
            if (visibleChanged || positionChanged) loopColorStateSettings.visible = newColorSettings.visible
            if (colorChanged) loopColorStateSettings.color = newColorSettings.color
          }
        }
      }
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "APPEARANCE_CHANGE")
    }
  }

  const handleCheckMarkChange = (newCheckMarkSettings: CheckMarkSettings, range: TableSelectionRange | undefined) => {
    if (elementContent && relRndProps.currentSlideId && !_.isEqual(currentCell?.checkMarkSettings, newCheckMarkSettings) && currentCell && activeItem) {
      if (range === undefined) range = { startRow: activeItem.rIdx, startCol: activeItem.cIdx, endRow: activeItem.rIdx, endCol: activeItem.cIdx }
      const sizeChanged = currentCell?.checkMarkSettings?.size !== newCheckMarkSettings.size
      const positionChanged = currentCell?.checkMarkSettings?.position !== newCheckMarkSettings.position
      const visibleChanged = currentCell?.checkMarkSettings?.visible !== newCheckMarkSettings.visible
      const checkedChanged = currentCell?.checkMarkSettings?.checked !== newCheckMarkSettings.checked
      let newContent = _.cloneDeep(elementContent)
      for (let rIdx = range.startRow; rIdx <= range.endRow; rIdx++) {
        for (let cIdx = range.startCol; cIdx <= range.endCol; cIdx++) {
          if (!newContent.rows[rIdx][cIdx].checkMarkSettings) newContent.rows[rIdx][cIdx].checkMarkSettings = { ...newCheckMarkSettings, visible: false }
          let loopCheckMarkSettings = newContent.rows[rIdx][cIdx].checkMarkSettings
          if (loopCheckMarkSettings) {
            if (sizeChanged) loopCheckMarkSettings.size = newCheckMarkSettings.size
            if (positionChanged) loopCheckMarkSettings.position = newCheckMarkSettings.position
            if (visibleChanged || positionChanged) loopCheckMarkSettings.visible = newCheckMarkSettings.visible
            if (checkedChanged) loopCheckMarkSettings.checked = newCheckMarkSettings.checked
          }
        }
      }
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "APPEARANCE_CHANGE")
    }
  }

  const handleTableCellSettingsChange = (newCell: TableCell, range: TableSelectionRange | undefined) => {
    if (elementContent && relRndProps.currentSlideId && !_.isEqual(currentCell, newCell) && currentCell && activeItem) {
      if (range === undefined) range = { startRow: activeItem.rIdx, startCol: activeItem.cIdx, endRow: activeItem.rIdx, endCol: activeItem.cIdx }
      const cellVisibleChanged = currentCell.cellVisible !== newCell.cellVisible
      const rowspanChanged = currentCell?.rowspan !== newCell.rowspan
      const colspanChanged = currentCell?.colspan !== newCell.colspan
      const regionChanged = currentCell?.tableRegion !== newCell.tableRegion
      const textVisibleChanged = currentCell?.textSettings.visible !== newCell.textSettings.visible
      let newContent = _.cloneDeep(elementContent)
      for (let rIdx = range.startRow; rIdx <= range.endRow; rIdx++) {
        for (let cIdx = range.startCol; cIdx <= range.endCol; cIdx++) {
          let loopCell = newContent.rows[rIdx][cIdx]
          if (cellVisibleChanged) loopCell.cellVisible = newCell.cellVisible
          if (rowspanChanged) loopCell.rowspan = newCell.rowspan
          if (colspanChanged) loopCell.colspan = newCell.colspan
          if (regionChanged) loopCell.tableRegion = newCell.tableRegion
          if (textVisibleChanged) loopCell.textSettings = { ...loopCell.textSettings, visible: newCell.textSettings.visible }
        }
      }
      updateElementContent(relRndProps.currentSlideId, el.elementId, newContent, "APPEARANCE_CHANGE")
    }
  }

  const handleElementTitleTextChange = (newText: Descendant[]) => {
    if (relRndProps.handleElementTitleTextChange) relRndProps.handleElementTitleTextChange(el, newText)
  }

  const elementSpecificPanel = (
    <StyledToggleButtonGroup size="small" arial-label="element specific options">
      <Tooltip title="Paste Excel cells">
        <ToggleButton selected={false} color="primary" onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => { setExcelPasteAnchorEl(event.currentTarget) }} value=""  >
          <RiFileExcel2Line style={{ width: '25px', height: '25px', color: theme.palette.action.active }} />
        </ToggleButton>
      </Tooltip>
      {activeItem &&
        <DropdownButton mainButtonIcon={<RiInsertColumnRight />} mainButtonTooltip='Insert a row or a column' buttonHeight='25px'
          secondaryButtons={[
            { icon: <RiInsertColumnRight />, onClick: () => { addColumn(true) }, tooltip: 'Insert column right of current cell' },
            { icon: <RiInsertColumnLeft />, onClick: () => { addColumn(false) }, tooltip: 'Insert column left of current cell' },
            { icon: <RiInsertRowTop />, onClick: () => { addRow(false) }, tooltip: 'Insert row above current cell' },
            { icon: <RiInsertRowBottom />, onClick: () => { addRow(true) }, tooltip: 'Insert row below current cell' },
          ]} />
      }
      {activeItem &&
        <DropdownButton mainButtonIcon={<RiDeleteColumn />} mainButtonTooltip='Remove a row or a column' buttonHeight='25px'
          secondaryButtons={[
            { icon: <RiDeleteColumn />, onClick: () => { deleteCurrentColumn() }, tooltip: 'Remove current column' },
            { icon: <RiDeleteRow />, onClick: () => { deleteCurrentRow() }, tooltip: 'Remove current row' },]} />
      }
      {activeItem &&
        <Tooltip title="Cell settings">
          <ToggleButton selected={false} color="primary" onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => { setTableCellSettingsPopoverAnchorEl(document.getElementById(currentCellIdentifier)) }} value=""  >
            <MdOutlineSettingsApplications style={{ width: '25px', height: '25px', color: theme.palette.action.active }} />
          </ToggleButton>
        </Tooltip>
      }
    </StyledToggleButtonGroup>
  )


  return (
    <RelRnd {...relRndProps} el={el} elementSelected={elementSelected} elementActive={elementActive} elementSpecificPanel={elementSpecificPanel}>
      <Box sx={{
        width: 1, height: 1, display: "flex", justifyContent: "center", flexWrap: "wrap", alignContent: "flex-start",
        alignItems: "flex-start", position: "relative",

      }}>
        {/* the element title */}
        {el.title?.showTitle && el.title?.richTextBox &&
          <RichTextPopover el={el} relRndProps={relRndProps} richTextBox={el.title?.richTextBox} handleBlur={handleElementTitleTextChange}
            textRole='TITLE' />}

        {elementContent.rows.map((row, rIdx: number) =>
          row.map((cell, cIdx: number) => (
            cell.textSettings.richText &&
            <Box key={String(rIdx) + String(cIdx) + JSON.stringify(cell.textSettings.richText)} >
              <ClickAwayListener onClickAway={() => { }}>
                <Box onClick={() => handleCellActivation({ rIdx: rIdx, cIdx: cIdx }, { rIdx: rIdx, cIdx: cIdx })} >

                  {/* The invisible anchor for the popovers */}
                  <InElementBox el={el} relRndProps={relRndProps} box={cell.box} ><Box sx={{ width: 1, height: 1 }} id={currentCellIdentifierString + String(rIdx) + String(cIdx)}></Box></InElementBox>

                  {/* The skeleton showing the active cell */}
                  {activeItem?.cIdx === cIdx && activeItem.rIdx === rIdx &&
                    <InElementBox el={el} relRndProps={relRndProps} box={cell.box} >
                      <Skeleton variant="rectangular" sx={{
                        width: 1, height: 1,
                        backgroundColor: hexToRgba(theme.palette.background.paper, 0.8),
                        borderRadius: theme.customStyling.relRndBoarderRadius,
                        borderStyle: "solid", borderWidth: "1px",
                        borderColor: theme.palette.text.disabled
                      }}></Skeleton>
                    </InElementBox>}

                  {/* the clickable cell box to open the cell settings */}
                  <InElementBox el={el} relRndProps={relRndProps} box={cell.box} >
                    <Box sx={{ width: 1, height: 1, cursor: 'cell' }}
                      onClick={() => { setTableCellSettingsPopoverAnchorEl(document.getElementById(currentCellIdentifierString + String(rIdx) + String(cIdx))) }}>
                    </Box>
                  </InElementBox>

                  {/* the item text popover */}
                  {cell.textSettings.visible &&
                    <RichTextPopover el={el} relRndProps={relRndProps} richTextBox={{ richText: cell.textSettings.richText, textBox: cell.box }}
                      handleBlur={(newText: Descendant[]) => { handleCellTextChange(newText, { rIdx: rIdx, cIdx: cIdx }) }} clickTargetSize={0.9}
                      textRole={cell.tableRegion ? cell.tableRegion as TextRole : 'BODY1'}
                    />}

                  {/* the clickable icon box to open the settings */}
                  {cell.iconSettings?.visible &&
                    <InElementBox el={el} relRndProps={relRndProps} box={cell.iconSettings.box} >
                      <Box sx={{ width: 1, height: 1, cursor: 'pointer' }} onClick={() => { setTableCellSettingsPopoverAnchorEl(document.getElementById(currentCellIdentifierString + String(rIdx) + String(cIdx))); setCellSettingsTabValue(1) }}></Box>
                    </InElementBox>}

                  {/* the clickable harvey box to open the settings */}
                  {cell.harveyBallSettings?.visible &&
                    <InElementBox el={el} relRndProps={relRndProps} box={cell.harveyBallSettings.box} >
                      <Box sx={{ width: 1, height: 1, cursor: 'pointer' }} onClick={() => { setTableCellSettingsPopoverAnchorEl(document.getElementById(currentCellIdentifierString + String(rIdx) + String(cIdx))); setCellSettingsTabValue(2) }}></Box>
                    </InElementBox>}

                  {/* the clickable progress bar box to open the settings */}
                  {cell.progressBarSettings?.visible &&
                    <InElementBox el={el} relRndProps={relRndProps} box={cell.progressBarSettings.box} >
                      <Box sx={{ width: 1, height: 1, cursor: 'pointer' }} onClick={() => { setTableCellSettingsPopoverAnchorEl(document.getElementById(currentCellIdentifierString + String(rIdx) + String(cIdx))); setCellSettingsTabValue(3) }}></Box>
                    </InElementBox>}

                  {/* the clickable color state box to open the settings */}
                  {cell.colorStateSettings?.visible &&
                    <InElementBox el={el} relRndProps={relRndProps} box={cell.colorStateSettings.box} >
                      <Box sx={{ width: 1, height: 1, cursor: 'pointer' }} onClick={() => { setTableCellSettingsPopoverAnchorEl(document.getElementById(currentCellIdentifierString + String(rIdx) + String(cIdx))); setCellSettingsTabValue(4) }}></Box>
                    </InElementBox>}

                  {/* the clickable check mark box to open the settings */}
                  {cell.checkMarkSettings?.visible &&
                    <InElementBox el={el} relRndProps={relRndProps} box={cell.checkMarkSettings.box} >
                      <Box sx={{ width: 1, height: 1, cursor: 'pointer' }} onClick={() => { setTableCellSettingsPopoverAnchorEl(document.getElementById(currentCellIdentifierString + String(rIdx) + String(cIdx))); setCellSettingsTabValue(5) }}></Box>
                    </InElementBox>}

                </Box>
              </ClickAwayListener>
            </Box>
          )))}
      </Box>

      {/* Excel import */}
      <NoDragPopover open={!!excelPasteAnchorEl} anchorEl={excelPasteAnchorEl} onClose={() => setExcelPasteAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }} >
        <ExcelPastePanel handleNewExcelTable={handleExcelPaste} />
      </NoDragPopover>

      {/* All Table Cell Settings under tabs*/}
      {currentCell &&
        <NoDragPopover open={!!tableCellSettingsPopoverAnchorEl} anchorEl={tableCellSettingsPopoverAnchorEl} onClose={() => setTableCellSettingsPopoverAnchorEl(null)} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 1 }}>
            <Tabs value={cellSettingsTabValue} onChange={handleCellSettingsTabChange} variant="fullWidth">
              <Tooltip title="General cell settings"><Tab icon={<MdOutlineSettingsApplications style={tabIconStyle} />} sx={tabStyle} /></Tooltip>
              <Tooltip title="Icon settings"><Tab icon={<InsertEmoticonIcon style={tabIconStyle} />} sx={tabStyle} /></Tooltip>
              <Tooltip title="Harvey Ball settings"><Tab icon={<BiSolidPieChartAlt style={tabIconStyle} />} sx={tabStyle} /></Tooltip>
              <Tooltip title="Progress Bar settings"><Tab icon={<FaBarsProgress style={tabIconStyle} />} sx={tabStyle} /></Tooltip>
              <Tooltip title="Color State settings"><Tab icon={<TbColorFilter style={tabIconStyle} />} sx={tabStyle} /></Tooltip>
              <Tooltip title="Check Mark settings"><Tab icon={<CheckCircleOutlineIcon style={tabIconStyle} />} sx={tabStyle} /></Tooltip>
            </Tabs>
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', overflow: 'hidden' }}>
            {cellSettingsTabValue === 0 &&
              <Box sx={{ gridColumnStart: 1, gridRowStart: 1 }}>
                <TableCellSettings
                  allowedPositions={['TOPLEFT', 'TOPCENTER', 'TOPRIGHT', 'CENTERLEFT', 'CENTERCENTER', 'CENTERRIGHT', 'BOTTOMLEFT', 'BOTTOMCENTER', 'BOTTOMRIGHT']}
                  allowedSizes={[{ displayName: 'tiny', size: 1 }, { displayName: 'very small', size: 1.5 }, { displayName: 'small', size: 2 },
                  { displayName: 'medium', size: 3 }, { displayName: 'large', size: 4 }]} currentTableCell={currentCell}
                  handleTableCellSettingsChange={handleTableCellSettingsChange} rIdx={activeItem?.rIdx} cIdx={activeItem?.cIdx} colCount={rows[0].length} rowCount={rows.length} />
              </Box>}
            {cellSettingsTabValue === 1 &&
              <Box sx={{ gridColumnStart: 1, gridRowStart: 1 }}>
                <IconSettings
                  allowedPositions={['TOPLEFT', 'TOPCENTER', 'TOPRIGHT', 'CENTERLEFT', 'CENTERCENTER', 'CENTERRIGHT', 'BOTTOMLEFT', 'BOTTOMCENTER', 'BOTTOMRIGHT']}
                  allowedSizes={[{ displayName: 'tiny', size: 1 }, { displayName: 'very small', size: 1.5 }, { displayName: 'small', size: 2 },
                  { displayName: 'medium', size: 3 }, { displayName: 'large', size: 4 }, { displayName: 'very large', size: 6 }]} currentIconState={currentCell?.iconSettings}
                  handleIconStateChange={handleIconStateChange} rIdx={activeItem?.rIdx} cIdx={activeItem?.cIdx} colCount={rows[0].length} rowCount={rows.length} />
              </Box>}
            {cellSettingsTabValue === 2 &&
              <Box sx={{ gridColumnStart: 1, gridRowStart: 1 }}>
                <HarveySettings
                  allowedPositions={['TOPLEFT', 'TOPCENTER', 'TOPRIGHT', 'CENTERLEFT', 'CENTERCENTER', 'CENTERRIGHT', 'BOTTOMLEFT', 'BOTTOMCENTER', 'BOTTOMRIGHT']}
                  allowedSizes={[{ displayName: 'tiny', size: 1 }, { displayName: 'very small', size: 1.5 }, { displayName: 'small', size: 2 },
                  { displayName: 'medium', size: 3 }, { displayName: 'large', size: 4 }, { displayName: 'very large', size: 6 }]} currentHarveyState={currentCell?.harveyBallSettings}
                  handleHarveyStateChange={handleHarveyStateChange} rIdx={activeItem?.rIdx} cIdx={activeItem?.cIdx} colCount={rows[0].length} rowCount={rows.length} />
              </Box>}
            {cellSettingsTabValue === 3 &&
              <Box sx={{ gridColumnStart: 1, gridRowStart: 1 }}>
                <ProgressSettings
                  allowedPositions={['TOPLEFT', 'TOPCENTER', 'TOPRIGHT', 'CENTERLEFT', 'CENTERCENTER', 'CENTERRIGHT', 'BOTTOMLEFT', 'BOTTOMCENTER', 'BOTTOMRIGHT']}
                  allowedSizes={[{ displayName: 'tiny', size: 1 }, { displayName: 'very small', size: 1.5 }, { displayName: 'small', size: 2 },
                  { displayName: 'medium', size: 3 }, { displayName: 'large', size: 4 }, { displayName: 'very large', size: 6 }]} currentProgressState={currentCell?.progressBarSettings}
                  handleProgressStateChange={handleProgressStateChange} rIdx={activeItem?.rIdx} cIdx={activeItem?.cIdx} colCount={rows[0].length} rowCount={rows.length} />
              </Box>}
            {cellSettingsTabValue === 4 &&
              <Box sx={{ gridColumnStart: 1, gridRowStart: 1 }}>
                <ColorStateSettings
                  allowedPositions={['TOPLEFT', 'TOPCENTER', 'TOPRIGHT', 'CENTERLEFT', 'CENTERCENTER', 'CENTERRIGHT', 'BOTTOMLEFT', 'BOTTOMCENTER', 'BOTTOMRIGHT']}
                  allowedSizes={[{ displayName: 'tiny', size: 1 }, { displayName: 'very small', size: 1.5 }, { displayName: 'small', size: 2 },
                  { displayName: 'medium', size: 3 }, { displayName: 'large', size: 4 }, { displayName: 'very large', size: 6 }]} currentColorState={currentCell?.colorStateSettings}
                  handleColorStateChange={handleColorStateChange} rIdx={activeItem?.rIdx} cIdx={activeItem?.cIdx} colCount={rows[0].length} rowCount={rows.length} />
              </Box>}
            {cellSettingsTabValue === 5 &&
              <Box sx={{ gridColumnStart: 1, gridRowStart: 1 }}>
                <CheckMarkSettings
                  allowedPositions={['TOPLEFT', 'TOPCENTER', 'TOPRIGHT', 'CENTERLEFT', 'CENTERCENTER', 'CENTERRIGHT', 'BOTTOMLEFT', 'BOTTOMCENTER', 'BOTTOMRIGHT']}
                  allowedSizes={[{ displayName: 'tiny', size: 1 }, { displayName: 'very small', size: 1.5 }, { displayName: 'small', size: 2 },
                  { displayName: 'medium', size: 3 }, { displayName: 'large', size: 4 }]} currentCheckMarkSettings={currentCell?.checkMarkSettings}
                  handleCheckMarkSettingsChange={handleCheckMarkChange} rIdx={activeItem?.rIdx} cIdx={activeItem?.cIdx} colCount={rows[0].length} rowCount={rows.length} />
              </Box>}
          </Box>
        </NoDragPopover>}

    </RelRnd>
  )
}

export default Table1
