import { createSlice, createSelector } from '@reduxjs/toolkit'
import { RootState } from './store'
import _ from 'lodash'
import { Descendant } from 'slate'
import { initTitleElement } from '../lib/init'


// Type definitions
const initialState: Slides = {}
type SlideActionPayload = { payload: Slides }
type SlideActionSingleSlide = { payload: SlideIndex & { singleSlide: SingleSlide } }
type SlideActionLogicalSlide = { payload: SlideIndex & { logicalSlide: LogicalSlide } }
type SlideActionLogicalSlideWithBump = { payload: SlideIndex & { logicalSlide: LogicalSlide | undefined } }
// type SlideActionAspectRatio = { payload: SlideIndex & { aspectRatio: number } }
// type SlideActionImg = { payload: SlideIndex & { slideImgB64: string } }
type SlideActionElementBox = { payload: SlideElementIndex & { box: ElementBox } & { changeType: LogicalSlideChangeType } }
type SlideActionRemoveElement = { payload: SlideElementIndex }
type SlideActionElementContent = { payload: SlideElementIndex & { content: ElementContent } & { changeType: LogicalSlideChangeType } }
type SlideActionElementTitle = { payload: SlideElementIndex & { title: ElementTitle } & { changeType: LogicalSlideChangeType } }
type SlideActionElementColCon = { payload: SlideElementIndex & { col: number, con: number } }
type SlideActionElementBasicStyle = { payload: SlideElementIndex & { basicStyle: BasicStyle | null } }
type SlideActionElementFramePadding = { payload: SlideElementIndex & { framePadding: number | null } }
type SlideActionAddElement = { payload: SlideElementIndex & { logicalElement: LogicalElement } }
type SlideActionSetElementUserFontFactor = { payload: SlideElementIndex & { userFontFactors: UserFontFactors | undefined } }
type SlideActionRenderDetail = { payload: SlideIndex & { renderDetail: SlideRenderDetail } }
type SlideActionLayout = { payload: SlideIndex & { masterId: string | undefined, layoutId: number } }
type SlideActionTitle = { payload: SlideIndex & { titleRichText: Descendant[] } }

// slice definition
export const slidesSlice = createSlice({
  name: 'slides',
  initialState,
  reducers: {
    setSlides: (state, action: SlideActionPayload) => {
      return action.payload
    },
    setSingleSlide: (state, action: SlideActionSingleSlide) => {
      if (action.payload.slideId) state[action.payload.slideId] = action.payload.singleSlide
    },
    // USE CAREFULLY AND THINK ABOUT THE VERSION HANDLING
    // TODO: change to setLogicalSlideFromBackend with special change type to prevent new rendering
    setLogicalSlide: (state, action: SlideActionLogicalSlide) => {
      if (action.payload.slideId && action.payload.slideId in state) state[action.payload.slideId].logicalSlide = action.payload.logicalSlide
    },
    setLogicalSlideWithVersionBump: (state, action: SlideActionLogicalSlideWithBump) => {
      if (action.payload.slideId && action.payload.slideId in state && action.payload.logicalSlide !== undefined) {
        state[action.payload.slideId].logicalSlide = { ...action.payload.logicalSlide, version: state[action.payload.slideId].logicalSlide.version + 1, lastChangeType: "AUTO_ALIGN" }
      }
    },
    // setSlideAspectRatio: (state, action: SlideActionAspectRatio) => {
    //   state[action.payload.slideId].renderDetail.aspectRatio = action.payload.aspectRatio
    // },
    // setSlideImg: (state, action: SlideActionImg) => {
    //   state[action.payload.slideId].renderDetail.slideImgB64 = action.payload.slideImgB64
    // },
    setSlideRenderDetail: (state, action: SlideActionRenderDetail) => {
      if (action.payload.slideId && action.payload.slideId in state) state[action.payload.slideId].renderDetail = action.payload.renderDetail
    },
    setSlideLayout: (state, action: SlideActionLayout) => {
      if (action.payload.slideId && action.payload.masterId && action.payload.slideId in state) {
        if (!_.isEqual(state[action.payload.slideId].logicalSlide.layoutIdByMaster[action.payload.masterId], action.payload.layoutId)) {
          state[action.payload.slideId].logicalSlide.layoutIdByMaster[action.payload.masterId] = action.payload.layoutId
          state[action.payload.slideId].logicalSlide.version += 1
        }
      }
    },
    setSlideTitle: (state, action: SlideActionTitle) => {
      if (action.payload.slideId && action.payload.slideId in state) {
        let currentTitleElement = state[action.payload.slideId].logicalSlide.titleElement
        if (!currentTitleElement) currentTitleElement = initTitleElement(action.payload.slideId)
        if (!_.isEqual(currentTitleElement?.title?.richTextBox?.richText, action.payload.titleRichText)) {
          state[action.payload.slideId].logicalSlide.titleElement = { ...currentTitleElement, title: { ...currentTitleElement.title, richTextBox: { ...currentTitleElement.title?.richTextBox, richText: action.payload.titleRichText } } }
          state[action.payload.slideId].logicalSlide.version += 1
          state[action.payload.slideId].logicalSlide.lastChangeType = "ELEMENT_CONTENT_TYPING"
        }
      }
    },
    setSlideElementBox: (state, action: SlideActionElementBox) => {
      if (action.payload.slideId && action.payload.slideId in state && action.payload.elementId in state[action.payload.slideId].logicalSlide.rootElement.childElements) {
        const currentBox = state[action.payload.slideId].logicalSlide.rootElement.childElements[action.payload.elementId].box
        if (!_.isEqual(currentBox, action.payload.box)) {
          state[action.payload.slideId].logicalSlide.rootElement.childElements[action.payload.elementId].box = action.payload.box
          state[action.payload.slideId].logicalSlide.version += 1
          state[action.payload.slideId].logicalSlide.lastChangeType = action.payload.changeType
        }
      }
    },
    setSlideElementContent: (state, action: SlideActionElementContent) => {
      if (action.payload.slideId && action.payload.slideId in state && action.payload.elementId in state[action.payload.slideId].logicalSlide.rootElement.childElements) {
        const currentContent = state[action.payload.slideId].logicalSlide.rootElement.childElements[action.payload.elementId].content
        if (!_.isEqual(currentContent, action.payload.content)) {
          state[action.payload.slideId].logicalSlide.rootElement.childElements[action.payload.elementId].content = action.payload.content
          state[action.payload.slideId].logicalSlide.version += 1
          state[action.payload.slideId].logicalSlide.lastChangeType = action.payload.changeType
        }
      }
    },
    setSlideElementTitle: (state, action: SlideActionElementTitle) => {
      if (action.payload.slideId && action.payload.slideId in state && action.payload.elementId in state[action.payload.slideId].logicalSlide.rootElement.childElements) {
        const currentTitle = state[action.payload.slideId].logicalSlide.rootElement.childElements[action.payload.elementId].title
        if (!_.isEqual(currentTitle, action.payload.title)) {
          state[action.payload.slideId].logicalSlide.rootElement.childElements[action.payload.elementId].title = action.payload.title
          state[action.payload.slideId].logicalSlide.version += 1
          state[action.payload.slideId].logicalSlide.lastChangeType = action.payload.changeType
        }
      }
    },
    setSlideElementColCon: (state, action: SlideActionElementColCon) => {
      if (action.payload.slideId && action.payload.slideId in state && action.payload.elementId in state[action.payload.slideId].logicalSlide.rootElement.childElements) {
        const currentApperance = { ...state[action.payload.slideId].logicalSlide.rootElement.childElements[action.payload.elementId].appearance }
        if (currentApperance.color !== action.payload.col || currentApperance.contrast !== action.payload.con) {
          state[action.payload.slideId].logicalSlide.rootElement.childElements[action.payload.elementId].appearance = { ...currentApperance, color: action.payload.col, contrast: action.payload.con }
          state[action.payload.slideId].logicalSlide.version += 1
          state[action.payload.slideId].logicalSlide.lastChangeType = "APPEARANCE_CHANGE"
        }
      }
    },
    setSlideElementBasicStyle: (state, action: SlideActionElementBasicStyle) => {
      if (action.payload.slideId && action.payload.slideId in state && action.payload.elementId in state[action.payload.slideId].logicalSlide.rootElement.childElements) {
        const currentApperance = { ...state[action.payload.slideId].logicalSlide.rootElement.childElements[action.payload.elementId].appearance }
        if (!_.isEqual(currentApperance.basicStyle, action.payload.basicStyle)) {
          state[action.payload.slideId].logicalSlide.rootElement.childElements[action.payload.elementId].appearance = { ...currentApperance, basicStyle: action.payload.basicStyle }
          state[action.payload.slideId].logicalSlide.version += 1
          state[action.payload.slideId].logicalSlide.lastChangeType = "APPEARANCE_CHANGE"
        }
      }
    },
    setSlideElementFramePadding: (state, action: SlideActionElementFramePadding) => {
      if (action.payload.slideId && action.payload.slideId in state && action.payload.elementId in state[action.payload.slideId].logicalSlide.rootElement.childElements) {
        const currentFramePadding = state[action.payload.slideId].logicalSlide.rootElement.childElements[action.payload.elementId].framePadding
        if (currentFramePadding !== action.payload.framePadding) {
          state[action.payload.slideId].logicalSlide.rootElement.childElements[action.payload.elementId].framePadding = action.payload.framePadding
          state[action.payload.slideId].logicalSlide.version += 1
          state[action.payload.slideId].logicalSlide.lastChangeType = "APPEARANCE_CHANGE"
        }
      }
    },
    setSlideElement: (state, action: SlideActionAddElement) => {
      if (action.payload.slideId && action.payload.slideId in state) {
        state[action.payload.slideId].logicalSlide.rootElement.childElements[action.payload.elementId] = action.payload.logicalElement
        state[action.payload.slideId].logicalSlide.version += 1
        state[action.payload.slideId].logicalSlide.lastChangeType = "NEW_ELEMENT"
      }
    },
    setElementFontSizeFactor: (state, action: SlideActionSetElementUserFontFactor) => {
      if (action.payload.slideId && action.payload.slideId in state) {
        //Slide TitleElement?
        if (state[action.payload.slideId].logicalSlide.titleElement && state[action.payload.slideId].logicalSlide.titleElement?.elementId === action.payload.elementId) {
          state[action.payload.slideId].logicalSlide.titleElement!.userFontFactors = action.payload.userFontFactors
          state[action.payload.slideId].logicalSlide.version += 1
          state[action.payload.slideId].logicalSlide.lastChangeType = "USER_FONT_SIZE_CHANGE"
        }
        // Or normalElement
        if (action.payload.elementId in state[action.payload.slideId].logicalSlide.rootElement.childElements) {
          state[action.payload.slideId].logicalSlide.rootElement.childElements[action.payload.elementId].userFontFactors = action.payload.userFontFactors
          state[action.payload.slideId].logicalSlide.version += 1
          state[action.payload.slideId].logicalSlide.lastChangeType = "USER_FONT_SIZE_CHANGE"
        }
      }
    },
    removeSlideElement: (state, action: SlideActionRemoveElement) => {
      if (action.payload.slideId && action.payload.slideId in state && action.payload.elementId in state[action.payload.slideId].logicalSlide.rootElement.childElements) {
        delete state[action.payload.slideId].logicalSlide.rootElement.childElements[action.payload.elementId]
        state[action.payload.slideId].logicalSlide.version += 1
        state[action.payload.slideId].logicalSlide.lastChangeType = "REMOVED_ELEMENT"
      }
    },
  },
})

//actions
export const { setSlides, setSingleSlide, setSlideElementBox, setSlideElementContent, setSlideRenderDetail, setSlideLayout,
  setSlideElement, setSlideTitle, setLogicalSlide, removeSlideElement, setSlideElementColCon, setSlideElementTitle,
  setSlideElementBasicStyle, setSlideElementFramePadding, setLogicalSlideWithVersionBump, setElementFontSizeFactor } = slidesSlice.actions

// selectors
// TODO: Look into correct selector structure for multiple component usage of same selector (https://react-redux.js.org/api/hooks)
export const getSlides = createSelector(
  [(state: RootState) => state.slides],
  (slides) => slides
)
export const getSlide = createSelector(
  [(state: RootState, props: SlideIndex) => state.slides,
  (state: RootState, props: SlideIndex) => props.slideId],
  (slides, slideId) => ((slideId && slideId in slides) ? slides[slideId] : undefined)
)
export const getLogicalSlide = createSelector(
  [(state: RootState, props: SlideIndex) => state.slides,
  (state: RootState, props: SlideIndex) => props.slideId],
  (slides, slideId) => ((slideId && slideId in slides) ? slides[slideId].logicalSlide : undefined)
)
export const getLogicalSlideVersion = createSelector(
  [(state: RootState, props: SlideIndex) => state.slides,
  (state: RootState, props: SlideIndex) => props.slideId],
  (slides, slideId) => ((slideId && slideId in slides) ? slides[slideId].logicalSlide.version : undefined)
)
export const getLogicalSlideLastChangeType = createSelector(
  [(state: RootState, props: SlideIndex) => state.slides,
  (state: RootState, props: SlideIndex) => props.slideId],
  (slides, slideId) => ((slideId && slideId in slides) ? slides[slideId].logicalSlide.lastChangeType : undefined)
)
export const getSlideRenderDetail = createSelector(
  [(state: RootState, props: SlideIndex) => state.slides,
  (state: RootState, props: SlideIndex) => props.slideId],
  (slides, slideId) => ((slideId && slideId in slides) ? slides[slideId].renderDetail : undefined)
)
export const getSlideRenderVersion = createSelector(
  [(state: RootState, props: SlideIndex) => state.slides,
  (state: RootState, props: SlideIndex) => props.slideId],
  (slides, slideId) => ((slideId && slideId in slides) ? slides[slideId].renderDetail.version : undefined)
)
export const getSlideAspectRatio = createSelector(
  [(state: RootState, props: SlideIndex) => state.slides,
  (state: RootState, props: SlideIndex) => props.slideId],
  (slides, slideId) => ((slideId && slideId in slides) ? slides[slideId].renderDetail.aspectRatio : undefined)
)
export const getSlideElement = createSelector(
  [(state: RootState, props: SlideElementIndex) => state.slides,
  (state: RootState, props: SlideElementIndex) => props.slideId,
  (state: RootState, props: SlideElementIndex) => props.elementId],
  (slides, slideId, elementId) => ((slideId && slideId in slides) ? slides[slideId].logicalSlide.rootElement.childElements[elementId] : undefined)
)
export const getSlideElementContent = createSelector(
  [(state: RootState, props: SlideElementIndex) => state.slides,
  (state: RootState, props: SlideElementIndex) => props.slideId,
  (state: RootState, props: SlideElementIndex) => props.elementId],
  (slides, slideId, elementId) => ((slideId && slideId in slides) ? slides[slideId].logicalSlide.rootElement.childElements[elementId].content : undefined)
)
export const getSlideElementBox = createSelector(
  [(state: RootState, props: SlideElementIndex) => state.slides,
  (state: RootState, props: SlideElementIndex) => props.slideId,
  (state: RootState, props: SlideElementIndex) => props.elementId],
  (slides, slideId, elementId) => ((slideId && slideId in slides) ? slides[slideId].logicalSlide.rootElement.childElements[elementId].box : undefined)
)

export default slidesSlice.reducer
